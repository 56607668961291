import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from 'lodash';
import deepmerge from 'deepmerge';

import config from 'configuration';

import { useChatbot } from './ChatbotProvider';

import {
  createApiInput,
  createInitialContext,
  formatMessage,
  getGoTo,
  getNextStep,
  isAction,
  isCondition,
  isQuestion,
  validateDatasheet,
  isSegment,
} from 'utils/quoteUtils';

import quoteReducer, { context } from 'reducers/quoteReducer';
import { actions } from 'actions/quoteActions';
import useReducer from 'hooks/useReducer';
import { IContext, IDatasheet } from 'types/quote';
import { IUser } from 'types/user';

import { yesno } from 'shared/utils/createQuestion';
import {
  ActionStep,
  ApiAction,
  ApiActionStrategyEnum,
  ApiActionTypeEnum,
  ConditionStep,
  ForkActionTypeEnum,
  Question,
  QuestionStep,
  SegmentStep,
  StatementQuestionTypeEnum,
  Step,
} from '@inshur/apis/steps/api';
import EventManager, { EventType } from '../utils/EventManager';
import { useLocale } from './LocaleProvider';
import { useHistory } from 'react-router-dom';
import { IPolicyKey } from 'shared/graphql/api/types';
import { getValueAtPath, setValueAtPath } from 'utils/contextUtils';
import * as Sentry from '@sentry/react';
import DevTools from '../components/Editor/DevTools';
import { IApiCallVariables, useCallApiHandler } from './CallApiHandlerProvider';
import { QuoteType } from './TrackingProvider';
import withProductSteps from '../hoc/withProductSteps';
import { LoadedProductHistory } from '../hooks';

const debug = require('debug')('customer-web:quote');

interface IAnswerOptions {
  track?: boolean;
}

export type AnswerQuestion<T = any> = (
  response: string,
  value?: T,
  options?: IAnswerOptions
) => void;

export interface IQuoteContext {
  $: IContext;
  productHistory: LoadedProductHistory;
  currentQuestion?: Question;
  answerQuestion: AnswerQuestion;
  jumpTo(id: string): void;
  showInput: boolean;
  showInputDelay: number | undefined;
  renewalOf?: IPolicyKey;
  mtaOf?: IPolicyKey;
  stepId?: string;
  isApiLoading: boolean;
  undo(snapshotVersion: string, id: string): void;
  isDatasetChanged: boolean;
  quoteType: QuoteType;
  updateDataSheet(datasheet: IDatasheet): void;
  progress: number;
}

export interface IChatbotConfiguration {
  name: string;
}

export const QuoteContext = createContext<IQuoteContext>({
  $: {} as IContext,
  productHistory: {
    initial: {} as any,
    current: {} as any,
    latest: {} as any,
  },
  currentQuestion: undefined,
  showInput: false,
  showInputDelay: undefined,
  answerQuestion: noop,
  jumpTo: noop,
  stepId: undefined,
  isApiLoading: false,
  undo: noop,
  isDatasetChanged: false,
  quoteType: undefined as any,
  updateDataSheet: noop,
  progress: 0,
});

export const usePreQuote = () => useContext(QuoteContext);

interface Props {
  configuration?: IChatbotConfiguration;
  productHistory: LoadedProductHistory;
  user: IUser;
  initialValue?: any;
  renewalOf?: IPolicyKey;
  mtaOf?: IPolicyKey;
  steps?: any[];
  quoteType: QuoteType;
}

function QuoteProvider({
  configuration = { name: 'Ami' },
  productHistory,
  renewalOf,
  mtaOf,
  user,
  initialValue,
  steps = [],
  children,
  quoteType,
}: PropsWithChildren<Props>) {
  const { lang: locale } = useLocale();
  const history = useHistory();
  const { current: product } = productHistory;
  const { schema } = product;
  const [timer, setTimer] = useState<number>();
  const [v, setSnapshotVersion] = useState(1);
  const snapshotVersion = `v${v}`;
  const [isApiLoading, setApiLoading] = useState(false);
  const { send, remove, getMessageDelay } = useChatbot();
  const utm = JSON.parse(localStorage.getItem('utm') || '{ }');
  const initial = {
    steps,
    $: createInitialContext(schema, user, utm, initialValue),
    showInput: false,
    currentStep: steps[0],
    isUpdatedOnce: false,
    snapshotVersions: [
      {
        id: snapshotVersion,
        context: createInitialContext(schema, user, utm, initialValue),
      },
    ],
  };
  const [state, dispatch] = useReducer(quoteReducer, initial, actions);
  const { currentStep, showInput, delay } = state;
  const $ = context(state);
  const callApiHandler = useCallApiHandler();

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'quote',
      message: `${product?.code}: ${quoteType}`,
      level: Sentry.Severity.Info,
    });
  }, [product, quoteType]);

  useEffect(() => {
    if (currentStep?.id) {
      setSnapshotVersion(v + 1);

      handleStep(currentStep);

      Sentry.addBreadcrumb({
        category: 'step',
        message: currentStep?.id,
        level: Sentry.Severity.Info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep?.id]);

  useEffect(() => {
    debug('snapshotVersion', snapshotVersion);
    debug('state', state);
  }, [state, snapshotVersion]);

  useEffect(() => {
    debug('context', $);

    validateDatasheet($.datasheet, product?.schema);
  }, [$, product]);

  useEffect(() => {
    // @ts-ignore
    window.jumpTo = jumpTo;

    // @ts-ignore
    window.dispatch = dispatch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDatasetChanged = useMemo(() => {
    return JSON.stringify($.datasheet) !== JSON.stringify(initialValue);
  }, [$.datasheet, initialValue]);

  function undo(snapshotVersion: string, id: string) {
    debug('undo()', snapshotVersion, id);
    dispatch.undo(snapshotVersion);
    remove(snapshotVersion);
    const step = steps.find((q) => q.id === id)!;
    debug('undo() - step', step);
    dispatch.setStep(step);
  }

  function goToNext(current: Step, value?: string) {
    // Find the next step
    const step = getNextStep(steps, current, product.id, quoteType, value);

    // If there isn't one, we're at the end
    if (!step) {
      dispatch.toggleInput(false);

      switch (getGoTo(current, value)) {
        case 'GO_TO_DASHBOARD':
          return history.push(`/?lang=${locale}`);
        case 'GO_TO_PRODUCT':
          return history.push(`/product/${product?.id}?lang=${locale}`);
        default:
          return;
      }
    }

    dispatch.setStep(step);
  }

  async function handleStep(step: Step) {
    debug('handleStep()', step.id);

    if (isCondition(step)) {
      return handleCondition(step);
    }

    if (isAction(step)) {
      return handleAction(step);
    }

    if (isSegment(step)) {
      return handleSegment(step);
    }

    return handleQuestion(step);
  }

  async function handleSegment(step: SegmentStep) {
    return goToNext(step);
  }

  async function handleAction(step: ActionStep) {
    debug('handleAction()', step.id);

    if (step.action.type === ForkActionTypeEnum.fork) {
      const value = getValueAtPath({ $ }, step.action.path);
      goToNext(step, value);
      return;
    }

    if (step.action.type === ApiActionTypeEnum.api) {
      let nextStep = undefined;
      setApiLoading(true);

      try {
        const apiActionSuccess = await handleApiAction($, step.action);
        if (apiActionSuccess) {
          nextStep = steps.find(
            (q) => q.id === (step.action as ApiAction).onSuccessGoTo
          );
        } else {
          nextStep = steps.find(
            (q) => q.id === (step.action as ApiAction).onFailureGoTo
          );
        }
      } catch (e) {
        Sentry.captureException(e);
        debug(
          `ERROR: Api action: ${(step.action as ApiAction).handler} failed!  `,
          e
        );
        if (!nextStep) {
          nextStep = steps.find(
            (q) => q.id === (step.action as ApiAction).onFailureGoTo
          );
        }
      } finally {
        setApiLoading(false);

        if (nextStep) {
          dispatch.setStep(nextStep);
        }
      }

      return;
    }

    // Process the action
    dispatch.processAction(snapshotVersion, step.action);
    goToNext(step);
  }

  const handleApiAction = async (context: IContext, action: ApiAction) => {
    const { handler, params, path, strategy } = action;

    const input = createApiInput($, params);
    const variables = { input } as IApiCallVariables;

    const response = await callApiHandler(handler, variables);

    if (!response) {
      debug('callApi no response');

      return false;
    }

    debug('callApi response', response);

    if (!response.success || !response.data) {
      debug('callApi failed', response.error);

      return false;
    }

    if (strategy === ApiActionStrategyEnum.set_single) {
      const { $ } = setValueAtPath({ $: context }, path, response.data);
      dispatch.updateDatasheet(snapshotVersion, $.datasheet);
      dispatch.updateContext($);
    }

    if (strategy === ApiActionStrategyEnum.merge) {
      const originalValue = getValueAtPath({ $: context }, path);
      const merged = deepmerge(originalValue, response.data);
      const { $ } = setValueAtPath({ $: context }, path, merged);

      dispatch.updateDatasheet(snapshotVersion, $.datasheet);
      dispatch.updateContext($);
    }

    return true;
  };

  async function handleQuestion(step: QuestionStep) {
    const { question } = step;

    // Convert question to message
    const message = formatMessage(question.text, $, schema, locale);

    const delay = getMessageDelay(message);

    // Send a message to the user
    send(message, configuration.name, true, {
      questionId: step.id,
      snapshotVersion,
    });

    // If this question is a statement, wait and then move to the next question
    if (question.type === StatementQuestionTypeEnum.statement) {
      setTimer(window.setTimeout(() => goToNext(step), delay));
      return;
    }

    dispatch.toggleInput(true, delay);
  }

  async function handleCondition(step: ConditionStep) {
    if ('$empty' in step.condition.if && step.condition.if.$empty) {
      const value = getValueAtPath({ $ }, step.condition.if.$empty);
      goToNext(step, value);
    }

    if ('$equals' in step.condition.if && step.condition.if.$equals) {
      const value = getValueAtPath({ $ }, step.condition.if.$equals.path!);
      goToNext(step, value);
    }

    if ('$before' in step.condition.if && step.condition.if.$before) {
      const value = getValueAtPath({ $ }, step.condition.if.$before.path!);
      goToNext(step, value);
    }

    if ('$after' in step.condition.if && step.condition.if.$after) {
      const value = getValueAtPath({ $ }, step.condition.if.$after.path!);
      goToNext(step, value);
    }

    if ('$greater' in step.condition.if && step.condition.if.$greater) {
      const value = getValueAtPath({ $ }, step.condition.if.$greater.path!);
      goToNext(step, value);
    }

    if ('$lessThan' in step.condition.if && step.condition.if.$lessThan) {
      const value = getValueAtPath({ $ }, step.condition.if.$lessThan.path!);
      goToNext(step, value);
    }
  }

  function answerQuestion(
    response: string,
    value?: any,
    options?: IAnswerOptions
  ) {
    // Get the answer value
    const answer =
      (value && (typeof value == 'string' ? value.trim() : value)) ?? response;

    // Send the response to the chat
    send(response, user.firstName ?? '', false, {
      questionId: currentStep?.id,
      snapshotVersion,
    });

    // Disable the user input
    dispatch.toggleInput(false);

    if (!currentStep) {
      return;
    }

    if (isQuestion(currentStep)) {
      const { gtmId } = currentStep.question;

      if (options?.track !== false) {
        // Track question answer
        EventManager.track({
          event: EventType.QuestionAnswered,
          questionId: gtmId ?? currentStep.id,
          quoteType,
          productId: product?.id,
        });
      }
    }

    dispatch.answerQuestion(snapshotVersion, currentStep.id, answer);

    setTimeout(() => goToNext(currentStep, response), getMessageDelay(''));
  }

  function jumpTo(id: string, force?: boolean) {
    const step = steps.find((q) => q.id === id);

    if (!step) {
      return;
    }

    clearTimeout(timer);

    if (force) {
      dispatch.setStep(step);

      return;
    }

    dispatch.toggleInput(false);

    const back = yesno('go-back', 'Are you sure you want to go back?', [
      id,
      currentStep!.id,
    ]);

    dispatch.setStep(back);
  }

  const updateDataSheet = (datasheet: IDatasheet) => {
    dispatch.updateDatasheet(snapshotVersion, datasheet);
  };

  const currentQuestion =
    currentStep && isQuestion(currentStep) ? currentStep.question : undefined;

  const currentStepIndex: number = useMemo(
    () => steps.findIndex((s) => s.id === currentStep?.id),
    [steps, currentStep]
  );

  const progress: number = useMemo(
    () => (currentStepIndex / (steps.length - 1)) * 100,
    [steps, currentStepIndex]
  );

  return (
    <QuoteContext.Provider
      value={{
        updateDataSheet,
        currentQuestion,
        answerQuestion,
        showInput,
        showInputDelay: delay,
        jumpTo,
        $,
        productHistory,
        renewalOf,
        mtaOf,
        stepId: currentStep?.id,
        isApiLoading,
        undo,
        isDatasetChanged,
        quoteType,
        progress,
      }}
    >
      {children}

      {config.enableDevTools && (
        <DevTools
          id={product?.code}
          json={$.datasheet}
          onChange={(datasheet: IDatasheet) =>
            dispatch.updateDatasheet(snapshotVersion, datasheet)
          }
          steps={steps}
          jumpTo={jumpTo}
        />
      )}
    </QuoteContext.Provider>
  );
}

export default withProductSteps(QuoteProvider);
