import React from 'react';
import './OpenQuote.scss';
import './OpenQuoteGroup.scss';
import String from '../String/String';
import OpenQuoteDuration, { QuoteDuration } from './OpenQuoteDuration';
import {
  IProductDetailsFragment,
  IQuoteDetailsFragment,
} from 'shared/graphql/api/types';
import { useLocale } from 'providers/LocaleProvider';
import {
  getLowestFinancePlanPrices,
  getLowestInstalmentPlanPrices,
  adjustedQuoteStartDate,
  getValuationRange,
  findFinancePaymentPlan,
  findInstalmentsPaymentPlan,
} from 'utils/quoteUtils';
import DateFormat from 'components/Date/Date';
import { daysBetween } from 'shared/utils/dateUtils';
import classNames from 'classnames';
interface Props {
  quoteGroup: IQuoteDetailsFragment[];
  product?: IProductDetailsFragment;
}

function OpenQuoteGroup({ quoteGroup, product }: Props) {
  const { locale } = useLocale();
  const { datasheet } = quoteGroup?.[0];

  const formattedStartDate = quoteGroup?.[0] && (
    <DateFormat
      date={adjustedQuoteStartDate(quoteGroup?.[0])}
      timeZone={product?.timezone}
      locale={locale}
    />
  );
  const daysUntilStart = daysBetween(
    new Date(new Date().setHours(0, 0, 0, 0)),
    adjustedQuoteStartDate(quoteGroup?.[0])
  );

  return (
    <div className={classNames('OpenQuote', 'OpenQuoteGroup')}>
      <div className="OpenQuote-wrapper">
        <p
          data-testid="open-quote-group-vehicle"
          className="OpenQuote-section OpenQuote-vehicle"
        >{`${datasheet.vehicles?.[0].make} ${datasheet.vehicles?.[0].model}`}</p>
        <p className="OpenQuote-section OpenQuote-date-title">
          <String id="pages.product.dashboard.start_date" />
        </p>
        <p
          data-testid="open-quote-group-startdate"
          className="OpenQuote-section OpenQuote-date"
        >
          {formattedStartDate}{' '}
          <span className="OpenQuoteGroup-days-until">
            (
            <String
              id="pages.product.open_quote.days_until_start"
              values={{ itemCount: daysUntilStart }}
            />
            )
          </span>
        </p>
        {quoteGroup.map((quote) => {
          const { packages, quoteNumber, id, datasheet, renewalOf } = quote;
          const valuationRange = getValuationRange(packages, locale, product!);
          let prices = undefined;

          const hasInstalmentPlan = packages.some(findInstalmentsPaymentPlan);
          const hasFinancePlan = packages.some(findFinancePaymentPlan);

          if (hasInstalmentPlan) {
            prices = getLowestInstalmentPlanPrices(packages);
          }

          if (hasFinancePlan) {
            prices = getLowestFinancePlanPrices(packages);
          }

          return (
            <OpenQuoteDuration
              key={id}
              quoteId={id}
              quoteNumber={quoteNumber}
              duration={datasheet.policy.duration}
              price={valuationRange}
              prices={prices}
              showInstalments={hasInstalmentPlan}
              showFinance={
                hasFinancePlan &&
                quote.datasheet.policy.duration === QuoteDuration.DAYS_365
              }
              renewal={!!renewalOf?.id}
              quoteStatus={quote.status}
              currency={product?.currency ?? 'gbp'}
            />
          );
        })}
      </div>
    </div>
  );
}
export default OpenQuoteGroup;
