export enum ErrorCodes {
  // AUTHENTICATION ERROR
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  NO_USER = 'NO_USER',

  // PRODUCT
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  PRODUCT_INVALID_STEPS = 'PRODUCT_INVALID_STEPS',
  PRODUCT_ERROR = 'PRODUCT_ERROR',

  // QUOTE
  QUOTE_OVERVIEW_NO_QUOTE = 'QUOTE_OVERVIEW_NO_QUOTE',

  // USER
  USER_NOT_FOUND = 'USER_NOT_FOUND',

  // PAYMENT
  PAYMENT_NO_QUOTE = 'PAYMENT_NO_QUOTE',
  PAYMENT_NO_PACKAGE = 'PAYMENT_NO_PACKAGE',
  PAYMENT_NO_PACKAGE_VALUATION = 'PAYMENT_NO_PACKAGE_VALUATION',
  PAYMENT_CONFIRMATION_ERROR = 'PAYMENT_CONFIRMATION_ERROR',
  PAYMENT_NO_VEHICLE = 'PAYMENT_NO_VEHICLE',
  PAYMENT_UNSUCCESSFUL = 'PAYMENT_UNSUCCESSFUL',
  PAYMENT_NO_REDIRECT_URL = 'PAYMENT_NO_REDIRECT_URL',
  PAYMENT_NO_INTENT = 'PAYMENT_NO_INTENT',
  PAYMENT_PREMIUM_FINANCE_UNAVAILABLE = 'PAYMENT_PREMIUM_FINANCE_UNAVAILABLE',
  PAYMENT_PREMIUM_FINANCE_UNABLE_TO_CREATE_LOAN_APPLICATION = 'PAYMENT_PREMIUM_FINANCE_UNABLE_TO_CREATE_LOAN_APPLICATION',

  // SERVER
  SERVER_ERROR = 'SERVER_ERROR',

  // CLIENT
  ASSERTION_ERROR = 'ASSERTION_ERROR',

  // POLICY
  POLICY_NOT_FOUND = 'POLICY_NOT_FOUND',

  // LOQATE
  LOQATE_OUT_OF_CREDITS = 'LOQATE_OUT_OF_CREDITS',

  // MYLICENCE
  MYLICENCE_ERROR = 'MYLICENCE_ERROR',

  // VEHICLE
  VEHICLE_ERROR = 'VEHICLE_ERROR',

  // DATE_INPUT
  DATE_INPUT_ERROR = 'DATE_INPUT_ERROR',

  FAILED_TO_FETCH_FROM_CUSTOMER_API = 'FAILED_TO_FETCH_FROM_CUSTOMER_API',
  USER_ID_NOT_PRESENT = 'USER_ID_NOT_PRESENT',
  WALLET_DOESNT_EXIST = 'WALLET_DOESNT_EXIST',
}

export class ApplicationError extends Error {
  public productId?: string;

  constructor(name: string, public code: ErrorCodes, message?: string) {
    super();
    this.name = name;
    this.message = [code, message].filter(Boolean).join(' ');
  }
}

export class AuthenticationError extends ApplicationError {
  constructor(code: ErrorCodes, message?: string) {
    super('Authentication Error', code, message);
  }
}

export class ProductError extends ApplicationError {
  constructor(public productId: string, code: ErrorCodes, message?: string) {
    super('Product Error', code, message);
  }
}

export class PaymentError extends ApplicationError {
  constructor(public productId: string, code: ErrorCodes, message?: string) {
    super('Payment Error', code, message);
  }
}

export class PaymentFormError extends ApplicationError {
  constructor(code: ErrorCodes, message?: string) {
    super('Payment Error', code, message);
  }
}

export class QuoteError extends ApplicationError {
  constructor(code: ErrorCodes, message?: string) {
    super('Quote Error', code, message);
  }
}

export class GraphQLError extends Error {
  name = 'GraphQL Error';

  constructor(public message: string) {
    super(message);
  }
}

export class PremiumFinanceError extends ApplicationError {
  constructor() {
    super(
      'Premium Finance',
      ErrorCodes.PAYMENT_PREMIUM_FINANCE_UNABLE_TO_CREATE_LOAN_APPLICATION,
      'Problem fetching premium finance information from api'
    );
  }
}

export class UserIdNotPresent extends ApplicationError {
  constructor(message: string) {
    super('UserId Not Present', ErrorCodes.USER_ID_NOT_PRESENT, message);
  }

  static from(message: string) {
    return new UserIdNotPresent(message);
  }
}

export class FailedToFetchFromCustomerApi extends ApplicationError {
  constructor(
    readonly productId: string,
    readonly userId: string,
    readonly queryCode: string,
    readonly cause: Error
  ) {
    super(
      'Failed To Fetch From Customer API',
      ErrorCodes.FAILED_TO_FETCH_FROM_CUSTOMER_API,
      'Could not fetch successfully from customer-api'
    );
  }

  static from(
    productId: string,
    userId: string,
    queryCode: string,
    cause: Error
  ) {
    return new FailedToFetchFromCustomerApi(
      productId,
      userId,
      queryCode,
      cause
    );
  }
}

export class WalletDoesntExist extends ApplicationError {
  constructor(readonly productId: string, readonly userId: string) {
    super(
      "Wallet Doesn't Exist",
      ErrorCodes.WALLET_DOESNT_EXIST,
      'Customer wallet is returning null'
    );
  }

  static from(productId: string, userId: string) {
    return new WalletDoesntExist(productId, userId);
  }
}
