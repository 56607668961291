import './ProgressBarPercent.scss';

interface Props {
  progress: number;
}

const ProgressBarPercent = ({ progress }: Props) => {
  return (
    <div className="ProgressBarPercent">
      <div className="ProgressBarPercent-container">
        <div
          className="ProgressBarPercent-filler"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBarPercent;
