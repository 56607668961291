import React from 'react';
import { useModal } from 'providers/ModalProvider';
import String from 'components/String/String';
import TRANSLATIONS from 'constants/translations';

import './ContactUs.scss';

interface Props {
  dataTestId?: string;
  onClick?: () => any;
}

function ContactUsButton({ dataTestId, onClick }: Props) {
  const { display, dismiss } = useModal();
  return (
    <button
      className="ContactUsButton"
      data-testid={dataTestId}
      onClick={(e) => {
        e.preventDefault();
        display('contact', {
          onCancel: dismiss,
        });
        if (onClick !== undefined) {
          onClick();
        }
      }}
    >
      <String
        id="pages.error.contact_us"
        defaultValue={TRANSLATIONS.en['pages.error.contact_us']}
      />
    </button>
  );
}

export default ContactUsButton;
