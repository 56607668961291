import React from 'react';

import { IModal, useModal } from 'providers/ModalProvider';

import ContactModal from './ContactModal/ContactModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import InfoModal from './InfoModal/InfoModal';
import LoadingModal from './LoadingModal/LoadingModal';
import ClaimsContactModal from './ClaimsContactModal/ClaimsContactModal';
import MtaSelfServeModal from './MtaSelfServeModal/MtaSelfServeModal';
import VehicleSearchModal from './VehicleSearchModal/VehicleSearchModal';
import ExistingMtaModal from './MtaExistsModal/ExistingMtaModal';
import ResponseEditModal from './ResponseEditModal/ResponseEditModal';
import DocumentsModal from './DocumentsModal/DocumentsModal';
import PaymentScheduleModal from './PaymentScheduleModal/PaymentScheduleModal';
import RenewalTrackingModal from './RenewalModal/RenewalTrackingModal';
import CancelPolicyModal from './CancelPolicyModal/CancelPolicyModal';

const MODALS: Record<IModal, React.ElementType> = {
  contact: ContactModal,
  info: InfoModal,
  restart_confirmation: ConfirmationModal,
  logout_confirmation: ConfirmationModal,
  mta_terms: ConfirmationModal,
  claims: ClaimsContactModal,
  loading: LoadingModal,
  mta_self_serve: MtaSelfServeModal,
  vehicle_search_modal: VehicleSearchModal,
  leave_page_confirmation: ConfirmationModal,
  edit_response_confirmation: ResponseEditModal,
  existing_mta: ExistingMtaModal,
  documents: DocumentsModal,
  payment_schedule: PaymentScheduleModal,
  my_renewal: RenewalTrackingModal,
  cancel_policy: CancelPolicyModal,
};

function ModalRoot() {
  const { current } = useModal();

  if (!current) {
    return null;
  }

  const Modal = MODALS[current.type];

  if (!Modal) {
    return null;
  }

  return <Modal {...current.props} />;
}

export default ModalRoot;
