import { IInvoice } from 'shared/graphql/api/types';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import Link from '../../../../../components/Router/Link';
import String from 'components/String/String';
import './DashboardNotifications.scss';
import { getFullDaysBetween } from 'shared/utils/dateUtils';
import { useStrings } from 'hooks';
import Currency from '../../../../../components/Currency/Currency';
import React from 'react';

interface Props {
  invoice: IInvoice;
}

export function getInvoiceNotificationMessage(daysUntilDue: number): string {
  switch (true) {
    case daysUntilDue > 0:
      return 'pages.product.unpaid_invoices.infobox.due_in_days_message';
    case daysUntilDue === 0:
      return 'pages.product.unpaid_invoices.infobox.due_today_message';
    default:
      return 'pages.product.unpaid_invoices.infobox.overdue_message';
  }
}

export function getInfoBoxType(daysUntilDue: number): InfoBoxType {
  if (daysUntilDue > 0) {
    return InfoBoxType.Info;
  }
  return daysUntilDue === 0 ? InfoBoxType.Warn : InfoBoxType.Error;
}

const UnpaidInvoiceNotification = ({ invoice }: Props) => {
  const { get } = useStrings();
  const daysUntilDue: number = getFullDaysBetween(
    new Date().toUTCString(),
    new Date(invoice.dueDate!).toUTCString()
  );
  return (
    <InfoBox
      key={invoice.invoiceNumber} // Assuming invoiceNumber is unique
      title={get('pages.product.unpaid_invoices.infobox.title')}
      text={get(getInvoiceNotificationMessage(daysUntilDue), {
        invoiceNumber: invoice.invoiceNumber,
        amount: <Currency currency={invoice.currency} value={invoice.amount} />,
        daysDue: daysUntilDue,
        link: (
          <Link
            to={invoice.paymentLink}
            className="ProductPage-infobox-message-link"
            onClick={(e) => {
              e.preventDefault();
              window.open(invoice.paymentLink, '_blank')?.focus();
            }}
          >
            <String id="pages.product.unpaid_invoices.infobox.link" />
          </Link>
        ),
      })}
      type={getInfoBoxType(daysUntilDue)}
      testId={`policy-open_invoice-infobox-${invoice.invoiceNumber}`}
    />
  );
};

export default UnpaidInvoiceNotification;
