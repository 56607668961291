import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ThemeProvider from 'providers/ThemeProvider';
import AuthProvider from 'providers/AuthProvider';
import StringProvider from 'providers/StringProvider';
import App from './App';
import GlobalStyle from 'themes/globalStyle';
import { useAuthClient } from 'hooks/useAuthClient';
import LocaleProvider from './providers/LocaleProvider';
import Head from './components/Head/Head';
import NavigationProvider from './providers/NavigationProvider';
import ApolloProvider from './providers/ApolloProvider';
import Loader from 'components/Loader/Loader';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import DatePickerProvider from './providers/DatePickerProvider';
import ApplicationProvider from './providers/ApplicationProvider';
import IntlProvider from './providers/IntlProvider';
import ModalRoot from 'components/Modal/ModalRoot';
import ModalProvider from './providers/ModalProvider';
import configuration from './configuration';
import TrackingProvider from './providers/TrackingProvider';
import MaintenanceBoundary from './pages/maintenance/MaintenanceBoundary';
import { FlagsProvider } from './providers/FlagsProvider';
import { PWAProvider } from 'providers/PWAProvider';
import { MessagesProvider } from 'providers/MessagesProvider';

function Root() {
  const authClient = useAuthClient();

  const mocked =
    configuration.environment === 'local' && configuration.disableAuth;

  if (!authClient) {
    return <Loader />;
  }

  // noinspection HtmlRequiredTitleElement
  return (
    <Router basename={configuration.region}>
      <FlagsProvider>
        <MessagesProvider>
          <PWAProvider>
            <LocaleProvider defaultValue={configuration.defaultLocale}>
              <MaintenanceBoundary>
                <AuthProvider client={authClient} mocked={mocked}>
                  <TrackingProvider>
                    <ApolloProvider>
                      <StringProvider>
                        <IntlProvider>
                          <ApplicationProvider>
                            <NavigationProvider>
                              <DatePickerProvider>
                                <ModalProvider>
                                  <ThemeProvider>
                                    <GlobalStyle />
                                    <Head />
                                    <ErrorBoundary>
                                      <App />
                                      <ModalRoot />
                                    </ErrorBoundary>
                                  </ThemeProvider>
                                </ModalProvider>
                              </DatePickerProvider>
                            </NavigationProvider>
                          </ApplicationProvider>
                        </IntlProvider>
                      </StringProvider>
                    </ApolloProvider>
                  </TrackingProvider>
                </AuthProvider>
              </MaintenanceBoundary>
            </LocaleProvider>
          </PWAProvider>
        </MessagesProvider>
      </FlagsProvider>
    </Router>
  );
}

export default Root;
