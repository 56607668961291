import {
  Configuration as BillingConfiguration,
  BillingApi,
} from '@inshur/apis/billing';
import { useEffect, useState } from 'react';
import configuration from '../configuration';
import { useAuth } from '../providers/AuthProvider';

export interface IApiClients {
  billing: BillingApi;
}

function createBasePath(endpoint?: string) {
  return `${configuration.api.base}/${configuration.region}/${endpoint ?? ''}`;
}

export function useApiClients() {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<IApiClients | undefined>();
  const { accessToken } = useAuth();
  useEffect(() => {
    if (accessToken) {
      setClients({
        billing: new BillingApi(
          new BillingConfiguration({
            basePath: createBasePath(configuration.api.billing),
            accessToken,
          })
        ),
      });

      setLoading(false);
    }
  }, [accessToken]);

  return {
    loading,
    clients,
  };
}
