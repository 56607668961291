import {
  ICreatePaymentIntentMutationVariables,
  useCreatePaymentIntentMutation,
} from 'shared/graphql/api/types';

export const useCreatePaymentIntent = () => {
  const [createIntent, { loading, data, error }] =
    useCreatePaymentIntentMutation();

  async function create(input: ICreatePaymentIntentMutationVariables['input']) {
    const { data } = await createIntent({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.intent;
  }

  return {
    create,
    loading,
    intent: data?.intent,
    error,
  };
};
