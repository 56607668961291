import React from 'react';
import { QuoteType } from 'providers/TrackingProvider';
import { IStepsType } from 'shared/graphql/api/types';
import { useLocale } from 'providers/LocaleProvider';
import { useProductSteps } from 'hooks/useProductSteps';
import Loader from 'components/Loader/Loader';
import { ErrorCodes, ProductError } from '../constants/errors';
import { ProductHistory } from '../hooks';

interface ProductStepsProps {
  productHistory: ProductHistory;
  quoteType: QuoteType;
}

function withProductSteps<P extends React.PropsWithChildren<ProductStepsProps>>(
  Wrapped: React.ComponentType<P>
): React.ComponentType<P> {
  function getStepsType(quoteType: QuoteType): IStepsType {
    switch (quoteType) {
      case QuoteType.MIGRATION:
        return 'MIGRATION';
      case QuoteType.MTA:
        return 'MTA';
      case QuoteType.NEW_BUSINESS:
      case QuoteType.NEW_BUSINESS_PRE:
        return 'NEW_BUSINESS';
      case QuoteType.RENEWAL:
      case QuoteType.RENEWAL_PRE:
        return 'RENEWAL';

      default: {
        throw new Error(`Unknown quote type: ${quoteType}`);
      }
    }
  }

  return (props) => {
    const { lang: locale } = useLocale();
    const { productHistory, quoteType } = props;
    const { id, version } = productHistory.current!;
    const stepsType = getStepsType(quoteType);

    const { steps, loading } = useProductSteps(id, version, locale, stepsType);

    if (loading) {
      return <Loader />;
    }

    if (!steps.length) {
      throw new ProductError(
        productHistory.current!.id,
        ErrorCodes.PRODUCT_INVALID_STEPS
      );
    }

    return <Wrapped {...props} steps={steps} />;
  };
}

export default withProductSteps;
