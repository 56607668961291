import React from 'react';
import { useParams } from 'react-router-dom';

import { useNavigation } from 'providers/NavigationProvider';
import { QuoteType } from 'providers/TrackingProvider';
import ChatbotProvider from 'providers/ChatbotProvider';
import CallApiHandlerProvider from 'providers/CallApiHandlerProvider';
import { useLocale } from 'providers/LocaleProvider';
import QuoteProvider from 'providers/QuoteProvider';
import { useUser } from 'providers/UserProvider';
import { useModal } from 'providers/ModalProvider';

import { LoadedProductHistory, useHistory, useStrings } from 'hooks';
import { useProduct } from 'hooks/useProduct';
import { useGetPolicyVersion } from 'hooks/useGetPolicy';
import { parseKey } from 'utils/quoteUtils';

import EventManager, { EventType } from 'utils/EventManager';
import Button from 'components/Buttons/Button';
import Page from 'components/Page/Page';
import Chatbot from 'components/Chatbot/Chatbot';
import Loader from 'components/Loader/Loader';

const withPage = (children: React.ReactElement): React.ReactElement => {
  return (
    <Page name="VehicleChangePage" slug="Change vehicle details" padded={false}>
      {children}
    </Page>
  );
};

function VehicleChangePage() {
  const { id: productId, policyKey } = useParams<{
    id: string;
    policyKey: string;
  }>();
  const { lang: locale } = useLocale();
  const { get } = useStrings();
  const { close } = useNavigation();
  const { push } = useHistory();
  const { display, dismiss } = useModal();

  const user = useUser();

  const {
    loading: loadingProduct,
    productHistory,
    error: productError,
  } = useProduct(productId, locale, false);
  const product = productHistory.initial;

  const { id: policyId, version: policyVersion } = parseKey(policyKey) ?? {};

  const {
    loading: loadingPolicies,
    policy,
    error: policyError,
  } = useGetPolicyVersion(policyId!, policyVersion!);

  function onShowLeavePageConfirmation() {
    function onCancel() {
      EventManager.track({
        event: EventType.QuestionAnswered,
        questionId: 'MTA Leave page - cancel',
        quoteType: QuoteType.MTA,
        productId: productId,
      });
      close();
      dismiss();
    }

    function leave() {
      EventManager.track({
        event: EventType.QuestionAnswered,
        questionId: 'MTA Leave page - leave',
        quoteType: QuoteType.MTA,
        productId: productId,
      });
      push(`/product/${productId}`);
      close();
      dismiss();
    }

    display('leave_page_confirmation', {
      title: get('pages.product.mta.modal.leave_page.title'),
      message: get('pages.product.mta.modal.leave_page.message'),
      onCancel,
      actions: (
        <>
          <Button variant="secondary" onClick={onCancel} testId="leave-cancel">
            {get('pages.product.mta.modal.leave_page.cancel')}
          </Button>
          <Button variant="primary" onClick={leave} testId="leave-confirm">
            {get('pages.product.mta.modal.leave_page.leave')}
          </Button>
        </>
      ),
    });
  }

  if (productError) {
    throw productError;
  }

  if (policyError) {
    throw policyError;
  }

  if (loadingProduct || loadingPolicies) {
    return withPage(<Loader />);
  }

  if (!product) {
    throw new Error('Property `product` is not provided');
  }

  if (!policy) {
    throw new Error('Property `policy` is not provided');
  }

  return withPage(
    <ChatbotProvider>
      <CallApiHandlerProvider>
        <QuoteProvider
          user={user}
          productHistory={productHistory as LoadedProductHistory}
          initialValue={policy.datasheet}
          mtaOf={policy}
          quoteType={QuoteType.MTA}
        >
          <Chatbot onClose={onShowLeavePageConfirmation} />
        </QuoteProvider>
      </CallApiHandlerProvider>
    </ChatbotProvider>
  );
}

export default VehicleChangePage;
