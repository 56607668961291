import React from 'react';
import String from 'components/String/String';
import {
  IQuotePackageValuation,
  IFlexWalletPaymentPlan,
  ICurrency,
} from 'shared/graphql/api/types';
import { findFlexPaymentPlan } from 'utils/quoteUtils';
import Currency from 'components/Currency/Currency';
import './UsagePolicyConfirmation.scss';

interface Props {
  package: IQuotePackageValuation;
  currency: ICurrency;
}

export default function UsagePolicyConfirmation({
  package: _package,
  currency = 'GBP',
}: Props) {
  const { unitPrice, initialTopupUnits, minWalletUnits, topupUnits } =
    findFlexPaymentPlan(_package) as IFlexWalletPaymentPlan;

  return (
    <div
      data-testid="WalletPurchaseConfirmation"
      className="WalletPurchaseConfirmation"
    >
      <div className="WalletPurchaseConfirmation-wallet-breakdown">
        <p className="WalletPurchaseConfirmation-subtitle">
          <String id="pages.payment-confirmation.wallet.subtitle" />
        </p>

        <p
          data-testid="WalletPurchaseConfirmation-wallet-balance"
          className="WalletPurchaseConfirmation-wallet-balance"
        >
          <Currency currency={currency} value={unitPrice * initialTopupUnits} />{' '}
        </p>
        <p
          data-testid="WalletPurchaseConfirmation-unitprice"
          className="WalletPurchaseConfirmation-unitprice"
        >
          <String
            id="pages.payment-confirmation.wallet.you_pay"
            values={{
              currencyValue: <Currency currency={currency} value={unitPrice} />,
            }}
          />
        </p>
        <p className="WalletPurchaseConfirmation-hour-coverage">
          <String
            id="pages.payment-confirmation.wallet.balance_cover"
            values={{ initialTopupUnits }}
          />
        </p>
      </div>
      <div className="WalletPurchaseConfirmation-wallet-description">
        <p>
          <String
            id="pages.payment-confirmation.wallet.description"
            values={{
              topupUnits,
              minWalletUnits,
              topUpUnitsCurrency: (
                <Currency currency={currency} value={topupUnits * unitPrice} />
              ),
              minWalletUnitsCurrency: (
                <Currency
                  currency={currency}
                  value={minWalletUnits * unitPrice}
                />
              ),
            }}
          />
        </p>

        <p className="WalletPurchaseConfirmation-flex-app">
          <String id="pages.payment-confirmation.wallet.flex_app_prompt" />
        </p>
      </div>
    </div>
  );
}
