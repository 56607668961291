import React from 'react';

import { FormattedMessage, IntlProvider } from 'react-intl';
import TRANSLATIONS from 'constants/translations';
import { useLocale } from 'providers/LocaleProvider';
import LogoHorizontal from 'components/Icons/Logo/LogoHorizontal';

import './MaintenancePage.scss';

interface Props {
  message?: string;
}

function MaintenancePage({ message }: Props) {
  const { lang: locale } = useLocale();

  return (
    <IntlProvider locale={locale} messages={TRANSLATIONS[locale]}>
      <div className="MaintenancePage">
        <div className="MaintenancePage-container">
          <div className="MaintenancePage-header">
            <LogoHorizontal />
          </div>
          <div className="MaintenancePage-title">
            <h1>
              {message ? (
                message
              ) : (
                <FormattedMessage id="pages.maintenance.title" />
              )}
            </h1>
          </div>
          <div className="MaintenancePage-message">
            <p>
              <FormattedMessage id="pages.maintenance.message" />
            </p>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}

export default MaintenancePage;
