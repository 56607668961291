import { useEffect, useState } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import configuration from 'configuration';

export const useAuthClient = () => {
  const [client, setClient] = useState<Auth0Client>();

  useEffect(() => {
    createAuth0Client({
      domain: configuration.auth0.domain,
      client_id: configuration.auth0.clientId,
      redirect_uri: configuration.auth0.redirectUri,
      audience: configuration.auth0.audience,
      logout_uri: configuration.auth0.logoutUri,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    }).then(setClient);
  }, []);

  return client;
};
