import React from 'react';

import { useStrings } from 'hooks';
import Currency from 'components/Currency/Currency';
import String from 'components/String/String';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import configuration from 'configuration';
import { getProductParameters } from 'components/PaymentOverview/paymentOverviewUtils';

import {
  findFlexPaymentPlan,
  findInstalmentsPaymentPlan,
  findFinancePaymentPlan,
} from 'utils/quoteUtils';
import { PolicyDuration } from 'utils/datasheet/types/datasheet-types';

import {
  ICurrency,
  IQuotePackageValuation,
  IFinancePaymentPlan,
  IPricingModelEnum,
  ILineItem,
} from 'shared/graphql/api/types';
import { getFeesLineItems } from 'utils/lineItems';

function durationTranslate(policyDuration: PolicyDuration) {
  return <String id={`enums.policy_duration.${policyDuration}`} />;
}

interface QuotePriceProps {
  _package: IQuotePackageValuation;
  currency: ICurrency;
  productCode: string;
  productCountry: string;
  policyDuration: PolicyDuration;
  showPaymentPlans?: boolean;
}

type PricingModel = IPricingModelEnum | undefined | null;

export default function QuoteOptionPrice({
  _package,
  currency,
  productCode,
  productCountry,
  policyDuration,
  showPaymentPlans = true,
}: QuotePriceProps) {
  const { get } = useStrings();

  if (!Object.keys(_package).length) return null;

  const { pricingModel } = _package;

  const premiumFinancePlan = findFinancePaymentPlan(_package);

  const { showIpt } = getProductParameters(productCode, productCountry);

  function renderSwitch(pricingModel: PricingModel) {
    if (pricingModel === 'USAGE_BASED') {
      return usagePricingRender({ _package, currency });
    }

    return fixedPricingRender({
      _package,
      currency,
      policyDuration,
      showIpt,
    });
  }

  return (
    <div className="QuoteOption-price" data-testid="QuoteOption-price">
      {premiumFinancePlan &&
        showPaymentPlans &&
        premiumFinancePricingRender({
          showIpt,
          premiumFinancePlan,
          currency,
          get,
        })}
      {renderSwitch(pricingModel)}
    </div>
  );
}
/**
 * Convert the addon type to the base addon type as the suffix is concatenated by quote-api
 * This is going to be updated in quote-api to become a status instead of generating 3 addon types
 */
export function convertToBaseAddonType(addonType: string): string {
  // Remove the '_paid' or '_unpaid' or '_waived' suffix from the addon type
  return addonType.replace(/_paid|_unpaid|_waived/g, '');
}

export function usagePricingRender({
  _package,
  currency,
}: {
  _package: IQuotePackageValuation;
  currency: ICurrency;
}) {
  // MTA for usage based?
  const flexPlan = findFlexPaymentPlan(_package);
  const pricePerUnit = flexPlan?.unitPrice;
  const componentKey = 'usageBased';

  return (
    <div
      className="QuoteOption-price-plan"
      data-testid={`QuoteOption-price-plan-${componentKey}`}
    >
      <span
        className="QuoteOption-price--value"
        data-testid={`QuoteOption-price-value-${componentKey}`}
      >
        <Currency currency={currency} value={pricePerUnit} />{' '}
      </span>
      <span
        className="QuoteOption-price--duration"
        data-testid={`QuoteOption-price-duration-${componentKey}`}
      >
        / <String id="const.hour" />
      </span>
      <p
        className="QuoteOption-tax"
        data-testid={`QuoteOption-price-tax-${componentKey}`}
      >
        <String
          id="pages.quote.options.inclusive_ipt_hourly"
          values={{
            ipt: configuration.usageIptPercentage,
          }}
        />
      </p>
    </div>
  );
}

export function fixedPricingRender({
  currency,
  policyDuration,
  showIpt,
  _package,
}: {
  currency: ICurrency;
  policyDuration: PolicyDuration;
  showIpt: boolean;
  _package: IQuotePackageValuation;
}) {
  const componentKey = 'fixedTerm';
  const instalmentsPlan = findInstalmentsPaymentPlan(_package);

  // MTA support (e.g. PaymentOverview)
  const valuation =
    _package.valuation?.mta?.effectiveValuation ?? _package.valuation;

  const { total, tax } = valuation!;
  const fees: ILineItem[] = getFeesLineItems(valuation);

  // TODO - is instalmentsPlan.instalment.total === total?
  const value = instalmentsPlan ? instalmentsPlan.instalment.total : total;

  return (
    <div
      className="QuoteOption-price-plan"
      data-testid={`QuoteOption-price-plan-${componentKey}`}
    >
      <span
        className="QuoteOption-price--value"
        data-testid={`QuoteOption-price-value-${componentKey}`}
      >
        <Currency currency={currency} value={value} />{' '}
      </span>
      <span
        className="QuoteOption-price--duration"
        data-testid={`QuoteOption-price-duration-${componentKey}`}
      >
        /{' '}
        {instalmentsPlan ? (
          <String id="const.month" />
        ) : (
          durationTranslate(policyDuration)
        )}
      </span>

      {showIpt && (
        <p
          className="QuoteOption-tax"
          data-testid={`QuoteOption-price-tax-${componentKey}`}
        >
          <String
            id="pages.quote.options.inclusive_ipt"
            values={{
              ipt: <Currency currency={currency} value={tax} />,
            }}
          />
        </p>
      )}

      {fees.length > 0 &&
        fees.map(({ value, id, name }) => (
          <p
            className="QuoteOption-tax"
            key={id}
            data-testid={`QuoteOption-price-addon-${id}`}
          >
            {!configuration.enableTranslationsFees ? (
              <String
                id={`pages.quote.options.addon.${convertToBaseAddonType(id)}`}
                values={{
                  total: <Currency currency={currency} value={value} />,
                }}
              />
            ) : (
              <String
                id={`pages.quote.options.addon`}
                values={{
                  total: <Currency currency={currency} value={value} />,
                  feeName: name,
                }}
              />
            )}
          </p>
        ))}

      {showIpt && (
        <p
          className="QuoteOption-tax"
          data-testid={`QuoteOption-inclusive-prices`}
        >
          <String id="pages.quote.options.inclusive_prices" />
        </p>
      )}
    </div>
  );
}

function premiumFinancePricingRender({
  premiumFinancePlan,
  currency,
  get,
}: {
  showIpt: boolean;
  premiumFinancePlan: IFinancePaymentPlan;
  currency: ICurrency;
  get: (id: string, values?: any, defaultValue?: string | undefined) => string;
}) {
  return (
    <>
      <div
        className="QuoteOption-price-plan"
        data-testid="QuoteOption-price-plan-premiumFinancePlan"
      >
        <span className="QuoteOption-price--overline">
          <String id="pages.quote.finance.overline" />
        </span>
        <span className="QuoteOption-price--value">
          <Currency
            currency={currency}
            value={premiumFinancePlan.financeInstalment.total}
          />{' '}
        </span>

        <span className="QuoteOption-price--duration">
          / {premiumFinancePlan.financeInstalment.period}
        </span>
      </div>
      {premiumFinancePlan.financeDownpayment && (
        <p className="QuoteOption-tax">
          <String
            id="pages.quote.payment.overview.payment_plan.finance.deposit_fixed"
            values={{
              amount: (
                <Currency
                  currency={currency}
                  value={premiumFinancePlan.financeDownpayment.total}
                />
              ),
            }}
          />
        </p>
      )}

      <div className="QuoteOption-accordion">
        <AccordionSection title={get('pages.quote.finance.breakdown')}>
          <div className="QuoteOption-accordion--text">
            <String
              id="pages.quote.finance.text"
              values={{
                period: premiumFinancePlan.agreementTerm!.toLowerCase(),
                apr: premiumFinancePlan.aprPercentage!,
              }}
            />
          </div>
        </AccordionSection>
      </div>

      <span className="QuoteOption-price--overline">
        <String id="pages.quote.oneoff.overline" />{' '}
        <small>
          <String id="pages.quote.oneoff.overline_excerpt" />
        </small>
      </span>
    </>
  );
}
