import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormControlLabelProps,
} from '@material-ui/core';

type Props = Omit<FormControlLabelProps, 'control'>;

function CheckBox({ checked, name, onChange, label }: Props) {
  return (
    <FormControlLabel
      control={<Checkbox color="primary" checked={checked} />}
      label={label}
      id={name}
      name={name}
      onChange={onChange}
    />
  );
}

export default CheckBox;
