import React from 'react';

export default function StartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
    >
      <path
        id="start-icon"
        data-name="start-icon"
        d="M54.391,303.168a28.241,28.241,0,1,0,28.24,28.24,28.253,28.253,0,0,0-28.24-28.24m0,59.74a31.5,31.5,0,1,1,31.5-31.5,31.523,31.523,0,0,1-31.5,31.5m16.467-30.729a16.489,16.489,0,0,0-7.063-13.519,1.731,1.731,0,0,0-1.981,2.84,13,13,0,1,1-14.846,0,1.73,1.73,0,1,0-1.979-2.839,16.467,16.467,0,1,0,25.869,13.519Zm-14.737.356V315.9a1.73,1.73,0,1,0-3.46,0v16.633a1.73,1.73,0,0,0,3.46,0Z"
        transform="translate(-22.891 -299.908)"
        fill="#1fc4d6"
      />
    </svg>
  );
}
