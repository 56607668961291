import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'hooks';
import { QuoteStatusEnum } from '@inshur/apis/quote';

import Button from '../Buttons/Button';
import String from '../String/String';
import { IQuoteStatus } from 'shared/graphql/api/types';

import './OpenQuote.scss';

interface Props {
  quoteId: string;
  quoteNumber: string;
  vehicle: string;
  startDate: ReactNode;
  price: string;
  duration: string;
  productId?: string;
  renewalOf?: string | undefined | null;
  showRenewalBanner: boolean;
  quoteStatus: IQuoteStatus;
}

function OpenQuote({
  quoteId,
  quoteNumber,
  vehicle,
  startDate,
  price,
  duration,
  renewalOf,
  showRenewalBanner,
  quoteStatus,
}: Props) {
  const { push } = useHistory();
  const { id: productId } = useParams<{ id: string }>();

  const viewQuote = () => {
    const quoteOptionsUrl = `/product/${productId}/quote/${quoteId}/options`;
    push(quoteOptionsUrl);
  };

  return (
    <div className="OpenQuote">
      {renewalOf && showRenewalBanner && (
        <p className="OpenQuote-section OpenQuote-renewal-of">
          <String id="pages.open_quote.renewal" />
        </p>
      )}

      <div className="OpenQuote-wrapper">
        <div className="OpenQuote-body">
          <p className="OpenQuote-section OpenQuote-ref">
            <String id="pages.open_quote.reference" /> {quoteNumber}
          </p>
          <p className="OpenQuote-section OpenQuote-vehicle">{vehicle}</p>

          <p className="OpenQuote-section OpenQuote-date-title">
            <String id="pages.open_quote.start_date" />
          </p>
          <p className="OpenQuote-section OpenQuote-date">{startDate}</p>
          <p className="OpenQuote-section OpenQuote-duration">
            <String id={`enums.policy_duration.${duration}`} />
          </p>
          {quoteStatus === QuoteStatusEnum.OK && (
            <p className="OpenQuote-section OpenQuote-price">{price}</p>
          )}
          <Button
            testId="continue-quote"
            className="OpenQuote-continue"
            onClick={viewQuote}
            variant="primary"
          >
            <String id="pages.open_quote.vew_quote" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OpenQuote;
