import React, { useState } from 'react';
import { object, boolean, SchemaOf, string } from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import Button from 'components/Buttons/Button';
import { ValidationAlertIcon } from 'components/Icons';
import Checkbox from '../components/Checkbox';
import ErrorBox from '../components/ErrorBox';
import { FormAction, formActionChoices } from '../QuoteFormSteps';

interface Values {
  insured: {
    insuranceQualification: {
      isEligible: boolean;
    };
  };
}

const stepOneValidationSchema: SchemaOf<Values> = object({
  insured: object({
    firstName: string(),
    insuranceQualification: object({
      isEligible: boolean()
        .required()
        .label('Eligible')
        .oneOf(
          [true],
          'Please tick the box highlighted above if you are eligible to continue.'
        ),
    }),
  }),
});

interface Props {
  data: any;
  dispatch: React.Dispatch<FormAction>;
}

const ELEGIBILITY = [
  {
    title: 'You',
    items: [
      'Are aged 23 - 66',
      'Have held a full UK driving licence for 2+ years',
      'Have been a UK resident for 1+ years',
      'Have not had more than 4 motoring convictions in the last 5 years',
      'Have no disclosable non-motoring convictions',
      'Are employed and licensed as a full/part time private hire driver',
    ],
  },
  {
    title: 'Insured vehicle',
    items: [
      'Will not be used for commuting or business use for a secondary occupation',
      'Can be used in addition, for Fast food delivery, but only if you are a licensed private hire driver',
    ],
  },
];

const QuoteFormOne = ({ dispatch, data }: Props) => {
  const [submited, setSubmited] = useState(false);

  function onSubmitClick() {
    setSubmited(true);
  }

  return (
    <Formik
      validationSchema={stepOneValidationSchema}
      initialValues={data}
      onSubmit={(values) =>
        dispatch({ action: formActionChoices.NEXT, data: values })
      }
    >
      {({ values, errors, setFieldValue }: FormikProps<Values>) => {
        return (
          <Form>
            {submited && errors && <ErrorBox />}
            <div className="QuoteForm-step-wrapper">
              <h2 className="sectionTitle">Are you eligible?</h2>
              <div className="Elegibility">
                {ELEGIBILITY.map(({ title, items }) => (
                  <div className="Elegibility-group" key={title}>
                    <div className="Elegibility-intro">{title}:</div>
                    {items.map((item) => (
                      <div key={item} className="Elegibility-item">
                        {item}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div
                className={`QuoteForm-step-conditions ${
                  errors?.insured?.insuranceQualification?.isEligible
                    ? ' QuoteForm-step-conditions-error'
                    : ''
                }`}
              >
                <Checkbox
                  checked={values.insured.insuranceQualification.isEligible}
                  name="insured.insuranceQualification.isEligible"
                  onChange={(_e, checked) => {
                    setFieldValue(
                      'insured.insuranceQualification.isEligible',
                      checked
                    );
                    setFieldValue(
                      'drivers[0].insuranceQualification.isEligible',
                      checked
                    );
                  }}
                  label={
                    <div>
                      I confirm I have read and meet the above eligibility
                    </div>
                  }
                />
              </div>
              {errors?.insured?.insuranceQualification?.isEligible && (
                <div className="QuoteOverview-actions-validation">
                  <ValidationAlertIcon />
                  <div className="QuoteOverview-actions-validation-text">
                    {errors?.insured?.insuranceQualification?.isEligible}
                  </div>
                </div>
              )}
            </div>

            <div className="QuoteForm-step-actions">
              <Button
                onClick={onSubmitClick}
                variant="primary"
                type="submit"
                testId="form-step-one-next"
                track={{ questionId: 'form-confirm-step-1-eligibility' }}
              >
                Continue
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default QuoteFormOne;
