import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  adjustedQuoteEndDate,
  adjustedQuoteStartDate,
  createKey,
  openQuotesRenewalsEnabled,
} from 'utils/quoteUtils';
import EventManager, { EventType } from 'utils/EventManager';

import { useHistory, useQuote, useStrings } from 'hooks';
import { Full } from 'components/Layout/Layout';
import Button from 'components/Buttons/Button';
import String from 'components/String/String';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import Page from 'components/Page/Page';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Loader from 'components/Loader/Loader';
import { Builder, SchemaBuilder } from 'components/Builder/Builder';
import LinkButton from 'components/LinkButton/LinkButton';
import { ValidationAlertIcon } from 'components/Icons';

import { QuoteType } from 'providers/TrackingProvider';

import { RenewalBanner } from '../RenewalBanner/RenewalBanner';
import OverviewSchema from './schemas';
import './QuoteOverviewPage.scss';

function QuoteOverviewPage() {
  const [confirmed, setConfirmed] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(false);

  const {
    id: productId,
    quoteId,
    packageId,
  } = useParams<{ id: string; quoteId: string; packageId: string }>();
  const { quote, loading: loadingQuote } = useQuote(quoteId, true);

  const { push } = useHistory();
  const { get } = useStrings();

  const isRenewalQuote = quote?.renewalOf != null;
  const showRenewalBanner = openQuotesRenewalsEnabled() && isRenewalQuote;

  if (loadingQuote) {
    return <Loader />;
  }
  // Fix start and end dates if they are in the past
  quote.endDate = adjustedQuoteEndDate(quote)?.toISOString();
  quote.startDate = adjustedQuoteStartDate(quote).toISOString();
  quote.datasheet.policy.startDateTime = quote.startDate;

  const { product } = quote;

  const selectedPackage =
    quote?.packages?.filter((p) => p?.id === packageId)[0] ?? undefined;

  function PageBody() {
    if (!quote) {
      throw new Error('Quote not found');
    }

    const overviewSchema = new SchemaBuilder(OverviewSchema);

    const onCheckConfirm = () => {
      setConfirmed(!confirmed);
    };

    const onClickConfirm = () => {
      if (!confirmed) {
        setShowInfoBox(true);
      } else {
        EventManager.track({
          event: EventType.PreExistingQuote,
          productId: productId,
          quoteType: isRenewalQuote
            ? QuoteType.RENEWAL_PRE
            : QuoteType.NEW_BUSINESS_PRE,
        });
        push(`/product/${productId}/quote/${quoteId}/payment`, {
          packageId,
          isOpenQuote: true,
        });
      }
    };

    function renderActions() {
      return (
        <div className="QuoteOverview-actions">
          <div
            className={`QuoteOverview-actions-terms ${
              showInfoBox ? ' QuoteOverview-actions-terms-error' : ''
            }`}
          >
            <h3>
              <String id="pages.quote.overview_page.confirm.title" />
            </h3>

            <div>
              <String id="pages.quote.overview_page.confirm.body" />
            </div>

            {product.location.country === 'US' && (
              <p className="QuoteOverview-make-changes-contact-us">
                <String id="pages.quote.summary.make_changes_contact_us" />
              </p>
            )}

            <h4>
              <String id="pages.quote.overview_page.confirm.agreement" />
            </h4>
            <label className="QuoteOverview-actions-terms-wrapper" tabIndex={1}>
              <Checkbox
                dataTestId="terms-documents"
                isChecked={confirmed}
                value={confirmed ? 'true' : 'false'}
                stringId="pages.quote.overview_page.confirm"
                onClick={onCheckConfirm}
              />
            </label>
          </div>
          {showInfoBox && (
            <div className=" QuoteOverview-actions-validation">
              <ValidationAlertIcon />
              <div className=" QuoteOverview-actions-validation-text">
                <String id="pages.quote.overview_page.required" />
              </div>
            </div>
          )}

          <div className="QuoteOverview-actions-buttons">
            <Button
              tabIndex={2}
              variant="primary"
              onClick={onClickConfirm}
              loading={loadingQuote}
              loaderColor="white"
              testId="overview-make-payment-button"
            >
              <String
                id={
                  isRenewalQuote
                    ? 'pages.quote.overview_page.continue.renewal.button'
                    : `pages.quote.overview_page.confirm.button`
                }
              />
            </Button>
            {!confirmed && product.location.country !== 'US' && (
              <LinkButton
                tabIndex={3}
                variant="secondary"
                to={`/product/${productId}/quote`}
                query={
                  isRenewalQuote
                    ? {
                        renewalOf: createKey(
                          quote?.renewalOf?.id,
                          quote?.renewalOf?.version
                        ),
                      }
                    : { force: true }
                }
                testId="overview-change-details-button"
                className="QuoteOverview-actions-button"
              >
                <String id="pages.quote.overview_page.change_details" />
              </LinkButton>
            )}
            <LinkButton
              tabIndex={4}
              variant="secondary"
              to={`/product/${productId}`}
              testId="overview-back-to-dashboard-button"
              className="QuoteOverview-actions-button"
            >
              <String id="pages.quote.overview_page.back" />
            </LinkButton>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`QuoteOverview-page${
          showRenewalBanner ? '-with-banner' : ''
        }`}
      >
        <ProgressBar
          currentStep={1}
          currentStepText="progress_bar.step.confirm_details"
          nextStepText="progress_bar.step.policy_aggreement"
          totalStepCount={3}
          isComplete={false}
          withBanner={showRenewalBanner}
        />

        <div className="Overview-detail-intro">
          <h3>
            <String id="pages.quote.overview_page.title" />
          </h3>
          <String id="pages.quote.overview_page.body" />
        </div>

        {showInfoBox && (
          <div className="Overview-detail-infobox">
            <div className="InfoBox-wrapper">
              <InfoBox
                title={get('pages.quote.overview_page.error.title')}
                text={get('pages.quote.overview_page.error.body')}
                type={InfoBoxType.Error}
                testId="quote-overview-infobox"
              />
            </div>
          </div>
        )}

        {product && (
          <Builder
            schemas={overviewSchema}
            values={{ quote, selectedPackage }}
            product={product}
          />
        )}

        {renderActions()}
      </div>
    );
  }

  return (
    <Page name="QuoteOverviewPage" slug="Quote Overview" padded={false}>
      {showRenewalBanner && <RenewalBanner />}
      <Full className="QuoteOverview">
        <PageBody />
      </Full>
    </Page>
  );
}

export default QuoteOverviewPage;
