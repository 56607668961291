import React from 'react';
import { AlertIcon } from '../Icons';
import './ErrorMessage.scss';

interface Props {
  message: string;
}

function ErrorMessage({ message }: Props) {
  return (
    <div className="ErrorMessage">
      <AlertIcon colorHex="#e71719" />
      <span className="ErrorMessage-message">{message}</span>
    </div>
  );
}

export default ErrorMessage;
