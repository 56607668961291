import React, { useState } from 'react';
import { take } from 'lodash';

import { Choice, ChoiceQuestion } from 'shared/types/question';

import configuration from 'configuration';
import { formatTestId } from 'utils/testIdUtils';
import { useStrings } from 'hooks';
import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';

import String from 'components/String/String';
import { RemoveIcon, SearchIcon } from 'components/Icons';
import UnstyledButton from 'components/Buttons/UnstyledButton';

interface Props {
  question: ChoiceQuestion;
  onAnswer: AnswerQuestion;
  searchable?: boolean;
}

function ChoiceDropdownInput({ question, onAnswer, searchable }: Props) {
  const { stepId } = usePreQuote();
  const { get } = useStrings();

  const [active, setActive] = useState(false);
  const [choices, setChoices] = useState(question.choices);
  const [search, setSearch] = useState('');

  function onClick(text: string, value: Choice['value']) {
    setActive(false);
    onAnswer(text, value);
  }

  function onSearch(search: string) {
    setSearch(search);

    if (search.length < configuration.dropDownSearchMinLetterCount) {
      setChoices(question.choices);
      return;
    }

    const filtered = question.choices.filter((choice) =>
      choice.text.toLowerCase().includes(search.toLowerCase())
    );

    setChoices(take(filtered, configuration.dropDownSearchMaxResultCount));
  }

  return (
    <>
      {active && (
        <div className="Chatbot-dropdown">
          {searchable && (
            <div className="Chatbot-dropdown-search">
              <SearchIcon />
              <input
                type="text"
                id="search"
                onChange={(e) => onSearch(e.target.value)}
                className="Chatbot-dropdown-search-text"
                placeholder={get('pages.dropdown.search.text')}
                value={search}
              />
              {search.length !== 0 && (
                <RemoveIcon onClick={() => onSearch('')} />
              )}
            </div>
          )}
          {choices.length ? (
            choices.map((choice) => (
              <UnstyledButton
                key={choice.text}
                className="Chatbot-dropdown-item"
                testId={`choice-dropdown-${formatTestId(choice.text)}`}
                onClick={() => onClick(choice.text, choice.value)}
              >
                {choice.text}
              </UnstyledButton>
            ))
          ) : (
            <div className="Chatbot-dropdown-search-no-result">
              <String id="pages.dropdown.search.no_result" />
            </div>
          )}
        </div>
      )}
      <div className="Chatbot-actions">
        <UnstyledButton
          className="Chatbot-action"
          testId="choice-dropdown-button"
          aria-hidden={active}
          onClick={() => setActive(true)}
          track={{ questionId: stepId }}
        >
          <String id="pages.quote.chatbot.select_option" />
        </UnstyledButton>
      </div>
    </>
  );
}

export default ChoiceDropdownInput;
