import React, { PropsWithChildren } from 'react';
import { ReceivedMessageBubble, SentMessageBubble } from './styled';

interface Props {
  received: boolean;
  dataTestId?: string;
}

function MessageBubble({ received, children }: PropsWithChildren<Props>) {
  const Bubble = received ? ReceivedMessageBubble : SentMessageBubble;

  return <Bubble className="Message-bubble">{children}</Bubble>;
}

export default MessageBubble;
