import React from 'react';
import UnstyledButton, { ButtonProps } from 'components/Buttons/UnstyledButton';
import { EventType } from 'utils/EventManager';

import './Document.scss';
import Loader from 'components/Loader/Loader';
import { getDisplayName } from 'utils/documentUtils';

interface Props extends ButtonProps {
  name: string;
  productCode: string;
  getDocument: () => void;
  track: { productId: string; label: string };
  loading?: boolean;
}

function Document({
  name,
  productCode,
  getDocument,
  track,
  loading,
}: React.PropsWithChildren<Props>) {
  return (
    <UnstyledButton
      className="document-download"
      data-document-id={name}
      onClick={getDocument}
      track={track}
      trackEvent={EventType.PolicyDocuments}
      testId="open-document"
      disabled={loading}
    >
      <div>{getDisplayName(name, productCode)}</div>
      {loading && <Loader />}
      <i className="link-icon" />
    </UnstyledButton>
  );
}

export default Document;
