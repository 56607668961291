import React from 'react';

interface Props {
  colorHex?: string;
}

export default function AlertIcon({ colorHex = '#ffffff' }: Props) {
  return (
    <svg
      className="AlertIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="22.895"
      height="20.103"
      viewBox="0 0 22.895 20.103"
    >
      <path
        d="M13.432,7.346a3,3,0,0,1,5.136,0L27.046,21.5A3,3,0,0,1,24.47,26H7.519a3,3,0,0,1-2.557-4.514ZM6.686,22.5A1,1,0,0,0,7.53,24H24.459a1,1,0,0,0,.863-1.486L16.855,8.379a1,1,0,0,0-1.709,0Z"
        transform="translate(-4.553 -5.897)"
        fill={colorHex}
      />
      <path
        d="M15,13a1,1,0,0,1,2,0v5a1,1,0,0,1-2,0Z"
        transform="translate(-4.553 -5.897)"
        fill={colorHex}
      />
      <path
        d="M16,20.305a1,1,0,1,1-1,1A1,1,0,0,1,16,20.305Z"
        transform="translate(-4.553 -5.897)"
        fill={colorHex}
      />
    </svg>
  );
}
