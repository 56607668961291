import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  buttons: {
    regular: {
      color: 'var(--color-font-primary)',
      'background-color': 'var(--color-background-white)',
    },
    primary: {
      color: 'var(--color-font-light)',
      'background-color': 'var(--color-background-primary)',
    },
    secondary: {
      color: 'var(--color-font-primary)',
      'background-color': 'var(--color-background-button-secondary)',
    },
    tertiary: {
      color: 'var(--color-font-light)',
      'background-color': 'var(--color-background-primary)',
    },
    activated: {
      color: 'var(--color-font-light)',
      'background-color': 'var(--color-background-button-activated)',
    },
  },
  chatbot: {
    'background-color': 'var(--color-background-white)',
    bubbles: {
      sent: {},
      received: {},
    },
  },
};

export default defaultTheme;
