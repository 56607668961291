import React from 'react';
import { Route, Switch as RouterSwitch, SwitchProps } from 'react-router-dom';
import NotFoundPage from 'pages/NotFoundPage';

function Switch({ children, ...props }: React.PropsWithChildren<SwitchProps>) {
  return (
    <RouterSwitch {...props}>
      {children}
      <Route path="*" component={NotFoundPage} />
    </RouterSwitch>
  );
}

export default Switch;
