import React, { useEffect, useMemo, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLocale } from '../../providers/LocaleProvider';
import configuration from '../../configuration';
import Loader from '../Loader/Loader';
import {
  ICurrency,
  IPaymentProviderAccount,
  IQuote,
} from 'shared/graphql/api/types';
import { getStripeAccountValue } from '../../utils/stripeAccountUtils';
const debug = require('debug')('customer-web:stripe');

export interface WithStripeProps {
  quote: IQuote;
  currency: ICurrency;
  amount: number;
  futureUsage: string | null | undefined;
}

const getFeatureUsage = (
  futureUsage: string | null | undefined
): 'off_session' | 'on_session' | null => {
  if (futureUsage === 'on_session') {
    return 'on_session';
  }
  if (futureUsage === 'off_session') {
    return 'off_session';
  }
  return null;
};

function withStripe<P extends WithStripeProps>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  return (props) => {
    const { lang: locale } = useLocale();
    const { product } = props.quote;
    const [stripe, setStripe] = useState<any>();

    const account: IPaymentProviderAccount | undefined | null = useMemo(() => {
      if (product) {
        return configuration.enableStripeAccountFromConfig
          ? getStripeAccountValue(product.code)
          : product.paymentProvider?.account;
      }
      return undefined;
    }, [product]);

    useEffect(() => {
      if (product && account) {
        debug(`Loading stripe for ${account}...`);
        setStripe(() => loadStripe((configuration.stripe.key as any)[account]));
      }
      //eslint-disable-next-line
    }, []);

    if (!stripe) {
      return <Loader />;
    }
    return (
      <Elements
        stripe={stripe}
        options={{
          locale,
          currency: props.currency.toLowerCase(),
          amount: props.amount,
          mode: 'payment',
          setupFutureUsage: getFeatureUsage(props.futureUsage),
        }}
      >
        <Component {...props} />
      </Elements>
    );
  };
}

export default withStripe;
