import {
  IFinancePaymentPlanDetailsFragment,
  IFlexWalletPaymentPlanDetailsFragment,
  IInstalmentPaymentPlanDetailsFragment,
  IPackageValuationFragment,
  IPackageValuationStatusEnum,
  IPaymentPlanEnum,
  IPricingModelEnum,
  IProductDetailsFragment,
  IQuotePackageFragment,
} from 'shared/graphql/api/types';
import { PaymentPlanEnum } from 'enums/PaymentPlan';

import './PaymentPlanSelection.scss';
import BillingPaymentPlanSelection from './BillingPaymentPlanSelection';
import { PackageValuation } from '@inshur/apis/billing';
import { Centered } from '../Layout/Layout';
import Loader from '../Loader/Loader';
import React from 'react';
import String from '../String/String';

interface Props {
  paymentOptions: PackageValuation[] | undefined;
  quoteId: string;
  quotePackage: IQuotePackageFragment;
  product: IProductDetailsFragment;
  onClick: (plan: PaymentPlanEnum) => void;
  selectedPlan?: PaymentPlanEnum;
  disabledPlans: PaymentPlanEnum[];
  loading: boolean;
}

export type IQuotePackageFragmentType = {
  id: string;
  packageValuationId?: string | null;
  name: string;
  status?: IPackageValuationStatusEnum | null;
  pricingModel?: IPricingModelEnum | null;
  valuation?: IPackageValuationFragment | null;

  paymentPlans?: Array<
    | IFinancePaymentPlanDetailsFragment
    | IFlexWalletPaymentPlanDetailsFragment
    | {
        paymentPlan: IPaymentPlanEnum;
        policyBindAmount: number;
        total: number;
      }
    | IInstalmentPaymentPlanDetailsFragment
    | { paymentPlan: IPaymentPlanEnum }
    | null
    | undefined
  > | null;
};

function PaymentPlanSelectionContainer({
  paymentOptions,
  quoteId,
  onClick,
  product,
  selectedPlan,
  disabledPlans,
  quotePackage,
  loading,
}: Props) {
  if (loading) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  if (paymentOptions !== undefined && paymentOptions.length > 0) {
    return (
      <div>
        <BillingPaymentPlanSelection
          product={product}
          quotePackage={quotePackage}
          paymentOptions={paymentOptions}
          onClick={onClick}
          selectedPlan={selectedPlan}
          disabledPlans={disabledPlans}
        />
      </div>
    );
  }

  return (
    <div>
      <String id="pages.quote.payment.payment_options" />
    </div>
  );
}

export default PaymentPlanSelectionContainer;
