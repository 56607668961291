import { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useAuth } from 'providers/AuthProvider';
import { useEffectAsync } from 'hooks/useEffectAsync';

import Switch from 'components/Router/Switch';
import Loader from 'components/Loader/Loader';
import AuthRoute from 'components/Router/AuthRoute';

import LogoutPage from 'pages/auth/LogoutPage/LogoutPage';
import LoginPage from 'pages/auth/LoginPage/LoginPage';
import TermsPage from 'pages/TermsPage/TermsPage';
import CallbackPage from 'pages/auth/CallbackPage/CallbackPage';
import EmailVerifiedPage from 'pages/auth/EmailVerifiedPage/EmailVerifiedPage';
import PasswordChangedPage from 'pages/auth/PasswordChangedPage/PasswordChangedPage';
import ProductRoute from 'pages/product/ProductRoute';
import PermissionsPage from 'pages/auth/PermissionsPage/PermissionsPage';
import ProductLoginPage from './pages/product/ProductLoginPage/ProductLoginPage';
import ProductByCode from 'pages/product/ProductByCode';
import PartnerLoginPage from 'pages/PartnerLoginPage/PartnerLoginPage';
import configuration from 'configuration';
import { setAmbassadorValues } from './utils/ambassadorUtils';
import EventManager, { EventType } from './utils/EventManager';
import ProductsPage from 'pages/product/ProductsPage/ProductsPage';
import QuoteRoute from 'pages/quote/QuoteRoute';

function App() {
  const { client, init } = useAuth();

  useEffectAsync(init, [client]);

  useEffect(() => {
    // storing input name
    var params = new URL(window.location.href).searchParams;

    const utm = JSON.parse(localStorage.getItem('utm') || '{ }');
    utm.utm_source = utm.utm_source
      ? utm.utm_source
      : params.get('utm_source') || '';
    utm.utm_medium = utm.utm_medium
      ? utm.utm_medium
      : params.get('utm_medium') || '';
    utm.utm_campaign = utm.utm_campaign
      ? utm.utm_campaign
      : params.get('utm_campaign') || '';
    utm.utm_content = utm.utm_content
      ? utm.utm_content
      : params.get('utm_content') || '';
    localStorage.setItem('utm', JSON.stringify(utm));

    setAmbassadorValues(params);
    EventManager.track({
      event: EventType.ApplicationStarted,
      label: new URL(window.location.href).pathname,
    });
  }, []);

  if (!client) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path="/callback" component={CallbackPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/permissions" component={PermissionsPage} />
      <Route path="/verified" component={EmailVerifiedPage} />
      <Route path="/changed" component={PasswordChangedPage} />
      <Route
        path="/product/:code([A-Za-z0-9]{3,4})"
        component={ProductByCode}
      />
      {configuration.releaseFlag.enableAnonymousQuote && (
        <Route path="/quote" component={QuoteRoute} />
      )}
      {configuration.releaseFlag.enableAnonymousQuote && (
        <Route path="/product" exact component={ProductsPage} />
      )}
      <Route path="/product/:id/login" component={ProductLoginPage} />
      <AuthRoute path="/product" component={ProductRoute} />
      <AuthRoute path="/terms" component={TermsPage} />
      <AuthRoute
        path={configuration.api.loginPartnerPath}
        component={PartnerLoginPage}
      />
    </Switch>
  );
}

export default App;
