import React from 'react';
import { DateTimeFormatOptions } from 'intl';

interface Props {
  date?: Date;
  locale?: string | string[];
  timeZone?: string;
  showTime?: boolean;
  options?: DateTimeFormatOptions;
}

function DateFormat({ date, locale, timeZone, showTime, options }: Props) {
  if (!date) {
    return null;
  }
  locale = locale || 'en-GB';
  timeZone = timeZone || 'UTC';
  const formatOptions: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone,
    timeZoneName: showTime ? 'short' : undefined,
    ...(showTime && {
      hour: '2-digit',
      minute: '2-digit',
    }),
    ...options,
  };
  const int = new Intl.DateTimeFormat(locale, formatOptions);
  const dateString = int.format(date);
  return <>{dateString}</>;
}

export default DateFormat;
