import Page from '../../../../components/Page/Page';
import { FlexRow } from '../../../../components/Layout/Layout';
import String from '../../../../components/String/String';
import LinkButton from '../../../../components/LinkButton/LinkButton';

import './ExpiredQuotePage.scss';
import { useParams } from 'react-router-dom';
import React from 'react';

function ExpiredQuotePage() {
  const { id: productId } = useParams<{ id: string }>();

  function PageBody() {
    return (
      <div className="ExpiredQuote">
        <div className="ExpiredQuote-body">
          <h2>
            <String id="pages.expired_quote.title" />
          </h2>
          <div>
            <String id="pages.expired_quote.body_first" />
          </div>
          <br />
          <div>
            <String id="pages.expired_quote.body_second" />
          </div>
          <FlexRow>
            <LinkButton
              className="ExpiredQuote-button"
              testId="goto_dashboard-linkbutton"
              to={`/product/${productId}`}
              variant="primary"
            >
              <String id="pages.expired_quote.goto_dashboard" />
            </LinkButton>
          </FlexRow>
        </div>
      </div>
    );
  }

  return (
    <Page name="ExpiredMta" slug="Expired Quote" padded={false}>
      <PageBody />
    </Page>
  );
}

export default ExpiredQuotePage;
