import React, { PropsWithChildren, useMemo } from 'react';
import Button from 'components/Buttons/Button';
import Modal from '../Modal';
import { useStrings } from '../../../hooks';
import String from '../../String/String';
import { Configuration } from 'configuration';

import './ContactModal.scss';

interface Props {
  onCancel(): void;
}

function ContactModal({ onCancel }: PropsWithChildren<Props>) {
  const { get } = useStrings();

  const configuration = Configuration.get();

  const telephoneStringId = 'links.contact.phone_number.US';

  const openingHoursStringId = useMemo(() => {
    switch (configuration.region) {
      case 'us':
        return 'pages.menu.contact.opening_hours.US';
      default:
        return 'page.menu.contact.chat.opening_hours';
    }
  }, [configuration]);

  const emailStringId = 'links.contact.email.US';

  return (
    <Modal
      title={get('pages.menu.contact.title')}
      className="ContactModal"
      data-testid="contact-modal"
      onClose={onCancel}
      actions={
        <Button variant="secondary" onClick={onCancel} testId="contact-close">
          <String id="pages.menu.contact.button.close" />
        </Button>
      }
    >
      {configuration.region === 'us' ? (
        <>
          <section>
            <h4>
              <String id="pages.menu.contact.label.telephone" />
            </h4>
            <p>
              <a href={`tel:${get(telephoneStringId)}`}>
                <String id={telephoneStringId} />
              </a>
              <br />
              <String id={openingHoursStringId} />
            </p>
          </section>
          <section>
            <h4>
              <String id="pages.menu.contact.label.email" />
            </h4>
            <a href={`mailto:${get(emailStringId)}`}>
              <String id={emailStringId} />
            </a>
          </section>
        </>
      ) : (
        <section>
          <h4>
            <String id="modal.contact.title" />
          </h4>
          <h4>
            <String id="modal.chat.link.title" />
          </h4>
          <p>
            <a href={`${get('links.contact.chat')}`}>
              <String id={'modal.chat.contact_us'} />
            </a>
            <br />
            <String id={openingHoursStringId} />
          </p>
        </section>
      )}
    </Modal>
  );
}

export default ContactModal;
