import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth } from 'providers/AuthProvider';
import { AuthenticationError, ErrorCodes } from 'constants/errors';
import { useEffectAsync, useQuery } from 'hooks';

import EventManager, { EventType } from 'utils/EventManager';

import Page from 'components/Page/Page';
import { Centered } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import Redirect from 'components/Router/Redirect';

function CallbackPage({ history }: RouteComponentProps) {
  const { handleCallback } = useAuth();

  const query = useQuery<{ error?: string }>();

  const [error, setError] = useState<string | undefined>(query.error);

  useEffectAsync(async () => {
    if (error) {
      return;
    }

    try {
      const { redirectUri, productId } = await handleCallback();

      history.replace(redirectUri ?? '/');

      EventManager.track({
        event: 'LoginSucceeded',
        productId: productId,
      });
      EventManager.track({
        event: EventType.LoginSuccessful,
        label: EventType.LoginSuccessful,
        productId: productId,
      });
    } catch (e) {
      EventManager.track({
        event: EventType.LoginFailed,
        label: EventType.LoginFailed,
      });
      setError(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function PageBody() {
    if (error === 'access_denied') {
      return <Redirect to="/permissions" />;
    }

    if (error) {
      throw new AuthenticationError(ErrorCodes.AUTHENTICATION_ERROR, error);
    }

    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return (
    <Page name="CallbackPage" slug="Logging in...">
      <PageBody />
    </Page>
  );
}

export default CallbackPage;
