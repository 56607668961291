import configuration from '../configuration';
import { openFile } from './fileUtils';
import { format } from 'date-fns';
import {
  IDocumentTypeFragment,
  IGetPolicyDocumentsQuery,
  IPolicyDetailFragment,
} from 'shared/graphql/api/types';
import { DocumentTypeDataModelEnum } from '@inshur/apis/document';

export enum DOC_FORMAT {
  PDF = 'pdf',
  HTML = 'html',
}

export function generateBlobFormat(blob: Blob, docFormat: DOC_FORMAT): Blob {
  return docFormat === DOC_FORMAT.HTML
    ? new Blob([blob], { type: 'text/html; charset=utf-8' })
    : new Blob([blob], { type: 'application/pdf' });
}

export async function getDocumentPdf(
  id: string,
  name: string,
  uri: string,
  accessToken: string
) {
  const pdfUrl = `${configuration.api.base}/${configuration.region}/${configuration.api.document}/document/${id}/pdf`;

  try {
    const response = await fetch(pdfUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const blob = await response.blob();

    const docFormat = uri.includes('.html') ? DOC_FORMAT.HTML : DOC_FORMAT.PDF;
    const formatedBlob = generateBlobFormat(blob, docFormat);

    openFile(
      formatedBlob,
      `${name}_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${docFormat}`
    );
  } catch (e) {
    throw new Error('An error occurred retrieving document');
  }
}

export function getExpectedDocumentTypes(
  policy: IPolicyDetailFragment
): IDocumentTypeFragment[] {
  return policy.product.documentTypes.filter(
    (documentType) =>
      documentType.dataModel === DocumentTypeDataModelEnum.POLICY &&
      // handle ipid and any future package-specific documentation
      (documentType.packageId === policy.package.id || !documentType.packageId)
  );
}

export function getMissingDocumentTypes(
  documents: IGetPolicyDocumentsQuery | undefined,
  expectedDocumentTypes: IDocumentTypeFragment[]
): IDocumentTypeFragment[] {
  if (
    !!documents?.documents &&
    documents?.documents.length !== expectedDocumentTypes.length
  ) {
    const presentDocumentTypeIds = documents.documents.map(
      (type) => type.typeId
    );
    return expectedDocumentTypes.filter(
      (documentType) => !presentDocumentTypeIds.includes(documentType.id)
    );
  }
  return [];
}

export function getDisplayName(name: string, productCode: string): string {
  // reuse of old templates for newer product versions e.g. uko -> uko2
  const codeWithoutNumbers = productCode.replace(/\d/g, '');
  // break apart new US-R-CA format codes
  const separatedProductCode = productCode.split('-R-');
  name = name
    .replace(new RegExp(codeWithoutNumbers, 'ig'), '')
    .replace(/[-_]/g, ' ')
    .replace(new RegExp(productCode, 'ig'), '')
    .replace(/v\d/, '');
  separatedProductCode.forEach((part) => (name = name.replace(part, '')));
  return name.trim();
}
