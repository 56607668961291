import { ApolloError } from '@apollo/client';
import {
  ICreatePolicyMutationVariables,
  useCreatePolicyMutation,
} from 'shared/graphql/api/types';

interface CreatePolicy {
  create: (
    input: ICreatePolicyMutationVariables['input']
  ) => Promise<boolean | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
  policy: boolean | undefined;
}

export const useCreatePolicy = (): CreatePolicy => {
  const [createPolicy, { loading, data, error }] = useCreatePolicyMutation();

  const create = async (input: ICreatePolicyMutationVariables['input']) => {
    const { data } = await createPolicy({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.policy;
  };

  return {
    create,
    loading,
    error,
    policy: data?.policy,
  };
};
