import React, { createContext, PropsWithChildren, useContext } from 'react';
import { IUserFragment, useGetUserQuery } from 'shared/graphql/api/types';
import Loader from 'components/Loader/Loader';
import { ApplicationError, ErrorCodes } from '../constants/errors';
import VerificationPage from 'pages/auth/VerificationPage/VerificationPage';
import { validationRequired } from '../utils/userUtils';

export const UserContext = createContext<IUserFragment>({} as any);

export const useUser = () => useContext(UserContext);

function UserProvider({ children }: PropsWithChildren<unknown>) {
  const { loading, data, error, refetch } = useGetUserQuery();

  if (loading) {
    return <Loader />;
  }

  const user = data?.user;

  if (!user || error) {
    throw new ApplicationError(
      error?.message || 'No user found',
      ErrorCodes.NO_USER
    );
  }

  if (validationRequired(user)) {
    return <VerificationPage user={user} fetchUser={refetch} />;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default UserProvider;
