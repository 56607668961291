import React from 'react';
import { Statement } from '@inshur/apis/steps';
import EligibilityStatement from '../ElegibilityStatement/EligibilityStatement';

import './EligibilitySection.scss';

interface Props {
  title?: string;
  statements: Statement[];
  showInputs: boolean;
  onAgree(statement: Statement): void;
}

function EligibilitySection({ title, statements, showInputs, onAgree }: Props) {
  return (
    <div className="EligibilitySection">
      <p className="EligibilitySection-title">{title}</p>
      {statements.map((s) => {
        return (
          <EligibilityStatement
            key={s.text}
            text={s.text}
            showInput={showInputs}
            onAgreed={() => onAgree(s)}
          />
        );
      })}
      <br />
    </div>
  );
}

export default EligibilitySection;
