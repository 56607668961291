import { ILocale } from 'shared/graphql/api/types';

export function getLocale(
  pathname: string,
  accepted: ILocale[],
  defaultValue: ILocale
): ILocale {
  const [, locale] = pathname.toLowerCase().split('/');

  if (accepted.find((a) => a.toLowerCase() === locale)) {
    return locale as ILocale;
  }

  return defaultValue;
}

export function getProductId(pathname: string) {
  const PRODUCT_ID_REGEX = /.*product\/(?<productId>[^/]+)/;
  return getProductIdFromUrl(pathname, PRODUCT_ID_REGEX);
}

export function getMtaPolicyId(pathname: string) {
  const PRODUCT_ID_REGEX = /.*mta\/(?<productId>[^/]+)/;
  return getProductIdFromUrl(pathname, PRODUCT_ID_REGEX);
}

function getProductIdFromUrl(pathname: string, PRODUCT_ID_REGEX: RegExp) {
  const match = pathname.match(PRODUCT_ID_REGEX);

  if (!match || !match.groups) {
    return undefined;
  }

  const { productId } = match.groups;

  if (!isUUID(productId)) {
    return undefined;
  }

  return productId;
}

export function isUUID(uuid: string) {
  let s = '' + uuid;

  let result = s.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  return result !== null;
}
