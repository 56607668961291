import _ from 'lodash';

import DateFormat from 'components/Date/Date';
import { useLocale } from 'providers/LocaleProvider';
import OpenQuote from 'components/OpenQuote/OpenQuote';
import String from 'components/String/String';
import OpenQuoteGroup from 'components/OpenQuote/OpenQuoteGroup';
import {
  getValuationRange,
  openQuotesRenewalsEnabled,
  adjustedQuoteStartDate,
} from 'utils/quoteUtils';

import {
  IProductDetailsFragment,
  IQuoteDetailsFragment,
} from 'shared/graphql/api/types';

import './QuotesTab.scss';

interface Props {
  activeQuotes?: IQuoteDetailsFragment[];
  product?: IProductDetailsFragment;
}

function QuotesTab({ product, activeQuotes }: Props) {
  const { locale } = useLocale();

  const showRenewalBanner = openQuotesRenewalsEnabled();

  const quoteGroups = _.groupBy(activeQuotes?.reverse(), (value) => {
    return value.quoteGroupId ?? '';
  });

  return (
    <div data-testid="quotes_tab">
      {activeQuotes && activeQuotes.length > 0 ? (
        <div className="QuotesTab">
          {Object.keys(quoteGroups).map((quoteGroupId) => {
            return quoteGroupId ? (
              <OpenQuoteGroup
                key={quoteGroupId}
                quoteGroup={quoteGroups[quoteGroupId]}
                product={product}
              />
            ) : null;
          })}

          {activeQuotes.map((quote) => {
            const {
              id,
              quoteNumber,
              datasheet,
              startDate,
              packages,
              quoteGroupId,
            } = quote;
            const valuationRange = getValuationRange(
              packages,
              locale,
              product!
            );

            const formattedStartDate = startDate && (
              <DateFormat
                date={adjustedQuoteStartDate({ startDate })}
                timeZone={product?.timezone}
                locale={locale}
              />
            );

            // already displayed in a group
            if (quoteGroupId) return null;

            return (
              <OpenQuote
                key={quoteNumber}
                quoteId={id}
                quoteNumber={quoteNumber}
                vehicle={`${datasheet.vehicles[0].make} ${datasheet.vehicles[0].model}`}
                startDate={formattedStartDate}
                price={valuationRange}
                duration={datasheet.policy.duration}
                productId={product?.id}
                renewalOf={quote.renewalOf?.id}
                showRenewalBanner={showRenewalBanner}
                quoteStatus={quote.status}
              />
            );
          })}
        </div>
      ) : (
        <div className="QuotesTab-no-quotes" data-testid="quotes_tab.no_quotes">
          <p className="QuotesTab-no-quotes-title">
            <String id="pages.product.dashboard.quotes_tab.no_quotes.title" />
          </p>
          <p className="QuotesTab-no-quotes-message">
            <String id="pages.product.dashboard.quotes_tab.no_quotes.message" />
          </p>
        </div>
      )}
    </div>
  );
}

export default QuotesTab;
