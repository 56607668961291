import React from 'react';
import String from '../String/String';
import Currency from 'components/Currency/Currency';

export interface InstalmentPrices {
  inFullAmount: number;
  instalmentAmount: number;
}
interface Props {
  prices: InstalmentPrices;
  planType: 'instalments' | 'finance';
  currency: string;
}

function OpenQuotePlanPrices({ prices, planType, currency }: Props) {
  return (
    <>
      <p className="OpenQuote-section OpenQuoteGroup-day-quote">
        <String id={`pages.open_quote.${planType}.monthly`} />
      </p>
      <p
        data-testid="duration-instalment-price"
        className="OpenQuote-section OpenQuote-price"
      >
        <Currency value={prices?.instalmentAmount} currency={currency} />
      </p>
      <p className="OpenQuote-section OpenQuoteGroup-day-quote">
        <String id={`pages.open_quote.${planType}.one-time`} />
      </p>
      <p
        data-testid="duration-inFull-price"
        className="OpenQuote-section OpenQuote-price"
      >
        <Currency value={prices?.inFullAmount} currency={currency} />
      </p>
    </>
  );
}

export default OpenQuotePlanPrices;
