import React from 'react';
import { IProduct } from 'types/product';

import { FlexColumn, FlexRow } from '../../Layout/Layout';
import { format, getType, getValue } from '../utils/builderUtils';
import { IComponent, ISection, IValues } from '../Schema';
import Detail from '../Detail/Detail';
import String from '../../String/String';

interface SectionProps {
  section: ISection;
  content: IValues;
  product: IProduct;
  locale: string;
  translationKey: string;
}

function renderTitle(name: string, translationKey: string) {
  return (
    <div className="Overview-detail">
      <div className="Overview-detail-value medium">
        <String id={`${translationKey}.${name}.title`} />
      </div>
    </div>
  );
}

function renderComponents(
  sectionName: string,
  components: IComponent[],
  translationKey: string,
  values: IValues,
  product: IProduct,
  locale: string
) {
  return components.map((component) => {
    if (Array.isArray(component)) {
      return (
        <FlexRow>
          {component.map((_component: IComponent) => {
            const { label, content } = _component;
            const type = getType(_component, values);
            const value = getValue(_component, values);

            if (type === null || value === null) {
              return null;
            }

            return (
              <FlexColumn>
                <Detail
                  key={`${translationKey}.${label}`}
                  testId={`${sectionName}-overview-${label}`}
                  label={`${translationKey}.${label}`}
                  value={format({
                    type,
                    value,
                    product,
                    values,
                    translationKey,
                    variables: content.values,
                  })}
                />
              </FlexColumn>
            );
          })}
        </FlexRow>
      );
    }

    const { label, content } = component;
    const type = getType(component, values);
    const value = getValue(component, values);

    if (type === null || value === null) {
      return null;
    }

    return (
      <Detail
        key={`${translationKey}.${label}`}
        testId={`${sectionName}-overview-${label}`}
        label={`${translationKey}.${label}`}
        value={format({
          type,
          value,
          product,
          translationKey,
          values,
          locale,
          variables: content.values,
        })}
      />
    );
  });
}

function Section({
  section,
  content,
  translationKey,
  locale,
  product,
}: SectionProps) {
  const { name, components, title } = section;

  return (
    <div className="Overview" key={`${name}-section`}>
      {title && renderTitle(name, translationKey)}
      {renderComponents(
        name,
        components,
        translationKey + '.' + name,
        content,
        product,
        locale
      )}
    </div>
  );
}

export default Section;
