import React from 'react';
import { ErrorMessage } from '../Error/Error';

const debug = require('debug')('customer-web:error');

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<any, State> {
  state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    debug('ERROR', error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (!errorInfo) {
      return <>{children}</>;
    }

    return <ErrorMessage error={error} info={errorInfo} />;
  }
}

export default ErrorBoundary;
