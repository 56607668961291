import React from 'react';

import SyncLoader from 'react-spinners/SyncLoader';

import './Loader.scss';

function Loader({ size = 10, color = '#868f9e' }) {
  return (
    <div className="Loader" data-testid="loader">
      <SyncLoader size={size} color={color} />
    </div>
  );
}

export default Loader;
