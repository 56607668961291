import React, { useState } from 'react';
import { AnswerQuestion } from 'providers/QuoteProvider';
import { IQuote, IQuotePackage } from 'types/quote';

import String from 'components/String/String';
import { useStrings } from 'hooks';

import { PoliciesQuestion } from 'shared/types/question';

import QuoteOverviewModal from './QuoteOverview/QuoteOverviewModal';

import './PolicyInput.scss';

interface Props {
  question: PoliciesQuestion;
  onAnswer: AnswerQuestion;
}

function PolicyInput({ onAnswer }: Props) {
  const [displayQuote, setDisplayQuote] = useState(false);
  const { get } = useStrings();

  function viewPolicyOptions() {
    setDisplayQuote(true);
  }

  function onClose(value: { package: IQuotePackage; quote: IQuote }) {
    setDisplayQuote(false);

    const { name } = value.package;

    const answer = get('pages.quote.policy.selected', { name });

    onAnswer(answer, value);
  }

  return (
    <div className="Chatbot-actions">
      {displayQuote && <QuoteOverviewModal onClose={onClose} />}
      <button
        className="Chatbot-action"
        onClick={viewPolicyOptions}
        tabIndex={0}
        data-testid="policy-input-button"
      >
        <String id="pages.quote.chatbot.view_policy" />
      </button>
    </div>
  );
}

export default PolicyInput;
