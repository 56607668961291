interface PaymentOverviewParams {
  showIpt: boolean;
  showDriverName: boolean;
  showVehicle: boolean;
  showStartDate: boolean;
  showEndDate: boolean;
  showClaimFreeYears: boolean;
  showProtectedNcd: boolean;
}

const defaultParams = {
  showIpt: true,
  showDriverName: true,
  showVehicle: true,
  showStartDate: true,
  showEndDate: true,
  showClaimFreeYears: true,
  showProtectedNcd: true,
};

const paramsByCode: Record<string, PaymentOverviewParams> = {
  UKA: {
    ...defaultParams,
    showIpt: false,
    showClaimFreeYears: false,
    showProtectedNcd: false,
  },
};

const paramsByCountry: Record<string, PaymentOverviewParams> = {
  US: {
    ...defaultParams,
    showIpt: false,
    showClaimFreeYears: false,
    showProtectedNcd: false,
  },
};

/**
 * Returns product params for a given product. Primary lookup is by product code,
 * falling back to product country if provided. Default params are returned if
 * there is no match.
 * @param productCode To be taken from `product.code`
 * @param productCountry To be taken from `product.location.country`
 */
export const getProductParameters = (
  productCode: string,
  productCountry: string
): PaymentOverviewParams => {
  return (
    paramsByCode[productCode] ??
    paramsByCountry[productCountry] ??
    defaultParams
  );
};
