import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import LocalStorage from 'utils/LocalStorage';
import { getRedirectPath } from 'utils/pageUtils';

import { useAuth } from 'providers/AuthProvider';
import { useStrings } from 'hooks';

import Page from 'components/Page/Page';
import String from 'components/String/String';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';

import Agreements from './Agreements/Agreements';
import Agreement from './Agreements/Agreement/Agreement';

import './TermsPage.scss';
import EventManager, { EventType } from '../../utils/EventManager';

function TermsPage({ history }: RouteComponentProps) {
  const { claims } = useAuth();
  const { get } = useStrings();

  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);

  const canProceed = agreedTerms && agreedPrivacy;

  function onProceed() {
    if (!claims) {
      return;
    }

    LocalStorage.set(`${claims.sub}:terms-agreed`, true);
    LocalStorage.set(`${claims.sub}:privacy-agreed`, true);

    const redirect = getRedirectPath(window.location.search);
    EventManager.track({
      event: EventType.TermsAgreed,
      label: EventType.TermsAgreed,
    });
    history.push(redirect || '/');
  }
  EventManager.track({
    event: EventType.TermsShown,
    label: EventType.TermsShown,
  });
  return (
    <Page name="TermsPage" slug="Terms" showLogout={false}>
      <div className="TermsPage-hero">
        <div className="TermsPage-hero-intro">
          <div className="mascot">
            <Avatar />
          </div>
          <div>
            <String id="pages.terms.intro" />
          </div>
        </div>
        <Agreements>
          <Agreement
            text={get('pages.terms.terms_of_business')}
            link={get('links.tob')}
            agreed={agreedTerms}
            setAgreed={setAgreedTerms}
            dataTestId="agreement-terms-of-business"
          />
          <Agreement
            text={get('pages.terms.privacy_policy')}
            link={get('links.privacy_policy')}
            agreed={agreedPrivacy}
            setAgreed={setAgreedPrivacy}
            dataTestId="agreement-privacy-policy"
          />
        </Agreements>
      </div>
      <div className="Partners">
        <Button
          onClick={onProceed}
          disabled={!canProceed}
          variant="primary"
          testId="terms-buttons"
        >
          <String id="pages.terms.proceed" />
        </Button>
      </div>
    </Page>
  );
}

export default TermsPage;
