import React, { ChangeEvent } from 'react';
import String from 'components/String/String';
import './Checkbox.scss';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  stringId?: string;
  dataTestId: string;
  onChecked?(checked: boolean): void;
}

function Checkbox({
  stringId,
  isChecked,
  value,
  onChange,
  dataTestId,
  children,
  onChecked,
  ...props
}: CheckboxProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange?.(event);
    onChecked?.(event.target.checked);
  }

  return (
    <label className="Checkbox" data-testid={dataTestId}>
      <input
        className="Checkbox-input"
        type="checkbox"
        checked={isChecked}
        value={value}
        onChange={handleChange}
        {...props}
      />

      <span className="Checkbox-checkmark" />

      {stringId && (
        <span className="Checkbox-label">
          <String id={stringId} />
        </span>
      )}
    </label>
  );
}

export default Checkbox;
