import React, { useEffect, useRef, useState } from 'react';

import { useChatbot } from 'providers/ChatbotProvider';
import { usePreQuote } from 'providers/QuoteProvider';

import Messages from './Messages/Messages';
import ChatbotInput from './ChatbotInput/ChatbotInput';

import { ChatbotStyled } from './styled';

import './Chatbot.scss';
import ProgressBarPercent from 'components/ProgressBar/ProgressBarPercent';
import configuration from 'configuration';

function Chatbot({ onClose }: { onClose?: () => void }) {
  const { currentQuestion, showInput, showInputDelay, progress } =
    usePreQuote();
  const { messages } = useChatbot();

  const [questionLoaded, setQuestionLoaded] = useState(false);
  const [avatarDirection, setAvatarDirection] = useState<
    'normal' | 'right' | 'down'
  >('normal');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showInput) {
      if (showInputDelay) {
        setTimeout(() => {
          setQuestionLoaded(true);
        }, showInputDelay + 500);
        setTimeout(() => {
          setAvatarDirection('down');
        }, showInputDelay + 800);
      } else {
        setQuestionLoaded(true);
      }
    } else {
      setQuestionLoaded(false);
      setAvatarDirection('right');
    }
  }, [showInput, showInputDelay]);

  return (
    <ChatbotStyled className="Chatbot">
      {configuration.releaseFlag.displayProgressBar && (
        <ProgressBarPercent progress={progress} />
      )}

      <Messages
        onLeaveButtonClick={onClose}
        messages={messages}
        showInput={showInput}
        avatarDirection={avatarDirection}
        scrollRef={ref}
      />
      {showInput && (
        <div className="Chatbot-footer" style={{ flexShrink: 0 }}>
          {currentQuestion && questionLoaded && (
            <ChatbotInput scrollRef={ref} question={currentQuestion} />
          )}
        </div>
      )}
    </ChatbotStyled>
  );
}

export default Chatbot;
