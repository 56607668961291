import configuration from '../configuration';
import Logger from './Logger';
import { getFullDaysBetween } from '../shared/utils/dateUtils';

const logger = Logger.create('adjustProductIdBasedOnDate');

// TODO: remove this code after 1st of October Jira ticket F2-1477
export const adjustProductIdBasedOnDate = (id: string): string => {
  const versionMapping: {
    [key: string]: {
      newProduct: string | undefined;
      oldProduct: string;
    };
  } = {
    ukc: {
      newProduct: configuration.productId.UKC2,
      oldProduct: configuration.productId.UKC,
    },
    uko: {
      newProduct: configuration.productId.UKO3,
      oldProduct: configuration.productId.UKO2,
    },
    ukr: {
      newProduct: configuration.productId.UKR3,
      oldProduct: configuration.productId.UKR2,
    },
    uka: {
      newProduct: configuration.productId.UKA2,
      oldProduct: configuration.productId.UKA,
    },
    ukb: {
      newProduct: configuration.productId.UKB2,
      oldProduct: configuration.productId.UKB,
    },
    ukbl: {
      newProduct: configuration.productId.UKBL2,
      oldProduct: configuration.productId.UKBL,
    },
    ukog: {
      newProduct: configuration.productId.UKOG2,
      oldProduct: configuration.productId.UKOG,
    },
  };

  const cutOffDate = configuration.wakamUkProductsStartTime
    ? new Date(configuration.wakamUkProductsStartTime)
    : new Date('2024-10-01T00:00:00Z');
  const currentDate = new Date(); // Use the current date

  const daysUntilCutOff = getFullDaysBetween(
    currentDate.toISOString(),
    cutOffDate.toISOString()
  );
  for (const product in versionMapping) {
    const { newProduct, oldProduct } = versionMapping[product];

    if (!newProduct) {
      logger.info(`newProduct is undefined returning ID ${id}`);
      return id;
    }

    if (id === newProduct) {
      if (daysUntilCutOff > 0) {
        logger.info(
          `ID ${id} matches current and date is before cutoff. Returning previous ID: ${oldProduct}`
        );
        return oldProduct;
      }
      logger.info(
        `ID ${id} matches current but date is on or after cutoff. Returning current ID.`
      );
      return newProduct;
    }
    if (id === oldProduct) {
      if (daysUntilCutOff <= 0) {
        logger.info(
          `ID ${id} matches previous and date is on or after cutoff. Returning current ID: ${newProduct}`
        );
        return newProduct ?? id;
      }
      logger.info(
        `ID ${id} matches previous but date is before cutoff. Returning previous ID.`
      );
      return oldProduct;
    }
  }

  logger.info(`No match found for ID ${id}. Returning original ID.`);
  return id;
};
