import QuoteOverviewPage from 'pages/product/quote/QuoteOverviewPage/QuoteOverviewPage';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import QuotePage from './QuotePage';

function QuoteRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={QuotePage} />
      <Route
        path={`${match.path}/:quoteId/package/:packageId/overview`}
        component={QuoteOverviewPage}
      />
    </Switch>
  );
}

export default QuoteRoute;
