import type { Feature } from '@inshur/nowucit/features/Feature';
import LocalStorage from 'utils/LocalStorage';

export type LocalStorageFeature = {
  type: 'feature';
  name: string;
  value: boolean;
};

export const getLocalFlags = (
  collectionName: string
): LocalStorageFeature[] => {
  return LocalStorage.get(`local_flags:${collectionName}`);
};

export const setLocalFlag = (flag: Feature, collectionName: string): void => {
  const flagToSet: LocalStorageFeature = {
    name: flag.name,
    value: flag.value,
    type: 'feature',
  };

  const localFlags = LocalStorage.get(
    `local_flags:${collectionName}`
  ) as LocalStorageFeature[];

  const flagsToSet = localFlags.map((item) => {
    if (item.name !== flagToSet.name) {
      return { ...item };
    }

    return { ...flagToSet };
  });

  LocalStorage.set(`local_flags:${collectionName}`, flagsToSet);
};

export const setLocalFlags = (
  flags: LocalStorageFeature[],
  collectionName: string
): void => {
  LocalStorage.set(`local_flags:${collectionName}`, flags);
};
