import React, { createContext, PropsWithChildren } from 'react';
import { useGetApplication } from 'hooks';
import { useLocale } from './LocaleProvider';
import TRANSLATIONS from '../constants/translations';

export const StringContext = createContext<any>(undefined);

interface Props {
  defaultValue?: any;
}

function StringProvider({ children }: PropsWithChildren<Props>) {
  const { lang: locale } = useLocale();

  const { application } = useGetApplication();

  const defaults = TRANSLATIONS[locale];

  return (
    <StringContext.Provider value={{ ...defaults, ...application?.strings }}>
      {children}
    </StringContext.Provider>
  );
}

export default StringProvider;
