import { SchemaBuilder } from 'components/Builder/Builder';
import { default as UKR } from './schema.UKR.json';
import { default as UKC } from './schema.UKC.json';
import { default as UKA } from './schema.UKA.json';
import { default as UKO } from './schema.UKO.json';
import { default as UKB } from './schema.UKB.json';
import { default as NBV } from './schema.NBV.json';
import { default as NYR } from './schema.NYR.json';
import { default as UKOG } from './schema.UKOG.json';
import { default as AZR } from './schema.AZR.json';
import { default as US_TAXI } from './schema.US-TAXI.json';
import { default as ANCIL } from './schema.ANCIL.json';

export default SchemaBuilder.create({
  ANCIL,
  UKR,
  UKC,
  UKO,
  UKB,
  UKBL: UKB,
  NBV,
  NBVO: NBV,
  NYR,
  NYRS: NYR,
  UKA,
  UKOG,
  AZR,
  'US-R-CA': US_TAXI,
  'US-R-GA': US_TAXI,
  'US-R-CO': US_TAXI,
  'US-R-NY': US_TAXI,
  'US-R-WA': US_TAXI,
  'US-R-TX': US_TAXI,
});
