import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import React, { PropsWithChildren, useMemo } from 'react';
import merge from 'deepmerge';

import { defaultTheme } from 'themes';
import { useApplication } from 'providers/ApplicationProvider';

interface Props {
  theme?: DefaultTheme;
}

function ThemeProvider({ children }: PropsWithChildren<Props>) {
  const [application] = useApplication();

  // Merge the theme from the server into the default theme
  const theme = useMemo(
    () => merge(defaultTheme, application?.theme?.styles || {}),
    [application]
  );

  // TODO: Consider that if the request is loading, we might not want to show the UI yet...

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}

export default ThemeProvider;
