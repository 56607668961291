import { PropsWithChildren } from 'react';
import { IPolicy, useGetInstalmentPlanQuery } from 'shared/graphql/api/types';
import { useStrings } from '../../../hooks';
import String from '../../String/String';
import Modal from '../Modal';
import { getProductLocale } from 'utils/productUtils';
import Button from 'components/Buttons/Button';
import DateFormat from 'components/Date/Date';
import Currency from 'components/Currency/Currency';
import './PaymentScheduleModal.scss';

interface Props {
  policy: IPolicy;
  onCancel(): void;
}

function PaymentScheduleModal({ onCancel, policy }: PropsWithChildren<Props>) {
  const { get } = useStrings();

  const { data, error, loading } = useGetInstalmentPlanQuery({
    variables: {
      policyId: policy.id,
      policyVersion: policy.version!,
    },
  });

  const instalmentPlan = data?.instalmentPlan;

  return (
    <Modal
      title={get('pages.product.dashboard.modal.payment_schedule.title')}
      className="PaymentScheduleModal"
      dataTestId="payment-schedule-modal"
      onClose={onCancel}
      actionsFlow="row"
      loading={loading}
      actions={
        <Button
          variant="secondary"
          onClick={onCancel}
          testId="payment_schedule_cancel"
        >
          <String id="commons.ok" />
        </Button>
      }
    >
      {error ? (
        <String id="pages.product.dashboard.modal.payment_schedule.error" />
      ) : (
        instalmentPlan && (
          <table className="PaymentSchedule-table">
            <thead>
              <tr>
                <th>
                  <String id="pages.product.dashboard.modal.payment_schedule.date" />
                </th>
                <th>
                  <String id="pages.product.dashboard.modal.payment_schedule.type" />
                </th>
                <th>
                  <String id="pages.product.dashboard.modal.payment_schedule.amount" />
                </th>
              </tr>
            </thead>
            <tbody data-testid="payment-schedule-table-body">
              {instalmentPlan.paymentSchedule.map((scheduleItem) => (
                <tr key={scheduleItem.date}>
                  <td>
                    <DateFormat
                      date={new Date(scheduleItem.date)}
                      locale={getProductLocale(policy.product)}
                      timeZone={policy.product.timezone}
                      options={{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }}
                    />
                  </td>
                  <td>
                    <String
                      id={`enums.payment_schedule.${scheduleItem.type}`}
                    />
                  </td>
                  <td>
                    <Currency
                      currency={policy.product.currency}
                      value={scheduleItem.amount}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </Modal>
  );
}

export default PaymentScheduleModal;
