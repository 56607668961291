import { useCallback, useState } from 'react';

/**
 * Same as React.useRef, except this will cause a re-render when "current" is populated
 */
export function useRef<T>(): [T | undefined, (node: T) => void] {
  const [current, setCurrent] = useState<T>();

  const ref = useCallback<(node: T) => void>((node) => {
    setCurrent(node);
  }, []);

  return [current, ref];
}
