import {
  IQuote,
  useGetOkQuotesForUserQuery,
  useGetQuoteQuery,
} from 'shared/graphql/api/types';

export function useQuote(id: string, ignoreCache?: boolean) {
  const { data, loading, error, refetch } = useGetQuoteQuery({
    variables: { id },
    fetchPolicy: ignoreCache ? 'no-cache' : 'cache-first',
  });

  return {
    loading,
    error,
    refetch,
    quote: data?.quote as IQuote,
  };
}

export function useGetQuotesForUser(productId?: string) {
  const { data, loading, error } = useGetOkQuotesForUserQuery({
    variables: { productId },
    fetchPolicy: 'no-cache',
  });

  return {
    loading,
    error,
    quotes: data?.quotes,
  };
}
