import {
  ICreatePaymentEventMutationVariables,
  useCreatePaymentEventMutation,
} from 'shared/graphql/api/types';

export const useCreatePaymentEvent = () => {
  const [createEvent, { loading, data }] = useCreatePaymentEventMutation();

  const create = (input: ICreatePaymentEventMutationVariables['input']) => {
    return createEvent({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  };

  return {
    create,
    loading,
    event: data?.event,
  };
};
