import React, { useState } from 'react';

import FormDriverModal from './FormDriverModal';
import FormTextField from './TextField';

import './FormDriver.scss';

interface Props {
  initialValue: string;
  handleDriversDetails: (value: any) => void;
}

function Driver({ initialValue, handleDriversDetails }: Props) {
  const [displayModal, setDisplayModal] = useState(false);

  const onClose = (licence: any) => {
    setDisplayModal(false);

    if (!licence) {
      return;
    }

    handleDriversDetails(licence);
  };

  return (
    <div className="dlnSearch">
      <FormDriverModal
        active={displayModal}
        onClose={onClose}
        dataTestId="dln-modal"
      />
      <FormTextField
        onFocus={() => setDisplayModal(true)}
        value={initialValue}
      />
    </div>
  );
}

export default Driver;
