import React, { ErrorInfo, useEffect } from 'react';
import configuration from 'configuration';
import { ApplicationError } from 'constants/errors';
import EventManager from 'utils/EventManager';
import { Centered } from 'components/Layout/Layout';
import String from 'components/String/String';
import './Error.scss';
import * as Sentry from '@sentry/react';
import { usePreQuote } from 'providers/QuoteProvider';
import TRANSLATIONS from 'constants/translations';
import ContactUsButton from 'components/Buttons/ContactUs/ContactUs';
import Avatar from 'components/Avatar';

interface Props {
  error?: Error | ApplicationError;
  info?: ErrorInfo;
}

export function ErrorMessage({ error, info }: Props) {
  const hasError = error || info;
  const { quoteType } = usePreQuote();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }

    if (error instanceof ApplicationError) {
      EventManager.track({
        event: 'GenericError',
        errorCode: error.code,
        productId: error.productId,
        quoteType,
        message: error.message,
      });
    } else {
      EventManager.track({
        event: 'GenericError',
        message: error?.message,
      });
    }
  }, [error, quoteType]);

  function onRestart() {
    window.location.reload();
  }

  return (
    <Centered className="Error">
      <Avatar expression="sad" />
      <div className="main-message">
        <String
          id="pages.error.message"
          defaultValue={TRANSLATIONS.en['pages.error.message']}
        />
      </div>
      <button
        onClick={onRestart}
        className="try-again"
        data-testid="error_restart_button"
      >
        <String
          id="pages.error.try_again"
          defaultValue={TRANSLATIONS.en['pages.error.try_again']}
        />
      </button>
      <ContactUsButton />
      {configuration.isDevelopment && hasError && (
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error?.toString()}
          <br />
          {info?.componentStack}
        </details>
      )}
    </Centered>
  );
}
