import React from 'react';
import Page from 'components/Page/Page';
import String from 'components/String/String';
import { Centered } from 'components/Layout/Layout';

function NotFoundPage() {
  return (
    <Page name="NotFound" slug="Not Found">
      <Centered>
        <h2>
          <String id="pages.not_found.message" />
        </h2>
      </Centered>
    </Page>
  );
}

export default NotFoundPage;
