import { Step } from '../../types/question'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A big int */
  BigInt: any;
  /** A date */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A list of steps */
  Steps: Array<Step>;
  /** A list of vehicles */
  Vehicles: { [make: string]: string[] };
};

export type IAddon = {
  amount: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  isSelected: Scalars['Boolean'];
  name: Scalars['String'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
  type: Scalars['String'];
};

export type IAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  postcode: Scalars['String'];
};

export type IAddressOverview = {
  description: Scalars['String'];
  highlight: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type IApiActionResponse = {
  data?: Maybe<Scalars['JSON']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type IApplication = {
  auth0: IAuth0Configuration;
  locale: Scalars['String'];
  strings: Scalars['JSON'];
  theme: ITheme;
  vehicles: Scalars['Vehicles'];
  version: Scalars['String'];
};

export type IAuth0Configuration = {
  connection?: Maybe<Scalars['String']>;
};

export type ICountry =
  | 'GB'
  | 'NL'
  | 'US';

export type ICoverage = {
  description: Scalars['String'];
  id: Scalars['ID'];
  max: Scalars['BigInt'];
  min: Scalars['BigInt'];
  name: Scalars['String'];
};

export type ICoverageOffer = {
  excess: Scalars['BigInt'];
  id: Scalars['ID'];
  limit: Scalars['BigInt'];
};

export type ICreateMtaPolicyInput = {
  mtaOf: IPolicyKeyInput;
  packageId: Scalars['String'];
  quoteId: Scalars['String'];
};

export type ICreatePaymentEventInput = {
  data: Scalars['JSON'];
  packageId: Scalars['ID'];
  paymentPlan: IPaymentPlanEnum;
  quoteId: Scalars['ID'];
};

export type ICreatePolicyDocumentInput = {
  documentTypeId: Scalars['String'];
  policyId: Scalars['String'];
  version: Scalars['String'];
};

export type ICreatePolicyInput = {
  packageId: Scalars['String'];
  quoteId: Scalars['String'];
};

export type ICreateQuoteDocumentInput = {
  documentTypeId: Scalars['String'];
  quoteId: Scalars['String'];
};

export type ICreateQuoteInput = {
  cancellationOf?: InputMaybe<IPolicyKeyInput>;
  datasheet: Scalars['JSON'];
  mtaOf?: InputMaybe<IPolicyKeyInput>;
  product: IProductKeyInput;
  quoteGroupId?: InputMaybe<Scalars['ID']>;
  renewalOf?: InputMaybe<IPolicyKeyInput>;
};

export type ICurrency =
  | 'EUR'
  | 'GBP'
  | 'USD';

export type IDocument = {
  documentType?: Maybe<IDocumentType>;
  id: Scalars['ID'];
  typeId: Scalars['ID'];
  uri: Scalars['String'];
  visibleToCustomer?: Maybe<Scalars['Boolean']>;
};

export type IDocumentType = {
  dataModel?: Maybe<IDocumentTypeDataModelEnum>;
  defaultCustomerVisibilty?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packageId?: Maybe<Scalars['String']>;
};

export type IDocumentTypeDataModelEnum =
  | 'POLICY'
  | 'QUOTE';

export type IDocumentTypeInput = {
  id: Scalars['ID'];
};

export type IDriverLookUpProvider =
  | 'JDPOWER'
  | 'MYLICENCE';

export type IFeature = {
  featureConfiguration?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  properties: Array<IFeatureProperty>;
  version: Scalars['String'];
};

export type IFeatureProperty = {
  description: Scalars['String'];
  id: Scalars['ID'];
  jsonPath: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  version: Scalars['String'];
};

export type IFinancePaymentPlan = {
  agreementTerm?: Maybe<Scalars['String']>;
  aprPercentage?: Maybe<Scalars['Float']>;
  cashPrice: Scalars['Int'];
  costOfCredit: Scalars['Int'];
  financeDownpayment?: Maybe<IFinancePaymentPlanDownpayment>;
  financeInstalment: IFinancePaymentPlanInstalment;
  paymentPlan: IPaymentPlanEnum;
  policyBindAmount: Scalars['Int'];
  tax: Scalars['Int'];
  total: Scalars['Int'];
};

export type IFinancePaymentPlanDownpayment = {
  fee?: Maybe<Scalars['Int']>;
  percentageOfPackageCost?: Maybe<Scalars['Float']>;
  total: Scalars['Int'];
  value: Scalars['Int'];
};

export type IFinancePaymentPlanInstalment = {
  fee?: Maybe<Scalars['Int']>;
  interestPercentage: Scalars['Float'];
  number: Scalars['Int'];
  period?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  value: Scalars['Int'];
};

export type IFlexWalletPaymentPlan = {
  initialTopupUnits: Scalars['Int'];
  minWalletBalance: Scalars['Int'];
  minWalletUnits: Scalars['Int'];
  paymentPlan: IPaymentPlanEnum;
  policyBindAmount: Scalars['Int'];
  topupAmount: Scalars['Int'];
  topupUnits: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

export type IGetDriverDetailsInput = {
  context?: InputMaybe<Scalars['String']>;
  licenceNumber: Scalars['String'];
  provider?: InputMaybe<IDriverLookUpProvider>;
};

export type IGetDriverDetailsUsInput = {
  birthDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  licenseNumber: Scalars['String'];
  state: Scalars['String'];
};

export type IGetTlcYearsHeldInput = {
  tlcLicense: Scalars['String'];
};

export type IIPackageValuation = {
  id: Scalars['ID'];
  name: Scalars['String'];
  offers: Array<ICoverageOffer>;
  status?: Maybe<IPackageValuationStatusEnum>;
  valuation?: Maybe<IValuation>;
};

export type IIPaymentPlans = {
  paymentPlans?: Maybe<Array<Maybe<IPaymentPlanSummary>>>;
};

export type IIdentity = {
  connection: Scalars['String'];
  provider: Scalars['String'];
};

export type IInFullPaymentPlan = {
  paymentPlan: IPaymentPlanEnum;
  policyBindAmount: Scalars['Int'];
  total: Scalars['Int'];
};

export type IInstalmentPlan = {
  paymentSchedule: Array<IPaymentScheduleItem>;
  policyId: Scalars['ID'];
  version: Scalars['String'];
};

export type IInstalmentsPaymentPlan = {
  downpayment: IInstalmentsPaymentPlanDownpayment;
  instalment: IInstalmentsPaymentPlanInstalment;
  numberOfInstalments: Scalars['Int'];
  paymentPlan: IPaymentPlanEnum;
  policyBindAmount: Scalars['Int'];
  total: Scalars['Int'];
};

export type IInstalmentsPaymentPlanDownpayment = {
  fee: Scalars['Int'];
  percentageOfPackageCost: Scalars['Float'];
  total: Scalars['Int'];
  value: Scalars['Int'];
};

export type IInstalmentsPaymentPlanInstalment = {
  fee: Scalars['Int'];
  total: Scalars['Int'];
  value: Scalars['Int'];
};

export type IInvoice = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['String'];
  paymentLink: Scalars['String'];
  quoteId: Scalars['String'];
  status: Scalars['String'];
};

export type ILineItem = {
  breakdown?: Maybe<Array<ILineItem>>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['Int'];
};

export type ILocale =
  | 'en'
  | 'nl';

export type ILocation = {
  country: ICountry;
};

export type ILyftProfile = {
  driversLicenseNumber?: Maybe<Scalars['String']>;
  driversLicenseState?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  rideCount?: Maybe<Scalars['Int']>;
  vehicleLicensePlateNumber?: Maybe<Scalars['String']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
};

export type IManualPaymentPlan = {
  paymentPlan: IPaymentPlanEnum;
};

export type IMarketingPreferences = {
  email: Scalars['String'];
  optIn: Scalars['Boolean'];
};

export type IMarketingPreferencesInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  optIn: Scalars['Boolean'];
};

export type IMtaDelta = {
  adjustmentAmount: Scalars['Int'];
  effectiveValuation: IValuation;
  newValuation: IValuation;
  originalValuation: IValuation;
  percentageOfTermRemaining: Scalars['Float'];
};

export type IMutation = {
  _?: Maybe<Scalars['Boolean']>;
  cancelPolicy: Scalars['Boolean'];
  createMtaPolicy: Scalars['Boolean'];
  createPaymentEvent: IPaymentEvent;
  createPaymentIntent: IPaymentIntent;
  createPolicy: Scalars['Boolean'];
  createPolicyDocument: IDocument;
  createQuote: IQuote;
  createQuoteDocument: IDocument;
  driver: IApiActionResponse;
  driverUs: IApiActionResponse;
  premiumCreditFitsInitiateLoanApplication: IPremiumCreditFitsInitiateLoanApplicationResponse;
  tlcYearsHeld: IApiActionResponse;
  uberDriverDetail: IApiActionResponse;
  updateAutoRenewalOptIn: IPolicyAutoRenew;
  updateAutoRenewalOptInStatus: Scalars['Boolean'];
  updateMarketingPreferences: IApiActionResponse;
  vehicleSearch: IApiActionResponse;
  vehicleSearchUs: IApiActionResponse;
};


export type IMutationCancelPolicyArgs = {
  id: Scalars['ID'];
};


export type IMutationCreateMtaPolicyArgs = {
  input: ICreateMtaPolicyInput;
};


export type IMutationCreatePaymentEventArgs = {
  input: ICreatePaymentEventInput;
};


export type IMutationCreatePaymentIntentArgs = {
  input: IPaymentIntentInput;
};


export type IMutationCreatePolicyArgs = {
  input: ICreatePolicyInput;
};


export type IMutationCreatePolicyDocumentArgs = {
  input: ICreatePolicyDocumentInput;
};


export type IMutationCreateQuoteArgs = {
  input: ICreateQuoteInput;
};


export type IMutationCreateQuoteDocumentArgs = {
  input: ICreateQuoteDocumentInput;
};


export type IMutationDriverArgs = {
  input?: InputMaybe<IGetDriverDetailsInput>;
};


export type IMutationDriverUsArgs = {
  input?: InputMaybe<IGetDriverDetailsUsInput>;
};


export type IMutationPremiumCreditFitsInitiateLoanApplicationArgs = {
  input: IPremiumCreditFitsInitiateLoanApplicationInput;
};


export type IMutationTlcYearsHeldArgs = {
  input?: InputMaybe<IGetTlcYearsHeldInput>;
};


export type IMutationUpdateAutoRenewalOptInArgs = {
  input: IUpdateAutoRenewalOptInInput;
};


export type IMutationUpdateAutoRenewalOptInStatusArgs = {
  input: IUpdateAutoRenewalOptInStatusInput;
};


export type IMutationUpdateMarketingPreferencesArgs = {
  input: IMarketingPreferencesInput;
};


export type IMutationVehicleSearchArgs = {
  input: IVehicleSearchInput;
};


export type IMutationVehicleSearchUsArgs = {
  input: IVehicleSearchUsInput;
};

export type IOuPolicy = {
  activeTo: Scalars['String'];
  datasheet: Scalars['JSON'];
  id: Scalars['ID'];
  policyNumber: Scalars['String'];
};


export type IOuPolicyDatasheetArgs = {
  map?: InputMaybe<Scalars['JSON']>;
};

export type IOuPolicyResponse = {
  policy?: Maybe<IOuPolicy>;
  status: IOuPolicyStatus;
};

export type IOuPolicyStatus =
  | 'INVALID'
  | 'NOT_FOUND'
  | 'UNAUTHORISED'
  | 'VALID';

export type IPackage = {
  id: Scalars['ID'];
  name: Scalars['String'];
  offers?: Maybe<Array<ICoverageOffer>>;
  pricingModel?: Maybe<IPricingModel>;
};

export type IPackageValuation = IIPackageValuation & {
  id: Scalars['ID'];
  name: Scalars['String'];
  offers: Array<ICoverageOffer>;
  pricingModel?: Maybe<IPricingModelEnum>;
  status?: Maybe<IPackageValuationStatusEnum>;
  valuation?: Maybe<IValuation>;
};

export type IPackageValuationStatusEnum =
  | 'DECLINED'
  | 'OK';

export type IPaymentEvent = {
  createdBy: Scalars['String'];
  createdOn: Scalars['Date'];
  data: Scalars['JSON'];
  dateTime: Scalars['Date'];
  id: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
  quote: IQuote;
  type: IPaymentEventType;
  userId: Scalars['ID'];
};

export type IPaymentEventType =
  | 'API_REFUND_RESULT'
  | 'APP_PAYMENT_INTENT'
  | 'APP_PAYMENT_RESULT'
  | 'STRIPE_INVOICE_PAYMENT_RESULT'
  | 'STRIPE_PAYMENT_RESULT'
  | 'STRIPE_REFUND_UPDATED';

export type IPaymentIntent = {
  secret: Scalars['String'];
  setupFutureUsage?: Maybe<Scalars['String']>;
};

export type IPaymentIntentInput = {
  amount: Scalars['Int'];
  currency: ICurrency;
  packageId: Scalars['ID'];
  paymentPlan: IPaymentPlanEnum;
  quoteId: Scalars['ID'];
};

export type IPaymentMethod =
  | 'CARD'
  | 'IDEAL'
  | 'SEPA_DEBIT';

export type IPaymentPlanEnum =
  | 'FINANCE'
  | 'FLEX_WALLET'
  | 'INSTALMENTS'
  | 'IN_FULL'
  | 'MANUAL_BROKER'
  | 'MANUAL_CARD'
  | 'MANUAL_PREMIUM_FINANCE';

export type IPaymentPlanSummary = IFinancePaymentPlan | IFlexWalletPaymentPlan | IInFullPaymentPlan | IInstalmentsPaymentPlan | IManualPaymentPlan;

export type IPaymentProvider = {
  account?: Maybe<IPaymentProviderAccount>;
  continuousPaymentAuthority?: Maybe<Scalars['Boolean']>;
  paymentMethods?: Maybe<Array<Maybe<IPaymentMethod>>>;
  provider: IPaymentProviderEnum;
};

export type IPaymentProviderAccount =
  | 'STRIPE_AZR'
  | 'STRIPE_DEV0'
  | 'STRIPE_NBV'
  | 'STRIPE_NYR'
  | 'STRIPE_NYRS'
  | 'STRIPE_PROD0'
  | 'STRIPE_SANDBOX'
  | 'STRIPE_TEST0'
  | 'STRIPE_UKA'
  | 'STRIPE_UKC'
  | 'STRIPE_UKO'
  | 'STRIPE_UKO2'
  | 'STRIPE_UKOG'
  | 'STRIPE_UKR'
  | 'STRIPE_UKR2'
  | 'STRIPE_UK_WAKAM'
  | 'STRIPE_US_R_CA'
  | 'STRIPE_US_R_CO'
  | 'STRIPE_US_R_GA'
  | 'STRIPE_US_R_NY'
  | 'STRIPE_US_R_TX'
  | 'STRIPE_US_R_WA';

export type IPaymentProviderEnum =
  | 'STRIPE';

export type IPaymentScheduleItem = {
  amount: Scalars['Int'];
  date: Scalars['Date'];
  type: IPaymentScheduleType;
};

export type IPaymentScheduleType =
  | 'DOWNPAYMENT'
  | 'INSTALMENT';

export type IPaymentStatus = {
  status: IPaymentStatusType;
  valueReceived: Scalars['Float'];
  valueRequired: Scalars['Float'];
};

export type IPaymentStatusType =
  | 'INSUFFICIENT'
  | 'OK';

export type IPolicy = {
  activeFrom: Scalars['String'];
  activeTo: Scalars['String'];
  canRenew: Scalars['Boolean'];
  createdOn: Scalars['String'];
  datasheet: Scalars['JSON'];
  id: Scalars['ID'];
  package: IPolicyPackageValuation;
  policyNumber: Scalars['String'];
  product: IProduct;
  productKey: IProductKey;
  quote: IQuote;
  quoteKey: IQuoteKey;
  status: IPolicyStatus;
  version?: Maybe<Scalars['String']>;
};

export type IPolicyAutoRenew = {
  autoRenewable: Scalars['Boolean'];
  autoRenewalOptIn: Scalars['Boolean'];
};

export type IPolicyKey = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
};

export type IPolicyKeyInput = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type IPolicyPackageValuation = IIPackageValuation & {
  id: Scalars['ID'];
  name: Scalars['String'];
  offers: Array<ICoverageOffer>;
  paymentPlan?: Maybe<IPaymentPlanSummary>;
  status?: Maybe<IPackageValuationStatusEnum>;
  valuation?: Maybe<IValuation>;
};

export type IPolicyStatus =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'EXPIRED'
  | 'INCEPTED'
  | 'NOT_TAKEN_UP_CANCELLATION'
  | 'NOT_TAKEN_UP_MTA'
  | 'NOT_TAKEN_UP_POLICY'
  | 'PENDING'
  | 'PENDING_CANCELLATION'
  | 'PENDING_MTA'
  | 'REINSTATED'
  | 'REPLACED'
  | 'UNKNOWN'
  | 'UNSET'
  | 'VOIDED';

export type IPremiumCreditFitsInitiateLoanApplicationInput = {
  backUrl: Scalars['String'];
  brokerUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
  confirmUrl: Scalars['String'];
  declineUrl: Scalars['String'];
  errorUrl: Scalars['String'];
  packageValuationId: Scalars['String'];
  quoteId: Scalars['String'];
};

export type IPremiumCreditFitsInitiateLoanApplicationResponse = {
  cancelUrl: Scalars['String'];
  errorUrl: Scalars['String'];
  fitk: Scalars['String'];
  redirectUrl: Scalars['String'];
  reqId: Scalars['String'];
  xid: Scalars['String'];
};

export type IPricingModel =
  | 'FIXED_TERM'
  | 'USAGE_BASED';

export type IPricingModelEnum =
  | 'FIXED_TERM'
  | 'USAGE_BASED';

export type IProduct = {
  code: Scalars['String'];
  coverages: Array<ICoverage>;
  currency: ICurrency;
  documentTypes: Array<IDocumentType>;
  features: Array<IFeature>;
  id: Scalars['ID'];
  language: Scalars['String'];
  locale: ILocale;
  location: ILocation;
  name: Scalars['String'];
  packages: Array<IPackage>;
  paymentProvider: IPaymentProvider;
  productDeprecated?: Maybe<IProductDeprecated>;
  riskCapture?: Maybe<IRiskCapture>;
  schema: Scalars['JSON'];
  settings: IProductSettings;
  steps: Scalars['Steps'];
  timezone: Scalars['String'];
  type: IProductType;
  version: Scalars['String'];
};

export type IProductDeprecated = {
  from: Scalars['ID'];
  successorId: Scalars['String'];
};

export type IProductKey = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
};

export type IProductKeyInput = {
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type IProductKeyType = {
  productId: Scalars['ID'];
  version: Scalars['String'];
};

export type IProductOptions = {
  code: Scalars['ID'];
  identities: Array<IIdentity>;
  location: ILocation;
  name: Scalars['String'];
  productId: Scalars['ID'];
};

export type IProductSettings = {
  maxRenewalLeadTimeHours: Scalars['Int'];
  maxStartLeadTimeHours: Scalars['Int'];
  mtaEnabled?: Maybe<Scalars['Boolean']>;
  paymentMethods: Array<IPaymentMethod>;
};

export type IProductType =
  | 'ANCILLARY'
  | 'COURIER'
  | 'OWN_GOODS'
  | 'TAXI';

export type IQuery = {
  _?: Maybe<Scalars['Boolean']>;
  address: Array<IAddress>;
  addresses: Array<IAddressOverview>;
  application: IApplication;
  getInitialDatasheet: Scalars['JSON'];
  getPaymentStatus: IPaymentStatus;
  getUserInvoices: Array<IInvoice>;
  instalmentPlan: IInstalmentPlan;
  policies: Array<IPolicy>;
  policiesAllVersions: Array<IPolicy>;
  policiesByQuote: Array<IPolicy>;
  policy: IPolicy;
  policyAutoRenew: IPolicyAutoRenew;
  policyDocuments: Array<IDocument>;
  policyFromOU: IOuPolicyResponse;
  policyVersion: IPolicy;
  product: IProduct;
  productByPolicy: IProduct;
  productOptionLatest?: Maybe<IProductOptions>;
  productOptions?: Maybe<IProductOptions>;
  productOptionsByCode?: Maybe<IProductOptions>;
  productOptionsSearch?: Maybe<Array<IProductOptions>>;
  productSteps: Scalars['Steps'];
  products: Array<IProduct>;
  quote: IQuote;
  quoteDocumentType: IDocumentType;
  quoteDocuments: Array<IDocument>;
  quoteGroup: Array<IQuote>;
  quotes: Array<IQuote>;
  renewalTracking: IRenewalTracking;
  schema?: Maybe<Scalars['JSON']>;
  user: IUser;
  userDatasheet: Scalars['JSON'];
  vehicle: Array<Scalars['String']>;
  vehicles: Scalars['Vehicles'];
};


export type IQueryAddressArgs = {
  id: Scalars['ID'];
};


export type IQueryAddressesArgs = {
  postcode: Scalars['String'];
};


export type IQueryApplicationArgs = {
  locale: ILocale;
};


export type IQueryGetPaymentStatusArgs = {
  packageId: Scalars['String'];
  quoteId: Scalars['String'];
};


export type IQueryGetUserInvoicesArgs = {
  userId: Scalars['String'];
};


export type IQueryInstalmentPlanArgs = {
  policyId: Scalars['ID'];
  policyVersion: Scalars['String'];
};


export type IQueryPoliciesArgs = {
  productId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<IPolicyStatus>>;
};


export type IQueryPoliciesAllVersionsArgs = {
  productId?: InputMaybe<Scalars['ID']>;
};


export type IQueryPoliciesByQuoteArgs = {
  id: Scalars['ID'];
};


export type IQueryPolicyArgs = {
  id: Scalars['ID'];
};


export type IQueryPolicyAutoRenewArgs = {
  policyId: Scalars['ID'];
};


export type IQueryPolicyDocumentsArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};


export type IQueryPolicyFromOuArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type IQueryPolicyVersionArgs = {
  id: Scalars['ID'];
  version: Scalars['String'];
};


export type IQueryProductArgs = {
  id: Scalars['ID'];
  locale: ILocale;
  stepsType?: InputMaybe<IStepsType>;
};


export type IQueryProductByPolicyArgs = {
  id: Scalars['ID'];
  locale: ILocale;
  stepsType: IStepsType;
};


export type IQueryProductOptionLatestArgs = {
  id: Scalars['ID'];
};


export type IQueryProductOptionsArgs = {
  id: Scalars['ID'];
};


export type IQueryProductOptionsByCodeArgs = {
  code: Scalars['ID'];
};


export type IQueryProductOptionsSearchArgs = {
  search: Scalars['String'];
};


export type IQueryProductStepsArgs = {
  locale: ILocale;
  productId: Scalars['ID'];
  productVersion: Scalars['String'];
  stepsType: IStepsType;
};


export type IQueryProductsArgs = {
  locale: ILocale;
  stepsType: IStepsType;
};


export type IQueryQuoteArgs = {
  id: Scalars['ID'];
};


export type IQueryQuoteDocumentTypeArgs = {
  typeId: Scalars['ID'];
};


export type IQueryQuoteDocumentsArgs = {
  id: Scalars['ID'];
};


export type IQueryQuoteGroupArgs = {
  groupId: Scalars['ID'];
};


export type IQueryQuotesArgs = {
  productId?: InputMaybe<Scalars['ID']>;
};


export type IQueryRenewalTrackingArgs = {
  policyId: Scalars['ID'];
};


export type IQuerySchemaArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};


export type IQueryVehicleArgs = {
  make: Scalars['ID'];
};

export type IQuote = {
  boundTo?: Maybe<IPolicyKey>;
  cancellationOf?: Maybe<IPolicyKey>;
  createdBy: Scalars['String'];
  createdOn: Scalars['Date'];
  datasheet: Scalars['JSON'];
  documents?: Maybe<Array<IDocument>>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedBy: Scalars['String'];
  lastModifiedOn: Scalars['Date'];
  mtaOf?: Maybe<IPolicyKey>;
  packages: Array<IQuotePackageValuation>;
  product: IProduct;
  quoteGroupId?: Maybe<Scalars['ID']>;
  quoteNumber: Scalars['String'];
  renewalOf?: Maybe<IPolicyKey>;
  startDate?: Maybe<Scalars['String']>;
  status: IQuoteStatus;
  userId: Scalars['ID'];
  validUntil: Scalars['Date'];
};

export type IQuoteKey = {
  id: Scalars['ID'];
};

export type IQuotePackageValuation = IIPackageValuation & IIPaymentPlans & {
  id: Scalars['ID'];
  name: Scalars['String'];
  offers: Array<ICoverageOffer>;
  packageValuationId?: Maybe<Scalars['ID']>;
  paymentPlans?: Maybe<Array<Maybe<IPaymentPlanSummary>>>;
  pricingModel?: Maybe<IPricingModelEnum>;
  status?: Maybe<IPackageValuationStatusEnum>;
  valuation?: Maybe<IValuation>;
};

export type IQuoteStatus =
  | 'DECLINED'
  | 'OK'
  | 'REFER';

export type IRating = {
  id: Scalars['String'];
};

export type IRenewalTracking = {
  autoRenewable: Scalars['Boolean'];
  autoRenewalOptIn: Scalars['Boolean'];
  autoRenewalQuoteId?: Maybe<Scalars['String']>;
  policyId: Scalars['String'];
  status: IRenewalTrackingStatus;
  version: Scalars['String'];
};

export type IRenewalTrackingNotFound = {
  autoRenewable?: Maybe<Scalars['Boolean']>;
  autoRenewalOptIn?: Maybe<Scalars['Boolean']>;
  autoRenewalQuoteId?: Maybe<Scalars['String']>;
  policyId: Scalars['String'];
  status?: Maybe<IRenewalTrackingStatus>;
  version?: Maybe<Scalars['String']>;
};

export type IRenewalTrackingStatus =
  | 'BIND_FAILED'
  | 'BIND_PROCESSED'
  | 'BIND_PROCESSING'
  | 'BIND_REQUIRED'
  | 'INACTIVE'
  | 'NONE'
  | 'REMINDER_AUTO_RENEWAL_PROCESSED'
  | 'REMINDER_DISABLED'
  | 'REMINDER_EASY_RENEWAL_PROCESSED'
  | 'REMINDER_ONLY_PROCESSED'
  | 'REMINDER_PROCESSING'
  | 'REMINDER_REQUIRED'
  | 'REMINDER_UNABLE_TO_QUOTE_PROCESSED';

export type IRiskCapture = {
  form: IRiskCaptureConfig;
  steps: IRiskCaptureConfig;
};

export type IRiskCaptureConfig = {
  schema: Scalars['String'];
  version: Scalars['String'];
};

export type IStepsType =
  | 'MIGRATION'
  | 'MTA'
  | 'NEW_BUSINESS'
  | 'RENEWAL';

export type ITheme = {
  styles: Scalars['JSON'];
  tags: Array<Scalars['String']>;
  version: Scalars['String'];
};

export type IUberProfile = {
  rating: Scalars['Float'];
  tier: Scalars['String'];
  trips: Scalars['Int'];
};

export type IUpdateAutoRenewalOptInInput = {
  optIn: Scalars['Boolean'];
  policyId: Scalars['String'];
};

export type IUpdateAutoRenewalOptInStatusInput = {
  optIn: Scalars['Boolean'];
  policyId: Scalars['String'];
  version: Scalars['String'];
};

export type IUser = {
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identities: Array<IIdentity>;
  lastName?: Maybe<Scalars['String']>;
  lyft?: Maybe<ILyftProfile>;
  marketingPreferences?: Maybe<IMarketingPreferences>;
  telephone: Scalars['String'];
  uber?: Maybe<IUberProfile>;
};

export type IValuation = {
  addons?: Maybe<Array<IAddon>>;
  lineItems?: Maybe<ILineItem>;
  mta?: Maybe<IMtaDelta>;
  premium: Scalars['Int'];
  rating?: Maybe<IRating>;
  tax: Scalars['Int'];
  total: Scalars['Int'];
};

export type IVehicle = {
  bodyType: Scalars['String'];
  engineCapacityCc: Scalars['Int'];
  grossWeightKgs: Scalars['Int'];
  insuranceGroup: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  numberOfSeats: Scalars['Int'];
  registrationNumber: Scalars['String'];
  transmissionType: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['Float'];
  valueWhenNew: Scalars['Float'];
  vin: Scalars['String'];
  yearOfManufacture: Scalars['String'];
};

export type IVehicleLookupProvider =
  | 'CDL'
  | 'JDPOWER';

export type IVehicleSearchInput = {
  registrationNumber: Scalars['String'];
};

export type IVehicleSearchUsInput = {
  region: Scalars['String'];
  vin: Scalars['String'];
};

export type ICreatePolicyDocumentMutationVariables = Exact<{
  input: ICreatePolicyDocumentInput;
}>;


export type ICreatePolicyDocumentMutation = { document: IDocumentFragment };

export type ICreateQuoteDocumentMutationVariables = Exact<{
  input: ICreateQuoteDocumentInput;
}>;


export type ICreateQuoteDocumentMutation = { document: IDocumentFragment };

export type IGetDriverDetailsMutationVariables = Exact<{
  input: IGetDriverDetailsInput;
}>;


export type IGetDriverDetailsMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IGetDriverDetailsUsMutationVariables = Exact<{
  input: IGetDriverDetailsUsInput;
}>;


export type IGetDriverDetailsUsMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IGetTlcLicenseYearsHeldMutationVariables = Exact<{
  input: IGetTlcYearsHeldInput;
}>;


export type IGetTlcLicenseYearsHeldMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type ICreatePaymentIntentMutationVariables = Exact<{
  input: IPaymentIntentInput;
}>;


export type ICreatePaymentIntentMutation = { intent: { secret: string, setupFutureUsage?: string | null } };

export type ICreatePaymentEventMutationVariables = Exact<{
  input: ICreatePaymentEventInput;
}>;


export type ICreatePaymentEventMutation = { event: { id: string } };

export type ICreateMtaPolicyMutationVariables = Exact<{
  input: ICreateMtaPolicyInput;
}>;


export type ICreateMtaPolicyMutation = { policy: boolean };

export type ICreatePolicyMutationVariables = Exact<{
  input: ICreatePolicyInput;
}>;


export type ICreatePolicyMutation = { policy: boolean };

export type ICancelPolicyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ICancelPolicyMutation = { id: boolean };

export type IPremiumCreditFitsInitiateLoanApplicationMutationVariables = Exact<{
  input: IPremiumCreditFitsInitiateLoanApplicationInput;
}>;


export type IPremiumCreditFitsInitiateLoanApplicationMutation = { premiumCreditFitsInitiateLoanApplication: { xid: string, reqId: string, fitk: string, errorUrl: string, cancelUrl: string, redirectUrl: string } };

export type ICreateQuoteMutationVariables = Exact<{
  input: ICreateQuoteInput;
}>;


export type ICreateQuoteMutation = { quote: IQuoteDetailsFragment };

export type IUpdateAutoRenewalOptInMutationVariables = Exact<{
  input: IUpdateAutoRenewalOptInInput;
}>;


export type IUpdateAutoRenewalOptInMutation = { updateAutoRenewalOptIn: { autoRenewalOptIn: boolean } };

export type IGetUberDriverDetailMutationVariables = Exact<{ [key: string]: never; }>;


export type IGetUberDriverDetailMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IUpdateMarketingPreferencesMutationVariables = Exact<{
  input: IMarketingPreferencesInput;
}>;


export type IUpdateMarketingPreferencesMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IGetVehicleDetailsMutationVariables = Exact<{
  input: IVehicleSearchInput;
}>;


export type IGetVehicleDetailsMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IGetVehicleDetailsUsMutationVariables = Exact<{
  input: IVehicleSearchUsInput;
}>;


export type IGetVehicleDetailsUsMutation = { response: { success: boolean, error?: string | null, data?: any | null } };

export type IGetAddressesQueryVariables = Exact<{
  postcode: Scalars['String'];
}>;


export type IGetAddressesQuery = { addresses: Array<{ id: string, text: string }> };

export type IGetAddressQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetAddressQuery = { address: Array<{ id: string, label: string, line1: string, line2: string, city: string, county: string, postcode: string, country: string }> };

export type IGetApplicationQueryVariables = Exact<{
  locale: ILocale;
}>;


export type IGetApplicationQuery = { application: { strings: any, theme: { version: string, tags: Array<string>, styles: any } } };

export type IGetInstalmentPlanQueryVariables = Exact<{
  policyId: Scalars['ID'];
  policyVersion: Scalars['String'];
}>;


export type IGetInstalmentPlanQuery = { instalmentPlan: { policyId: string, version: string, paymentSchedule: Array<{ type: IPaymentScheduleType, amount: number, date: any }> } };

export type IGetUserInvoicesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type IGetUserInvoicesQuery = { getUserInvoices: Array<{ invoiceNumber: string, amount: number, currency: string, status: string, quoteId: string, paymentLink: string, dueDate?: string | null }> };

export type IGetPaymentStatusQueryVariables = Exact<{
  quoteId: Scalars['String'];
  packageId: Scalars['String'];
}>;


export type IGetPaymentStatusQuery = { status: { valueReceived: number, valueRequired: number, status: IPaymentStatusType } };

export type IPolicyDetailFragment = { id: string, version?: string | null, policyNumber: string, status: IPolicyStatus, datasheet: any, activeFrom: string, activeTo: string, createdOn: string, canRenew: boolean, package: { id: string, name: string, valuation?: { total: number, tax: number } | null, paymentPlan?: IFinancePaymentPlanDetailsFragment | IFlexWalletPaymentPlanDetailsFragment | { paymentPlan: IPaymentPlanEnum, policyBindAmount: number, total: number } | IInstalmentPaymentPlanDetailsFragment | { paymentPlan: IPaymentPlanEnum } | null }, quote: { id: string, renewalOf?: { id: string } | null }, product: IProductDetailsFragment };

export type IGetPolicyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetPolicyQuery = { policy: IPolicyDetailFragment };

export type IGetPolicyVersionQueryVariables = Exact<{
  id: Scalars['ID'];
  version: Scalars['String'];
}>;


export type IGetPolicyVersionQuery = { policyVersion: IPolicyDetailFragment };

export type IGetPolicyFromOuQueryVariables = Exact<{
  id: Scalars['ID'];
  token: Scalars['String'];
  map?: InputMaybe<Scalars['JSON']>;
}>;


export type IGetPolicyFromOuQuery = { response: { status: IOuPolicyStatus, policy?: { id: string, policyNumber: string, datasheet: any, activeTo: string } | null } };

export type IGetDashboardPoliciesQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<IPolicyStatus> | IPolicyStatus>;
}>;


export type IGetDashboardPoliciesQuery = { policies: Array<IPolicyDetailFragment> };

export type IGetAllPoliciesQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']>;
}>;


export type IGetAllPoliciesQuery = { policiesAllVersions: Array<IPolicyDetailFragment> };

export type IGetPoliciesQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<IPolicyStatus> | IPolicyStatus>;
}>;


export type IGetPoliciesQuery = { policies: Array<{ id: string, version?: string | null, policyNumber: string, status: IPolicyStatus, canRenew: boolean, datasheet: any, activeTo: string, product: { id: string } }> };

export type IGetPoliciesByQuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetPoliciesByQuoteQuery = { policies: Array<IPolicyDetailFragment> };

export type IGetPolicyDocumentsQueryVariables = Exact<{
  id: Scalars['ID'];
  version: Scalars['String'];
}>;


export type IGetPolicyDocumentsQuery = { documents: Array<IDocumentFragment> };

export type IDocumentTypeFragment = { id: string, name: string, dataModel?: IDocumentTypeDataModelEnum | null, packageId?: string | null, defaultCustomerVisibilty?: boolean | null };

export type IProductSummaryFragment = { id: string, name: string, code: string, productDeprecated?: { from: string, successorId: string } | null };

export type IProductDetailsFragment = { id: string, name: string, type: IProductType, language: string, version: string, code: string, schema: any, currency: ICurrency, timezone: string, steps: Array<Step>, productDeprecated?: { from: string, successorId: string } | null, location: { country: ICountry }, settings: { maxStartLeadTimeHours: number, maxRenewalLeadTimeHours: number, paymentMethods: Array<IPaymentMethod>, mtaEnabled?: boolean | null }, coverages: Array<{ id: string, name: string, description: string, min: any, max: any }>, packages: Array<{ id: string, name: string, pricingModel?: IPricingModel | null }>, documentTypes: Array<IDocumentTypeFragment>, paymentProvider: { account?: IPaymentProviderAccount | null, continuousPaymentAuthority?: boolean | null } };

export type IGetProductQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: ILocale;
  stepsType?: InputMaybe<IStepsType>;
}>;


export type IGetProductQuery = { product: IProductDetailsFragment };

export type IGetProductStepsQueryVariables = Exact<{
  productId: Scalars['ID'];
  productVersion: Scalars['String'];
  locale: ILocale;
  stepsType: IStepsType;
}>;


export type IGetProductStepsQuery = { steps: Array<Step> };

export type IGetProductByPolicyQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: ILocale;
  stepsType: IStepsType;
}>;


export type IGetProductByPolicyQuery = { productByPolicy: IProductDetailsFragment };

export type IGetProductSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
}>;


export type IGetProductSchemaQuery = { schema?: any | null };

export type IGetProductsQueryVariables = Exact<{
  locale: ILocale;
  stepsType: IStepsType;
}>;


export type IGetProductsQuery = { products: Array<IProductSummaryFragment> };

export type IGetProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: ILocale;
  stepsType: IStepsType;
}>;


export type IGetProductDetailsQuery = { product: { id: string, name: string, version: string, features: Array<{ id: string, name: string, properties: Array<{ id: string, jsonPath: string }> }> } };

export type IGetProductOptionLatestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetProductOptionLatestQuery = { productOptionLatest?: { productId: string, name: string, code: string, location: { country: ICountry }, identities: Array<{ connection: string, provider: string }> } | null };

export type IGetProductOptionsSearchQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type IGetProductOptionsSearchQuery = { productOptionsSearch?: Array<{ productId: string, name: string, code: string, location: { country: ICountry }, identities: Array<{ connection: string, provider: string }> }> | null };

export type IGetProductOptionsByCodeQueryVariables = Exact<{
  code: Scalars['ID'];
}>;


export type IGetProductOptionsByCodeQuery = { productOptions?: { productId: string, name: string, code: string, location: { country: ICountry }, identities: Array<{ connection: string, provider: string }> } | null };

export type IDocumentFragment = { id: string, typeId: string, uri: string, visibleToCustomer?: boolean | null, documentType?: IDocumentTypeFragment | null };

export type IInstalmentPaymentPlanDetailsFragment = { numberOfInstalments: number, paymentPlan: IPaymentPlanEnum, policyBindAmount: number, total: number, downpayment: { fee: number, value: number, total: number, percentageOfPackageCost: number }, instalment: { fee: number, value: number, total: number } };

export type IFlexWalletPaymentPlanDetailsFragment = { paymentPlan: IPaymentPlanEnum, policyBindAmount: number, initialTopupUnits: number, unitPrice: number, minWalletUnits: number, topupUnits: number, minWalletBalance: number, topupAmount: number };

export type IFinancePaymentPlanDetailsFragment = { paymentPlan: IPaymentPlanEnum, policyBindAmount: number, aprPercentage?: number | null, agreementTerm?: string | null, total: number, tax: number, costOfCredit: number, cashPrice: number, financeDownpayment?: { fee?: number | null, value: number, percentageOfPackageCost?: number | null, total: number } | null, financeInstalment: { number: number, period?: string | null, fee?: number | null, value: number, total: number, interestPercentage: number } };

export type IQuotePackageFragment = { id: string, packageValuationId?: string | null, name: string, status?: IPackageValuationStatusEnum | null, pricingModel?: IPricingModelEnum | null, valuation?: IPackageValuationFragment | null, paymentPlans?: Array<IFinancePaymentPlanDetailsFragment | IFlexWalletPaymentPlanDetailsFragment | { paymentPlan: IPaymentPlanEnum, policyBindAmount: number, total: number } | IInstalmentPaymentPlanDetailsFragment | { paymentPlan: IPaymentPlanEnum } | null> | null };

export type IPackageValuationFragment = { tax: number, total: number, premium: number, mta?: { effectiveValuation: { total: number, tax: number, premium: number } } | null, addons?: Array<{ name: string, type: string, amount: number, tax: number, total: number, isHidden: boolean, isSelected: boolean }> | null, lineItems?: { id: string, value: number, type: string, name: string, breakdown?: Array<{ id: string, value: number, type: string, name: string, breakdown?: Array<{ id: string, value: number, type: string, name: string }> | null }> | null } | null };

export type IQuoteDetailsFragment = { id: string, status: IQuoteStatus, createdOn: any, createdBy: string, validUntil: any, quoteNumber: string, datasheet: any, startDate?: string | null, endDate?: string | null, quoteGroupId?: string | null, product: IProductDetailsFragment, packages: Array<IQuotePackageFragment>, documents?: Array<IDocumentFragment> | null, mtaOf?: { id: string, version?: string | null } | null, renewalOf?: { id: string, version?: string | null } | null, cancellationOf?: { id: string, version?: string | null } | null, boundTo?: { id: string, version?: string | null } | null };

export type IGetQuotesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetQuotesQuery = { quotes: Array<IQuoteDetailsFragment> };

export type IGetOkQuotesForUserQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']>;
}>;


export type IGetOkQuotesForUserQuery = { quotes: Array<IQuoteDetailsFragment> };

export type IGetQuoteGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type IGetQuoteGroupQuery = { quoteGroup: Array<IQuoteDetailsFragment> };

export type IGetQuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetQuoteQuery = { quote: IQuoteDetailsFragment };

export type IGetQuoteDocumentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetQuoteDocumentsQuery = { documents: Array<IDocumentFragment> };

export type IGetQuoteDocumentTypeQueryVariables = Exact<{
  typeId: Scalars['ID'];
}>;


export type IGetQuoteDocumentTypeQuery = { documentType: IDocumentTypeFragment };

export type IGetInitialDatasheetQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetInitialDatasheetQuery = { datasheet: any };

export type IGetPolicyAutoRenewQueryVariables = Exact<{
  policyId: Scalars['ID'];
}>;


export type IGetPolicyAutoRenewQuery = { policyAutoRenew: { autoRenewable: boolean, autoRenewalOptIn: boolean } };

export type IGetRenewalTrackingQueryVariables = Exact<{
  policyId: Scalars['ID'];
}>;


export type IGetRenewalTrackingQuery = { renewalTracking: { status: IRenewalTrackingStatus, policyId: string, version: string, autoRenewable: boolean, autoRenewalOptIn: boolean, autoRenewalQuoteId?: string | null } };

export type IUserFragment = { firstName?: string | null, lastName?: string | null, email: string, emailVerified: boolean, telephone: string, uber?: { trips: number, rating: number, tier: string } | null, lyft?: { driversLicenseNumber?: string | null, driversLicenseState?: string | null, rating?: number | null, rideCount?: number | null, vehicleLicensePlateNumber?: string | null, vehicleMake?: string | null, vehicleModel?: string | null, vehicleVin?: string | null, vehicleYear?: number | null } | null, marketingPreferences?: { email: string, optIn: boolean } | null, identities: Array<{ provider: string, connection: string }> };

export type IGetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserQuery = { user: IUserFragment };

export type IGetUserDatasheetQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserDatasheetQuery = { userDatasheet: any };

export type IGetVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetVehiclesQuery = { vehicles: { [make: string]: string[] } };

export type IGetVehicleQueryVariables = Exact<{
  make: Scalars['ID'];
}>;


export type IGetVehicleQuery = { vehicle: Array<string> };

export const InstalmentPaymentPlanDetailsFragmentDoc = gql`
    fragment InstalmentPaymentPlanDetails on InstalmentsPaymentPlan {
  downpayment {
    fee
    value
    total
    percentageOfPackageCost
  }
  instalment {
    fee
    value
    total
  }
  numberOfInstalments
  paymentPlan
  policyBindAmount
  total
}
    `;
export const FinancePaymentPlanDetailsFragmentDoc = gql`
    fragment FinancePaymentPlanDetails on FinancePaymentPlan {
  paymentPlan
  policyBindAmount
  aprPercentage
  agreementTerm
  total
  tax
  costOfCredit
  cashPrice
  financeDownpayment {
    fee
    value
    percentageOfPackageCost
    total
  }
  financeInstalment {
    number
    period
    fee
    value
    total
    interestPercentage
  }
}
    `;
export const FlexWalletPaymentPlanDetailsFragmentDoc = gql`
    fragment FlexWalletPaymentPlanDetails on FlexWalletPaymentPlan {
  paymentPlan
  policyBindAmount
  initialTopupUnits
  unitPrice
  minWalletUnits
  topupUnits
  minWalletBalance
  topupAmount
}
    `;
export const DocumentTypeFragmentDoc = gql`
    fragment DocumentType on DocumentType {
  id
  name
  dataModel
  packageId
  defaultCustomerVisibilty
}
    `;
export const ProductDetailsFragmentDoc = gql`
    fragment ProductDetails on Product {
  id
  name
  type
  language
  version
  code
  schema
  currency
  timezone
  productDeprecated {
    from
    successorId
  }
  location {
    country
  }
  settings {
    maxStartLeadTimeHours
    maxRenewalLeadTimeHours
    paymentMethods
    mtaEnabled
  }
  coverages {
    id
    name
    description
    min
    max
  }
  packages {
    id
    name
    pricingModel
  }
  steps
  documentTypes {
    ...DocumentType
  }
  paymentProvider {
    account
    continuousPaymentAuthority
  }
}
    ${DocumentTypeFragmentDoc}`;
export const PolicyDetailFragmentDoc = gql`
    fragment PolicyDetail on Policy {
  id
  version
  policyNumber
  status
  datasheet
  activeFrom
  activeTo
  createdOn
  package {
    id
    name
    valuation {
      total
      tax
    }
    paymentPlan {
      ... on InstalmentsPaymentPlan {
        ...InstalmentPaymentPlanDetails
      }
      ... on ManualPaymentPlan {
        paymentPlan
      }
      ... on InFullPaymentPlan {
        paymentPlan
        policyBindAmount
        total
      }
      ... on FinancePaymentPlan {
        ...FinancePaymentPlanDetails
      }
      ... on FlexWalletPaymentPlan {
        ...FlexWalletPaymentPlanDetails
      }
    }
  }
  quote {
    id
    renewalOf {
      id
    }
  }
  canRenew
  product {
    ...ProductDetails
  }
}
    ${InstalmentPaymentPlanDetailsFragmentDoc}
${FinancePaymentPlanDetailsFragmentDoc}
${FlexWalletPaymentPlanDetailsFragmentDoc}
${ProductDetailsFragmentDoc}`;
export const ProductSummaryFragmentDoc = gql`
    fragment ProductSummary on Product {
  id
  name
  code
  productDeprecated {
    from
    successorId
  }
}
    `;
export const PackageValuationFragmentDoc = gql`
    fragment PackageValuation on Valuation {
  tax
  total
  premium
  mta {
    effectiveValuation {
      total
      tax
      premium
    }
  }
  addons {
    name
    type
    amount
    tax
    total
    isHidden
    isSelected
  }
  lineItems {
    id
    value
    type
    name
    breakdown {
      id
      value
      type
      name
      breakdown {
        id
        value
        type
        name
      }
    }
  }
}
    `;
export const QuotePackageFragmentDoc = gql`
    fragment QuotePackage on QuotePackageValuation {
  id
  packageValuationId
  name
  status
  pricingModel
  valuation {
    ...PackageValuation
  }
  paymentPlans {
    ... on InstalmentsPaymentPlan {
      ...InstalmentPaymentPlanDetails
    }
    ... on ManualPaymentPlan {
      paymentPlan
    }
    ... on InFullPaymentPlan {
      paymentPlan
      policyBindAmount
      total
    }
    ... on FinancePaymentPlan {
      ...FinancePaymentPlanDetails
    }
    ... on FlexWalletPaymentPlan {
      ...FlexWalletPaymentPlanDetails
    }
  }
}
    ${PackageValuationFragmentDoc}
${InstalmentPaymentPlanDetailsFragmentDoc}
${FinancePaymentPlanDetailsFragmentDoc}
${FlexWalletPaymentPlanDetailsFragmentDoc}`;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  id
  typeId
  uri
  visibleToCustomer
  documentType {
    ...DocumentType
  }
}
    ${DocumentTypeFragmentDoc}`;
export const QuoteDetailsFragmentDoc = gql`
    fragment QuoteDetails on Quote {
  id
  status
  createdOn
  createdBy
  validUntil
  quoteNumber
  datasheet
  startDate
  endDate
  product {
    ...ProductDetails
  }
  packages {
    ...QuotePackage
  }
  documents {
    ...Document
  }
  mtaOf {
    id
    version
  }
  renewalOf {
    id
    version
  }
  cancellationOf {
    id
    version
  }
  boundTo {
    id
    version
  }
  quoteGroupId
}
    ${ProductDetailsFragmentDoc}
${QuotePackageFragmentDoc}
${DocumentFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  firstName
  lastName
  email
  emailVerified
  telephone
  uber {
    trips
    rating
    tier
  }
  lyft {
    driversLicenseNumber
    driversLicenseState
    rating
    rideCount
    vehicleLicensePlateNumber
    vehicleMake
    vehicleModel
    vehicleVin
    vehicleYear
  }
  marketingPreferences {
    email
    optIn
  }
  identities {
    provider
    connection
  }
}
    `;
export const CreatePolicyDocumentDocument = gql`
    mutation CreatePolicyDocument($input: CreatePolicyDocumentInput!) {
  document: createPolicyDocument(input: $input) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;
export type ICreatePolicyDocumentMutationFn = Apollo.MutationFunction<ICreatePolicyDocumentMutation, ICreatePolicyDocumentMutationVariables>;

/**
 * __useCreatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyDocumentMutation, { data, loading, error }] = useCreatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ICreatePolicyDocumentMutation, ICreatePolicyDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreatePolicyDocumentMutation, ICreatePolicyDocumentMutationVariables>(CreatePolicyDocumentDocument, options);
      }
export type CreatePolicyDocumentMutationHookResult = ReturnType<typeof useCreatePolicyDocumentMutation>;
export type CreatePolicyDocumentMutationResult = Apollo.MutationResult<ICreatePolicyDocumentMutation>;
export type CreatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<ICreatePolicyDocumentMutation, ICreatePolicyDocumentMutationVariables>;
export const CreateQuoteDocumentDocument = gql`
    mutation CreateQuoteDocument($input: CreateQuoteDocumentInput!) {
  document: createQuoteDocument(input: $input) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;
export type ICreateQuoteDocumentMutationFn = Apollo.MutationFunction<ICreateQuoteDocumentMutation, ICreateQuoteDocumentMutationVariables>;

/**
 * __useCreateQuoteDocumentMutation__
 *
 * To run a mutation, you first call `useCreateQuoteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteDocumentMutation, { data, loading, error }] = useCreateQuoteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ICreateQuoteDocumentMutation, ICreateQuoteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateQuoteDocumentMutation, ICreateQuoteDocumentMutationVariables>(CreateQuoteDocumentDocument, options);
      }
export type CreateQuoteDocumentMutationHookResult = ReturnType<typeof useCreateQuoteDocumentMutation>;
export type CreateQuoteDocumentMutationResult = Apollo.MutationResult<ICreateQuoteDocumentMutation>;
export type CreateQuoteDocumentMutationOptions = Apollo.BaseMutationOptions<ICreateQuoteDocumentMutation, ICreateQuoteDocumentMutationVariables>;
export const GetDriverDetailsDocument = gql`
    mutation GetDriverDetails($input: GetDriverDetailsInput!) {
  response: driver(input: $input) {
    success
    error
    data
  }
}
    `;
export type IGetDriverDetailsMutationFn = Apollo.MutationFunction<IGetDriverDetailsMutation, IGetDriverDetailsMutationVariables>;

/**
 * __useGetDriverDetailsMutation__
 *
 * To run a mutation, you first call `useGetDriverDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDriverDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDriverDetailsMutation, { data, loading, error }] = useGetDriverDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDriverDetailsMutation(baseOptions?: Apollo.MutationHookOptions<IGetDriverDetailsMutation, IGetDriverDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetDriverDetailsMutation, IGetDriverDetailsMutationVariables>(GetDriverDetailsDocument, options);
      }
export type GetDriverDetailsMutationHookResult = ReturnType<typeof useGetDriverDetailsMutation>;
export type GetDriverDetailsMutationResult = Apollo.MutationResult<IGetDriverDetailsMutation>;
export type GetDriverDetailsMutationOptions = Apollo.BaseMutationOptions<IGetDriverDetailsMutation, IGetDriverDetailsMutationVariables>;
export const GetDriverDetailsUsDocument = gql`
    mutation GetDriverDetailsUs($input: GetDriverDetailsUsInput!) {
  response: driverUs(input: $input) {
    success
    error
    data
  }
}
    `;
export type IGetDriverDetailsUsMutationFn = Apollo.MutationFunction<IGetDriverDetailsUsMutation, IGetDriverDetailsUsMutationVariables>;

/**
 * __useGetDriverDetailsUsMutation__
 *
 * To run a mutation, you first call `useGetDriverDetailsUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDriverDetailsUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDriverDetailsUsMutation, { data, loading, error }] = useGetDriverDetailsUsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDriverDetailsUsMutation(baseOptions?: Apollo.MutationHookOptions<IGetDriverDetailsUsMutation, IGetDriverDetailsUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetDriverDetailsUsMutation, IGetDriverDetailsUsMutationVariables>(GetDriverDetailsUsDocument, options);
      }
export type GetDriverDetailsUsMutationHookResult = ReturnType<typeof useGetDriverDetailsUsMutation>;
export type GetDriverDetailsUsMutationResult = Apollo.MutationResult<IGetDriverDetailsUsMutation>;
export type GetDriverDetailsUsMutationOptions = Apollo.BaseMutationOptions<IGetDriverDetailsUsMutation, IGetDriverDetailsUsMutationVariables>;
export const GetTlcLicenseYearsHeldDocument = gql`
    mutation GetTlcLicenseYearsHeld($input: GetTlcYearsHeldInput!) {
  response: tlcYearsHeld(input: $input) {
    success
    error
    data
  }
}
    `;
export type IGetTlcLicenseYearsHeldMutationFn = Apollo.MutationFunction<IGetTlcLicenseYearsHeldMutation, IGetTlcLicenseYearsHeldMutationVariables>;

/**
 * __useGetTlcLicenseYearsHeldMutation__
 *
 * To run a mutation, you first call `useGetTlcLicenseYearsHeldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTlcLicenseYearsHeldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTlcLicenseYearsHeldMutation, { data, loading, error }] = useGetTlcLicenseYearsHeldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTlcLicenseYearsHeldMutation(baseOptions?: Apollo.MutationHookOptions<IGetTlcLicenseYearsHeldMutation, IGetTlcLicenseYearsHeldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetTlcLicenseYearsHeldMutation, IGetTlcLicenseYearsHeldMutationVariables>(GetTlcLicenseYearsHeldDocument, options);
      }
export type GetTlcLicenseYearsHeldMutationHookResult = ReturnType<typeof useGetTlcLicenseYearsHeldMutation>;
export type GetTlcLicenseYearsHeldMutationResult = Apollo.MutationResult<IGetTlcLicenseYearsHeldMutation>;
export type GetTlcLicenseYearsHeldMutationOptions = Apollo.BaseMutationOptions<IGetTlcLicenseYearsHeldMutation, IGetTlcLicenseYearsHeldMutationVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation CreatePaymentIntent($input: PaymentIntentInput!) {
  intent: createPaymentIntent(input: $input) {
    secret
    setupFutureUsage
  }
}
    `;
export type ICreatePaymentIntentMutationFn = Apollo.MutationFunction<ICreatePaymentIntentMutation, ICreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<ICreatePaymentIntentMutation, ICreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreatePaymentIntentMutation, ICreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<ICreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<ICreatePaymentIntentMutation, ICreatePaymentIntentMutationVariables>;
export const CreatePaymentEventDocument = gql`
    mutation CreatePaymentEvent($input: CreatePaymentEventInput!) {
  event: createPaymentEvent(input: $input) {
    id
  }
}
    `;
export type ICreatePaymentEventMutationFn = Apollo.MutationFunction<ICreatePaymentEventMutation, ICreatePaymentEventMutationVariables>;

/**
 * __useCreatePaymentEventMutation__
 *
 * To run a mutation, you first call `useCreatePaymentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentEventMutation, { data, loading, error }] = useCreatePaymentEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentEventMutation(baseOptions?: Apollo.MutationHookOptions<ICreatePaymentEventMutation, ICreatePaymentEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreatePaymentEventMutation, ICreatePaymentEventMutationVariables>(CreatePaymentEventDocument, options);
      }
export type CreatePaymentEventMutationHookResult = ReturnType<typeof useCreatePaymentEventMutation>;
export type CreatePaymentEventMutationResult = Apollo.MutationResult<ICreatePaymentEventMutation>;
export type CreatePaymentEventMutationOptions = Apollo.BaseMutationOptions<ICreatePaymentEventMutation, ICreatePaymentEventMutationVariables>;
export const CreateMtaPolicyDocument = gql`
    mutation CreateMtaPolicy($input: CreateMtaPolicyInput!) {
  policy: createMtaPolicy(input: $input)
}
    `;
export type ICreateMtaPolicyMutationFn = Apollo.MutationFunction<ICreateMtaPolicyMutation, ICreateMtaPolicyMutationVariables>;

/**
 * __useCreateMtaPolicyMutation__
 *
 * To run a mutation, you first call `useCreateMtaPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMtaPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMtaPolicyMutation, { data, loading, error }] = useCreateMtaPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMtaPolicyMutation(baseOptions?: Apollo.MutationHookOptions<ICreateMtaPolicyMutation, ICreateMtaPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateMtaPolicyMutation, ICreateMtaPolicyMutationVariables>(CreateMtaPolicyDocument, options);
      }
export type CreateMtaPolicyMutationHookResult = ReturnType<typeof useCreateMtaPolicyMutation>;
export type CreateMtaPolicyMutationResult = Apollo.MutationResult<ICreateMtaPolicyMutation>;
export type CreateMtaPolicyMutationOptions = Apollo.BaseMutationOptions<ICreateMtaPolicyMutation, ICreateMtaPolicyMutationVariables>;
export const CreatePolicyDocument = gql`
    mutation CreatePolicy($input: CreatePolicyInput!) {
  policy: createPolicy(input: $input)
}
    `;
export type ICreatePolicyMutationFn = Apollo.MutationFunction<ICreatePolicyMutation, ICreatePolicyMutationVariables>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(baseOptions?: Apollo.MutationHookOptions<ICreatePolicyMutation, ICreatePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreatePolicyMutation, ICreatePolicyMutationVariables>(CreatePolicyDocument, options);
      }
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<ICreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<ICreatePolicyMutation, ICreatePolicyMutationVariables>;
export const CancelPolicyDocument = gql`
    mutation CancelPolicy($id: ID!) {
  id: cancelPolicy(id: $id)
}
    `;
export type ICancelPolicyMutationFn = Apollo.MutationFunction<ICancelPolicyMutation, ICancelPolicyMutationVariables>;

/**
 * __useCancelPolicyMutation__
 *
 * To run a mutation, you first call `useCancelPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPolicyMutation, { data, loading, error }] = useCancelPolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPolicyMutation(baseOptions?: Apollo.MutationHookOptions<ICancelPolicyMutation, ICancelPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICancelPolicyMutation, ICancelPolicyMutationVariables>(CancelPolicyDocument, options);
      }
export type CancelPolicyMutationHookResult = ReturnType<typeof useCancelPolicyMutation>;
export type CancelPolicyMutationResult = Apollo.MutationResult<ICancelPolicyMutation>;
export type CancelPolicyMutationOptions = Apollo.BaseMutationOptions<ICancelPolicyMutation, ICancelPolicyMutationVariables>;
export const PremiumCreditFitsInitiateLoanApplicationDocument = gql`
    mutation PremiumCreditFitsInitiateLoanApplication($input: PremiumCreditFitsInitiateLoanApplicationInput!) {
  premiumCreditFitsInitiateLoanApplication(input: $input) {
    xid
    reqId
    fitk
    errorUrl
    cancelUrl
    redirectUrl
  }
}
    `;
export type IPremiumCreditFitsInitiateLoanApplicationMutationFn = Apollo.MutationFunction<IPremiumCreditFitsInitiateLoanApplicationMutation, IPremiumCreditFitsInitiateLoanApplicationMutationVariables>;

/**
 * __usePremiumCreditFitsInitiateLoanApplicationMutation__
 *
 * To run a mutation, you first call `usePremiumCreditFitsInitiateLoanApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiumCreditFitsInitiateLoanApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiumCreditFitsInitiateLoanApplicationMutation, { data, loading, error }] = usePremiumCreditFitsInitiateLoanApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePremiumCreditFitsInitiateLoanApplicationMutation(baseOptions?: Apollo.MutationHookOptions<IPremiumCreditFitsInitiateLoanApplicationMutation, IPremiumCreditFitsInitiateLoanApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPremiumCreditFitsInitiateLoanApplicationMutation, IPremiumCreditFitsInitiateLoanApplicationMutationVariables>(PremiumCreditFitsInitiateLoanApplicationDocument, options);
      }
export type PremiumCreditFitsInitiateLoanApplicationMutationHookResult = ReturnType<typeof usePremiumCreditFitsInitiateLoanApplicationMutation>;
export type PremiumCreditFitsInitiateLoanApplicationMutationResult = Apollo.MutationResult<IPremiumCreditFitsInitiateLoanApplicationMutation>;
export type PremiumCreditFitsInitiateLoanApplicationMutationOptions = Apollo.BaseMutationOptions<IPremiumCreditFitsInitiateLoanApplicationMutation, IPremiumCreditFitsInitiateLoanApplicationMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation CreateQuote($input: CreateQuoteInput!) {
  quote: createQuote(input: $input) {
    ...QuoteDetails
  }
}
    ${QuoteDetailsFragmentDoc}`;
export type ICreateQuoteMutationFn = Apollo.MutationFunction<ICreateQuoteMutation, ICreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<ICreateQuoteMutation, ICreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateQuoteMutation, ICreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<ICreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<ICreateQuoteMutation, ICreateQuoteMutationVariables>;
export const UpdateAutoRenewalOptInDocument = gql`
    mutation UpdateAutoRenewalOptIn($input: UpdateAutoRenewalOptInInput!) {
  updateAutoRenewalOptIn(input: $input) {
    autoRenewalOptIn
  }
}
    `;
export type IUpdateAutoRenewalOptInMutationFn = Apollo.MutationFunction<IUpdateAutoRenewalOptInMutation, IUpdateAutoRenewalOptInMutationVariables>;

/**
 * __useUpdateAutoRenewalOptInMutation__
 *
 * To run a mutation, you first call `useUpdateAutoRenewalOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoRenewalOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoRenewalOptInMutation, { data, loading, error }] = useUpdateAutoRenewalOptInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutoRenewalOptInMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateAutoRenewalOptInMutation, IUpdateAutoRenewalOptInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateAutoRenewalOptInMutation, IUpdateAutoRenewalOptInMutationVariables>(UpdateAutoRenewalOptInDocument, options);
      }
export type UpdateAutoRenewalOptInMutationHookResult = ReturnType<typeof useUpdateAutoRenewalOptInMutation>;
export type UpdateAutoRenewalOptInMutationResult = Apollo.MutationResult<IUpdateAutoRenewalOptInMutation>;
export type UpdateAutoRenewalOptInMutationOptions = Apollo.BaseMutationOptions<IUpdateAutoRenewalOptInMutation, IUpdateAutoRenewalOptInMutationVariables>;
export const GetUberDriverDetailDocument = gql`
    mutation GetUberDriverDetail {
  response: uberDriverDetail {
    success
    error
    data
  }
}
    `;
export type IGetUberDriverDetailMutationFn = Apollo.MutationFunction<IGetUberDriverDetailMutation, IGetUberDriverDetailMutationVariables>;

/**
 * __useGetUberDriverDetailMutation__
 *
 * To run a mutation, you first call `useGetUberDriverDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUberDriverDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUberDriverDetailMutation, { data, loading, error }] = useGetUberDriverDetailMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetUberDriverDetailMutation(baseOptions?: Apollo.MutationHookOptions<IGetUberDriverDetailMutation, IGetUberDriverDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetUberDriverDetailMutation, IGetUberDriverDetailMutationVariables>(GetUberDriverDetailDocument, options);
      }
export type GetUberDriverDetailMutationHookResult = ReturnType<typeof useGetUberDriverDetailMutation>;
export type GetUberDriverDetailMutationResult = Apollo.MutationResult<IGetUberDriverDetailMutation>;
export type GetUberDriverDetailMutationOptions = Apollo.BaseMutationOptions<IGetUberDriverDetailMutation, IGetUberDriverDetailMutationVariables>;
export const UpdateMarketingPreferencesDocument = gql`
    mutation UpdateMarketingPreferences($input: MarketingPreferencesInput!) {
  response: updateMarketingPreferences(input: $input) {
    success
    error
    data
  }
}
    `;
export type IUpdateMarketingPreferencesMutationFn = Apollo.MutationFunction<IUpdateMarketingPreferencesMutation, IUpdateMarketingPreferencesMutationVariables>;

/**
 * __useUpdateMarketingPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingPreferencesMutation, { data, loading, error }] = useUpdateMarketingPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarketingPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateMarketingPreferencesMutation, IUpdateMarketingPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateMarketingPreferencesMutation, IUpdateMarketingPreferencesMutationVariables>(UpdateMarketingPreferencesDocument, options);
      }
export type UpdateMarketingPreferencesMutationHookResult = ReturnType<typeof useUpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationResult = Apollo.MutationResult<IUpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationOptions = Apollo.BaseMutationOptions<IUpdateMarketingPreferencesMutation, IUpdateMarketingPreferencesMutationVariables>;
export const GetVehicleDetailsDocument = gql`
    mutation GetVehicleDetails($input: VehicleSearchInput!) {
  response: vehicleSearch(input: $input) {
    success
    error
    data
  }
}
    `;
export type IGetVehicleDetailsMutationFn = Apollo.MutationFunction<IGetVehicleDetailsMutation, IGetVehicleDetailsMutationVariables>;

/**
 * __useGetVehicleDetailsMutation__
 *
 * To run a mutation, you first call `useGetVehicleDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVehicleDetailsMutation, { data, loading, error }] = useGetVehicleDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVehicleDetailsMutation(baseOptions?: Apollo.MutationHookOptions<IGetVehicleDetailsMutation, IGetVehicleDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetVehicleDetailsMutation, IGetVehicleDetailsMutationVariables>(GetVehicleDetailsDocument, options);
      }
export type GetVehicleDetailsMutationHookResult = ReturnType<typeof useGetVehicleDetailsMutation>;
export type GetVehicleDetailsMutationResult = Apollo.MutationResult<IGetVehicleDetailsMutation>;
export type GetVehicleDetailsMutationOptions = Apollo.BaseMutationOptions<IGetVehicleDetailsMutation, IGetVehicleDetailsMutationVariables>;
export const GetVehicleDetailsUsDocument = gql`
    mutation GetVehicleDetailsUs($input: VehicleSearchUsInput!) {
  response: vehicleSearchUs(input: $input) {
    success
    error
    data
  }
}
    `;
export type IGetVehicleDetailsUsMutationFn = Apollo.MutationFunction<IGetVehicleDetailsUsMutation, IGetVehicleDetailsUsMutationVariables>;

/**
 * __useGetVehicleDetailsUsMutation__
 *
 * To run a mutation, you first call `useGetVehicleDetailsUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDetailsUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVehicleDetailsUsMutation, { data, loading, error }] = useGetVehicleDetailsUsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVehicleDetailsUsMutation(baseOptions?: Apollo.MutationHookOptions<IGetVehicleDetailsUsMutation, IGetVehicleDetailsUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetVehicleDetailsUsMutation, IGetVehicleDetailsUsMutationVariables>(GetVehicleDetailsUsDocument, options);
      }
export type GetVehicleDetailsUsMutationHookResult = ReturnType<typeof useGetVehicleDetailsUsMutation>;
export type GetVehicleDetailsUsMutationResult = Apollo.MutationResult<IGetVehicleDetailsUsMutation>;
export type GetVehicleDetailsUsMutationOptions = Apollo.BaseMutationOptions<IGetVehicleDetailsUsMutation, IGetVehicleDetailsUsMutationVariables>;
export const GetAddressesDocument = gql`
    query GetAddresses($postcode: String!) {
  addresses(postcode: $postcode) {
    id
    text
  }
}
    `;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useGetAddressesQuery(baseOptions: Apollo.QueryHookOptions<IGetAddressesQuery, IGetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAddressesQuery, IGetAddressesQueryVariables>(GetAddressesDocument, options);
      }
export function useGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAddressesQuery, IGetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAddressesQuery, IGetAddressesQueryVariables>(GetAddressesDocument, options);
        }
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>;
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>;
export type GetAddressesQueryResult = Apollo.QueryResult<IGetAddressesQuery, IGetAddressesQueryVariables>;
export const GetAddressDocument = gql`
    query GetAddress($id: ID!) {
  address(id: $id) {
    id
    label
    line1
    line2
    city
    county
    postcode
    country
  }
}
    `;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressQuery(baseOptions: Apollo.QueryHookOptions<IGetAddressQuery, IGetAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAddressQuery, IGetAddressQueryVariables>(GetAddressDocument, options);
      }
export function useGetAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAddressQuery, IGetAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAddressQuery, IGetAddressQueryVariables>(GetAddressDocument, options);
        }
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<typeof useGetAddressLazyQuery>;
export type GetAddressQueryResult = Apollo.QueryResult<IGetAddressQuery, IGetAddressQueryVariables>;
export const GetApplicationDocument = gql`
    query GetApplication($locale: Locale!) {
  application(locale: $locale) {
    theme {
      version
      tags
      styles
    }
    strings
  }
}
    `;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetApplicationQuery(baseOptions: Apollo.QueryHookOptions<IGetApplicationQuery, IGetApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetApplicationQuery, IGetApplicationQueryVariables>(GetApplicationDocument, options);
      }
export function useGetApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetApplicationQuery, IGetApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetApplicationQuery, IGetApplicationQueryVariables>(GetApplicationDocument, options);
        }
export type GetApplicationQueryHookResult = ReturnType<typeof useGetApplicationQuery>;
export type GetApplicationLazyQueryHookResult = ReturnType<typeof useGetApplicationLazyQuery>;
export type GetApplicationQueryResult = Apollo.QueryResult<IGetApplicationQuery, IGetApplicationQueryVariables>;
export const GetInstalmentPlanDocument = gql`
    query GetInstalmentPlan($policyId: ID!, $policyVersion: String!) {
  instalmentPlan(policyId: $policyId, policyVersion: $policyVersion) {
    policyId
    version
    paymentSchedule {
      type
      amount
      date
    }
  }
}
    `;

/**
 * __useGetInstalmentPlanQuery__
 *
 * To run a query within a React component, call `useGetInstalmentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstalmentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstalmentPlanQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *      policyVersion: // value for 'policyVersion'
 *   },
 * });
 */
export function useGetInstalmentPlanQuery(baseOptions: Apollo.QueryHookOptions<IGetInstalmentPlanQuery, IGetInstalmentPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetInstalmentPlanQuery, IGetInstalmentPlanQueryVariables>(GetInstalmentPlanDocument, options);
      }
export function useGetInstalmentPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetInstalmentPlanQuery, IGetInstalmentPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetInstalmentPlanQuery, IGetInstalmentPlanQueryVariables>(GetInstalmentPlanDocument, options);
        }
export type GetInstalmentPlanQueryHookResult = ReturnType<typeof useGetInstalmentPlanQuery>;
export type GetInstalmentPlanLazyQueryHookResult = ReturnType<typeof useGetInstalmentPlanLazyQuery>;
export type GetInstalmentPlanQueryResult = Apollo.QueryResult<IGetInstalmentPlanQuery, IGetInstalmentPlanQueryVariables>;
export const GetUserInvoicesDocument = gql`
    query GetUserInvoices($userId: String!) {
  getUserInvoices(userId: $userId) {
    invoiceNumber
    amount
    currency
    status
    quoteId
    paymentLink
    dueDate
  }
}
    `;

/**
 * __useGetUserInvoicesQuery__
 *
 * To run a query within a React component, call `useGetUserInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvoicesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInvoicesQuery(baseOptions: Apollo.QueryHookOptions<IGetUserInvoicesQuery, IGetUserInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserInvoicesQuery, IGetUserInvoicesQueryVariables>(GetUserInvoicesDocument, options);
      }
export function useGetUserInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserInvoicesQuery, IGetUserInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserInvoicesQuery, IGetUserInvoicesQueryVariables>(GetUserInvoicesDocument, options);
        }
export type GetUserInvoicesQueryHookResult = ReturnType<typeof useGetUserInvoicesQuery>;
export type GetUserInvoicesLazyQueryHookResult = ReturnType<typeof useGetUserInvoicesLazyQuery>;
export type GetUserInvoicesQueryResult = Apollo.QueryResult<IGetUserInvoicesQuery, IGetUserInvoicesQueryVariables>;
export const GetPaymentStatusDocument = gql`
    query GetPaymentStatus($quoteId: String!, $packageId: String!) {
  status: getPaymentStatus(quoteId: $quoteId, packageId: $packageId) {
    valueReceived
    valueRequired
    status
  }
}
    `;

/**
 * __useGetPaymentStatusQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatusQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useGetPaymentStatusQuery(baseOptions: Apollo.QueryHookOptions<IGetPaymentStatusQuery, IGetPaymentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPaymentStatusQuery, IGetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
      }
export function useGetPaymentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPaymentStatusQuery, IGetPaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPaymentStatusQuery, IGetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
        }
export type GetPaymentStatusQueryHookResult = ReturnType<typeof useGetPaymentStatusQuery>;
export type GetPaymentStatusLazyQueryHookResult = ReturnType<typeof useGetPaymentStatusLazyQuery>;
export type GetPaymentStatusQueryResult = Apollo.QueryResult<IGetPaymentStatusQuery, IGetPaymentStatusQueryVariables>;
export const GetPolicyDocument = gql`
    query GetPolicy($id: ID!) {
  policy(id: $id) {
    ...PolicyDetail
  }
}
    ${PolicyDetailFragmentDoc}`;

/**
 * __useGetPolicyQuery__
 *
 * To run a query within a React component, call `useGetPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyQuery(baseOptions: Apollo.QueryHookOptions<IGetPolicyQuery, IGetPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPolicyQuery, IGetPolicyQueryVariables>(GetPolicyDocument, options);
      }
export function useGetPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPolicyQuery, IGetPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPolicyQuery, IGetPolicyQueryVariables>(GetPolicyDocument, options);
        }
export type GetPolicyQueryHookResult = ReturnType<typeof useGetPolicyQuery>;
export type GetPolicyLazyQueryHookResult = ReturnType<typeof useGetPolicyLazyQuery>;
export type GetPolicyQueryResult = Apollo.QueryResult<IGetPolicyQuery, IGetPolicyQueryVariables>;
export const GetPolicyVersionDocument = gql`
    query GetPolicyVersion($id: ID!, $version: String!) {
  policyVersion(id: $id, version: $version) {
    ...PolicyDetail
  }
}
    ${PolicyDetailFragmentDoc}`;

/**
 * __useGetPolicyVersionQuery__
 *
 * To run a query within a React component, call `useGetPolicyVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetPolicyVersionQuery(baseOptions: Apollo.QueryHookOptions<IGetPolicyVersionQuery, IGetPolicyVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPolicyVersionQuery, IGetPolicyVersionQueryVariables>(GetPolicyVersionDocument, options);
      }
export function useGetPolicyVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPolicyVersionQuery, IGetPolicyVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPolicyVersionQuery, IGetPolicyVersionQueryVariables>(GetPolicyVersionDocument, options);
        }
export type GetPolicyVersionQueryHookResult = ReturnType<typeof useGetPolicyVersionQuery>;
export type GetPolicyVersionLazyQueryHookResult = ReturnType<typeof useGetPolicyVersionLazyQuery>;
export type GetPolicyVersionQueryResult = Apollo.QueryResult<IGetPolicyVersionQuery, IGetPolicyVersionQueryVariables>;
export const GetPolicyFromOuDocument = gql`
    query GetPolicyFromOU($id: ID!, $token: String!, $map: JSON) {
  response: policyFromOU(id: $id, token: $token) {
    status
    policy {
      id
      policyNumber
      datasheet(map: $map)
      activeTo
    }
  }
}
    `;

/**
 * __useGetPolicyFromOuQuery__
 *
 * To run a query within a React component, call `useGetPolicyFromOuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyFromOuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyFromOuQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *      map: // value for 'map'
 *   },
 * });
 */
export function useGetPolicyFromOuQuery(baseOptions: Apollo.QueryHookOptions<IGetPolicyFromOuQuery, IGetPolicyFromOuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPolicyFromOuQuery, IGetPolicyFromOuQueryVariables>(GetPolicyFromOuDocument, options);
      }
export function useGetPolicyFromOuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPolicyFromOuQuery, IGetPolicyFromOuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPolicyFromOuQuery, IGetPolicyFromOuQueryVariables>(GetPolicyFromOuDocument, options);
        }
export type GetPolicyFromOuQueryHookResult = ReturnType<typeof useGetPolicyFromOuQuery>;
export type GetPolicyFromOuLazyQueryHookResult = ReturnType<typeof useGetPolicyFromOuLazyQuery>;
export type GetPolicyFromOuQueryResult = Apollo.QueryResult<IGetPolicyFromOuQuery, IGetPolicyFromOuQueryVariables>;
export const GetDashboardPoliciesDocument = gql`
    query GetDashboardPolicies($productId: ID, $statuses: [PolicyStatus!]) {
  policies(productId: $productId, statuses: $statuses) {
    ...PolicyDetail
  }
}
    ${PolicyDetailFragmentDoc}`;

/**
 * __useGetDashboardPoliciesQuery__
 *
 * To run a query within a React component, call `useGetDashboardPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardPoliciesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetDashboardPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<IGetDashboardPoliciesQuery, IGetDashboardPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDashboardPoliciesQuery, IGetDashboardPoliciesQueryVariables>(GetDashboardPoliciesDocument, options);
      }
export function useGetDashboardPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDashboardPoliciesQuery, IGetDashboardPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDashboardPoliciesQuery, IGetDashboardPoliciesQueryVariables>(GetDashboardPoliciesDocument, options);
        }
export type GetDashboardPoliciesQueryHookResult = ReturnType<typeof useGetDashboardPoliciesQuery>;
export type GetDashboardPoliciesLazyQueryHookResult = ReturnType<typeof useGetDashboardPoliciesLazyQuery>;
export type GetDashboardPoliciesQueryResult = Apollo.QueryResult<IGetDashboardPoliciesQuery, IGetDashboardPoliciesQueryVariables>;
export const GetAllPoliciesDocument = gql`
    query GetAllPolicies($productId: ID) {
  policiesAllVersions(productId: $productId) {
    ...PolicyDetail
  }
}
    ${PolicyDetailFragmentDoc}`;

/**
 * __useGetAllPoliciesQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAllPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<IGetAllPoliciesQuery, IGetAllPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAllPoliciesQuery, IGetAllPoliciesQueryVariables>(GetAllPoliciesDocument, options);
      }
export function useGetAllPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAllPoliciesQuery, IGetAllPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAllPoliciesQuery, IGetAllPoliciesQueryVariables>(GetAllPoliciesDocument, options);
        }
export type GetAllPoliciesQueryHookResult = ReturnType<typeof useGetAllPoliciesQuery>;
export type GetAllPoliciesLazyQueryHookResult = ReturnType<typeof useGetAllPoliciesLazyQuery>;
export type GetAllPoliciesQueryResult = Apollo.QueryResult<IGetAllPoliciesQuery, IGetAllPoliciesQueryVariables>;
export const GetPoliciesDocument = gql`
    query GetPolicies($productId: ID, $statuses: [PolicyStatus!]) {
  policies(productId: $productId, statuses: $statuses) {
    id
    version
    policyNumber
    status
    canRenew
    datasheet
    activeTo
    product {
      id
    }
  }
}
    `;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<IGetPoliciesQuery, IGetPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPoliciesQuery, IGetPoliciesQueryVariables>(GetPoliciesDocument, options);
      }
export function useGetPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPoliciesQuery, IGetPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPoliciesQuery, IGetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<IGetPoliciesQuery, IGetPoliciesQueryVariables>;
export const GetPoliciesByQuoteDocument = gql`
    query GetPoliciesByQuote($id: ID!) {
  policies: policiesByQuote(id: $id) {
    ...PolicyDetail
  }
}
    ${PolicyDetailFragmentDoc}`;

/**
 * __useGetPoliciesByQuoteQuery__
 *
 * To run a query within a React component, call `useGetPoliciesByQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesByQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesByQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPoliciesByQuoteQuery(baseOptions: Apollo.QueryHookOptions<IGetPoliciesByQuoteQuery, IGetPoliciesByQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPoliciesByQuoteQuery, IGetPoliciesByQuoteQueryVariables>(GetPoliciesByQuoteDocument, options);
      }
export function useGetPoliciesByQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPoliciesByQuoteQuery, IGetPoliciesByQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPoliciesByQuoteQuery, IGetPoliciesByQuoteQueryVariables>(GetPoliciesByQuoteDocument, options);
        }
export type GetPoliciesByQuoteQueryHookResult = ReturnType<typeof useGetPoliciesByQuoteQuery>;
export type GetPoliciesByQuoteLazyQueryHookResult = ReturnType<typeof useGetPoliciesByQuoteLazyQuery>;
export type GetPoliciesByQuoteQueryResult = Apollo.QueryResult<IGetPoliciesByQuoteQuery, IGetPoliciesByQuoteQueryVariables>;
export const GetPolicyDocumentsDocument = gql`
    query GetPolicyDocuments($id: ID!, $version: String!) {
  documents: policyDocuments(id: $id, version: $version) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useGetPolicyDocumentsQuery__
 *
 * To run a query within a React component, call `useGetPolicyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetPolicyDocumentsQuery(baseOptions: Apollo.QueryHookOptions<IGetPolicyDocumentsQuery, IGetPolicyDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPolicyDocumentsQuery, IGetPolicyDocumentsQueryVariables>(GetPolicyDocumentsDocument, options);
      }
export function useGetPolicyDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPolicyDocumentsQuery, IGetPolicyDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPolicyDocumentsQuery, IGetPolicyDocumentsQueryVariables>(GetPolicyDocumentsDocument, options);
        }
export type GetPolicyDocumentsQueryHookResult = ReturnType<typeof useGetPolicyDocumentsQuery>;
export type GetPolicyDocumentsLazyQueryHookResult = ReturnType<typeof useGetPolicyDocumentsLazyQuery>;
export type GetPolicyDocumentsQueryResult = Apollo.QueryResult<IGetPolicyDocumentsQuery, IGetPolicyDocumentsQueryVariables>;
export const GetProductDocument = gql`
    query GetProduct($id: ID!, $locale: Locale!, $stepsType: StepsType) {
  product(id: $id, locale: $locale, stepsType: $stepsType) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      stepsType: // value for 'stepsType'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<IGetProductQuery, IGetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductQuery, IGetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductQuery, IGetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductQuery, IGetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<IGetProductQuery, IGetProductQueryVariables>;
export const GetProductStepsDocument = gql`
    query GetProductSteps($productId: ID!, $productVersion: String!, $locale: Locale!, $stepsType: StepsType!) {
  steps: productSteps(
    productId: $productId
    productVersion: $productVersion
    locale: $locale
    stepsType: $stepsType
  )
}
    `;

/**
 * __useGetProductStepsQuery__
 *
 * To run a query within a React component, call `useGetProductStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStepsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      productVersion: // value for 'productVersion'
 *      locale: // value for 'locale'
 *      stepsType: // value for 'stepsType'
 *   },
 * });
 */
export function useGetProductStepsQuery(baseOptions: Apollo.QueryHookOptions<IGetProductStepsQuery, IGetProductStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductStepsQuery, IGetProductStepsQueryVariables>(GetProductStepsDocument, options);
      }
export function useGetProductStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductStepsQuery, IGetProductStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductStepsQuery, IGetProductStepsQueryVariables>(GetProductStepsDocument, options);
        }
export type GetProductStepsQueryHookResult = ReturnType<typeof useGetProductStepsQuery>;
export type GetProductStepsLazyQueryHookResult = ReturnType<typeof useGetProductStepsLazyQuery>;
export type GetProductStepsQueryResult = Apollo.QueryResult<IGetProductStepsQuery, IGetProductStepsQueryVariables>;
export const GetProductByPolicyDocument = gql`
    query GetProductByPolicy($id: ID!, $locale: Locale!, $stepsType: StepsType!) {
  productByPolicy(id: $id, locale: $locale, stepsType: $stepsType) {
    ...ProductDetails
  }
}
    ${ProductDetailsFragmentDoc}`;

/**
 * __useGetProductByPolicyQuery__
 *
 * To run a query within a React component, call `useGetProductByPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByPolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      stepsType: // value for 'stepsType'
 *   },
 * });
 */
export function useGetProductByPolicyQuery(baseOptions: Apollo.QueryHookOptions<IGetProductByPolicyQuery, IGetProductByPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductByPolicyQuery, IGetProductByPolicyQueryVariables>(GetProductByPolicyDocument, options);
      }
export function useGetProductByPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductByPolicyQuery, IGetProductByPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductByPolicyQuery, IGetProductByPolicyQueryVariables>(GetProductByPolicyDocument, options);
        }
export type GetProductByPolicyQueryHookResult = ReturnType<typeof useGetProductByPolicyQuery>;
export type GetProductByPolicyLazyQueryHookResult = ReturnType<typeof useGetProductByPolicyLazyQuery>;
export type GetProductByPolicyQueryResult = Apollo.QueryResult<IGetProductByPolicyQuery, IGetProductByPolicyQueryVariables>;
export const GetProductSchemaDocument = gql`
    query GetProductSchema($id: ID!, $version: String) {
  schema(id: $id, version: $version)
}
    `;

/**
 * __useGetProductSchemaQuery__
 *
 * To run a query within a React component, call `useGetProductSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetProductSchemaQuery(baseOptions: Apollo.QueryHookOptions<IGetProductSchemaQuery, IGetProductSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductSchemaQuery, IGetProductSchemaQueryVariables>(GetProductSchemaDocument, options);
      }
export function useGetProductSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductSchemaQuery, IGetProductSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductSchemaQuery, IGetProductSchemaQueryVariables>(GetProductSchemaDocument, options);
        }
export type GetProductSchemaQueryHookResult = ReturnType<typeof useGetProductSchemaQuery>;
export type GetProductSchemaLazyQueryHookResult = ReturnType<typeof useGetProductSchemaLazyQuery>;
export type GetProductSchemaQueryResult = Apollo.QueryResult<IGetProductSchemaQuery, IGetProductSchemaQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($locale: Locale!, $stepsType: StepsType!) {
  products(locale: $locale, stepsType: $stepsType) {
    ...ProductSummary
  }
}
    ${ProductSummaryFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      stepsType: // value for 'stepsType'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions: Apollo.QueryHookOptions<IGetProductsQuery, IGetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductsQuery, IGetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductsQuery, IGetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductsQuery, IGetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<IGetProductsQuery, IGetProductsQueryVariables>;
export const GetProductDetailsDocument = gql`
    query GetProductDetails($id: ID!, $locale: Locale!, $stepsType: StepsType!) {
  product(id: $id, locale: $locale, stepsType: $stepsType) {
    id
    name
    version
    features {
      id
      name
      properties {
        id
        jsonPath
      }
    }
  }
}
    `;

/**
 * __useGetProductDetailsQuery__
 *
 * To run a query within a React component, call `useGetProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      stepsType: // value for 'stepsType'
 *   },
 * });
 */
export function useGetProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<IGetProductDetailsQuery, IGetProductDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductDetailsQuery, IGetProductDetailsQueryVariables>(GetProductDetailsDocument, options);
      }
export function useGetProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductDetailsQuery, IGetProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductDetailsQuery, IGetProductDetailsQueryVariables>(GetProductDetailsDocument, options);
        }
export type GetProductDetailsQueryHookResult = ReturnType<typeof useGetProductDetailsQuery>;
export type GetProductDetailsLazyQueryHookResult = ReturnType<typeof useGetProductDetailsLazyQuery>;
export type GetProductDetailsQueryResult = Apollo.QueryResult<IGetProductDetailsQuery, IGetProductDetailsQueryVariables>;
export const GetProductOptionLatestDocument = gql`
    query GetProductOptionLatest($id: ID!) {
  productOptionLatest(id: $id) {
    productId
    name
    code
    location {
      country
    }
    identities {
      connection
      provider
    }
  }
}
    `;

/**
 * __useGetProductOptionLatestQuery__
 *
 * To run a query within a React component, call `useGetProductOptionLatestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionLatestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionLatestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductOptionLatestQuery(baseOptions: Apollo.QueryHookOptions<IGetProductOptionLatestQuery, IGetProductOptionLatestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductOptionLatestQuery, IGetProductOptionLatestQueryVariables>(GetProductOptionLatestDocument, options);
      }
export function useGetProductOptionLatestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductOptionLatestQuery, IGetProductOptionLatestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductOptionLatestQuery, IGetProductOptionLatestQueryVariables>(GetProductOptionLatestDocument, options);
        }
export type GetProductOptionLatestQueryHookResult = ReturnType<typeof useGetProductOptionLatestQuery>;
export type GetProductOptionLatestLazyQueryHookResult = ReturnType<typeof useGetProductOptionLatestLazyQuery>;
export type GetProductOptionLatestQueryResult = Apollo.QueryResult<IGetProductOptionLatestQuery, IGetProductOptionLatestQueryVariables>;
export const GetProductOptionsSearchDocument = gql`
    query GetProductOptionsSearch($search: String!) {
  productOptionsSearch(search: $search) {
    productId
    name
    code
    location {
      country
    }
    identities {
      connection
      provider
    }
  }
}
    `;

/**
 * __useGetProductOptionsSearchQuery__
 *
 * To run a query within a React component, call `useGetProductOptionsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionsSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetProductOptionsSearchQuery(baseOptions: Apollo.QueryHookOptions<IGetProductOptionsSearchQuery, IGetProductOptionsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductOptionsSearchQuery, IGetProductOptionsSearchQueryVariables>(GetProductOptionsSearchDocument, options);
      }
export function useGetProductOptionsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductOptionsSearchQuery, IGetProductOptionsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductOptionsSearchQuery, IGetProductOptionsSearchQueryVariables>(GetProductOptionsSearchDocument, options);
        }
export type GetProductOptionsSearchQueryHookResult = ReturnType<typeof useGetProductOptionsSearchQuery>;
export type GetProductOptionsSearchLazyQueryHookResult = ReturnType<typeof useGetProductOptionsSearchLazyQuery>;
export type GetProductOptionsSearchQueryResult = Apollo.QueryResult<IGetProductOptionsSearchQuery, IGetProductOptionsSearchQueryVariables>;
export const GetProductOptionsByCodeDocument = gql`
    query GetProductOptionsByCode($code: ID!) {
  productOptions: productOptionsByCode(code: $code) {
    productId
    name
    code
    location {
      country
    }
    identities {
      connection
      provider
    }
  }
}
    `;

/**
 * __useGetProductOptionsByCodeQuery__
 *
 * To run a query within a React component, call `useGetProductOptionsByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionsByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionsByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetProductOptionsByCodeQuery(baseOptions: Apollo.QueryHookOptions<IGetProductOptionsByCodeQuery, IGetProductOptionsByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProductOptionsByCodeQuery, IGetProductOptionsByCodeQueryVariables>(GetProductOptionsByCodeDocument, options);
      }
export function useGetProductOptionsByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProductOptionsByCodeQuery, IGetProductOptionsByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProductOptionsByCodeQuery, IGetProductOptionsByCodeQueryVariables>(GetProductOptionsByCodeDocument, options);
        }
export type GetProductOptionsByCodeQueryHookResult = ReturnType<typeof useGetProductOptionsByCodeQuery>;
export type GetProductOptionsByCodeLazyQueryHookResult = ReturnType<typeof useGetProductOptionsByCodeLazyQuery>;
export type GetProductOptionsByCodeQueryResult = Apollo.QueryResult<IGetProductOptionsByCodeQuery, IGetProductOptionsByCodeQueryVariables>;
export const GetQuotesDocument = gql`
    query GetQuotes {
  quotes {
    ...QuoteDetails
  }
}
    ${QuoteDetailsFragmentDoc}`;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions?: Apollo.QueryHookOptions<IGetQuotesQuery, IGetQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQuotesQuery, IGetQuotesQueryVariables>(GetQuotesDocument, options);
      }
export function useGetQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQuotesQuery, IGetQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQuotesQuery, IGetQuotesQueryVariables>(GetQuotesDocument, options);
        }
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
export type GetQuotesQueryResult = Apollo.QueryResult<IGetQuotesQuery, IGetQuotesQueryVariables>;
export const GetOkQuotesForUserDocument = gql`
    query GetOkQuotesForUser($productId: ID) {
  quotes(productId: $productId) {
    ...QuoteDetails
  }
}
    ${QuoteDetailsFragmentDoc}`;

/**
 * __useGetOkQuotesForUserQuery__
 *
 * To run a query within a React component, call `useGetOkQuotesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOkQuotesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOkQuotesForUserQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetOkQuotesForUserQuery(baseOptions?: Apollo.QueryHookOptions<IGetOkQuotesForUserQuery, IGetOkQuotesForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetOkQuotesForUserQuery, IGetOkQuotesForUserQueryVariables>(GetOkQuotesForUserDocument, options);
      }
export function useGetOkQuotesForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetOkQuotesForUserQuery, IGetOkQuotesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetOkQuotesForUserQuery, IGetOkQuotesForUserQueryVariables>(GetOkQuotesForUserDocument, options);
        }
export type GetOkQuotesForUserQueryHookResult = ReturnType<typeof useGetOkQuotesForUserQuery>;
export type GetOkQuotesForUserLazyQueryHookResult = ReturnType<typeof useGetOkQuotesForUserLazyQuery>;
export type GetOkQuotesForUserQueryResult = Apollo.QueryResult<IGetOkQuotesForUserQuery, IGetOkQuotesForUserQueryVariables>;
export const GetQuoteGroupDocument = gql`
    query GetQuoteGroup($groupId: ID!) {
  quoteGroup(groupId: $groupId) {
    ...QuoteDetails
  }
}
    ${QuoteDetailsFragmentDoc}`;

/**
 * __useGetQuoteGroupQuery__
 *
 * To run a query within a React component, call `useGetQuoteGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetQuoteGroupQuery(baseOptions: Apollo.QueryHookOptions<IGetQuoteGroupQuery, IGetQuoteGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQuoteGroupQuery, IGetQuoteGroupQueryVariables>(GetQuoteGroupDocument, options);
      }
export function useGetQuoteGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQuoteGroupQuery, IGetQuoteGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQuoteGroupQuery, IGetQuoteGroupQueryVariables>(GetQuoteGroupDocument, options);
        }
export type GetQuoteGroupQueryHookResult = ReturnType<typeof useGetQuoteGroupQuery>;
export type GetQuoteGroupLazyQueryHookResult = ReturnType<typeof useGetQuoteGroupLazyQuery>;
export type GetQuoteGroupQueryResult = Apollo.QueryResult<IGetQuoteGroupQuery, IGetQuoteGroupQueryVariables>;
export const GetQuoteDocument = gql`
    query GetQuote($id: ID!) {
  quote(id: $id) {
    ...QuoteDetails
  }
}
    ${QuoteDetailsFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<IGetQuoteQuery, IGetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQuoteQuery, IGetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQuoteQuery, IGetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQuoteQuery, IGetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<IGetQuoteQuery, IGetQuoteQueryVariables>;
export const GetQuoteDocumentsDocument = gql`
    query GetQuoteDocuments($id: ID!) {
  documents: quoteDocuments(id: $id) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useGetQuoteDocumentsQuery__
 *
 * To run a query within a React component, call `useGetQuoteDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteDocumentsQuery(baseOptions: Apollo.QueryHookOptions<IGetQuoteDocumentsQuery, IGetQuoteDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQuoteDocumentsQuery, IGetQuoteDocumentsQueryVariables>(GetQuoteDocumentsDocument, options);
      }
export function useGetQuoteDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQuoteDocumentsQuery, IGetQuoteDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQuoteDocumentsQuery, IGetQuoteDocumentsQueryVariables>(GetQuoteDocumentsDocument, options);
        }
export type GetQuoteDocumentsQueryHookResult = ReturnType<typeof useGetQuoteDocumentsQuery>;
export type GetQuoteDocumentsLazyQueryHookResult = ReturnType<typeof useGetQuoteDocumentsLazyQuery>;
export type GetQuoteDocumentsQueryResult = Apollo.QueryResult<IGetQuoteDocumentsQuery, IGetQuoteDocumentsQueryVariables>;
export const GetQuoteDocumentTypeDocument = gql`
    query GetQuoteDocumentType($typeId: ID!) {
  documentType: quoteDocumentType(typeId: $typeId) {
    ...DocumentType
  }
}
    ${DocumentTypeFragmentDoc}`;

/**
 * __useGetQuoteDocumentTypeQuery__
 *
 * To run a query within a React component, call `useGetQuoteDocumentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteDocumentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteDocumentTypeQuery({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useGetQuoteDocumentTypeQuery(baseOptions: Apollo.QueryHookOptions<IGetQuoteDocumentTypeQuery, IGetQuoteDocumentTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQuoteDocumentTypeQuery, IGetQuoteDocumentTypeQueryVariables>(GetQuoteDocumentTypeDocument, options);
      }
export function useGetQuoteDocumentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQuoteDocumentTypeQuery, IGetQuoteDocumentTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQuoteDocumentTypeQuery, IGetQuoteDocumentTypeQueryVariables>(GetQuoteDocumentTypeDocument, options);
        }
export type GetQuoteDocumentTypeQueryHookResult = ReturnType<typeof useGetQuoteDocumentTypeQuery>;
export type GetQuoteDocumentTypeLazyQueryHookResult = ReturnType<typeof useGetQuoteDocumentTypeLazyQuery>;
export type GetQuoteDocumentTypeQueryResult = Apollo.QueryResult<IGetQuoteDocumentTypeQuery, IGetQuoteDocumentTypeQueryVariables>;
export const GetInitialDatasheetDocument = gql`
    query GetInitialDatasheet {
  datasheet: getInitialDatasheet
}
    `;

/**
 * __useGetInitialDatasheetQuery__
 *
 * To run a query within a React component, call `useGetInitialDatasheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialDatasheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialDatasheetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInitialDatasheetQuery(baseOptions?: Apollo.QueryHookOptions<IGetInitialDatasheetQuery, IGetInitialDatasheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetInitialDatasheetQuery, IGetInitialDatasheetQueryVariables>(GetInitialDatasheetDocument, options);
      }
export function useGetInitialDatasheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetInitialDatasheetQuery, IGetInitialDatasheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetInitialDatasheetQuery, IGetInitialDatasheetQueryVariables>(GetInitialDatasheetDocument, options);
        }
export type GetInitialDatasheetQueryHookResult = ReturnType<typeof useGetInitialDatasheetQuery>;
export type GetInitialDatasheetLazyQueryHookResult = ReturnType<typeof useGetInitialDatasheetLazyQuery>;
export type GetInitialDatasheetQueryResult = Apollo.QueryResult<IGetInitialDatasheetQuery, IGetInitialDatasheetQueryVariables>;
export const GetPolicyAutoRenewDocument = gql`
    query GetPolicyAutoRenew($policyId: ID!) {
  policyAutoRenew(policyId: $policyId) {
    autoRenewable
    autoRenewalOptIn
  }
}
    `;

/**
 * __useGetPolicyAutoRenewQuery__
 *
 * To run a query within a React component, call `useGetPolicyAutoRenewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyAutoRenewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyAutoRenewQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyAutoRenewQuery(baseOptions: Apollo.QueryHookOptions<IGetPolicyAutoRenewQuery, IGetPolicyAutoRenewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPolicyAutoRenewQuery, IGetPolicyAutoRenewQueryVariables>(GetPolicyAutoRenewDocument, options);
      }
export function useGetPolicyAutoRenewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPolicyAutoRenewQuery, IGetPolicyAutoRenewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPolicyAutoRenewQuery, IGetPolicyAutoRenewQueryVariables>(GetPolicyAutoRenewDocument, options);
        }
export type GetPolicyAutoRenewQueryHookResult = ReturnType<typeof useGetPolicyAutoRenewQuery>;
export type GetPolicyAutoRenewLazyQueryHookResult = ReturnType<typeof useGetPolicyAutoRenewLazyQuery>;
export type GetPolicyAutoRenewQueryResult = Apollo.QueryResult<IGetPolicyAutoRenewQuery, IGetPolicyAutoRenewQueryVariables>;
export const GetRenewalTrackingDocument = gql`
    query GetRenewalTracking($policyId: ID!) {
  renewalTracking(policyId: $policyId) {
    status
    policyId
    version
    autoRenewable
    autoRenewalOptIn
    autoRenewalQuoteId
  }
}
    `;

/**
 * __useGetRenewalTrackingQuery__
 *
 * To run a query within a React component, call `useGetRenewalTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalTrackingQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetRenewalTrackingQuery(baseOptions: Apollo.QueryHookOptions<IGetRenewalTrackingQuery, IGetRenewalTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetRenewalTrackingQuery, IGetRenewalTrackingQueryVariables>(GetRenewalTrackingDocument, options);
      }
export function useGetRenewalTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetRenewalTrackingQuery, IGetRenewalTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetRenewalTrackingQuery, IGetRenewalTrackingQueryVariables>(GetRenewalTrackingDocument, options);
        }
export type GetRenewalTrackingQueryHookResult = ReturnType<typeof useGetRenewalTrackingQuery>;
export type GetRenewalTrackingLazyQueryHookResult = ReturnType<typeof useGetRenewalTrackingLazyQuery>;
export type GetRenewalTrackingQueryResult = Apollo.QueryResult<IGetRenewalTrackingQuery, IGetRenewalTrackingQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserQuery, IGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserQuery, IGetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserQuery, IGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserQuery, IGetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<IGetUserQuery, IGetUserQueryVariables>;
export const GetUserDatasheetDocument = gql`
    query GetUserDatasheet {
  userDatasheet
}
    `;

/**
 * __useGetUserDatasheetQuery__
 *
 * To run a query within a React component, call `useGetUserDatasheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDatasheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDatasheetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDatasheetQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserDatasheetQuery, IGetUserDatasheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserDatasheetQuery, IGetUserDatasheetQueryVariables>(GetUserDatasheetDocument, options);
      }
export function useGetUserDatasheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserDatasheetQuery, IGetUserDatasheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserDatasheetQuery, IGetUserDatasheetQueryVariables>(GetUserDatasheetDocument, options);
        }
export type GetUserDatasheetQueryHookResult = ReturnType<typeof useGetUserDatasheetQuery>;
export type GetUserDatasheetLazyQueryHookResult = ReturnType<typeof useGetUserDatasheetLazyQuery>;
export type GetUserDatasheetQueryResult = Apollo.QueryResult<IGetUserDatasheetQuery, IGetUserDatasheetQueryVariables>;
export const GetVehiclesDocument = gql`
    query GetVehicles {
  vehicles
}
    `;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<IGetVehiclesQuery, IGetVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetVehiclesQuery, IGetVehiclesQueryVariables>(GetVehiclesDocument, options);
      }
export function useGetVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetVehiclesQuery, IGetVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetVehiclesQuery, IGetVehiclesQueryVariables>(GetVehiclesDocument, options);
        }
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<typeof useGetVehiclesLazyQuery>;
export type GetVehiclesQueryResult = Apollo.QueryResult<IGetVehiclesQuery, IGetVehiclesQueryVariables>;
export const GetVehicleDocument = gql`
    query GetVehicle($make: ID!) {
  vehicle(make: $make)
}
    `;

/**
 * __useGetVehicleQuery__
 *
 * To run a query within a React component, call `useGetVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleQuery({
 *   variables: {
 *      make: // value for 'make'
 *   },
 * });
 */
export function useGetVehicleQuery(baseOptions: Apollo.QueryHookOptions<IGetVehicleQuery, IGetVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetVehicleQuery, IGetVehicleQueryVariables>(GetVehicleDocument, options);
      }
export function useGetVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetVehicleQuery, IGetVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetVehicleQuery, IGetVehicleQueryVariables>(GetVehicleDocument, options);
        }
export type GetVehicleQueryHookResult = ReturnType<typeof useGetVehicleQuery>;
export type GetVehicleLazyQueryHookResult = ReturnType<typeof useGetVehicleLazyQuery>;
export type GetVehicleQueryResult = Apollo.QueryResult<IGetVehicleQuery, IGetVehicleQueryVariables>;