import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useHistory } from 'hooks';
import { useGetProductOptionsByCodeQuery } from 'shared/graphql/api/types';
import Loader from 'components/Loader/Loader';

interface Props
  extends Pick<RouteComponentProps<{ code: string }>, 'match' | 'location'> {}

function ProductByCode({ match, location }: Props) {
  const { push } = useHistory();
  const { code } = match.params;

  const { data } = useGetProductOptionsByCodeQuery({
    variables: {
      code,
    },
  });

  useEffect(() => {
    if (!data?.productOptions) {
      return;
    }

    const updated = location.pathname.replace(
      new RegExp(`^/product/${code}`),
      `/product/${data.productOptions.productId}`
    );

    push(updated, undefined, { preserveQuery: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location, code]);

  return <Loader />;
}

export default ProductByCode;
