import React, { useState } from 'react';

import { usePreQuote } from 'providers/QuoteProvider';
import { createKey } from 'utils/quoteUtils';

import Redirect from 'components/Router/Redirect';
import String from 'components/String/String';

import './PaymentInput.scss';

function PaymentInput() {
  const [displayOrder, setDisplayOrder] = useState(false);
  const {
    $,
    productHistory: { current: product },
    renewalOf,
  } = usePreQuote();

  const quoteId = $.quote?.id;
  const packageId = $.package?.id;
  const productId = product.id;

  return (
    <div className="Chatbot-actions">
      {displayOrder && (
        <Redirect
          to={`/product/${productId}/quote/${quoteId}/payment`}
          query={{
            packageId,
            renewalOf: renewalOf
              ? createKey(renewalOf.id, renewalOf.version)
              : undefined,
          }}
        />
      )}
      <button
        className="Chatbot-action"
        onClick={() => setDisplayOrder(true)}
        data-testid="payment-input-button"
      >
        <String id="pages.quote.chatbot.review_order" />
      </button>
    </div>
  );
}

export default PaymentInput;
