import {
  ILineItem,
  IPackageValuationFragment,
  IProductDetailsFragment,
  IQuotePackageFragment,
} from 'shared/graphql/api/types';
import classnames from 'classnames';
import RadioButton from '../Form/RadioButton/RadioButton';
import { formatTestId } from '../../utils/testIdUtils';
import String from '../String/String';
import { useStrings } from '../../hooks';
import { InstalmentsBreakdown } from './InstalmentsBreakdown';
import { FinancePlaymentPlanBreakdown } from './FinancePaymentPlanBreakdown';
import { getProductParameters } from './paymentOverviewUtils';
import {
  findFinancePaymentPlan,
  findFlexPaymentPlan,
  findInFullPaymentPlan,
  findInstalmentsPaymentPlan,
} from 'utils/quoteUtils';
import { PaymentPlanEnum } from 'enums/PaymentPlan';

import './PaymentPlanSelection.scss';
import { FlexPaymentPlanBreakdown } from './FlexPaymentPlanBreakdown';
import { convertToBaseAddonType } from 'components/QuoteOption/QuoteOptionPricing';
import Currency from '../Currency/Currency';
import React from 'react';
import { getFeesLineItems } from 'utils/lineItems';
import configuration from 'configuration';

interface Props {
  product: IProductDetailsFragment;
  quotePackage: IQuotePackageFragment;
  onClick: (plan: PaymentPlanEnum) => void;
  selectedPlan?: PaymentPlanEnum;
  disabledPlans: PaymentPlanEnum[];
}

function PaymentPlanSelection({
  quotePackage,
  onClick,
  product,
  selectedPlan,
  disabledPlans,
}: Props) {
  const { get } = useStrings();
  const { currency } = product;

  const { showIpt } = getProductParameters(
    product.code,
    product.location.country
  );

  const numPaymentPlans = quotePackage.paymentPlans?.length ?? 1;

  const instalmentsPaymentPlan = findInstalmentsPaymentPlan(quotePackage);
  const financePaymentPlan = findFinancePaymentPlan(quotePackage);
  const inFullPaymentPlan = findInFullPaymentPlan(quotePackage);
  const flexWalletPaymentPlan = findFlexPaymentPlan(quotePackage);

  const isInstalmentsPaymentPlanDisabled = disabledPlans.includes(
    PaymentPlanEnum.INSTALMENTS
  );
  const isFinancePaymentPlanDisabled = disabledPlans.includes(
    PaymentPlanEnum.FINANCE
  );

  const fees: ILineItem[] = getFeesLineItems(quotePackage.valuation);

  function handleClick(paymentPlan: PaymentPlanEnum) {
    if (!disabledPlans.includes(paymentPlan)) {
      onClick(paymentPlan);
    }
  }

  return (
    <div className="PaymentOverview-paymentPlan">
      <h4>
        <String id="pages.quote.payment.overview.payment_plan.title" />
      </h4>
      {numPaymentPlans > 1 && (
        <p>
          <String id="pages.quote.payment.overview.payment_plan.description" />
        </p>
      )}
      <div
        className={classnames(
          `QuoteOption-color`,
          quotePackage.name!.toLowerCase()
        )}
      />

      {inFullPaymentPlan && (
        <div
          className="QuoteOption"
          onClick={() => handleClick(PaymentPlanEnum.IN_FULL)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.IN_FULL}
            id="payment-option-in_full"
            value="payment-option-in_full"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.one_time.title'
            )}
            testId={formatTestId(`payment-option-select-in_full`)}
          />
          <div
            className="PaymentPlanBreakdown-inFull"
            data-testid="payment-plan-breakdown-in_full"
          >
            <div>
              <String id="pages.quote.payment.overview.payment_plan.one_time.price_title" />
            </div>
            <h1
              className="QuoteOption-price"
              data-testid="payment-plan-breakdown-price"
            >
              <Currency currency={currency} value={inFullPaymentPlan.total} />
            </h1>
            {showIpt && quotePackage.valuation?.tax && (
              <p
                className="QuoteOption-tax"
                data-testid="payment-plan-breakdown-ipt"
              >
                <String
                  id="pages.quote.payment.overview.payment_plan.one_time.ipt"
                  values={{
                    amount: (
                      <Currency
                        currency={currency}
                        value={quotePackage.valuation.tax}
                      />
                    ),
                  }}
                />
              </p>
            )}
            {fees.length > 0 &&
              fees.map(({ value, id, name }) => (
                <p
                  className="QuoteOption-tax"
                  key={id}
                  data-testid={`QuoteOption-price-addon-${id}`}
                >
                  {!configuration.enableTranslationsFees ? (
                    <String
                      id={`pages.quote.options.addon.${convertToBaseAddonType(
                        id
                      )}`}
                      values={{
                        total: <Currency currency={currency} value={value} />,
                      }}
                    />
                  ) : (
                    <String
                      id={`pages.quote.options.addon`}
                      values={{
                        total: <Currency currency={currency} value={value} />,
                        feeName: name,
                      }}
                    />
                  )}
                </p>
              ))}
          </div>
        </div>
      )}

      {instalmentsPaymentPlan && (
        <div
          className={classnames({
            QuoteOption: true,
            'QuoteOption-disabled': isInstalmentsPaymentPlanDisabled,
          })}
          onClick={() => handleClick(PaymentPlanEnum.INSTALMENTS)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.INSTALMENTS}
            id="payment-option-instalments"
            value="payment-option-instalments"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.instalments.title'
            )}
            testId={formatTestId(`payment-option-select-instalments`)}
            disabled={isInstalmentsPaymentPlanDisabled}
          />
          <InstalmentsBreakdown
            paymentPlan={instalmentsPaymentPlan}
            currency={currency}
            productCode={product.code}
          />
        </div>
      )}

      {financePaymentPlan && (
        <div
          className={classnames({
            QuoteOption: true,
            'QuoteOption-disabled': isFinancePaymentPlanDisabled,
          })}
          onClick={() => handleClick(PaymentPlanEnum.FINANCE)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.FINANCE}
            id="payment-option-finance"
            value="payment-option-finance"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.finance.title'
            )}
            testId={formatTestId(`payment-option-select-finance`)}
            disabled={isFinancePaymentPlanDisabled}
          />
          <FinancePlaymentPlanBreakdown
            paymentPlan={financePaymentPlan}
            currency={currency}
            fees={fees}
          />
        </div>
      )}

      {flexWalletPaymentPlan && (
        <div
          className="QuoteOption"
          onClick={() => handleClick(PaymentPlanEnum.FLEX_WALLET)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.FLEX_WALLET}
            id="payment-option-flex_wallet"
            value="payment-option-flex_wallet"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.flex_wallet.title'
            )}
            testId={formatTestId(`payment-option-select-flex_wallet`)}
          />
          <FlexPaymentPlanBreakdown
            paymentPlan={flexWalletPaymentPlan}
            currency={currency}
            valuation={quotePackage.valuation as IPackageValuationFragment}
          />
        </div>
      )}
    </div>
  );
}

export default PaymentPlanSelection;
