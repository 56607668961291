import String from 'components/String/String';
import LinkButton from 'components/LinkButton/LinkButton';
import { IQuote } from 'shared/graphql/api/types';
import Button from 'components/Buttons/Button';
import { usePWA } from 'providers/PWAProvider';
import EventManager from 'utils/EventManager';

import './PaymentStatus.scss';
import Loader from 'react-spinners/SyncLoader';
import { Centered } from 'components/Layout/Layout';

interface Props {
  productId: string;
  quote: IQuote;
  packageId: string;
  intentSecret?: string;
}

const PaymentStatus = ({ productId, quote, intentSecret }: Props) => {
  const { prompt, promptToInstall } = usePWA();

  function onDownloadPWA() {
    if (productId) {
      EventManager.track({
        event: 'ButtonPressed',
        productId: productId,
        questionId: 'PWA - Payment Status',
      });

      promptToInstall();
    }
  }

  if (!quote.boundTo && !intentSecret) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return (
    <>
      {quote.boundTo && (
        <>
          <div className="Payment__Status--message">
            <String id="pages.quote.summary.policy_purchased.message" />
          </div>
          <div className="Payment__Status--button">
            <LinkButton
              to={`/product/${productId}`}
              variant="primary"
              testId="policy-purchased-return-to-dashboard"
            >
              <String
                id="pages.quote.summary.policy_purchased.action_button"
                defaultValue="Return to dashboard"
              />
            </LinkButton>
          </div>
          {prompt && (
            <div>
              <Button
                variant="primary"
                onClick={onDownloadPWA}
                testId="download-app"
              >
                <String id="commons.download_app" />
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PaymentStatus;
