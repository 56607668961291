import {
  IPolicyDetailFragment,
  IQuoteDetailsFragment,
} from 'shared/graphql/api/types';
import configuration from 'configuration';
import { daysBetween } from 'shared/utils/dateUtils';
import { PolicyVersion } from 'enums/Policy';
import { PaymentPlanEnum } from 'enums/PaymentPlan';

export function getActivePendingPolicies(
  policies: IPolicyDetailFragment[] = []
) {
  return policies.filter((p) => ['ACTIVE', 'PENDING'].includes(p.status));
}

export function getMtaQuotes(quotes: IQuoteDetailsFragment[] = []) {
  return quotes.filter(({ mtaOf }) => mtaOf);
}

export function getCustomerPortalLink(code: string = '') {
  const { customerPortalLink } = configuration.stripe;

  const productPortalLink =
    customerPortalLink[code.toUpperCase() as keyof typeof customerPortalLink];

  return productPortalLink || getCustomerPortalLinkFromAccount(code);
}
function getCustomerPortalLinkFromAccount(code: string = '') {
  const { customerPortalLink, stripeAccount } = configuration.stripe;

  const productStripeAccount =
    stripeAccount[code.toUpperCase() as keyof typeof stripeAccount];

  if (!productStripeAccount) {
    return customerPortalLink.default;
  }

  const tidiedVariableName = productStripeAccount.replace('STRIPE_', '');

  const stripeAccountPortalLink =
    customerPortalLink[tidiedVariableName as keyof typeof customerPortalLink];

  return stripeAccountPortalLink || customerPortalLink.default;
}

export function isPolicyWithinAutoRenewalDeadline(
  policy: IPolicyDetailFragment
): boolean {
  if (!policy) return false;
  const { autoRenewalOptInDaysBeforeExpiry } = configuration;
  const { activeTo } = policy;

  const daysUntilExpiry = daysBetween(new Date(), activeTo);
  return daysUntilExpiry >= autoRenewalOptInDaysBeforeExpiry;
}
export function hasPremiumFinancePaymentPlan(policy: IPolicyDetailFragment) {
  return (
    policy.package.paymentPlan?.paymentPlan === PaymentPlanEnum.FINANCE ||
    policy.package.paymentPlan?.paymentPlan ===
      PaymentPlanEnum.MANUAL_PREMIUM_FINANCE
  );
}
// There is a BE bug that flips PF policies to IN_FULL payment plan after MTA is made (via CS).
// This then allows a customer to self-serve MTAs on a PF policy.
// Added a condition to check if version 1 of the policy was PF
export function wasPremiumFinance(policyAllVersions: IPolicyDetailFragment[]) {
  return policyAllVersions.some((policy) => {
    return (
      policy.version === PolicyVersion.VERSION_ONE &&
      hasPremiumFinancePaymentPlan(policy)
    );
  });
}

export function canSelfServeCancellation(policy: IPolicyDetailFragment) {
  return (
    policy.package.paymentPlan?.paymentPlan === PaymentPlanEnum.FLEX_WALLET
  );
}

export function getZendeskLink(policy: IPolicyDetailFragment) {
  const zendeskLinks = configuration.zendeskLinks as Record<string, string>;
  const link = zendeskLinks[policy.product.code] ?? zendeskLinks.default;
  return link;
}
