import { ICurrency, IPackageValuationFragment } from 'shared/graphql/api/types';
import { IFullLocale } from '../providers/LocaleProvider';

/**
 * Return a currency symbol for a locale
 * @param locale
 * @param currency
 */
export function getCurrencySymbol(locale: string, currency: string) {
  const formatter = createFormatterPolyfill(locale, currency);
  const currencyPart = formatter
    .formatToParts()
    .find((p) => p.type === 'currency');

  return currencyPart?.value;
}

/**
 * Format
 * @param value
 * @param currency
 * @param locale
 * @param digits
 */
export function formatCurrency(
  value: number,
  locale: string,
  currency: string,
  digits: number = 0
) {
  const formatter = createFormatter(locale, currency, digits);

  return formatter.format(value / 100);
}

/**
 * Create a formatter for a locale
 * @param locale
 * @param currency
 * @param digits
 */
export function createFormatter(locale: string, currency: string, digits = 0) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    currencyDisplay: 'symbol',
  });
}

/**
 * Create a polyfill formatter for a locale
 * @param locale
 * @param currency
 * @param digits
 */
export function createFormatterPolyfill(
  locale: string,
  currency: string,
  digits = 0
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    currencyDisplay: 'symbol',
  });
}

/**
 * Get currency value from string
 * NB: values are returned in PENCE/CENTS
 * @example 1,000 => 100000
 * @param text
 * @param country
 * @param currency
 */
export function getCurrencyValue(
  text: string,
  country: string,
  currency: string
) {
  const currencySeparators = getCurrencySeparatorsForCountry(country, currency);

  const sanitised = text
    .split(currencySeparators.thousand)
    .join('')
    .replace(currencySeparators.penny, '.');

  // Convert value to pence
  return Number(sanitised) * 100;
}

/**
 * Get currency penny and thousand separators for country
 * @param country
 * @param currency
 */
function getCurrencySeparatorsForCountry(country: string, currency: string) {
  const SEPARATOR_TEST_VALUE = 99.99;

  const formatter = createFormatterPolyfill(country, currency).formatToParts(
    SEPARATOR_TEST_VALUE
  );

  const decimal = formatter.find((f) => f.type === 'decimal')?.value;

  return decimal === ','
    ? { thousand: '.', penny: ',' }
    : { thousand: ',', penny: '.' };
}

/**
 * Get price range
 * @param values
 * @param locale
 * @param currency
 */
export function getValuationRangeString(
  values: Array<IPackageValuationFragment>,
  locale: IFullLocale,
  currency: ICurrency
) {
  if (!values.length) {
    return '';
  }

  if (values.length === 1) {
    return formatCurrency(values[0].total, locale, currency, 2);
  }

  const totals = values.map((value) => (value ? value.total : 0));

  const max = Math.max.apply(Math, totals);
  const min = Math.min.apply(Math, totals);

  return `${formatCurrency(min, locale, currency)} - ${formatCurrency(
    max,
    locale,
    currency,
    2
  )}`;
}
