import React from 'react';

interface Props {
  onClick?(): void;
}

function Logo({ onClick }: Props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1228.9 227.8"
      fill="#1F1F1F"
      onClick={onClick}
    >
      <g>
        <path
          d="M369.7,33.1c9.2,0,18.1-0.1,27,0.1c0.9,0,2,1.1,2.6,2c18.6,27.7,37.2,55.4,55.8,83.2
		c9.9,14.7,19.7,29.4,29.6,44c0.5,0.8,1.1,1.5,2.5,2.1c0-1.2,0-2.5,0-3.7c0-41.2,0-82.3,0-123.5c0-4.3,0-4.3,4.2-4.3
		c5.7,0,11.3,0,17.2,0c0,53.6,0,106.8,0,160.4c-1.1,0.1-2.1,0.2-3.2,0.2c-7,0-14-0.1-21,0.1c-2.4,0.1-3.7-0.7-5-2.7
		c-20.7-30.7-41.5-61.3-62.3-91.9c-7.8-11.6-15.7-23.1-23.5-34.7c-0.6-0.9-1.2-1.7-2.5-2.3c0,43.7,0,87.4,0,131.3
		c-7.3,0-14.2,0-21.4,0C369.7,140.1,369.7,86.9,369.7,33.1z"
        />
        <path
          d="M1183.3,120.5c15.2,24.5,30.2,48.5,45.5,73.1c-9.1,0-17.5,0.1-25.8-0.1c-0.9,0-2-1.5-2.6-2.5
		c-12.6-21.6-25.1-43.2-37.5-64.9c-1.4-2.5-2.9-3.5-5.9-3.4c-6.7,0.2-13.5,0.1-20.5,0.1c0,23.7,0,47,0,70.6c-7.2,0-14.1,0-21.5,0
		c0-1.1,0-2.3,0-3.4c-0.1-51-0.1-101.9-0.2-152.9c0-3.1,0.7-4.1,3.9-4.1c19.8,0.3,39.5-0.1,59.3,0.5c17.1,0.6,32.3,6.4,40.1,23
		c13.7,28.9-2.8,58.1-31.3,63.2C1185.8,119.9,1184.8,120.2,1183.3,120.5z M1136.6,103.9c9.2,0,18,0.2,26.8-0.1
		c6-0.2,12-0.6,17.8-1.8c9.6-2,16.1-7.8,18.1-17.9c2.8-13.7-2.9-25.2-14.9-29.5c-2.2-0.8-4.6-1.6-7-1.7c-13.4-0.5-26.8-0.7-40.2-1
		c-0.2,0-0.4,0.2-0.8,0.4C1136.6,69.3,1136.6,86.4,1136.6,103.9z"
        />
        <path
          d="M731.8,33.1c6.6,0,13-0.1,19.4,0.1c0.6,0,1.8,1.4,1.8,2.3c0.2,4.2,0.1,8.5,0.1,12.8c0,16.2,0,32.3,0,48.5
		c0,2.4,0.7,3.2,3.1,3.2c25.9-0.1,51.7,0,77.6-0.1c0.9,0,1.7-0.1,3-0.2c0-22.1,0-44.1,0-66.3c7.4,0,14.3,0,21.5,0
		c0,53.4,0,106.6,0,160.1c-7,0-13.9,0-21.3,0c0-24.2,0-48.5,0-73c-28.1,0-55.7,0-83.7,0c0,24.3,0,48.5,0,73c-7.2,0-14.2,0-21.4,0
		C731.8,140.1,731.8,86.9,731.8,33.1z"
        />
        <path
          d="M924.5,33.2c7.2,0,14,0,21.4,0c0,1.5,0,2.9,0,4.3c0,31.1,0,62.2,0,93.3c0,8.2,1.3,16.1,4.7,23.6
		c7.6,16.8,22.2,24.8,40.9,22.8c16.6-1.8,28.6-11.9,33-28.3c1.6-5.9,2.5-12.2,2.5-18.4c0.2-31.1,0.2-62.2,0-93.3
		c0-3.4,1-4.3,4.2-4.1c5.6,0.2,11.3,0.1,17.3,0.1c0.1,1.5,0.2,2.7,0.2,3.8c0,33,0,65.9-0.1,98.9c-0.1,33.7-22.8,58.9-56.4,61.6
		c-38.1,3.1-67.4-23.3-67.8-61c-0.3-33.3-0.1-66.6-0.1-99.9C924.4,35.6,924.5,34.6,924.5,33.2z"
        />
        <path
          d="M670.1,47.2c-6,5.4-11.6,10.5-17.5,15.9c-4.7-6.2-10.6-10.4-17.9-12.3c-10.4-2.6-20.6-2.3-30.2,2.9
		c-15.3,8.3-16.7,29.5-2.1,39c6.6,4.2,14.4,6.5,21.8,9.4c8.8,3.5,18,6,26.5,10.1c14.3,7,20.6,19.1,20.9,35
		c0.5,29.2-21.2,46.7-45.8,49.8c-16.8,2.1-33,0-47.6-9.1c-5-3.1-9.2-7.5-14.3-11.7c6.6-5.7,12.4-10.7,18.4-15.8
		c5.7,7.8,12.9,13,22,15.4c13.5,3.5,26.1,2,36.8-7.7c12-10.9,10.1-28.5-4.3-35.8c-8.2-4.2-17.3-6.6-25.9-9.9
		c-6.6-2.6-13.2-5.1-19.5-8.3c-26-13.3-30.7-53.5-5.1-73.3c10.2-7.9,21.7-11.3,34.4-11.4c12.2-0.2,24.2,0.4,35.3,6.4
		C661.3,38.6,665.9,42.3,670.1,47.2z"
        />
        <path
          d="M227.9,114.1c0,62.9-51,113.8-114,113.7C50.8,227.8,0,177,0,114C0,51.1,51,0.1,113.9,0
		C176.9-0.1,227.9,50.9,227.9,114.1z M111.9,10.4C53.8,11.8,8.7,59.2,10.5,117.9c1.8,57,50.1,101.8,107.7,99.5
		c56-2.2,101.1-49.4,99.4-107.3C216,54.6,168.2,9.1,111.9,10.4z"
        />
        <path
          d="M279.1,193.4c0-53.4,0-106.6,0-160c7.1,0,14.1,0,21.2,0c0,53.4,0,106.6,0,160
		C293.3,193.4,286.4,193.4,279.1,193.4z"
        />
        <path
          d="M114,26.5c46.8-0.2,85.5,38.2,85.7,84.8c0.2,47.6-37.9,86.2-85.2,86.3c-47.4,0.1-86-38-86.1-85
		C28.2,65,66.3,26.7,114,26.5z M119.4,186.7c47-2.5,77.6-49.5,68.2-87.3c-0.8,0.2-1.7,0.5-2.5,0.7c-16.4,5.5-32.9,11-49.3,16.6
		c-1,0.4-2,1.9-2.4,3c-3.3,8.7-11.2,14.3-20.4,14.3c-8.9-0.1-17-5.7-19.9-14.4c-0.7-2-1.8-2.9-3.7-3.6c-10.7-3.7-21.4-7.4-32.1-11.2
		c-5.6-2-11.3-3.9-17.5-6.1c-3.5,23.3,1.8,43.9,16.8,61.7c13.6,16.1,31,24.8,52.3,26.4c0-1.8,0-3,0-4.2c0-9.7,0-19.4,0-29.1
		c0-3.2,1.6-4.5,5.1-4.5c3.7,0,5.1,1,5.1,4.3c0.1,8.6,0.1,17.2,0.2,25.9C119.4,181.8,119.4,184.1,119.4,186.7z M42.7,88.8
		c16.8,5.9,33.4,11.6,50,17.4c3.8-8.6,9.6-13.9,18.7-14.5c10.8-0.8,17.9,4.7,22.3,14.6c17.3-5.8,34.5-11.6,51.7-17.3
		c-8.5-28-36.5-52.5-72.2-52.1C76.6,37.3,50,63.3,42.7,88.8z M124,113.1c0.1-6-4.5-10.9-10.4-11.1c-6.1-0.2-11.2,4.6-11.3,10.7
		c-0.1,5.8,4.8,10.9,10.7,11C118.9,123.8,123.8,119.1,124,113.1z"
        />
      </g>
    </svg>
  );
}

export default Logo;
