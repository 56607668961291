import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
} from '@material-ui/core';

export interface Option {
  value: string | number;
  text: string;
}

interface Props extends SelectProps {
  options: Option[];
  name: string;
  value: string;
  label?: string;
}

function FormSelect({ options, name, value, label, ...props }: Props) {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        {...props}
        variant="outlined"
        fullWidth
        labelId={name}
        id={name}
        value={value}
        name={name}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FormSelect;
