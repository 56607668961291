import React, { useState } from 'react';
import { VehicleQuestion } from 'shared/types/question';
import { AnswerQuestion } from 'providers/QuoteProvider';
import String from 'components/String/String';

interface Props {
  question: VehicleQuestion;
  onAnswer: AnswerQuestion;
  vehicles?: Record<string, string[]>;
}

function VehicleMakeInput({ onAnswer, vehicles }: Props) {
  const [active, setActive] = useState(false);

  if (!vehicles) {
    return null;
  }

  function onSelect(make: string) {
    setActive(false);
    onAnswer(make, make);
  }

  const makes = Object.keys(vehicles);

  return (
    <>
      {active && (
        <div className="Chatbot-dropdown">
          {makes.map((make, index) => (
            <div
              key={make + index}
              className="Chatbot-dropdown-item"
              onClick={() => onSelect(make)}
              data-testid={`vehicle-make-input-${make}"`}
            >
              {make}
            </div>
          ))}
        </div>
      )}
      <div className="Chatbot-actions">
        <div
          className="Chatbot-action"
          onClick={() => setActive(true)}
          data-testid="vehicle-make-input-button"
        >
          <String id="pages.quote.chatbot.select_option" />
        </div>
      </div>
    </>
  );
}

export default VehicleMakeInput;
