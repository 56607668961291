import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Page from 'components/Page/Page';
import { Centered } from 'components/Layout/Layout';
import String from 'components/String/String';
import Button from 'components/Buttons/Button';
import { useLocale } from 'providers/LocaleProvider';

function PermissionsPage({ history }: RouteComponentProps) {
  const { lang: locale } = useLocale();

  function tryAgain() {
    history.push(`/login?lang=${locale}`);
  }

  return (
    <Page name="PermissionsPage" slug="Invalid permissions">
      <Centered>
        <String id="pages.login.invalid_permissions" />
        <div className="Permissions-actions">
          <Button
            type="button"
            onClick={tryAgain}
            testId="permissions-try-again"
          >
            <String id="pages.login.try_again" />
          </Button>
        </div>
      </Centered>
    </Page>
  );
}

export default PermissionsPage;
