import { useState } from 'react';

import { ErrorCodes, QuoteError } from 'constants/errors';
import {
  ICurrency,
  IProductDetailsFragment,
  IQuotePackageValuation,
} from 'shared/graphql/api/types';
import { useLocale } from 'providers/LocaleProvider';
import EventManager from 'utils/EventManager';

import String from 'components/String/String';
import { Centered, Full } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { usePreQuote } from 'providers/QuoteProvider';
import configuration from 'configuration';

import { IPackage, IQuote } from '../../../../../types/quote';
import { ApolloError } from '@apollo/client';
import {
  adjustedQuoteStartDate,
  isUSProduct,
} from '../../../../../utils/quoteUtils';
import { Tabs } from '../../../../Tabs/Tabs';
import QuoteOption from '../../../../QuoteOption/QuoteOption';
import classnames from 'classnames';
import Button from '../../../../Buttons/Button';

import './QuoteOverview.scss';
import { useHistory, useStrings } from 'hooks';
import Table, {
  TABLE_ORIENTATION,
  TableDataItem,
} from 'components/Table/Table';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import { getProductLocale } from 'utils/productUtils';
import { QuoteStatusEnum } from '@inshur/apis/quote';
import { useAuth } from 'providers/AuthProvider';

export enum QuoteOverviewTab {
  DAYS_365 = 'pages.quote_overview.tabs.annual',
  DAYS_30 = 'pages.quote_overview.tabs.30_day',
}

interface Props {
  product: IProductDetailsFragment;
  quotes?: IQuote[];
  error?: ApolloError;
  loading: boolean;
  currency: ICurrency;
  onClose(value: { package: IPackage; quote: IQuote }): void;
}

function QuoteOverview({
  product,
  quotes,
  error,
  currency,
  loading,
  onClose,
}: Props) {
  const { quoteType } = usePreQuote();
  const { isAnonymous } = useAuth();
  const { lang: language } = useLocale();
  const { push } = useHistory();
  const { get } = useStrings();

  const [selectedPackage, setSelectedPackage] = useState<string>();

  const policyConditions = `${configuration.documents.url}/products/${product.code}/policy-conditions.${language}.pdf`;

  const [activeTab, setActiveTab] = useState<QuoteOverviewTab>(
    QuoteOverviewTab.DAYS_365
  );
  const activeTabEnum =
    activeTab === QuoteOverviewTab.DAYS_365 ? 'DAYS_365' : 'DAYS_30';
  const quote = quotes
    ? ((quotes.length > 1
        ? quotes?.find((q) => q.datasheet.policy.duration === activeTabEnum)
        : quotes[0]) as any)
    : undefined;

  function onContinue(_package?: IPackage) {
    if ((!selectedPackage || !quotes) && !_package) {
      return;
    }

    const pkg = !_package
      ? quote.packages.find((p: IPackage) => p.id === selectedPackage)
      : quote.packages.find((p: IPackage) => p.id === _package.id);

    EventManager.track({
      event: 'OfferSelected',
      productId: product.id,
      duration: quote?.datasheet?.policy?.duration,
      quoteType,
      coverageName: pkg.name,
    });

    if (configuration.releaseFlag.enableAnonymousQuote && isAnonymous) {
      return push(`/quote/${quote.id}/package/${pkg.id}/overview`);
    } else {
      onClose({
        package: pkg,
        quote,
      });
    }
  }

  function PageBody() {
    if (loading) {
      return (
        <Centered>
          <Loader />
        </Centered>
      );
    }

    if (!quote) {
      throw new QuoteError(ErrorCodes.QUOTE_OVERVIEW_NO_QUOTE, error?.message);
    }

    const { status, packages, datasheet, renewalOf } = quote;
    const duration = datasheet?.policy?.duration;
    const protectedNcd = datasheet?.insured?.protectedNcd;

    const tableData: Array<TableDataItem> = [
      {
        heading: get('pages.quote.summary.startDate'),
        content: [adjustedQuoteStartDate(quote).toISOString()],
      },
    ];

    const { product } = quote;
    const locale = getProductLocale(product);

    function infoBoxFromStatus(status: QuoteStatusEnum): InfoBoxType {
      switch (status) {
        case QuoteStatusEnum.DECLINED:
          return InfoBoxType.Info;

        case QuoteStatusEnum.REFER:
          return InfoBoxType.Success;

        default:
          return InfoBoxType.Info;
      }
    }

    return (
      <>
        <div className="Quote-overview-header-wrapper">
          <section className="Quote-overview-header">
            <h1 className="title">
              <String id={`pages.quote.overview.title.${status}`} />
            </h1>
            <div>
              <String id={`pages.quote.overview.subtitle.${status}`} />
            </div>
          </section>
          {quotes && quotes.length > 1 && (
            <Tabs
              tabs={[QuoteOverviewTab.DAYS_365, QuoteOverviewTab.DAYS_30]}
              activeTab={activeTab}
              setActive={setActiveTab}
            />
          )}
        </div>

        <div className="Quote-overview-info">
          <div className="Quote-overview-info-reference">
            <div className="Quote-overview-info-reference-summary">
              <String id="pages.quote.summary.reference" />
              <h2 className="quote-ref">{quote.quoteNumber}</h2>
              {quote.datasheet?.vehicles?.length > 0 && (
                <p className="vehicle-name">{`${
                  quote.datasheet.vehicles[0]?.make ?? ''
                } ${quote.datasheet.vehicles[0]?.model ?? ''}`}</p>
              )}
            </div>
          </div>
          {status !== QuoteStatusEnum.OK && (
            <div className="Quote-overview-info-body">
              <div data-testId="quote-info-table">
                <Table
                  data={tableData}
                  timezone={product?.timezone}
                  locale={locale}
                  orientation={TABLE_ORIENTATION.Vertical}
                  data-testId="quote-info-table"
                />
              </div>

              <InfoBox
                title={
                  <String id={`pages.quote.overview.message.title.${status}`} />
                }
                text={
                  <String id={`pages.quote.overview.message.text.${status}`} />
                }
                type={infoBoxFromStatus(status)}
                allowClose={false}
                testId="quote-overview-infobox"
              />
            </div>
          )}
        </div>

        {status === QuoteStatusEnum.OK && (
          <>
            <div className="Quote-overview-options-wrapper">
              {packages.map((_package: IQuotePackageValuation) => {
                return (
                  <QuoteOption
                    selectedPackage={selectedPackage === _package.id}
                    onQuoteOptionClick={setSelectedPackage}
                    key={_package.id}
                    package={_package}
                    productCode={product.code}
                    productCountry={product.location.country}
                    currency={currency}
                    policyDuration={duration}
                    protectedNcd={protectedNcd}
                    isRenewal={!!renewalOf?.id}
                    quote={quote}
                  />
                );
              })}
            </div>
            {!isUSProduct(product) && (
              <p className="Quote-overview-conditions">
                <a
                  className="Quote-overview-link-small"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={policyConditions}
                  tabIndex={2}
                >
                  <String id="pages.quote.options.conditions" />
                </a>
              </p>
            )}
            {selectedPackage && (
              <div className={classnames('QuoteOptionsPage-actions')}>
                <Button
                  onClick={() => onContinue()}
                  variant="primary"
                  testId={selectedPackage ? 'btn-continue' : 'btn-unselected'}
                >
                  <String id="pages.quote.summary.continue_to_review" />
                </Button>
              </div>
            )}
          </>
        )}

        {status !== QuoteStatusEnum.OK && (
          <div className="QuoteOptionsPage-actions">
            <Button
              onClick={() => push(`/product/${product.id}/`)}
              variant="primary"
              testId="btn-dashboard"
            >
              <String id="pages.quote.summary.back_to_dashboard" />
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Full className="Quote-overview" style={{ padding: 0 }}>
      <PageBody />
    </Full>
  );
}

export default QuoteOverview;
