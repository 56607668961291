import {
  IUpdateAutoRenewalOptInMutationVariables,
  useUpdateAutoRenewalOptInMutation,
} from 'shared/graphql/api/types';

export const useUpdateAutoRenewalTrackingOptIn = () => {
  const [updateAutoRenewalOptIn, { loading, data, error }] =
    useUpdateAutoRenewalOptInMutation();

  const update = async (
    input: IUpdateAutoRenewalOptInMutationVariables['input']
  ) => {
    const { data } = await updateAutoRenewalOptIn({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.updateAutoRenewalOptIn.autoRenewalOptIn;
  };

  return {
    update,
    loading,
    error,
    autoRenewalOptIn: data?.updateAutoRenewalOptIn.autoRenewalOptIn,
  };
};
