import React from 'react';
import String from 'components/String/String';
import {
  ICurrency,
  IFlexWalletPaymentPlan,
  IPackageValuationFragment,
} from 'shared/graphql/api/types';
import { getDepositTax } from '../../utils/quoteUtils';
import Currency from '../Currency/Currency';

interface FlexPaymentPlanBreakdownProps {
  paymentPlan: IFlexWalletPaymentPlan;
  currency: ICurrency;
  valuation: IPackageValuationFragment;
}

export function FlexPaymentPlanBreakdown({
  paymentPlan,
  currency,
  valuation,
}: FlexPaymentPlanBreakdownProps) {
  return (
    <div
      className="PaymentPlanBreakdown-flex"
      data-testid="payment-plan-breakdown-flex"
    >
      <div>
        <String id="pages.quote.payment.overview.payment_plan.flex_wallet.price_title" />
      </div>
      <h1
        className="QuoteOption-price"
        data-testid="payment-plan-breakdown-flex-price"
      >
        {<Currency currency={currency} value={paymentPlan.policyBindAmount} />}{' '}
        <span className="Flex-wallet-deposit">
          <String id="pages.quote.payment.overview.payment_plan.flex_wallet.deposit" />
        </span>
      </h1>
      <div>
        <p
          className="Flex-wallet-info"
          data-testid="payment-plan-breakdown-flex-wallet-info"
        >
          <String
            id="pages.quote.payment.overview.payment_plan.flex_wallet.topup_info"
            values={{
              minWalletAmount: (
                <Currency
                  currency={currency}
                  value={paymentPlan.minWalletBalance}
                />
              ),
              topUpAmount: (
                <Currency currency={currency} value={paymentPlan.topupAmount} />
              ),
            }}
          />
        </p>

        <p
          className="QuoteOption-tax"
          data-testid="payment-plan-breakdown-ipt-flex"
        >
          <String
            id="pages.quote.payment.overview.payment_plan.deposit.ipt"
            values={{
              amount: (
                <Currency
                  currency={currency}
                  value={getDepositTax(paymentPlan)}
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
}
