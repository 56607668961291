import React from 'react';

export default function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.845"
      height="15.646"
      viewBox="0 0 9.845 15.646"
    >
      <path
        id="back-icon"
        data-name="back-icon"
        d="M9.845,1.838,7.972,0,0,7.823l7.972,7.823,1.873-1.838L3.76,7.823Z"
        fill="#191e31"
      />
    </svg>
  );
}
