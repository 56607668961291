import Page from '../../../../components/Page/Page';
import { FlexRow } from '../../../../components/Layout/Layout';
import String from '../../../../components/String/String';
import LinkButton from '../../../../components/LinkButton/LinkButton';
import Avatar from '../../../../components/Avatar/Avatar';
import TRANSLATIONS from 'constants/translations';
import { useModal } from 'providers/ModalProvider';
import './ExpiredMtaPage.scss';
import { useParams } from 'react-router-dom';
import React from 'react';

function ExpiredMtaPage() {
  const { id: productId } = useParams<{ id: string }>();
  const { display, dismiss } = useModal();

  function onShowContactModal() {
    display('contact', {
      onCancel: dismiss,
    });
  }

  function PageBody() {
    return (
      <div className="ExpiredMta">
        <div className="ExpiredMta-body">
          <FlexRow>
            <Avatar direction="normal" expression={'sad'} />
          </FlexRow>
          <h2>
            <String id="pages.expired_mta.title" />
          </h2>
          <h4>
            <String id="pages.expired_mta.body" />
          </h4>
          <FlexRow>
            <LinkButton
              testId="goto_dashboard-linkbutton"
              to={`/product/${productId}`}
              variant="primary"
            >
              <String id="pages.expired_mta.goto_dashboard" />
            </LinkButton>
          </FlexRow>
          <FlexRow>
            <LinkButton
              testId="contact-linkbutton"
              to={`#`}
              variant="secondary"
              className="ExpiredMta-button"
              handleClick={(e) => {
                e.preventDefault();
                onShowContactModal();
              }}
            >
              <String
                id="pages.expired_mta.contact_us"
                defaultValue={TRANSLATIONS.en['pages.expired_mta.contact_us']}
              />
            </LinkButton>
          </FlexRow>
        </div>
      </div>
    );
  }

  return (
    <Page name="ExpiredMta" slug="ExpiredMta" padded={false}>
      <PageBody />
    </Page>
  );
}

export default ExpiredMtaPage;
