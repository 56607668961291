import React, { useState } from 'react';
import { object, string, number, boolean, SchemaOf } from 'yup';
import {
  Formik,
  Form,
  ErrorMessage as FormikErrorMessage,
  getIn,
  FormikProps,
} from 'formik';

import Button from 'components/Buttons/Button';
import FormDateInput from '../components/DateInput';
import FormSelect from '../components/Select';
import FormTextField from '../components/TextField';
import {
  NCD,
  NEGATIVE_HISTORY,
  TOTAL_CLAIMS,
  CLAIMS_AMOUNT,
} from '../constants/FormValues';
import RadioButtonForm from '../components/RadioButton';
import CheckBox from '../components/Checkbox';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ErrorBox from '../components/ErrorBox';
import { FormAction, formActionChoices } from '../QuoteFormSteps';

interface Values {
  policy: {
    startDateTime: string;
  };
  insured: {
    claimFreeYears: number;
    protectedNcd: boolean;
    insuranceQualification: {
      hasNegativeHistory: boolean;
    };
    previousClaims: {
      atFault: number;
      fireAndTheft?: number;
      total: number;
    };
    uber: {
      documentUploadConsent: boolean;
    };
  };
}

const stepValidationSchema: SchemaOf<Values> = object({
  policy: object({
    startDateTime: string().required(
      'Please provide the date that you would like your policy to start from.'
    ),
  }),
  insured: object({
    claimFreeYears: number()
      .required(
        'Please provide the number of years No Claims Discount that you have earned.'
      )
      .min(0, 'Please enter a number of 0 or more')
      .max(50, 'Please enter a number between 0 and 50'),
    protectedNcd: boolean().required(
      'Please provide the response "Yes" or "No" to continue.'
    ),
    insuranceQualification: object({
      hasNegativeHistory: boolean().required(
        'Please provide the response "Yes" or "No" to continue.'
      ),
    }),
    previousClaims: object({
      atFault: number().required(
        'Please provide your claims history to continue.'
      ),
      fireAndTheft: number(),
      total: number().required(
        'Please provide your claims history to continue.'
      ),
    }),
    uber: object({
      documentUploadConsent: boolean()
        .required()
        .oneOf(
          [true],
          'You are required by Uber to provide them with your motor insurance certificate, otherwise we are not able to offer you a policy with us.'
        ),
    }),
  }),
});

interface Props {
  data: any;
  dispatch: React.Dispatch<FormAction>;
}

const QuoteFormFour = ({ dispatch, data }: Props) => {
  const [submited, setSubmited] = useState(false);
  const handleSubmit = (values: Values) => {
    dispatch({ action: formActionChoices.NEXT, data: values });
  };

  const handlePrevStep = (values: Values) => {
    dispatch({ action: formActionChoices.PREV, data: values });
  };

  function onSubmitClick() {
    setSubmited(true);
  }

  return (
    <>
      <Formik
        validationSchema={stepValidationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          setFieldValue,
          touched,
        }: FormikProps<Values>) => {
          return (
            <Form>
              <>
                {submited && Object.keys(errors).length > 0 && <ErrorBox />}
                <div className="QuoteForm-step-wrapper">
                  <h2 className="sectionTitle">Your policy</h2>
                  <div className="Question">
                    <p className="Question-subtitle">Start Date for Policy</p>
                    <FormDateInput
                      min={new Date(Date.now())}
                      openTo="date"
                      handleChange={setFieldValue}
                      name="policy.startDateTime"
                      error={
                        Boolean(getIn(touched, 'policy.startDateTime')) &&
                        Boolean(getIn(errors, 'policy.startDateTime'))
                      }
                    />

                    <FormikErrorMessage
                      name="policy.startDateTime"
                      render={(message) => <ErrorMessage message={message} />}
                    />
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      How many years private hire No Claims Discount do you
                      have?
                    </div>
                    <p className="Question-subtitle">
                      Private Hire No Claims Discount
                    </p>

                    <FormTextField
                      name="insured.claimFreeYears"
                      value={values.insured.claimFreeYears.toString()}
                      onChange={handleChange}
                      type="number"
                      error={
                        Boolean(getIn(touched, 'insured.claimFreeYears')) &&
                        Boolean(getIn(errors, 'insured.claimFreeYears'))
                      }
                    />

                    <FormikErrorMessage
                      name="insured.claimFreeYears"
                      render={(message) => <ErrorMessage message={message} />}
                    />

                    {values.insured.claimFreeYears > 3 && (
                      <div className="SubQuestion">
                        <div className="SubQuestion-title">
                          Would you like to protect your No Claims Discount?
                        </div>

                        <div role="group">
                          {NCD.map((option) => (
                            <RadioButtonForm
                              handleChange={setFieldValue}
                              key={option.id}
                              id={option.id}
                              value={option.value}
                              fieldValue={values.insured.protectedNcd.toString()}
                              label={option.label}
                              name="insured.protectedNcd"
                            />
                          ))}
                        </div>

                        <FormikErrorMessage
                          name="insured.protectedNcd"
                          render={(message) => (
                            <ErrorMessage message={message} />
                          )}
                        />
                      </div>
                    )}
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      Have you, or anyone covered under this policy, ever had a
                      policy voided, a policy cancelled, a policy declined,
                      special terms imposed or had a claim refused by an
                      insurer?
                    </div>

                    <div role="group">
                      {NEGATIVE_HISTORY.map((option) => (
                        <RadioButtonForm
                          name="insured.insuranceQualification.hasNegativeHistory"
                          additionalFields={[
                            'drivers[0].insuranceQualification.hasNegativeHistory',
                          ]}
                          handleChange={setFieldValue}
                          key={option.id}
                          id={option.id}
                          value={option.value}
                          fieldValue={values.insured.insuranceQualification.hasNegativeHistory.toString()}
                          label={option.label}
                        />
                      ))}
                    </div>
                    <FormikErrorMessage
                      name="insured.insuranceQualification.hasNegativeHistory"
                      render={(message) => <ErrorMessage message={message} />}
                    />
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      How many Motor Insurance claims have you made in the last
                      three years? Please detail all claims whether fault or
                      non-fault (excluding windscreen claims).
                    </div>
                    <p className="Question-subtitle">Number of Claims</p>

                    <FormSelect
                      options={TOTAL_CLAIMS}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          'drivers[0].previousClaims.total',
                          e.target.value
                        );
                        if ((e.target.value as number) > 0) {
                          setFieldValue('insured.previousClaims.atFault', '');
                        } else {
                          setFieldValue('insured.previousClaims.atFault', 0);
                        }
                      }}
                      name="insured.previousClaims.total"
                      value={values.insured.previousClaims.total.toString()}
                      error={
                        Boolean(
                          getIn(touched, 'insured.previousClaims.total')
                        ) &&
                        Boolean(getIn(errors, 'insured.previousClaims.total'))
                      }
                    />
                    <FormikErrorMessage
                      name="insured.previousClaims.total"
                      render={(message) => <ErrorMessage message={message} />}
                    />

                    {!!values.insured.previousClaims.total && (
                      <div className="SubQuestion">
                        <div className="SubQuestion-title">
                          How many of these claims were you deemed partially or
                          fully at fault?
                        </div>

                        {CLAIMS_AMOUNT.slice(
                          0,
                          values.insured.previousClaims.total + 1
                        ).map((option) => (
                          <RadioButtonForm
                            handleChange={setFieldValue}
                            key={option.value}
                            value={option.value.toString()}
                            fieldValue={values.insured.previousClaims.atFault.toString()}
                            label={option.label}
                            name="insured.previousClaims.atFault"
                            additionalFields={[
                              'drivers[0].previousClaims.atFault',
                            ]}
                            type="number"
                          />
                        ))}

                        <FormikErrorMessage
                          name="insured.previousClaims.atFault"
                          render={(message) => (
                            <ErrorMessage message={message} />
                          )}
                        />
                      </div>
                    )}
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      Share your cover with Uber
                    </div>

                    <p>
                      Once you have bought your policy, it is a requirement for
                      us to share your policy details and documents with Uber,
                      so that they know you are covered and legally able to
                      drive.
                    </p>

                    <div
                      className={`QuoteForm-step-conditions ${
                        Boolean(
                          getIn(touched, 'insured.uber.documentUploadConsent')
                        ) &&
                        Boolean(
                          getIn(errors, 'insured.uber.documentUploadConsent')
                        )
                          ? ' QuoteForm-step-conditions-error'
                          : ''
                      }`}
                    >
                      <CheckBox
                        checked={values.insured.uber.documentUploadConsent}
                        name="insured.uber.documentUploadConsent"
                        onChange={(_e, checked) => {
                          setFieldValue(
                            'insured.uber.documentUploadConsent',
                            checked
                          );
                          setFieldValue(
                            'drivers[0].uber.documentUploadConsent',
                            checked
                          );
                        }}
                        label="I consent to INSHUR sharing my policy details and documents with Uber."
                      />
                    </div>

                    <FormikErrorMessage
                      name="insured.uber.documentUploadConsent"
                      render={(message) => <ErrorMessage message={message} />}
                    />
                  </div>
                </div>
                <div className="QuoteForm-step-actions">
                  <Button
                    onClick={onSubmitClick}
                    variant="primary"
                    type="submit"
                    testId="form-cover-next"
                    track={{ questionId: 'form-confirm-step-4-cover' }}
                  >
                    Get your quote
                  </Button>
                  <Button
                    onClick={() => handlePrevStep(values)}
                    variant="secondary"
                    type="submit"
                    testId="form-cover-back"
                    track={{ questionId: 'form-back-step-4-cover' }}
                  >
                    Back
                  </Button>
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default QuoteFormFour;
