import { useStrings } from 'hooks';
import { useGetPackageFeatures } from 'hooks/useGetPackageFeatures';
import Tick, { TickLabel } from 'components/Tick/Tick';
import {
  IQuoteDetailsFragment,
  IQuotePackageFragment,
} from 'shared/graphql/api/types';
import Cross, { CrossLabel } from 'components/Cross/Cross';

interface Props {
  productCode: string;
  package: IQuotePackageFragment;
  quote: IQuoteDetailsFragment;
}

function QuoteOptionHighlights({
  productCode,
  package: _package,
  quote,
}: Props) {
  const { get } = useStrings();
  const { getFeatures } = useGetPackageFeatures();
  const { includedFeatures, excludedFeatures } = getFeatures(
    productCode,
    _package,
    quote
  );

  return (
    <div className="QuoteOption-features">
      {includedFeatures.map((feature, index) => {
        return (
          <Tick key={index}>
            <TickLabel>{get(feature)}</TickLabel>
          </Tick>
        );
      })}
      {excludedFeatures.map((feature, index) => {
        return (
          <Cross key={index}>
            <CrossLabel>{get(feature)}</CrossLabel>
          </Cross>
        );
      })}
    </div>
  );
}

export default QuoteOptionHighlights;
