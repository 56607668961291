import React from 'react';
import Button from 'components/Buttons/Button';
import String from '../../String/String';

import { useStrings } from '../../../hooks';
import Modal from '../Modal';

interface Props {
  onCancel(): void;
  onContinue(): void;
}

function ExistingMtaModal({ onCancel, onContinue }: Props) {
  const { get } = useStrings();

  return (
    <Modal
      title={get('pages.product.dashboard.modal.existing_mta.title')}
      className="MtaSelfServeModal"
      data-testid="existing-mta-modal"
      onClose={onCancel}
      actions={
        <>
          <Button
            onClick={onContinue}
            variant="primary"
            testId="existing-mta-continue"
          >
            <String id="pages.product.dashboard.modal.existing_mta.continue" />
          </Button>

          <Button
            variant="secondary"
            onClick={onCancel}
            testId="existing-mta-cancel"
          >
            <String id="pages.product.dashboard.modal.existing_mta.cancel" />
          </Button>
        </>
      }
      actionsFlow="row"
    >
      <p>
        <String id="pages.product.dashboard.modal.existing_mta.message" />
      </p>
    </Modal>
  );
}

export default ExistingMtaModal;
