import { useQuery } from './useQuery';
import querystring from 'query-string';
import { useHistory as useRouterHistory } from 'react-router-dom';

type IPushQuery = Record<
  string,
  string | boolean | number | undefined | string[]
>;

type IPushOptions = {
  preserveQuery?: boolean;
};

export type IPush = (
  url: string,
  query?: IPushQuery,
  options?: IPushOptions
) => void;

type IUseHistory = {
  push: IPush;
};

interface QueryParams {
  lang?: string;
  _ga?: string;
}

export function useHistory(): IUseHistory {
  const { lang, _ga, ...rest } = useQuery<QueryParams>();
  const history = useRouterHistory();

  return {
    push(url, query, options) {
      const q = options?.preserveQuery
        ? { ...rest, lang, _ga, ...query }
        : { lang, _ga, ...query };

      const string = querystring.stringify(q, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma',
      });

      history.push(`${url}?${string}`);
    },
  };
}
