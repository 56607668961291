import React from 'react';

interface Props {
  onClick?(): void;
}

export default function RemoveIcon({ onClick }: Props) {
  return (
    <svg
      data-testid="remove-search"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      onClick={onClick}
      className="Chatbot-dropdown-search-clear-icon"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L20 0 20 20 0 20z" opacity=".87" />
        <path
          fill="#868F9E"
          fillRule="nonzero"
          d="M10 1.5c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5zm3.44 11.94c-.312.312-.816.312-1.128 0L10 11.128 7.688 13.44c-.312.312-.816.312-1.128 0-.312-.312-.312-.816 0-1.128L8.872 10 6.56 7.688c-.312-.312-.312-.816 0-1.128.312-.312.816-.312 1.128 0L10 8.872l2.312-2.312c.312-.312.816-.312 1.128 0 .312.312.312.816 0 1.128L11.128 10l2.312 2.312c.304.304.304.816 0 1.128z"
        />
      </g>
    </svg>
  );
}
