import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import Switch from 'components/Router/Switch';

import VehicleChangePage from './VehicleChangePage';
import MtaQuoteDeclined from '../MtaQuoteDeclined/MtaQuoteDeclined';

function MtaRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route
        path={`${match.path}/declined`}
        exact
        component={MtaQuoteDeclined}
      />
      <Route
        path={`${match.path}/:policyKey/vehicle`}
        component={VehicleChangePage}
      />
    </Switch>
  );
}

export default MtaRoute;
