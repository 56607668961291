import React from 'react';
import {
  ChoiceQuestion,
  ChoiceQuestionTemplateEnum,
} from 'shared/types/question';
import ChoiceButtonInput from './ChoiceButtonInput/ChoiceButtonInput';
import ChoiceDropdownInput from './ChoiceDropdownInput/ChoiceDropdownInput';
import { AnswerQuestion } from 'providers/QuoteProvider';

interface Props {
  question: ChoiceQuestion;
  onAnswer: AnswerQuestion;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

function ChoiceInput({ question, onAnswer, scrollRef }: Props) {
  const { template, searchable } = question;

  if (template === ChoiceQuestionTemplateEnum.dropdown) {
    return (
      <ChoiceDropdownInput
        question={question}
        onAnswer={onAnswer}
        searchable={searchable}
      />
    );
  }

  return (
    <ChoiceButtonInput
      question={question}
      onAnswer={onAnswer}
      scrollRef={scrollRef}
    />
  );
}

export default ChoiceInput;
