import { useIntl } from 'react-intl';

export function useStrings() {
  const intl = useIntl();

  function get(id: string, values?: any, defaultValue?: string) {
    return intl.formatMessage(
      { id, defaultMessage: defaultValue ?? `{${id}}` },
      values
    );
  }

  return {
    get,
  };
}
