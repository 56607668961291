import { IContextLookup } from '../types/quote';
import { Question } from 'shared/types/question';
import { get, set } from 'lodash';
import merge from 'deepmerge';
import { isChoice } from './quoteUtils';
import clone from 'clone-deep';

/**
 * Gets the value from a context object using a path
 * All paths must start with "$.". Example: $.datasheet.value
 * @param context
 * @param path
 */
export function getValueAtPath(context: IContextLookup, path: string) {
  return get(context, path);
}

/**
 * Set a value in the context using the given path
 * @param context
 * @param path
 * @param value
 */
export function setValueAtPath(
  context: IContextLookup,
  path: string,
  value: any
) {
  return set(clone(context), path, value);
}

/**
 * Gets the new value of a path based on the answer and the current value
 * @param context
 * @param path
 * @param question
 * @param answer
 */
export function getUpdatedValue(
  context: IContextLookup,
  path: string,
  question: Question,
  answer: any
) {
  // Get the current value
  const value = getValueAtPath(context, path);

  if (!isChoice(question) && typeof answer === 'object') {
    return merge(value, answer);
  }

  // If the answer is not a choice, the updated answer is simply the value to overwrite
  if (!isChoice(question)) {
    return answer;
  }

  // If the question is a choice, use the response to map to the value
  const { choices } = question;

  // Find the one with the same value as the answer
  const choice = choices.find((c) => c.value === answer);

  // If there isn't a choice, fail
  if (!choice) {
    throw new Error(`No choice found for answer: ${answer}`);
  }

  // If the choice value is an object, merge with whatever is there
  if (typeof choice.value === 'object' && typeof value === 'object') {
    return merge(value, choice.value);
  }

  // If the choice is not an object, then use that as the updated value
  return choice.value;
}
