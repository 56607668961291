import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useLocation } from 'react-router-dom';
import { ErrorCodes, PaymentError } from 'constants/errors';

import { IContext } from 'types/quote';
import { IProduct } from 'types/product';

import Currency from 'components/Currency/Currency';
import { Full } from 'components/Layout/Layout';
import Button from 'components/Buttons/Button';
import DateFormat from 'components/Date/Date';
import String from 'components/String/String';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import { useHistory, useStrings } from 'hooks';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';

import './PaymentOverview.scss';
import QuoteDocuments from 'components/Documents/QuoteDocuments';
import { toTitleCase } from '../../utils/stringUtils';
import { useLocale } from '../../providers/LocaleProvider';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {
  adjustedQuoteEndDate,
  adjustedQuoteStartDate,
  openQuotesRenewalsEnabled,
  findInstalmentsPaymentPlan,
  findFinancePaymentPlan,
  getDefaultPaymentPlan,
  findFlexPaymentPlan,
  isInstalmentPaymentPlan,
} from '../../utils/quoteUtils';
import { RenewalBanner } from '../../pages/product/quote/RenewalBanner/RenewalBanner';
import { getProductParameters } from './paymentOverviewUtils';
import { InstalmentsBreakdown } from './InstalmentsBreakdown';
import { PremiumFinanceFrame } from 'components/PremiumFinanceFrame/PremiumFinanceFrame';
import { FitsStatus } from 'enums/premiumFianance';
import { PaymentPlanEnum } from 'enums/PaymentPlan';
import QuoteOptionPrice from 'components/QuoteOption/QuoteOptionPricing';
import {
  IInstalmentsPaymentPlan,
  IPolicyDetailFragment,
  IQuoteDetailsFragment,
  IQuotePackageValuation,
  IValuation,
} from 'shared/graphql/api/types';
import PaymentPlanSelectionContainer from './PaymentPlanSelectionContainer';
import configuration from '../../configuration';
import PaymentPlanSelection from './PaymentPlanSelection';
import { PackageValuation } from '@inshur/apis/billing';
import { IApiClients, useApiClients } from '../../hooks/useApiClients';

interface Props {
  onMakePayment(
    amount: number,
    paymentPlan: PaymentPlanEnum
  ): Promise<void> | void;
  onCreatePolicy(): Promise<void> | void;
  context: IContext;
  product: IProduct;
  isOpenQuote?: boolean;
  fitsStatus?: string;
  policy?: IPolicyDetailFragment;
}

export const instalmentDifference = (
  quote?: IQuoteDetailsFragment,
  policy?: IPolicyDetailFragment
) => {
  if (!policy || !quote || !isInstalmentPaymentPlan(policy)) {
    return 0;
  }

  const originalPaymentPlan: IInstalmentsPaymentPlan = policy.package
    .paymentPlan as IInstalmentsPaymentPlan;
  const newPaymentPlan: IInstalmentsPaymentPlan = quote.packages[0]
    .paymentPlans![0]! as IInstalmentsPaymentPlan;
  const valuation: IValuation = quote.packages[0].valuation as IValuation;

  const instalmentDifference =
    newPaymentPlan.instalment.total - originalPaymentPlan.instalment.total;

  const refundAmount = valuation.total - instalmentDifference;
  return Math.abs(refundAmount);
};

function PaymentOverview({
  context,
  product,
  onMakePayment,
  onCreatePolicy,
  isOpenQuote,
  fitsStatus,
  policy,
}: Props) {
  const initialAgreementState = fitsStatus === FitsStatus.CONFIRM;
  const [instructionsConfirmed, setInstructionsConfirmed] = useState(
    initialAgreementState
  );
  const [documentsConfirmed, setDocumentsConfirmed] = useState(
    initialAgreementState
  );
  const [loadingPaymentPlans, setLoadingPaymentPlans] = useState(true);
  const { clients, loading: loadingApiClients } = useApiClients();
  const [paymentOptions, setPaymentOptions] = useState<PackageValuation[]>();

  const { quote } = context;

  const { locale } = useLocale();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const fetchPaymentPlans = async (clients: IApiClients, quoteId: string) => {
    try {
      const response = await clients.billing.getPaymentOptionDetails({
        quoteId,
      });
      const paymentPlans = response?.data;
      setPaymentOptions(paymentPlans);
      setLoadingPaymentPlans(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (clients !== undefined && !loadingApiClients && quote !== undefined) {
      fetchPaymentPlans(clients, quote.id);
    }
  }, [clients, loadingApiClients, quote]);

  const [currentStep, setCurrentStep] = useState(2);
  const [currentStepText, setCurrentStepText] = useState(
    'progress_bar.step.policy_aggreement'
  );
  const [nextStepText, setNextStepText] = useState('progress_bar.step.payment');
  const { get } = useStrings();
  const ref = useRef<HTMLDivElement>(null);

  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const initialSelectedPaymentPlan =
    fitsStatus === FitsStatus.CONFIRM
      ? PaymentPlanEnum.FINANCE
      : getDefaultPaymentPlan(context.package!);

  const [selectedPaymentPlan, setSelectedPaymentPlan] =
    useState<PaymentPlanEnum>(initialSelectedPaymentPlan!);

  const showRenewalBanner =
    openQuotesRenewalsEnabled() && quote?.renewalOf != null;

  const getDifference = useMemo(
    () => instalmentDifference(quote, policy),
    [policy, quote]
  );
  const paymentAmount = useMemo(() => {
    if (context.package) {
      if (selectedPaymentPlan === PaymentPlanEnum.INSTALMENTS) {
        return findInstalmentsPaymentPlan(context.package)!.policyBindAmount;
      }
      if (selectedPaymentPlan === PaymentPlanEnum.FINANCE) {
        return findFinancePaymentPlan(context.package)!.policyBindAmount;
      }
      if (selectedPaymentPlan === PaymentPlanEnum.FLEX_WALLET) {
        return findFlexPaymentPlan(context.package)!.policyBindAmount;
      }

      if (context.package.valuation) {
        return context.package.valuation.total;
      }
    }
    return 0;
  }, [context.package, selectedPaymentPlan]);

  const shouldPay = paymentAmount > 0;

  const {
    showVehicle,
    showStartDate,
    showEndDate,
    showDriverName,
    showProtectedNcd,
    showClaimFreeYears,
  } = getProductParameters(product.code, product.location.country);

  const isFinanceRenewal =
    selectedPaymentPlan === PaymentPlanEnum.FINANCE &&
    quote?.renewalOf &&
    !shouldPay;

  async function onContinue() {
    if (!documentsConfirmed || !instructionsConfirmed) {
      setHasValidationErrors(true);
      ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (
      selectedPaymentPlan === PaymentPlanEnum.FINANCE &&
      fitsStatus !== FitsStatus.CONFIRM &&
      !isFinanceRenewal
    ) {
      return push(pathname, { fits: FitsStatus.OPEN }, { preserveQuery: true });
    }

    setHasValidationErrors(false);
    setCurrentStep(3);
    setCurrentStepText('progress_bar.step.payment');
    setNextStepText('');

    if (shouldPay) {
      await onMakePayment(paymentAmount, selectedPaymentPlan);
    } else {
      await onCreatePolicy();
    }
  }

  async function onCancel() {
    push(`/product/${product.id}`);
  }

  function PageBody() {
    const { package: _package, datasheet } = context;
    const { insured, policy, vehicles } = datasheet;

    if (!quote) {
      throw new PaymentError(product.id, ErrorCodes.PAYMENT_NO_QUOTE);
    }

    if (!_package) {
      throw new PaymentError(product.id, ErrorCodes.PAYMENT_NO_PACKAGE);
    }

    if (!_package.valuation || !_package.packageValuationId) {
      throw new PaymentError(product.id, ErrorCodes.PAYMENT_NO_PACKAGE);
    }

    if (!vehicles?.[0]) {
      throw new PaymentError(product.id, ErrorCodes.PAYMENT_NO_VEHICLE);
    }

    const [vehicle] = vehicles;
    const { name, valuation, pricingModel } = _package;
    const { firstName, lastName, protectedNcd, claimFreeYears } = insured;
    const { yearOfManufacture, make, model } = vehicle;

    const differenceToPay = quote.mtaOf ? valuation.total : null;

    const startDate = adjustedQuoteStartDate(quote);
    const endDate = adjustedQuoteEndDate(quote);

    const { currency, paymentProvider } = product;

    const textToShow = useMemo(() => {
      if (differenceToPay != null) {
        if (differenceToPay > 0) {
          return 'more';
        } else if (differenceToPay === 0) {
          return 'equal';
        } else {
          return 'less';
        }
      }
    }, [differenceToPay]);

    const instalmentsPaymentPlan = findInstalmentsPaymentPlan(_package);
    const financePaymentPlan = findFinancePaymentPlan(_package);
    const flexWalletPaymentPlan = findFlexPaymentPlan(_package);

    function renderContinueButtonText() {
      if (selectedPaymentPlan === PaymentPlanEnum.FINANCE) {
        if (isFinanceRenewal) {
          return <String id="pages.quote.payment.complete_renewal" />;
        }
        if (fitsStatus === FitsStatus.CONFIRM) {
          return <String id="pages.quote.payment.continue_deposit" />;
        }
        return <String id="pages.quote.payment.continue_finance" />;
      }
      if (quote?.mtaOf && textToShow !== 'more') {
        return <String id="pages.quote.payment.complete_change" />;
      }

      if (quote?.renewalOf && textToShow !== 'more') {
        return <String id="pages.quote.payment.complete_renewal" />;
      }

      return <String id="pages.quote.payment.make_payment" />;
    }

    const overviewDetailNoteStringID = useMemo(() => {
      if (selectedPaymentPlan === PaymentPlanEnum.FLEX_WALLET) {
        return 'pages.quote.summary.flex_wallet.news';
      }
      if (textToShow === 'less') {
        if (selectedPaymentPlan === PaymentPlanEnum.IN_FULL) {
          return 'pages.quote.summary.in_full.news_less';
        } else {
          return 'pages.quote.summary.instalments.news_less';
        }
      }
      return `pages.quote.summary.news_${textToShow}`;
    }, [textToShow]);

    return (
      <>
        {showRenewalBanner && <RenewalBanner />}
        <div
          ref={ref}
          className="PaymentOverview-wrapper"
          data-testid="payment-overview"
          data-test-data={quote?.quoteNumber}
        >
          {isOpenQuote && (
            <ProgressBar
              currentStep={currentStep}
              currentStepText={currentStepText}
              nextStepText={nextStepText}
              totalStepCount={3}
              withBanner={showRenewalBanner}
            />
          )}
          <h3 className="PaymentOverview-title">
            <String id="pages.quote.summary.title" />
          </h3>

          {hasValidationErrors &&
            (!documentsConfirmed || !instructionsConfirmed) && (
              <div className="PaymentOverview-infobox-wrapper">
                <InfoBox
                  title={get('pages.quote.overview_page.error.title')}
                  text={get('pages.quote.overview_page.error.body')}
                  type={InfoBoxType.Error}
                  allowClose={false}
                  testId="policy-renewal-infobox"
                />
              </div>
            )}

          {financePaymentPlan &&
            shouldPay &&
            fitsStatus === FitsStatus.CONFIRM && (
              <>
                <div className="PaymentOverview-infobox-wrapper">
                  <InfoBox
                    title={get('pages.quote.payment.finance.accepted.title')}
                    text={
                      <String
                        id="pages.quote.payment.finance.accepted.body"
                        values={{
                          amount: (
                            <Currency
                              currency={currency}
                              value={financePaymentPlan.policyBindAmount}
                            />
                          ),
                        }}
                      />
                    }
                    type={InfoBoxType.Info}
                    allowClose={false}
                    testId="payment-finance-confirm-infobox"
                  />
                </div>
                <div className="PaymentOverview-infobox-wrapper">
                  <InfoBox
                    title={get('pages.quote.payment.finance.pay_deposit.title')}
                    text={
                      <String
                        id="pages.quote.payment.finance.pay_deposit.body"
                        values={{
                          amount: (
                            <Currency
                              currency={currency}
                              value={financePaymentPlan.policyBindAmount}
                            />
                          ),
                        }}
                      />
                    }
                    type={InfoBoxType.Info}
                    allowClose={false}
                    testId="payment-finance-deposit-infobox"
                  />
                </div>
              </>
            )}

          {financePaymentPlan && fitsStatus === FitsStatus.DECLINE && (
            <div className="PaymentOverview-infobox-wrapper">
              <InfoBox
                title={get('pages.quote.payment.finance.declined.title')}
                text={<String id="pages.quote.payment.finance.declined.body" />}
                type={InfoBoxType.Error}
                allowClose={false}
                testId="payment-finance-decline-infobox"
              />
            </div>
          )}

          {financePaymentPlan && fitsStatus === FitsStatus.ERROR && (
            <div className="PaymentOverview-infobox-wrapper">
              <InfoBox
                title={get('pages.quote.payment.finance.error.title')}
                text={get('pages.quote.payment.finance.error.body')}
                type={InfoBoxType.Error}
                allowClose={false}
                testId="payment-finance-error-infobox"
              />
            </div>
          )}

          {flexWalletPaymentPlan && (
            <div className="PaymentOverview-infobox-wrapper">
              <InfoBox
                title={get('pages.quote.payment.flex_wallet.title')}
                text={get('pages.quote.payment.flex_wallet.body', {
                  minHours: flexWalletPaymentPlan.minWalletUnits,
                  minWalletAmount: (
                    <Currency
                      currency={currency}
                      value={flexWalletPaymentPlan.minWalletBalance}
                    />
                  ),
                  topUpHours: flexWalletPaymentPlan.topupUnits,
                  topUpAmount: (
                    <Currency
                      currency={currency}
                      value={flexWalletPaymentPlan.topupAmount}
                    />
                  ),
                })}
                type={InfoBoxType.Info}
                allowClose={true}
                testId="payment-flex-wallet-infobox"
              />
            </div>
          )}

          {fitsStatus !== FitsStatus.CONFIRM && (
            <>
              <div className="Overview">
                <div className="Overview-detail">
                  <div className="Overview-detail-value medium">{name}</div>
                </div>
                {!quote.mtaOf && (
                  <div className="Overview-detail">
                    <div className="Overview-detail-value large">
                      <QuoteOptionPrice
                        _package={_package as IQuotePackageValuation}
                        currency={currency}
                        productCode={product.code}
                        productCountry={product.location.country}
                        policyDuration={policy.duration}
                        showPaymentPlans={false}
                      />
                    </div>
                  </div>
                )}
                {quote.mtaOf && differenceToPay != null && (
                  <div className="Overview-detail">
                    <div
                      className="Overview-detail-note"
                      data-testid="overview-detail-note"
                    >
                      <String
                        id={overviewDetailNoteStringID}
                        values={
                          textToShow === 'equal'
                            ? undefined
                            : {
                                amount:
                                  currency &&
                                  ReactDOMServer.renderToString(
                                    <Currency
                                      currency={currency}
                                      value={Math.abs(differenceToPay)}
                                    />
                                  ),
                              }
                        }
                      />
                      {instalmentsPaymentPlan && (
                        <InstalmentsBreakdown
                          paymentPlan={instalmentsPaymentPlan}
                          currency={currency}
                          textToShow={textToShow}
                          instalmentDifference={getDifference}
                          variant="compact"
                          productCode={product.code}
                        />
                      )}
                    </div>
                  </div>
                )}
                {showDriverName && (
                  <div className="Overview-detail">
                    <div className="Overview-detail-label">
                      <String id="pages.quote.summary.driver" />
                    </div>
                    <div className="Overview-detail-value">
                      {firstName} {lastName}
                    </div>
                  </div>
                )}
                {showVehicle && (
                  <div className="Overview-detail">
                    <div className="Overview-detail-label">
                      <String id="pages.quote.summary.vehicle" />
                    </div>
                    <div className="Overview-detail-value">
                      {yearOfManufacture} {toTitleCase(make)}{' '}
                      {toTitleCase(model)}
                    </div>
                  </div>
                )}
                {showStartDate && (
                  <div className="Overview-detail">
                    <div className="Overview-detail-label">
                      <String id="pages.quote.summary.start_date" />
                    </div>
                    <div className="Overview-detail-value">
                      <DateFormat
                        date={startDate}
                        timeZone={product.timezone}
                        locale={locale}
                      />
                    </div>
                  </div>
                )}
                {showEndDate && (
                  <div className="Overview-detail">
                    <div className="Overview-detail-label">
                      <String id="pages.quote.summary.end_date" />
                    </div>
                    <div className="Overview-detail-value">
                      <DateFormat
                        date={endDate}
                        timeZone={product.timezone}
                        locale={locale}
                      />
                    </div>
                  </div>
                )}
                {showProtectedNcd && (
                  <div
                    className="Overview-detail"
                    data-testid="payment-overview-protected_ncd"
                  >
                    <div className="Overview-detail-label">
                      <String id="pages.quote.overview_page.driving_details.ncd" />
                    </div>
                    <div className="Overview-detail-value normal-case">
                      {protectedNcd ? (
                        <String id="pages.quote.overview_page.driving_details.yes" />
                      ) : (
                        <String id="pages.quote.overview_page.driving_details.not_protected" />
                      )}
                    </div>
                  </div>
                )}
                {showClaimFreeYears && (
                  <div
                    className="Overview-detail"
                    data-testid="payment-overview-claim_free_years"
                  >
                    <div className="Overview-detail-label">
                      <String id="pages.quote.overview_page.driving_details.claims_free" />
                    </div>
                    <div className="Overview-detail-value normal-case">
                      <String
                        id="pages.quote.overview_page.driving_details.year_count"
                        values={{ v: claimFreeYears }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {configuration.enableAllPaymentOptionsFromBilling ? (
                <PaymentPlanSelectionContainer
                  paymentOptions={paymentOptions}
                  quoteId={quote.id}
                  product={product}
                  quotePackage={context.package!}
                  onClick={setSelectedPaymentPlan}
                  selectedPlan={selectedPaymentPlan}
                  disabledPlans={
                    fitsStatus === FitsStatus.DECLINE
                      ? [PaymentPlanEnum.FINANCE]
                      : []
                  }
                  loading={loadingPaymentPlans}
                />
              ) : (
                !quote.mtaOf && (
                  <PaymentPlanSelection
                    product={product}
                    quotePackage={context.package!}
                    onClick={setSelectedPaymentPlan}
                    selectedPlan={selectedPaymentPlan}
                    disabledPlans={
                      fitsStatus === FitsStatus.DECLINE
                        ? [PaymentPlanEnum.FINANCE]
                        : []
                    }
                  />
                )
              )}

              <QuoteDocuments quote={quote} package={_package} />

              <h3 className="PaymentOverview-actions-terms-agree">
                <String id="pages.quote.summary.agreement" />
              </h3>

              <div
                className={`PaymentOverview-actions-terms ${
                  hasValidationErrors && !documentsConfirmed
                    ? 'ErrorMessage-border'
                    : ''
                }`}
              >
                <label
                  className="PaymentOverview-actions-terms-wrapper"
                  tabIndex={1}
                >
                  <Checkbox
                    dataTestId="terms-documents"
                    isChecked={documentsConfirmed}
                    value={documentsConfirmed ? 'true' : 'false'}
                    stringId={
                      product.location.country === 'US'
                        ? 'pages.quote.summary.confirm_application_document'
                        : 'pages.quote.summary.confirm_documents'
                    }
                    onChecked={setDocumentsConfirmed}
                  />
                </label>
              </div>
              {hasValidationErrors && !documentsConfirmed && (
                <ErrorMessage
                  message={get('pages.quote.overview_page.required')}
                />
              )}

              <div
                className={`PaymentOverview-actions-terms ${
                  hasValidationErrors && !instructionsConfirmed
                    ? 'ErrorMessage-border'
                    : ''
                }`}
              >
                <label
                  className="PaymentOverview-actions-terms-wrapper"
                  tabIndex={1}
                >
                  <Checkbox
                    dataTestId="terms-instructions"
                    isChecked={instructionsConfirmed}
                    value={instructionsConfirmed ? 'true' : 'false'}
                    stringId={`pages.quote.summary.confirm_${
                      pricingModel === 'USAGE_BASED'
                        ? 'cpa'
                        : paymentProvider.continuousPaymentAuthority === true
                        ? 'cpa_fixed_term'
                        : 'instructions'
                    }`}
                    onChecked={setInstructionsConfirmed}
                  />
                </label>
              </div>

              {hasValidationErrors && !instructionsConfirmed && (
                <ErrorMessage
                  message={get('pages.quote.overview_page.required')}
                />
              )}

              <div className="PaymentOverview-actions-help">
                <String
                  id={
                    product.location.country === 'US'
                      ? 'pages.quote.summary.make_changes_contact_us'
                      : 'pages.quote.summary.make_changes'
                  }
                />
              </div>
            </>
          )}

          <div className="PaymentOverview-actions-buttons">
            <Button
              tabIndex={2}
              variant="primary"
              onClick={() => onContinue()}
              loaderColor="white"
              testId="make-payment-button"
            >
              {renderContinueButtonText()}
            </Button>

            {fitsStatus !== FitsStatus.CONFIRM && (
              <Button
                tabIndex={3}
                variant="secondary"
                onClick={() => onCancel()}
                loaderColor="white"
                testId="back-to-dashboard-button"
                className="PaymentOverview-actions-button"
              >
                <String id="pages.product.dashboard.modal.existing_mta.cancel" />
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }

  // @todo F1-188 Refactor where PremiumFinanceFrame is positioned in PaymentOverview
  return (
    <Full className="PaymentOverview">
      {quote &&
      context.package?.packageValuationId &&
      fitsStatus === FitsStatus.OPEN ? (
        <PremiumFinanceFrame
          quoteId={quote.id}
          packageValuationId={context.package.packageValuationId}
        />
      ) : (
        <PageBody />
      )}
    </Full>
  );
}

export default PaymentOverview;
