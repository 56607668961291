import React, { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import './Modal.scss';
import Loader from 'components/Loader/Loader';

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal');
document.body.appendChild(modalRoot);

interface Props {
  title: string;
  className?: string;
  onClose?(): void;
  type?: 'fixed';
  actions?: JSX.Element;
  actionsFlow?: 'row' | 'column';
  size?: 'small' | 'large' | 'full';
  dataTestId?: string;
  loading?: boolean;
  loadingMessage?: string;
}

function Modal({
  title,
  className,
  children,
  onClose,
  size = 'small',
  type,
  actions,
  actionsFlow,
  dataTestId,
  loading = false,
  loadingMessage = '',
}: PropsWithChildren<Props>) {
  useEffect(() => {
    function onKeyDown({ key }: KeyboardEvent) {
      if (key === 'Escape') {
        onClose?.();
      }
    }

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  return createPortal(
    <div
      className={classnames(
        'Modal',
        { [`Modal-${type}`]: type },
        `Modal-size-${size}`
      )}
      data-testid={dataTestId}
    >
      <div className="Modal-overlay" onClick={onClose} />
      <div className="Modal-scroll">
        <div className="Modal-content-container">
          <div className="Modal-content">
            <div className="Modal-title">{title}</div>
            <div className={classnames('Modal-body', className)}>
              {loading ? (
                <div>
                  {loadingMessage && (
                    <div className="Modal-body-loading-message">
                      {loadingMessage}
                    </div>
                  )}
                  <Loader />
                </div>
              ) : (
                children
              )}
            </div>
            {actions && (
              <div
                className={classnames(
                  'Modal-actions',
                  `Modal-actions-${actionsFlow ?? 'row'}`
                )}
              >
                {actions}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default Modal;
