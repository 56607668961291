import { useLocale } from '../providers/LocaleProvider';
import { useGetApplicationQuery } from 'shared/graphql/api/types';

export const useGetApplication = () => {
  const { lang: locale } = useLocale();

  const { loading, data } = useGetApplicationQuery({ variables: { locale } });

  return {
    loading,
    application: data?.application,
  };
};
