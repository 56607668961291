import React, { PropsWithChildren } from 'react';
import Button from 'components/Buttons/Button';

import Modal from '../Modal';

import './InfoModal.scss';

interface Props {
  title: string;
  message: string;
  closeText: string;
  onCancel(): void;
}

function InfoModal({
  title,
  message,
  closeText,
  onCancel,
}: PropsWithChildren<Props>) {
  return (
    <Modal
      title={title}
      className="InfoModal"
      onClose={onCancel}
      actions={
        <Button
          variant="secondary"
          onClick={onCancel}
          testId="info-model-close"
        >
          {closeText}
        </Button>
      }
    >
      {message}
    </Modal>
  );
}

export default InfoModal;
