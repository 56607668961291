import React from 'react';
import String from '../../String/String';

interface DetailProps {
  label: string;
  value: any;
  testId: string;
}

function Detail({ label, value, testId }: DetailProps) {
  return (
    <div className="Overview-detail">
      <div className="Overview-detail-label">
        <String id={label} />
      </div>
      <div className="Overview-detail-value" data-testid={testId}>
        {value}
      </div>
    </div>
  );
}

export default Detail;
