import querystring from 'query-string';
import configuration from 'configuration';

export function getRedirectPath(search: string) {
  const query = querystring.parse(search);
  const { redirect } = query as { redirect: string };

  return redirect ?? '';
}

export function createRedirectPath(
  location: Partial<Location>,
  region = configuration.region
) {
  const { pathname = '/', search } = location;

  const segments = pathname.split('/');

  // If the pathname contains the region, remove it
  if (segments[1] === region) {
    segments.splice(1, 1);
  }

  const path = segments.join('/');

  return `${path}${search ?? ''}`;
}
