import React, { useState } from 'react';

import './Document.scss';
import Document from './Document';
import { IDocument } from 'shared/graphql/api/types';

interface Props {
  name: string;
  productCode: string;
  getDocument: (id: string, uri: string) => Promise<void>;
  generateDocument: () => Promise<IDocument | undefined>;
  track: { productId: string; label: string };
}

function DocumentType({
  generateDocument,
  getDocument,
  productCode,
  name,
  track,
}: Props) {
  const [document, setDocument] = useState<IDocument | null>(null);
  const [loading, setLoading] = useState(false);

  async function showDocument() {
    setLoading(true);
    if (!document) {
      const doc = await generateDocument();

      if (doc) {
        setDocument(doc);
        await getDocument(doc.id, doc.uri);
        setLoading(false);
      }
    } else {
      await getDocument(document.id, document.uri);
      setLoading(false);
    }
  }

  return (
    <Document
      getDocument={showDocument}
      productCode={productCode}
      name={name}
      track={track}
      loading={loading}
    />
  );
}

export default DocumentType;
