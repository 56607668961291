import { IdentityConnection, IUser } from '../types/user';
import configuration from '../configuration';

export function validationRequired(user: IUser) {
  const { emailVerified } = user;

  return (
    configuration.verification.enforce &&
    !emailVerified &&
    hasConnection(user, IdentityConnection.Username)
  );
}

export function hasConnection(user: IUser, connection: IdentityConnection) {
  const { identities } = user;

  return identities.some((i) => i.connection === String(connection));
}
