import {
  IGetPolicyVersionQuery,
  IGetPolicyVersionQueryVariables,
  useGetPolicyFromOuQuery,
  useGetPolicyQuery,
  useGetPolicyVersionQuery,
} from 'shared/graphql/api/types';
import { QueryHookOptions } from '@apollo/client';

export const useGetPolicy = (id: string) => {
  const { error, loading, data } = useGetPolicyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return {
    error,
    loading,
    policy: data?.policy,
  };
};

export const useGetPolicyVersion = (
  id: string,
  version: string,
  options?: QueryHookOptions<
    IGetPolicyVersionQuery,
    IGetPolicyVersionQueryVariables
  >
) => {
  const { error, loading, data } = useGetPolicyVersionQuery({
    fetchPolicy: 'no-cache',
    ...options,
    variables: {
      id,
      version,
    },
  });

  return {
    error,
    loading,
    policy: data?.policyVersion,
  };
};

export const useGetPolicyFromOU = (id: string, token: string, map: any) => {
  const { error, loading, data } = useGetPolicyFromOuQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id,
      token,
      map,
    },
  });

  return {
    error,
    loading,
    status: data?.response.status,
    policy: data?.response.policy,
  };
};
