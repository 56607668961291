export const MockAuthUser = {
  given_name: 'John',
  family_name: 'Doe',
  nickname: 'John',
  name: 'mock_john@inshur.com',
  picture:
    'https://s.gravatar.com/avatar/c9076e0ea6caf6fb81f369eba421501b?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fan.png',
  updated_at: '2022-02-01T13:29:08.616Z',
  email: 'mock_john@inshur.com',
  email_verified: true,
  sub: 'auth0|6856bac005911500699c12cb',
};

export const MockAuthClaims = {
  __raw:
    'eyJhbGci23JSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im5na3VtYnpYa29qbkNBaFJ2aXJLWSJ9.eyJnaXZlbl9uYW1lIjoiQW5kcmVhcyIsImZhbWlseV9uYW1lIjoiS2FpdGlzIiwibmlja25hbWUiOiJhbmRyZWFzIiwibmFtZSI6ImFuZHJlYXNAaW5zaHVyLmNvbSIsInBpY3R1cmUiOiJodHRwczovL3MuZ3JhdmF0YXIuY29tL2F2YXRhci9jOTA3NmUwZWE2Y2FmNmZiODFmMzY5ZWJhNDIxNTAxYj9zPTQ4MCZyPXBnJmQ9aHR0cHMlM0ElMkYlMkZjZG4uYXV0aDAuY29tJTJGYXZhdGFycyUyRmFuLnBuZyIsInVwZGF0ZWRfYXQiOiIyMDIyLTAyLTAxVDEzOjI5OjA4LjYxNloiLCJlbWFpbCI6ImFuZHJlYXNAaW5zaHVyLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL2luc2h1ci1kZXYwLWN1c3RvbWVyLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2MDA2YmFjMDA1OTExNTAwNjk5YzEyY2IiLCJhdWQiOiJWeGNhdDY2TWQ1YW5DemZ1YzJqbXVsNkMzemtZd3ppWCIsImlhdCI6MTY0MzgwMDY1MywiZXhwIjoxNjQzODg3MDUzLCJub25jZSI6IlFqaFFWV3cxTG5oNk4yY3RTVkJ5TlZFMVFsUnpTbU5hUzBsc1ZsOHlZVTVmVGpCbmEwWXhTRjlRY3c9PSJ9.ETdcdeOaEr-_Vf7nv1VOEPKx5dRmtnG6tVsAQaUCaGTq4l-i5k390_v7hzxDyVZTCmQeE-ciNU-z3o2NAM1KVkyfF_ZwBseIv7M5qA7gqU2ITfcelcq8okkgsnSraCGXJMI0LCI7lGu86XXa5TXWBxluUlBPIPpPJUqhWfAH-CazFRv5Gc-hJl3wy2P0jZkaQBjsho9EcCctv3Ef3O8g0tEK5JojGHKV-q8UbdhjaSOonQWIz2HWhpOsYGeEBHU6ntOdg_k955p0_ok9A3So8wrtfqoaF4h_mVW6iRvR0-R0w2ti5GOMklgb2VY4J5r-UM5C-a-iwJkOJatkk_J5gQ',
  given_name: 'John',
  family_name: 'Doe',
  nickname: 'John',
  name: 'mock_john@inshur.com',
  picture:
    'https://s.gravatar.com/avatar/c9076e0ea6caf6fb81f369eba421501b?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fan.png',
  updated_at: '2022-02-01T13:29:08.616Z',
  email: 'mock_john@inshur.com',
  email_verified: true,
  iss: 'https://inshur-dev0-customer.eu.auth0.com/',
  sub: 'auth0|6856bac005911500699c12cb',
  aud: 'Vxcat66Md5anCzfuc2jmul6C3zkYwziX',
  iat: 1643800653,
  exp: 1643887053,
  nonce: 'QjhNMWw1Lnh6N2ctSVByNVE1QlRzSmNaS0lsVl8yYU5fTjBna0YxSF9Qcw==',
};

export const MockAppState = {
  redirectUri: '/product/dda6a33b-042b-4b42-b9d4-4d2abda57041?lang=en',
  productId: 'dda6a33b-042b-4b42-b9d4-4d2abda57041',
};
