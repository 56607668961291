import React, { ReactNode } from 'react';
import { Flex1Col, FlexColumn } from 'components/Layout/Layout';
import String from 'components/String/String';
import { Configuration } from 'configuration';

type WalletBalanceColumnProps = {
  children: ReactNode;
};

const WalletBalanceColumn: React.FC<WalletBalanceColumnProps> = ({
  children,
}) => (
  <FlexColumn className="ProductPage-details">
    <Flex1Col>
      <div
        data-testid="ProductPage-details-wallet-balance-title"
        className="field"
      >
        <String id="pages.product.dashboard.wallet-balance" />
      </div>
    </Flex1Col>
    <Flex1Col>
      <div className="value" data-testid="ProductPage-details-wallet-balance">
        {children}
      </div>
    </Flex1Col>

    {Configuration.get().walletBalanceError.isEnabled && (
      <span className="warning">
        <String id="pages.product.dashboard.wallet-error-balance-sync" />
      </span>
    )}
  </FlexColumn>
);

export default WalletBalanceColumn;
