import React from 'react';

import String from 'components/String/String';

export interface HeaderInfo {
  title?: string;
  description?: string;
  icon?: JSX.Element;
}

type Props = HeaderInfo;

function QuoteFormHeader({ title, description, icon }: Props) {
  return (
    <div className="QuoteForm-header">
      <div className="info">
        {title && (
          <h2 className="title">
            <String id={title} />
          </h2>
        )}
        {description && (
          <p>
            <String id={description} />
          </p>
        )}
      </div>
      {icon && <div className="icon">{icon}</div>}
    </div>
  );
}

export default QuoteFormHeader;
