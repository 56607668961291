import { useGetRenewalTrackingQuery } from 'shared/graphql/api/types';

export const useGetRenewalTracking = (id: string) => {
  const { error, loading, data } = useGetRenewalTrackingQuery({
    fetchPolicy: 'no-cache',
    variables: {
      policyId: id,
    },
  });

  if (data && !loading) {
    return {
      error,
      loading,
      renewalTracking: data.renewalTracking,
    };
  }

  return {
    error,
    loading,
    renewalTracking: data?.renewalTracking,
  };
};
