import { createRoot } from 'react-dom/client';
import Root from './Root';
import EventManager from './utils/EventManager';
import configuration from 'configuration';
import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { datadogRum } from '@datadog/browser-rum';

import './index.css';

if (configuration.datadog.isEnabled) {
  datadogRum.init({
    applicationId: configuration.datadog.applicationId,
    clientToken: configuration.datadog.clientToken,
    site: configuration.datadog.site,
    service: configuration.datadog.service,
    env: configuration.datadog.env,
    version: configuration.datadog.version,
    sessionSampleRate: configuration.datadog.sessionSampleRate,
    sessionReplaySampleRate: configuration.datadog.sessionReplaySampleRate,
    trackResources: configuration.datadog.trackResources,
    trackLongTasks: configuration.datadog.trackLongTasks,
    trackUserInteractions: configuration.datadog.trackUserInteractions,
  });
}

if (configuration.sentry.dsn) {
  Sentry.init({
    environment: configuration.environment,
    release: `customer-web@${configuration.version}`,
    dsn: configuration.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeBreadcrumb(
      breadcrumb: Breadcrumb,
      hint?: BreadcrumbHint
    ): Breadcrumb | null {
      if (breadcrumb.category === 'ui.click' && hint) {
        const { target } = hint.event;

        // Append testid if the element has one
        const testId = (target as HTMLElement)?.getAttribute('data-testid');

        if (testId) {
          breadcrumb.message = `${breadcrumb.message} [${testId}]`;
        }
      }

      return breadcrumb;
    },
  });
}

const gtmOptions = {
  gtmId: configuration.googleTagManager.id,
  auth: configuration.googleTagManager.auth,
  preview: configuration.googleTagManager.preview,
};

EventManager.initialize(gtmOptions);

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Root />);

serviceWorkerRegistration.register();
