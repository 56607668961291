import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import configuration from 'configuration';
import MaintenancePage from './MaintenancePage';
import useMessages from '@inshur/nowucit/messages/useMessages';
import Message from '@inshur/nowucit/messages/Message';

import './MaintenancePage.scss';

function showMaintenanceMessage(
  maintenanceMessage: Message | undefined,
  location: string
): boolean {
  if (!maintenanceMessage || !maintenanceMessage.enabled) {
    return false;
  }

  if (maintenanceMessage.regex) {
    const regex = new RegExp(maintenanceMessage.regex);
    const regexMatch: boolean = regex.test(location);

    const userPercentage = localStorage.getItem('MAINTENANCE_PERCENTAGE');
    if (!userPercentage) {
      const percentage = Math.floor(Math.random() * 100).toString();
      localStorage.setItem('MAINTENANCE_PERCENTAGE', percentage.toString());
    }

    const unallowedPercentage: boolean = userPercentage
      ? Number(userPercentage) > maintenanceMessage.allowPercentage
      : true;

    return regexMatch && unallowedPercentage;
  }

  return false;
}

function MaintenanceBoundary({ children }: React.PropsWithChildren<unknown>) {
  const { messages } = useMessages();
  const { pathname, search } = useLocation();
  const location = pathname + search;

  const maintenanceMessage = useMemo(
    () => messages.find(({ name }) => name === 'maintenance-page'),
    [messages]
  );

  const shouldDisplayMaintenanceMessage: boolean = useMemo(
    () => showMaintenanceMessage(maintenanceMessage, location),
    [maintenanceMessage, location]
  );

  if (configuration.maintenanceEnabled || shouldDisplayMaintenanceMessage) {
    return <MaintenancePage message={maintenanceMessage?.message} />;
  }

  return <>{children}</>;
}

export default MaintenanceBoundary;
