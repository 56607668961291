import React, { useMemo } from 'react';
import useMessages from '@inshur/nowucit/messages/useMessages';
import { useLocation } from 'react-router-dom';

import Banner from './Banner';

function AlertBanner() {
  const { messages } = useMessages();
  const { search, pathname } = useLocation();
  const location = pathname + search;

  const banner = useMemo(() => {
    const message = messages.find(({ name }) => {
      return name === 'maintenance-banner';
    });

    if (!message || !message.enabled || !message.regex) {
      return null;
    }

    const reg = new RegExp(message.regex);
    if (reg.test(location)) {
      return message;
    }

    return null;
  }, [messages, location]);

  if (!banner) {
    return null;
  }

  return <Banner severity={banner.severity} message={banner.message || ''} />;
}

export default AlertBanner;
