import './QuoteOptionMessage.scss';

interface Props {
  title: string | JSX.Element;
  message: string | JSX.Element;
}

function QuoteOptionMessage({ title, message }: Props) {
  return (
    <div data-testid="QuoteOptionMessage" className="QuoteOptionMessage padded">
      <div className="QuoteOptionMessage-color" />
      <div className="QuoteOptionMessage-body">
        <div className="QuoteOptionMessage-title">{title}</div>
        <div className="QuoteOptionMessage-message">{message}</div>
      </div>
    </div>
  );
}

export default QuoteOptionMessage;
