import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocale } from 'providers/LocaleProvider';
import {
  useGetAllPolicies,
  useGetDashboardPolicies,
} from 'hooks/useGetPolicies';
import { useGetQuotesForUser, useProduct } from 'hooks';
import { useUser } from 'providers/UserProvider';
import { useQuery } from '../../../hooks';

import Page from 'components/Page/Page';
import { Centered, FlexColumn } from 'components/Layout/Layout';
import String from 'components/String/String';
import Loader from 'components/Loader/Loader';

import './ProductPage.scss';
import { Tabs } from '../../../components/Tabs/Tabs';
import { InfoBoxType } from 'components/InfoBox/InfoBox';
import PoliciesTab from './PoliciesTab/PoliciesTab';
import QuotesTab from './QuotesTab/QuotesTab';
import configuration from 'configuration';
import LinkButton from '../../../components/LinkButton/LinkButton';
import { openQuotesRenewalsEnabled } from '../../../utils/quoteUtils';
import Button from 'components/Buttons/Button';
import { usePWA } from 'providers/PWAProvider';
import EventManager from 'utils/EventManager';
import { QuoteStatusEnum } from '@inshur/apis/quote';
import { ApplicationError, ErrorCodes } from 'constants/errors';
import DashboardNotifications from './PoliciesTab/DashboardNotifications/DashboardNotifications';
import { useUserId } from '../../../hooks/useUserId';
import { useGetUserInvoices } from '../../../hooks/useGetUserInvoices';

export enum ProductPageTab {
  POLICIES = 'pages.product.tabs.policies',
  QUOTES = 'pages.product.tabs.quotes',
}

interface ProductPageQuery {
  newPurchase: boolean;
  cardUpdateStatus: InfoBoxType;
  tab: string;
}

function ProductPage() {
  const { id } = useParams<{ id: string }>();
  const { tab } = useQuery<ProductPageQuery>();
  const { lang } = useLocale();
  const { getUserInvoices, marketingSiteURL } = configuration;

  const {
    policies = [],
    loading: loadingPolicies,
    refetch: refetchActivePendingCanxPolicies,
  } = useGetDashboardPolicies({
    statuses: ['ACTIVE', 'PENDING', 'CANCELLED'],
  });
  const { quotes, loading: loadingQuotes } = useGetQuotesForUser();
  const {
    productHistory: { current: product },
    loading: loadingProduct,
    error: productError,
  } = useProduct(id, lang);

  const { allPolicies, loading: loadingAllVersions } = useGetAllPolicies();

  const userId = useUserId()!;
  const { getInvoices, unpaidInvoices } = useGetUserInvoices(userId);

  useEffect(() => {
    if (getUserInvoices) {
      getInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserInvoices]);

  const activePendingPolicies = policies?.filter(
    (policy) => policy.status === 'ACTIVE' || policy.status === 'PENDING'
  );

  function getDefaultSelectedTab(value: string | null): ProductPageTab {
    switch (value) {
      case 'quote':
        return ProductPageTab.QUOTES;
      case 'policy':
      default:
        return ProductPageTab.POLICIES;
    }
  }

  const [activeTab, setActiveTab] = useState<ProductPageTab>(
    getDefaultSelectedTab(tab)
  );

  const user = useUser();

  const showOpenQuotesRenewals = openQuotesRenewalsEnabled();

  const showStartQuote = useMemo(() => {
    const hasPolicies = activePendingPolicies.length > 0;
    const isUSProduct = product?.location.country === 'US';
    return !isUSProduct && !hasPolicies;
  }, [activePendingPolicies, product]);

  const activeNotTakenUpQuotes = quotes?.filter((quote) => {
    if (allPolicies) {
      for (const policy of allPolicies) {
        if (policy.quote && policy.quote.id === quote.id) {
          return false;
        }
      }
    }
    return true;
  });

  const validActiveQuotes = activeNotTakenUpQuotes?.filter(
    (quote) =>
      !quote.mtaOf &&
      !quote.cancellationOf &&
      quote.status !== QuoteStatusEnum.DECLINED
  );

  const activeQuotes = validActiveQuotes?.filter((quote) => {
    return showOpenQuotesRenewals ? true : !quote.renewalOf;
  });

  const { prompt, promptToInstall } = usePWA();

  function onDownloadPWA() {
    EventManager.track({
      event: 'ButtonPressed',
      productId: id,
      questionId: 'PWA - Product Page',
    });
    promptToInstall();
  }

  function PageBody() {
    const error = productError;

    if (error) {
      throw new ApplicationError(error.message, ErrorCodes.SERVER_ERROR);
    }

    if (
      loadingPolicies ||
      loadingQuotes ||
      loadingProduct ||
      loadingAllVersions
    ) {
      return (
        <Centered>
          <Loader />
        </Centered>
      );
    }

    const { firstName, lastName, email } = user;

    return (
      <>
        <div className="ProductPage-intro-wrapper">
          <h1 className="ProductPage-intro">
            <String
              id="pages.product.dashboard.title"
              values={{
                firstName: firstName ?? '',
                lastName: lastName ?? '',
              }}
            />
          </h1>
          <div className="ProductPage-intro-username">
            <span style={{ fontWeight: 'bold' }}>
              <String id="pages.product.dashboard.username" />
              {': '}
            </span>
            {email}
          </div>
          <Tabs
            tabs={[ProductPageTab.POLICIES, ProductPageTab.QUOTES]}
            activeTab={activeTab}
            setActive={setActiveTab}
          />
        </div>
        <>
          {activeTab === ProductPageTab.POLICIES && (
            <>
              <DashboardNotifications
                activeQuotes={activeQuotes}
                policies={policies}
                setActiveTab={setActiveTab}
                unpaidInvoices={unpaidInvoices}
              />
              <PoliciesTab
                activePendingPolicies={activePendingPolicies}
                allPolicies={allPolicies}
                quotes={quotes}
                policiesRefetch={refetchActivePendingCanxPolicies}
              />
            </>
          )}
          {activeTab === ProductPageTab.QUOTES && (
            <QuotesTab activeQuotes={activeQuotes} product={product} />
          )}
        </>
        {showStartQuote && (
          <>
            <FlexColumn className="ProductPage-footer">
              <LinkButton
                testId="get_quote-linkbutton"
                className="ProductPage-button"
                to={id ? `/product/${id}/quote` : '#'}
                query={{
                  newOpenQuote: true,
                }}
                variant="secondary"
                handleClick={(e: MouseEvent) => {
                  if (id) {
                    return;
                  }
                  e.preventDefault();
                  window.location.href = marketingSiteURL;
                }}
              >
                <String id="pages.product.dashboard.get_quote" />
              </LinkButton>
            </FlexColumn>
          </>
        )}
        {prompt && (
          <div className="ProductPage-download">
            <Button onClick={onDownloadPWA} testId="download-app">
              <String id="commons.download_app" />
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Page name="ProductPage" slug="Dashboard" padded={false}>
      <PageBody />
    </Page>
  );
}

export default ProductPage;
