import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import Switch from 'components/Router/Switch';
import PackagesPage from './PackagesPage/PackagesPage';

function PackagesRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={PackagesPage} />
    </Switch>
  );
}

export default PackagesRoute;
