import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import Switch from 'components/Router/Switch';

import QuotePage from './QuotePage/QuotePage';
import PaymentRoute from './payment/PaymentRoute';
import ReviewMtaPage from './ReviewMtaPage/ReviewMtaPage';
import SuccessPolicyPage from './SuccessPolicyPage/SuccessPolicyPage';
import ExpiredMtaPage from './ExpiredMtaPage/ExpiredMtaPage';
import EmailLandingPage from './EmailLandingPage/EmailLandingPage';
import ExpiredQuotePage from './ExpiredQuotePage/ExpiredQuotePage';
import PackagesRoute from './packages/PackagesRoute';
import QuoteOptionsPage from './QuoteOptionsPage/QuoteOptionsPage';
import QuoteOverviewPage from './QuoteOverviewPage/QuoteOverviewPage';
import QuotePageForm from './QuotePage/form/QuoteForm';

function QuoteRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={QuotePage} />
      <Route path={`${match.path}/form`} component={QuotePageForm} />
      <Route path="/mtaExpired" component={ExpiredMtaPage} />
      <Route path={`${match.path}/:quoteId/review`} component={ReviewMtaPage} />
      <Route
        path={`${match.path}/:quoteId/:quoteType/success`}
        component={SuccessPolicyPage}
      />
      <Route
        path={`${match.path}/:quoteId/quoteExpired`}
        component={ExpiredQuotePage}
      />
      <Route
        path={`${match.path}/:quoteId/mtaExpired`}
        component={ExpiredMtaPage}
      />
      <Route
        path={`${match.path}/:quoteId/packages`}
        component={PackagesRoute}
      />
      <Route
        path={`${match.path}/:quoteId/options`}
        component={QuoteOptionsPage}
      />
      <Route
        path={`${match.path}/:quoteId/package/:packageId/overview`}
        component={QuoteOverviewPage}
      />
      <Route path={`${match.path}/:quoteId/payment`} component={PaymentRoute} />
      <Route path={`${match.path}/:quoteId`} component={EmailLandingPage} />
    </Switch>
  );
}

export default QuoteRoute;
