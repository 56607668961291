import React from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useQuery } from 'hooks';
import configuration from 'configuration';
import { usePremiumCreditFitsInitiateLoanApplicationMutation } from 'shared/graphql/api/types';
import { FitsStatus } from 'enums/premiumFianance';
import { useHistory } from './useHistory';

enum InitialisationStatus {
  Init,
  Fetching,
  Error,
  Ready,
}

type Input = {
  quoteId: string;
  packageValuationId: string;
};

type PremiumCreditFitsApplicationOutputInit = {
  status: InitialisationStatus.Init;
};

type PremiumCreditFitsApplicationOutputFetching = {
  status: InitialisationStatus.Fetching;
};

type PremiumCreditFitsApplicationOutputError = {
  status: InitialisationStatus.Error;
};

type PremiumCreditFitsApplicationOutputReady = {
  status: InitialisationStatus.Ready;
  xid: string;
  reqId: string;
  fitk: string;
  errorUrl: string;
  cancelUrl: string;
  redirectUrl: string;
};

type Output =
  | PremiumCreditFitsApplicationOutputInit
  | PremiumCreditFitsApplicationOutputFetching
  | PremiumCreditFitsApplicationOutputError
  | PremiumCreditFitsApplicationOutputReady;

export function isPremiumCreditFitsInitiateLoanApplicationError(
  value: Output
): value is PremiumCreditFitsApplicationOutputError {
  return value.status === InitialisationStatus.Error;
}

export function isPremiumCreditFitsInitiateLoanApplicationReady(
  value: Output
): value is PremiumCreditFitsApplicationOutputReady {
  return value.status === InitialisationStatus.Ready;
}

export function isPremiumCreditFitsInitiateLoanApplicationFetching(
  value: Output
): value is PremiumCreditFitsApplicationOutputFetching {
  return value.status === InitialisationStatus.Fetching;
}

export function usePremiumCreditFitsInitiateLoanApplication({
  quoteId,
  packageValuationId,
}: Input): Output {
  const [createLoanApplication, { data, loading, error }] =
    usePremiumCreditFitsInitiateLoanApplicationMutation();
  const location = useLocation();
  const { push } = useHistory();
  const { pathname } = location;
  const queryParams = useQuery<Record<string, any>>();

  React.useEffect(() => {
    const addPathToUrl = (path: string): string => {
      return `${window.location.protocol}//${window.location.host}/${configuration.region}${path}`;
    };

    const createQueryString = (
      params: Record<string, string | undefined>
    ): string => {
      return querystring.stringify(params, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma',
      });
    };

    const createRedirectRoute = (
      key: string,
      fitsStatus?: FitsStatus
    ): string => {
      const params = {
        redirectTo: `/${configuration.region}${pathname}?${createQueryString({
          ...queryParams,
          fits: fitsStatus,
        })}`,
      };

      return `${addPathToUrl(`/premiumCredit/fits/${key}`)}?${createQueryString(
        params
      )}`;
    };

    const confirmUrl = createRedirectRoute('confirm', FitsStatus.CONFIRM);
    const declineUrl = createRedirectRoute('decline', FitsStatus.DECLINE);
    const errorUrl = createRedirectRoute('error', FitsStatus.ERROR);
    const backUrl = createRedirectRoute('back');
    const cancelUrl = `${addPathToUrl(pathname)}?${createQueryString({
      ...queryParams,
      fits: undefined,
    })}`;
    const brokerUrl = addPathToUrl('/fitsFrameResizer.html');

    createLoanApplication({
      variables: {
        input: {
          quoteId,
          packageValuationId,
          confirmUrl,
          backUrl,
          declineUrl,
          cancelUrl,
          errorUrl,
          brokerUrl,
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return { status: InitialisationStatus.Fetching };
  } else if (error) {
    if (error.message.includes('[019]')) {
      push(`${pathname}/error`, undefined, {
        preserveQuery: true,
      });
      return { status: InitialisationStatus.Error };
    }
    return { status: InitialisationStatus.Error };
  } else if (data) {
    const {
      premiumCreditFitsInitiateLoanApplication: {
        xid,
        reqId,
        fitk,
        errorUrl,
        cancelUrl,
        redirectUrl,
      },
    } = data;
    return {
      status: InitialisationStatus.Ready,
      xid,
      reqId,
      fitk,
      errorUrl,
      cancelUrl,
      redirectUrl,
    };
  }

  return {
    status: InitialisationStatus.Init,
  };
}
