import TrackingProvider, { QuoteType } from 'providers/TrackingProvider';
import ChatbotProvider from 'providers/ChatbotProvider';
import CallApiHandlerProvider from 'providers/CallApiHandlerProvider';
import QuoteProvider from 'providers/QuoteProvider';
import Chatbot from 'components/Chatbot/Chatbot';
import { LoadedProductHistory, useProduct } from 'hooks';
import Loader from 'components/Loader/Loader';
import { ApplicationError, ErrorCodes } from 'constants/errors';
import { ILocale, IUserFragment } from 'shared/graphql/api/types';

const DEFAULT_QUOTE_TYPE = QuoteType.NEW_BUSINESS;

const DEFAULT_USER_DATA: IUserFragment = {
  email: '',
  emailVerified: false,
  telephone: '',
  identities: [],
};

interface Props {
  productId: string;
  locale: ILocale;
}

function QuoteProductPage({ productId, locale }: Props) {
  const {
    loading: loadingProduct,
    productHistory,
    error: productError,
  } = useProduct(productId, locale);

  if (loadingProduct) {
    return <Loader />;
  }

  if (productError) {
    throw new ApplicationError(productError.message, ErrorCodes.SERVER_ERROR);
  }

  return (
    <TrackingProvider
      defaultArguments={{ productId, quoteType: DEFAULT_QUOTE_TYPE }}
    >
      <ChatbotProvider>
        <CallApiHandlerProvider>
          <QuoteProvider
            user={DEFAULT_USER_DATA}
            productHistory={productHistory as LoadedProductHistory}
            quoteType={DEFAULT_QUOTE_TYPE}
          >
            <Chatbot onClose={undefined} />
          </QuoteProvider>
        </CallApiHandlerProvider>
      </ChatbotProvider>
    </TrackingProvider>
  );
}

export default QuoteProductPage;
