/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components/macro';
import { LinkButtonProps } from './LinkButton';
import { useView } from '../../utils/themeUtils';
import Link from 'components/Router/Link';

export type LinkButtonVariants =
  | 'regular'
  | 'primary'
  | 'secondary'
  | 'activated';

interface LinkButtonInputProps extends LinkButtonProps {
  variant: LinkButtonVariants;
  to: string;
}

export const StyledLinkButton = styled(Link)<LinkButtonInputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.disabled ? '' : '')};
  flex: 1;
  min-height: 3rem;
  min-width: 10rem;
  padding: 0 1rem;
  letter-spacing: 0.03rem;
  font-weight: 900;
  font-size: 1rem;
  border-radius: 0.25rem;
  position: relative;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background: var(--color-background-button-primary);
  color: var(--color-font-button);
  text-decoration: none;
  ${useView((theme, { variant }) =>
    theme.buttons ? theme.buttons[variant] : {}
  )};

  &:active {
    background: var(--color-background-button-active);
  }

  &[disabled] {
    opacity: 0.3;
  }
`;
