export function formatTestId(testId: string): string {
  const formatTestIdResult = testId
    .toLowerCase()
    .replace(/\s\s+/g, ' ') // removes all multiple spaces/tabs/new lines to one space
    .replace(/\s/g, '-') // replaces single spaces with "-"
    .split("'") // split and join replaces all instances
    .join('');

  return formatTestIdResult;
}
