import { useGetProductOptionLatestQuery } from 'shared/graphql/api/types';

export function useProductOptions(id: string) {
  const { data, loading, error } = useGetProductOptionLatestQuery({
    variables: { id },
  });

  return {
    loading,
    error,
    productOptions: data?.productOptionLatest,
  };
}
