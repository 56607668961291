import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { usePreQuote } from 'providers/QuoteProvider';
import Loader from 'components/Loader/Loader';
import { IMessage, ITextMessage } from 'types/message';
import MessageBubble from './MessageBubble';
import { useModal } from '../../../../providers/ModalProvider';
import EventManager, { EventType } from 'utils/EventManager';
import { useNavigation } from 'providers/NavigationProvider';
import { EditIcon } from 'components/Icons';
import './Message.scss';
import { useChatbot } from '../../../../providers/ChatbotProvider';

interface Props {
  detail: IMessage;
}

function isTextMessage(message: IMessage): message is ITextMessage {
  return message.type === 'text';
}

function Message({ detail }: Props) {
  const [typing, setTyping] = useState(detail.received);
  const { display, dismiss } = useModal();
  const { jumpTo, undo } = usePreQuote();
  const { received, data } = detail;
  const { close } = useNavigation();
  const { getMessageDelay } = useChatbot();

  useEffect(() => {
    if (!isTextMessage(detail)) {
      return;
    }

    const delay = getMessageDelay(detail.text);

    setTimeout(() => {
      setTyping(false);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isTextMessage(detail)) {
    // TODO: Handle this
    return null;
  }

  const { text } = detail;

  function onCancel() {
    EventManager.track({
      event: EventType.ResponseEditCanceled,
      questionId: data?.questionId,
    });
    close();
    dismiss();
  }

  function onEdit() {
    EventManager.track({
      event: EventType.ResponseEdited,
      questionId: data?.questionId,
    });

    if (data?.questionId && data?.snapshotVersion && !received) {
      jumpTo(data?.questionId);
      undo(data.snapshotVersion, data.questionId);
    }

    close();
    dismiss();
  }

  function onEditResponseConfirmation() {
    display('edit_response_confirmation', {
      onCancel,
      onEdit,
    });
  }

  return (
    <div
      className={classnames('Message', {
        'Message-received': received,
        'Message-sent': !received,
      })}
    >
      <div className="Message-detail">
        <MessageBubble received={received}>
          {typing && <Loader size={8} color="#757575" />}
          {!typing && (
            <div
              className="Message-text"
              data-testid={`message-bubble-${data?.questionId}`}
            >
              <ReactMarkdown children={text} linkTarget="_blank" />

              {!received && (
                <div
                  data-testid="edit-answer-icon"
                  onClick={onEditResponseConfirmation}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          )}
        </MessageBubble>
      </div>
    </div>
  );
}

export default Message;
