import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dateFns from '@date-io/date-fns';
import { useLocale } from './LocaleProvider';
import enLocale from 'date-fns/locale/en-GB';
import nlLocale from 'date-fns/locale/nl';
import { ILocale } from 'shared/graphql/api/types';

const locales: Record<ILocale, typeof enLocale> = {
  en: enLocale,
  nl: nlLocale,
};

function DatePickerProvider({ children }: React.PropsWithChildren<{}>) {
  const { lang: locale } = useLocale();

  return (
    <MuiPickersUtilsProvider utils={dateFns} locale={locales[locale]}>
      {children}
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerProvider;
