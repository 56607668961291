import { SchemaBuilder } from '../../../components/Builder/Builder';
import { default as UKC } from './package-features-schema.UKC.json';
import { default as UKC2 } from './package-features-schema.UKC2.json';
import { default as UKO } from './package-features-schema.UKO.json';
import { default as NBV } from './package-features-schema.NBV.json';
import { default as UKO2 } from './package-features-schema.UKO2.json';
import { default as UKO3 } from './package-features-schema.UKO3.json';
import { default as UKB } from './package-features-schema.UKB.json';
import { default as UKB2 } from './package-features-schema.UKB2.json';
import { default as NYR } from './package-features-schema.NYR.json';
import { default as UKA } from './package-features-schema.UKA.json';
import { default as UKA2 } from './package-features-schema.UKA2.json';
import { default as UKR } from './package-features-schema.UKR.json';
import { default as UKR2 } from './package-features-schema.UKR2.json';
import { default as UKR3 } from './package-features-schema.UKR3.json';
import { default as UKOG } from './package-features-schema.UKOG.json';
import { default as UKOG2 } from './package-features-schema.UKOG2.json';
import { default as AZR } from './package-features-schema.AZR.json';
import { default as US_TAXI } from './package-features-schema.US-TAXI.json';

export default SchemaBuilder.create({
  UKC,
  UKC2,
  UKR,
  UKA,
  UKA2,
  UKO,
  NBV,
  UKR2,
  UKR3,
  UKO2,
  UKO3,
  UKB,
  UKB2,
  UKBL: UKB,
  NYR,
  NYRS: NYR,
  UKOG,
  UKOG2,
  AZR,
  'US-R-CA': US_TAXI,
  'US-R-GA': US_TAXI,
  'US-R-CO': US_TAXI,
  'US-R-NY': US_TAXI,
  'US-R-WA': US_TAXI,
  'US-R-TX': US_TAXI,
});
