import React, { PropsWithChildren } from 'react';

import './Agreements.scss';
import String from '../../../components/String/String';

function Agreements({ children }: PropsWithChildren<any>) {
  return (
    <div className="Agreements">
      <div className="Agreements-title">
        <String id="pages.terms.confirmation" />
      </div>
      {children}
    </div>
  );
}

export default Agreements;
