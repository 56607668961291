import React from 'react';
import classnames from 'classnames';

import './Input.scss';

function Input({
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return <input className={classnames('Input', className)} {...props} />;
}

export default Input;
