import React from 'react';
import Button from 'components/Buttons/Button';
import String from '../../String/String';

import { useStrings } from '../../../hooks';
import Modal from '../Modal';

interface Props {
  onCancel(): void;
  onEdit(): void;
}

function ResponseEditModal({ onCancel, onEdit }: Props) {
  const { get } = useStrings();

  return (
    <Modal
      title={get('pages.response_edit.modal.title')}
      className="ResponseEditModal"
      data-testid="response-edit-modal"
      onClose={onCancel}
      actions={
        <>
          <Button
            variant="primary"
            onClick={onEdit}
            testId="edit-response-confirm"
          >
            <String id="pages.response_edit.modal.edit" />
          </Button>
          <Button
            variant="secondary"
            onClick={onCancel}
            testId="edit-response-cancel"
          >
            <String id="pages.response_edit.modal.cancel" />
          </Button>
        </>
      }
      actionsFlow="row"
    >
      <p>
        <String id="pages.response_edit.modal.message" />
      </p>
    </Modal>
  );
}

export default ResponseEditModal;
