import React, { useEffect, useMemo } from 'react';
import Page from '../../../../components/Page/Page';
import { FlexRow } from '../../../../components/Layout/Layout';
import String from '../../../../components/String/String';
import LinkButton from '../../../../components/LinkButton/LinkButton';
import Avatar from '../../../../components/Avatar/Avatar';
import './SuccessPolicyPage.scss';
import configuration from 'configuration';
import { useParams } from 'react-router-dom';
import { useGetPoliciesByQuoteQuery } from 'shared/graphql/api/types';
import { useStrings } from 'hooks';
import { PaymentPlanEnum } from 'enums/PaymentPlan';

export enum QuoteType {
  RENEWAL = 'renewal',
  MTA = 'mta',
}

/***
 * Policy creation Success Page for non-payment MTAs and policies
 * that can be renewed with 0 binding payment. (E.g. usage based UKA)
 *
 * Can be refactored to display different views, depending on the product
 * via custom components.
 *
 * Note - This was repurposed for 0 binding policies also (after MTAs), so some translations
 * are still named 'mta'.
 **/
function SuccessPolicyPage() {
  const {
    id: productId,
    quoteId,
    quoteType = QuoteType.MTA,
  } = useParams<{
    id: string;
    quoteId: string;
    quoteType: string;
  }>();

  const { get } = useStrings();

  // Renewal determined via route
  const isRenewal = quoteType === QuoteType.RENEWAL;

  // Poll to the policies endpoint for policies related to the quoteId
  const { data, stopPolling } = useGetPoliciesByQuoteQuery({
    variables: { id: quoteId },
    pollInterval: configuration.policy.pollInterval,
  });

  const [policy] = data?.policies || [];

  // Create a timeout that invokes when we've been polling for a certain time
  const cancelTimeout = useMemo(() => {
    const timeout = setTimeout(() => {
      stopPolling();
    }, configuration.policy.pollTimeout);

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If we find a policy, stop polling and cancel the timeout
    if (policy) {
      stopPolling();
      cancelTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, stopPolling]);

  function PageBody() {
    const email = policy?.datasheet?.insured?.email || 'your email';
    const isFinanceRenewal =
      isRenewal &&
      policy?.package?.paymentPlan?.paymentPlan === PaymentPlanEnum.FINANCE;

    return (
      <div className="SuccessMta">
        <div className="SuccessMta-body">
          <FlexRow>
            <Avatar direction="normal" expression={'happy'} />
          </FlexRow>
          <h2>
            <String id={`pages.success_${quoteType}.title`} />
          </h2>

          {isFinanceRenewal ? (
            <h4>
              <String
                id="pages.success_renewal_finance.body"
                values={{ email }}
              />
            </h4>
          ) : (
            <h4>
              <String id="pages.success_mta.body" values={{ email }} />
            </h4>
          )}
          {isRenewal && policy?.product?.code === 'UKA' && (
            <h4>
              <String id="pages.success_renewal_wallet.info" />
            </h4>
          )}
          <h4>
            <String id="pages.success_mta.thanks" />
          </h4>
          <LinkButton
            testId="goto_dashboard-linkbutton"
            to={`/product/${productId}`}
            variant="primary"
          >
            <String id="pages.success_mta.goto_dashboard" />
          </LinkButton>
        </div>
      </div>
    );
  }

  return (
    <Page
      name="SuccessMta"
      slug={get(`pages.success_${quoteType}.title`)}
      padded={false}
    >
      <PageBody />
    </Page>
  );
}

export default SuccessPolicyPage;
