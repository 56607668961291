import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import configuration from 'configuration';
import { ErrorCodes, PaymentError } from 'constants/errors';
import { useQuery, useQuote } from 'hooks';
import { useLocale } from 'providers/LocaleProvider';
import EventManager from 'utils/EventManager';

import { useGetPoliciesByQuoteQuery } from 'shared/graphql/api/types';

import Loader from 'components/Loader/Loader';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Page from 'components/Page/Page';
import Avatar from 'components/Avatar';
import String from 'components/String/String';
import LinkButton from 'components/LinkButton/LinkButton';
import Button from 'components/Buttons/Button';
import { RenewalBanner } from '../../RenewalBanner/RenewalBanner';
import { openQuotesRenewalsEnabled } from '../../../../../utils/quoteUtils';
import { usePWA } from 'providers/PWAProvider';

import './PaymentConfirmationPage.scss';
import UsagePolicyConfirmation from './UsagePolicyConfirmation';
import { postRefererGoal } from '../../../../../utils/ambassadorUtils';
import { useAuth } from '../../../../../providers/AuthProvider';

interface PaymentConfirmationPageQueryParams {
  status?: string;
  packageId?: string;
  isOpenQuote?: string;
}

function PaymentConfirmationPage() {
  const { accessToken, user } = useAuth();
  const { quoteId } = useParams<{ quoteId: string }>();
  const { status, packageId, isOpenQuote } =
    useQuery<PaymentConfirmationPageQueryParams>();
  const [error, setError] = useState<Error>();
  const { lang: locale } = useLocale();
  const { quote } = useQuote(quoteId);
  const product = quote?.product;
  const productId = product?.id;
  const { prompt, promptToInstall } = usePWA();
  // Poll to the policies endpoint for policies related to the quoteId
  const {
    data,
    stopPolling,
    error: policyError,
  } = useGetPoliciesByQuoteQuery({
    variables: { id: quoteId },
    pollInterval: configuration.policy.pollInterval,
  });

  const [policy] = data?.policies || [];
  const _package = quote?.packages.find((p) => p.id === packageId);

  const showRenewalBanner =
    openQuotesRenewalsEnabled() && quote?.renewalOf != null;

  // Create a timeout that invokes when we've been polling for a certain time
  const cancelTimeout = useMemo(() => {
    const timeout = setTimeout(() => {
      setError(new Error('Policy poll timeout reached'));
      stopPolling();
    }, configuration.policy.pollTimeout);

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If we find a policy, stop polling and cancel the timeout
    if (policy) {
      stopPolling();
      cancelTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, stopPolling]);

  useEffect(() => {
    if (policy && product && _package && locale) {
      postRefererGoal(user?.email, policy.policyNumber, accessToken);

      EventManager.track({
        event: 'PolicyCreated',
        duration: quote?.datasheet?.policy?.duration,
        firstName: policy.datasheet.insured.firstName,
        lastName: policy.datasheet.insured.lastName,
        email: policy.datasheet.insured.email,
        policyNumber: policy.policyNumber,
        productCode: product.code,
        productId: product.id,
        amount: (_package.valuation?.total ?? 0) / 100,
        currency: product.currency,
        locale: `${product.language}_${product.location.country}`,
        paymentPlan: policy.package.paymentPlan?.paymentPlan,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, product, _package, locale, quote]);

  // A policy is creating if the endpoint returns no values
  const creatingPolicy = !data?.policies.length;

  function PageBody() {
    const paymentError = policyError ?? error;

    // If there is an error, show an error page
    if (paymentError) {
      throw new PaymentError(
        productId,
        ErrorCodes.PAYMENT_CONFIRMATION_ERROR,
        paymentError?.message
      );
    }

    // If the policy is being created, show a loader
    if (creatingPolicy) {
      return (
        <div className="PaymentConfirmationPage-loader">
          <p>
            <String id="pages.quote.policy.creating" />
          </p>
          <Loader />
        </div>
      );
    }

    const email = policy?.datasheet?.insured?.email || 'your email';

    let usageBased = _package?.pricingModel === 'USAGE_BASED';
    let fixed_term = _package?.pricingModel === 'FIXED_TERM';

    return (
      <>
        {_package && fixed_term && (
          <div data-testid="fixed_term">
            <p>
              <String
                id={`pages.payment-confirmation.${status}.message`}
                values={{ email }}
              />
            </p>
          </div>
        )}

        {_package && usageBased && (
          <div data-testid="usage_based">
            <UsagePolicyConfirmation
              currency={product.currency}
              package={_package}
            />
          </div>
        )}
        <div className="PaymentConfirmationPage-button">
          <LinkButton
            to={`/product/${productId}`}
            variant="primary"
            testId={`payment-confirmation.${status}.button`}
            query={
              isOpenQuote &&
              ({
                newPurchase: true,
              } as any)
            }
          >
            <String id={`pages.payment-confirmation.${status}.button`} />
          </LinkButton>
        </div>
        {prompt && (
          <div className="PaymentConfirmationPage-buttonDownload">
            <Button
              variant="secondary"
              onClick={promptToInstall}
              testId="download-app"
            >
              <String id="commons.download_app" />
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Page name="PaymentConfirmationPage" slug="Payment">
      {showRenewalBanner && <RenewalBanner />}
      <div className="PaymentConfirmationPage-progress_bar">
        {isOpenQuote && (
          <ProgressBar
            currentStep={3}
            currentStepText="progress_bar.step.policy_purchased"
            totalStepCount={3}
            isComplete={true}
          />
        )}
      </div>
      <div className="PaymentConfirmationPage-box">
        <Avatar
          direction="normal"
          expression={status === 'succeeded' ? 'happy' : 'sad'}
        />
        <h2>
          <String id={`pages.payment-confirmation.${status}.title`} />
        </h2>
        <div className="PaymentConfirmationPage-body">
          <PageBody />
        </div>
      </div>
    </Page>
  );
}

export default PaymentConfirmationPage;
