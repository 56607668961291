class LocalStorage {
  public static get<T>(key: string, defaultValue?: T): T {
    try {
      const value = window.localStorage.getItem(key);

      if (!value) {
        return defaultValue as T;
      }

      return JSON.parse(value) ?? defaultValue;
    } catch (e) {
      return defaultValue as T;
    }
  }

  public static set(key: string, value: any) {
    return window.localStorage.setItem(key, JSON.stringify(value));
  }

  public static remove(key: string) {
    window.localStorage.removeItem(key);
  }
}

export default LocalStorage;
