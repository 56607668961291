import React, { ChangeEvent, useState } from 'react';

import { usePreQuote } from 'providers/QuoteProvider';
import { getCurrencySymbol, getCurrencyValue } from 'utils/currencyUtils';
import { TextField, OutlinedTextFieldProps } from '@material-ui/core';
import classNames from 'classnames';

import './CurrencyInput.scss';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  initialValue: number;
  setField: (value: number) => void;
}

const CURRENCY_REGEX = /^[0-9,.\b]+$/;

function CurrencyInput({ initialValue, setField, ...props }: Props) {
  const {
    productHistory: { current: product },
  } = usePreQuote();
  const symbol = getCurrencySymbol(product.language, product.currency);

  const [text, setText] = useState(() => {
    // If value is a number, convert it to a decimal value and format
    return !isNaN(initialValue) ? formatValue(String(initialValue / 100)) : 0;
  });

  function formatValue(value: string) {
    if (value === '' || CURRENCY_REGEX.test(value)) {
      const sanitised =
        getCurrencyValue(value, product.language, product.currency) / 100;
      const formatted = sanitised.toLocaleString(product.language);

      return parseInt(formatted) > 0 ? formatted : '';
    }

    return value;
  }

  function handleChange({ target }: ChangeEvent<HTMLInputElement>) {
    const textValue = formatValue(target.value);
    const value = getCurrencyValue(
      textValue,
      product.language,
      product.currency
    );

    if (!isNaN(value)) {
      setField(value);
    }
    setText(textValue);
  }

  return (
    <div className="CurrencyInputForm">
      <h4
        className={classNames('CurrencyInputForm-symbol', {
          'CurrencyInputForm-symbol-error': props.error,
        })}
      >
        {symbol}
      </h4>
      <TextField
        {...props}
        variant="outlined"
        fullWidth
        aria-label="currency-input"
        data-testid="currency-input"
        value={text}
        onChange={handleChange}
      />
    </div>
  );
}

export default CurrencyInput;
