import React, { useEffect, useRef, useState } from 'react';

import configuration from 'configuration';
import { IUser } from 'types/user';

import { useModal } from 'providers/ModalProvider';

import Loader from 'components/Loader/Loader';
import String from 'components/String/String';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import Page from 'components/Page/Page';

import './VerificationPage.scss';

interface Props {
  user: IUser;
  fetchUser(): void;
}

function VerificationPage({ user, fetchUser }: Props) {
  const { display, dismiss } = useModal();
  const [polling, setPolling] = useState(true);
  const interval = useRef<NodeJS.Timeout>();
  const timeout = useRef<NodeJS.Timeout>();

  const verified = user?.emailVerified;

  function showContactModal() {
    display('contact', {
      onCancel: dismiss,
    });
  }

  function startPolling() {
    const { pollInterval, pollTimeout } = configuration.verification;

    interval.current = setInterval(fetchUser, pollInterval);
    timeout.current = setTimeout(stopPolling, pollTimeout);

    setPolling(true);
  }

  function stopPolling() {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setPolling(false);
  }

  useEffect(() => {
    startPolling();

    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  return (
    <Page name="VerificationPage" slug="Verification required">
      <Avatar />
      <h1>
        <String id="pages.verification.title" />
      </h1>
      <div className="VerificationPage-proceed">
        {polling && <Loader />}
        {!polling && (
          <Button
            variant="primary"
            onClick={startPolling}
            testId="verification-retry"
          >
            <String id="pages.verification.retry" />
          </Button>
        )}
      </div>
      <p>
        <String id="pages.verification.line1" values={{ email: user.email }} />
      </p>
      <p>
        <String id="pages.verification.line2" />
      </p>
      <p className="VerificationPage-contactMessage">
        <String id="pages.verification.contact.message" />
        <Button
          variant="secondary"
          onClick={showContactModal}
          testId="verification-contact-us"
        >
          <String id="pages.verification.contact.button" />
        </Button>
      </p>
    </Page>
  );
}

export default VerificationPage;
