import Page from 'components/Page/Page';

import { useQuery } from 'hooks';
import { useLocale } from 'providers/LocaleProvider';
import Loader from 'components/Loader/Loader';
import { ApplicationError, ErrorCodes } from 'constants/errors';
import { useGetProductOptionsByCodeQuery } from 'shared/graphql/api/types';
import configuration from 'configuration';
import Redirect from 'components/Router/Redirect';
import React from 'react';
import QuoteProductPage from './QuoteProductPage';

function QuotePage() {
  const { code } = useQuery<{ code: string }>();
  const { lang: locale } = useLocale();

  const { data, error, loading } = useGetProductOptionsByCodeQuery({
    variables: {
      code,
    },
  });

  if (!code) {
    return <Redirect preserveQuery to="/product" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    throw new ApplicationError(error.message, ErrorCodes.SERVER_ERROR);
  }

  if (!data?.productOptions?.productId) {
    return <Redirect preserveQuery to="/product" />;
  }

  if (
    data?.productOptions?.productId &&
    !configuration.anonymousQuoteProductCodes.includes(code)
  ) {
    return (
      <Redirect
        preserveQuery
        to={`/product/${data.productOptions.productId}/quote`}
      />
    );
  }

  return (
    <QuoteProductPage
      productId={data.productOptions.productId}
      locale={locale}
    />
  );
}

const withPage = (Component: React.ElementType) => () => {
  return (
    <Page name="QuotePage" slug="Quote" padded={false}>
      <Component />
    </Page>
  );
};

export default withPage(QuotePage);
