import React from 'react';

export default function ShieldIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <path
        id="shield-icon"
        data-name="shield-icon"
        d="M-3364.964-3673.351a31.535,31.535,0,0,1,31.5-31.5,31.536,31.536,0,0,1,31.5,31.5,31.535,31.535,0,0,1-31.5,31.5A31.534,31.534,0,0,1-3364.964-3673.351Zm3.259,0a28.273,28.273,0,0,0,28.241,28.243,28.273,28.273,0,0,0,28.241-28.243,28.271,28.271,0,0,0-28.241-28.24A28.271,28.271,0,0,0-3361.706-3673.351Zm27.417,19.928c-5.758-3.606-9.647-7.538-12.145-12.535-2.456-4.954-3.477-10.906-3.477-18.6a1.519,1.519,0,0,1,1.282-1.5,26.543,26.543,0,0,0,7.3-2.433,34,34,0,0,0,6.886-4.671,1.491,1.491,0,0,1,1.977.021,32.546,32.546,0,0,0,6.865,4.65,26.575,26.575,0,0,0,7.343,2.454,1.513,1.513,0,0,1,1.238,1.478c0,7.692-1.021,13.644-3.5,18.6-2.474,5.017-6.386,8.929-12.165,12.556a1.5,1.5,0,0,1-.781.219A1.542,1.542,0,0,1-3334.289-3653.422Zm-5.714-32.414a30.041,30.041,0,0,1-6.91,2.5c.11,6.648,1.044,11.82,3.152,16.056,2.106,4.258,5.431,7.67,10.3,10.841,4.846-3.171,8.169-6.583,10.277-10.841,2.128-4.236,3.063-9.408,3.171-16.056a30.232,30.232,0,0,1-6.931-2.5,34.693,34.693,0,0,1-6.517-4.258A34.875,34.875,0,0,1-3340-3685.836Zm4.974,16.815-3.933-3.932a1.484,1.484,0,0,1,0-2.108,1.483,1.483,0,0,1,2.109,0l2.866,2.869,5.5-5.475a1.5,1.5,0,0,1,2.108-.022,1.5,1.5,0,0,1,0,2.108l-6.538,6.561a1.481,1.481,0,0,1-1.052.432A1.491,1.491,0,0,1-3335.028-3669.021Z"
        transform="translate(3365.464 3705.351)"
        fill="#1fc4d6"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    </svg>
  );
}
