import React from 'react';
import { Helmet } from 'react-helmet';

function Head() {
  return (
    <Helmet>
      <title>Home - INSHUR</title>
    </Helmet>
  );
}

export default Head;
