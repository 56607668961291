import React from 'react';
import { ProgressBarCompleteIcon, BackIcon } from 'components/Icons';
import { useState, useEffect } from 'react';
import String from 'components/String/String';
import classnames from 'classnames';

import './ProgressBar.scss';

interface Props {
  totalStepCount: number;
  currentStepText: string;
  nextStepText?: string;
  currentStep: number;
  isComplete?: boolean;
  withBanner?: boolean;
  handlePrevStep?: () => void;
}

function ProgressBar({
  totalStepCount,
  currentStepText,
  nextStepText,
  currentStep,
  isComplete,
  withBanner,
  handlePrevStep,
}: Props) {
  const [isScrolled, setIsScrolled] = useState(false);

  const colour = isComplete ? 'progress-bar-green' : 'progress-bar-blue';
  const stuckContainerOffsetTop = withBanner ? 64 : 0;

  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById(
        'progress-container'
      ) as HTMLDivElement;

      if (container?.offsetTop > stuckContainerOffsetTop) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    handleScroll();

    window.addEventListener('wheel', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
    //eslint-disable-next-line
  }, []);

  function progressStripes() {
    return new Array(totalStepCount).fill(undefined).map((_item, index) => {
      return (
        <div
          key={index}
          className={`progress-stripe ${index < currentStep ? colour : ''}`}
        ></div>
      );
    });
  }

  return (
    <div
      id="progress-container"
      className={classnames(`container`, withBanner ? 'with-banner' : '')}
    >
      <div className="heading">
        {handlePrevStep && (
          <div onClick={handlePrevStep} className="progress-back">
            <BackIcon />
          </div>
        )}
        {isComplete && (
          <div className="complete-icon">
            <ProgressBarCompleteIcon />
          </div>
        )}
        <div className={`steps${isScrolled ? ' steps-mini' : ''}`}>
          {isComplete ? (
            <String id="progress_bar.complete" />
          ) : (
            <String
              id="progress_bar.steps_of"
              values={{ currentStep, totalStepCount }}
            />
          )}
        </div>
        <div
          className={`current-step${isScrolled ? ' current-step-mini' : ''}`}
        >
          <String id={currentStepText} />
        </div>
      </div>
      <div className="progress">{progressStripes()}</div>
      {!isComplete &&
        !isScrolled &&
        nextStepText &&
        nextStepText.length > 0 && (
          <div className="next">
            <div className="next-title">
              <String id="progress_bar.next" />:
            </div>
            <div className="next-text">
              <String id={nextStepText} />
            </div>
          </div>
        )}
    </div>
  );
}

export default ProgressBar;
