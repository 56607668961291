import { saveAs } from 'file-saver';
import { detect } from 'detect-browser';
import configuration from '../configuration';

export enum FileHandlerMethod {
  DOWNLOAD = 'DOWNLOAD',
  DISPLAY = 'DISPLAY',
}

export function fileHandlerMethod(): FileHandlerMethod {
  if (configuration.fileHandlerMethod) {
    return configuration.fileHandlerMethod;
  }

  const browser = detect();

  if (
    browser &&
    ['safari', 'ios-webview', 'edge-ios', 'fxios', 'ios'].includes(browser.name)
  ) {
    return FileHandlerMethod.DOWNLOAD;
  }

  return FileHandlerMethod.DISPLAY;
}

export function openFile(blob: Blob, name?: string) {
  const method = fileHandlerMethod();

  switch (method) {
    case FileHandlerMethod.DISPLAY: {
      const url = URL.createObjectURL(blob);
      return window.open(url, '_blank');
    }
    case FileHandlerMethod.DOWNLOAD:
    default: {
      return saveAs(blob, name);
    }
  }
}
