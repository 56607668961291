import React from 'react';

interface Props {
  onClick?(): void;
}

function Send({ onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z" />
        <path
          fill="#1FC4D6"
          fillRule="nonzero"
          d="M4.369 5.771l2.678 4.279.072.099c.13.156.31.264.512.305L15.236 12 7.63 13.547c-.242.05-.453.195-.584.404L4.37 18.23l-.08.161-.048.128c-.745 2.245 1.703 4.163 3.79 3.061l14.791-7.816.156-.092c1.293-.873 1.196-2.719-.155-3.433L8.03 2.42l-.186-.09C5.7 1.39 3.385 3.465 4.302 5.643l.067.128z"
        />
      </g>
    </svg>
  );
}

export default Send;
