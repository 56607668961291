import React from 'react';

function LogoHorizontal({ color = '#fff' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446 95">
      <style>
        {
          '.prefix__st0{fill:none;stroke:#1f2b55;stroke-width:4;stroke-miterlimit:10}.prefix__st1{fill:#1f2b55}'
        }
      </style>
      <g>
        <path
          className="prefix__st0"
          style={{ stroke: color }}
          d="M54.13 40.39c-3.14 0-5.69 2.55-5.69 5.69 0 3.14 2.55 5.69 5.69 5.69 3.14 0 5.69-2.55 5.69-5.69 0-3.14-2.55-5.69-5.69-5.69zM26.79 38.72l22.1 7.7M58.68 46.66l23.69-7.94"
        />
        <path
          style={{ fill: color }}
          d="M56.37 72.42c0 .71-.84 1.29-1.88 1.29s-1.88-.58-1.88-1.29V60.15c0-.71.84-1.28 1.88-1.28s1.88.57 1.88 1.28v12.27z"
        />
        <circle
          className="prefix__st0"
          cx={54.41}
          cy={46.5}
          r={38.5}
          style={{ stroke: color }}
        />
        <path
          className="prefix__st0"
          style={{ stroke: color }}
          d="M82.87 45.82c0 15.72-12.74 28.46-28.46 28.46S25.95 61.54 25.95 45.82 38.7 17.37 54.41 17.37c15.72 0 28.46 12.74 28.46 28.45z"
        />
        <text
          transform="matrix(1.0053 0 0 1 107 74.031)"
          style={{ fill: color }}
          letterSpacing={8.95}
          fontSize={75.887}
        >
          INSHUR
        </text>
      </g>
    </svg>
  );
}

export default LogoHorizontal;
