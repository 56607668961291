import RadioButton from 'components/Form/RadioButton/RadioButton';

interface Props {
  label: string;
  value: string;
  id?: string;
  name: string;
  additionalFields?: string[];
  fieldValue: string | number | boolean;
  handleChange: (fieldName: string, id: string | boolean | number) => void;
  type?: 'boolean' | 'string' | 'number';
  onClickButton?: (value: string) => void;
}

const FormRadioButton = (props: Props) => {
  const {
    value,
    label,
    name,
    additionalFields,
    handleChange,
    fieldValue,
    id,
    type = 'string',
    onClickButton,
  } = props;

  function updateFields(value: number | string | boolean) {
    handleChange(name, value);
    if (additionalFields) {
      additionalFields.forEach((field) => {
        handleChange(field, value);
      });
    }
  }

  function onClick(value: string) {
    if (onClickButton) {
      onClickButton(value);
    }
    if (type === 'number') {
      updateFields(parseInt(value));
      return;
    }
    if (value.includes('true')) {
      updateFields(true);
      return;
    }
    if (value.includes('false')) {
      updateFields(false);
      return;
    }
    handleChange(name, value);
  }

  return (
    <RadioButton
      id={id || value}
      name={name}
      value={value}
      label={label}
      selected={value === fieldValue}
      onClick={onClick}
    />
  );
};

export default FormRadioButton;
