import React, { useState } from 'react';

import { Question } from 'shared/types/question';
import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';

import UnstyledButton from 'components/Buttons/UnstyledButton';
import String from 'components/String/String';

import AddressModal, { Address } from './AddressModal/AddressModal';

import './AddressInput.scss';

interface Props {
  question: Question;
  onAnswer: AnswerQuestion<Address>;
}

const countries: { [locale: string]: string } = {
  en: 'GBR',
  nl: 'NL',
  us: 'USA',
};

function AddressInput({ onAnswer }: Props) {
  const [displayModal, setDisplayModal] = useState(false);
  const {
    productHistory: { current: product },
  } = usePreQuote();

  const onClose = (address: Address) => {
    setDisplayModal(false);

    if (!address) {
      return;
    }

    const {
      addressLine1,
      addressLine2,
      addressLine3,
      county,
      postalCode,
      country,
      region,
      city,
      state,
      province,
    } = address;

    const message = [
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      postalCode,
      country,
    ]
      .filter(Boolean)
      .join(',\n');

    onAnswer(message, {
      addressLine1,
      addressLine2,
      addressLine3,
      county,
      postalCode,
      country,
      region,
      city,
      state,
      province,
    });
  };

  return (
    <div className="Chatbot-actions">
      <AddressModal
        active={displayModal}
        onClose={onClose}
        countries={[countries[product.location.country.toLowerCase()]]}
        dataTestId="address-modal"
      />
      <UnstyledButton
        className="Chatbot-action"
        onClick={() => setDisplayModal(true)}
        data-testid="address-modal-button"
      >
        <String id="pages.quote.chatbot.search_address" />
      </UnstyledButton>
    </div>
  );
}

export default AddressInput;
