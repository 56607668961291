import { IUserFragment } from 'shared/graphql/api/types';
import {
  Auth0UberIdentityConnectionEnum,
  Auth0UsernamePasswordIdentityConnectionEnum,
} from '@inshur/apis/user';

export type IUser = IUserFragment;
export type IUserDatasheet = Record<string, any>;

export enum IdentityConnection {
  Username = Auth0UsernamePasswordIdentityConnectionEnum.Username_Password_Authentication,
  Uber = Auth0UberIdentityConnectionEnum.uber,
}
