import * as Sentry from '@sentry/react';

export function reportError<
  Error extends {
    productId?: string;
    userId?: string;
    message?: string;
  }
>(error: Error) {
  Sentry.withScope((scope) => {
    scope.setTag('productId', error.productId);
    scope.setTag('userId', error.userId);

    if (error.message) {
      scope.setExtra('Cause message', error.message);
    }

    Sentry.captureException(error);
  });
}
