import React, { useCallback } from 'react';
import { usePreQuote } from 'providers/QuoteProvider';
import { createKey } from 'utils/quoteUtils';
import Redirect from 'components/Router/Redirect';
import String from 'components/String/String';
import { useCreateQuote } from 'hooks/useCreateQuote';
import Loader from 'components/Loader/Loader';

function MtaPaymentInput() {
  const {
    $,
    productHistory: { initial: product },
    mtaOf,
    isDatasetChanged,
  } = usePreQuote();
  const { create, loading, quote, error } = useCreateQuote();

  if (error) {
    throw error;
  }

  if (!mtaOf) {
    throw new Error('Property `mtaOf` is not provided');
  }

  if (!product) {
    throw new Error('Property `product` is not provided');
  }

  const productId = product.id;
  const productVersion = product.version;
  const mtaOfId = mtaOf.id;
  const mtaOfVersion = mtaOf.version;
  const datasheet = $.datasheet;

  const createQuote = useCallback(
    () =>
      create({
        product: {
          id: productId,
          version: productVersion,
        },
        datasheet,
        mtaOf: {
          id: mtaOfId,
          version: mtaOfVersion,
        },
      }),
    [productId, productVersion, mtaOfId, mtaOfVersion, datasheet, create]
  );

  if (!isDatasetChanged) {
    return <Redirect to={`/product/${productId}/mta/declined`} />;
  }

  if (quote && quote.status === 'OK') {
    const quoteId = quote.id;

    return (
      <Redirect
        to={`/product/${productId}/quote/${quoteId}/payment`}
        query={{
          packageId: $.package?.id,
          mtaOf: createKey(mtaOfId, mtaOfVersion),
        }}
      />
    );
  } else if (quote && quote.status === 'DECLINED') {
    return (
      <Redirect
        to={`/product/${productId}/mta/declined`}
        query={{
          packageId: $.package?.id,
          mtaOf: createKey(mtaOfId, mtaOfVersion),
        }}
      />
    );
  }

  if (loading) {
    return (
      <div className="Chatbot-actions">
        <Loader />
      </div>
    );
  }

  return (
    <div className="Chatbot-actions">
      <button
        className="Chatbot-action"
        onClick={createQuote}
        data-testid="payment-input-button"
      >
        <String id="pages.quote.chatbot.review_order" />
      </button>
    </div>
  );
}

export default MtaPaymentInput;
