import { PropsWithChildren } from 'react';
import Button from 'components/Buttons/Button';
import String from '../../String/String';
import Modal from '../Modal';
import './DocumentsModal.scss';
import { IPolicyDetailFragment } from 'shared/graphql/api/types';
import PolicyDocuments from '../../Documents/PolicyDocuments';
import { useStrings } from '../../../hooks';

interface Props {
  policy: IPolicyDetailFragment;
  onCancel(): void;
}

function DocumentsModal({ onCancel, policy }: PropsWithChildren<Props>) {
  const { get } = useStrings();
  const cancelHandler = () => {
    onCancel();
  };

  return (
    <Modal
      title={get('modal.documents.title')}
      className="DocumentsModal"
      dataTestId="document-download-modal"
      onClose={onCancel}
      actions={
        <>
          <Button
            variant="secondary"
            onClick={cancelHandler}
            testId="document_download_cancel"
          >
            <String id="commons.cancel" />
          </Button>
        </>
      }
      actionsFlow="row"
    >
      <PolicyDocuments policy={policy} />
    </Modal>
  );
}

export default DocumentsModal;
