import { ApplicationError, ErrorCodes } from '../constants/errors';

export function assert(
  value: unknown,
  error: string = 'Value assertion failed'
): asserts value {
  if (!value) {
    throw new ApplicationError(
      'Application Error',
      ErrorCodes.ASSERTION_ERROR,
      error ?? 'Assertion for value failed'
    );
  }
}
