import { useMemo, useState } from 'react';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import { useQuery, useStrings } from 'hooks';
import Link from '../../../../../components/Router/Link';
import String from 'components/String/String';
import {
  IPolicyDetailFragment,
  IQuoteDetailsFragment,
  IInvoice,
} from 'shared/graphql/api/types';
import { ProductPageTab } from '../../ProductPage';
import './DashboardNotifications.scss';
import { PolicyStatus } from '@inshur/apis/policy';
import { daysBetween } from 'shared/utils/dateUtils';
import configuration from 'configuration';
import { canSelfServeCancellation } from 'utils/policyUtils';
import UnpaidInvoiceNotification from './UnpaidInvoiceNotification';
interface ProductPageQuery {
  newPurchase: boolean;
  cardUpdateStatus: InfoBoxType;
  tab: string;
}

interface DashboardNotificationsProps {
  policies: IPolicyDetailFragment[] | undefined;
  activeQuotes: IQuoteDetailsFragment[] | undefined;
  setActiveTab: any;
  unpaidInvoices: IInvoice[] | undefined;
}

const DashboardNotifications = ({
  policies,
  activeQuotes,
  setActiveTab,
  unpaidInvoices,
}: DashboardNotificationsProps) => {
  const { get } = useStrings();

  const { newPurchase, cardUpdateStatus } = useQuery<ProductPageQuery>();

  const { policyCancelledDashNotificationDurationDays } = configuration;

  const [newPurchaseInfoBoxShown, setNewPurchaseInfoBoxShown] = useState(false);
  const [cardUpdatedInfoBoxShown, setCardUpdatedInfoBoxShown] = useState(false);
  const [
    policyRecentlyCancelledInfoBoxShown,
    setPolicyRecentlyCancelledInfoBoxShown,
  ] = useState(false);

  const cardUpdateAttempt =
    cardUpdateStatus === InfoBoxType.Success ||
    cardUpdateStatus === InfoBoxType.Error;

  const activePendingPolicies = policies?.filter(
    (policy) => policy.status === 'ACTIVE' || policy.status === 'PENDING'
  );

  const activeQuoteInfoBoxShown =
    activePendingPolicies?.length === 0 &&
    activeQuotes &&
    activeQuotes?.length > 0;

  const recentlyCancelledPolicy = useMemo(() => {
    const cancelledPolicies = policies?.filter(
      (policy: IPolicyDetailFragment) => {
        return (
          policy.status === PolicyStatus.CANCELLED &&
          canSelfServeCancellation(policy)
        );
      }
    );
    cancelledPolicies?.sort((a, b) => {
      return new Date(b.activeTo).getTime() - new Date(a.activeTo).getTime();
    });

    const mostRecentCanxPolicy = cancelledPolicies?.[0];
    return mostRecentCanxPolicy &&
      // activeTo === date of cancellation
      daysBetween(mostRecentCanxPolicy.activeTo, new Date()) <=
        policyCancelledDashNotificationDurationDays
      ? mostRecentCanxPolicy
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);

  return (
    <div className="DashboardNotificationsContainer">
      {newPurchase && !newPurchaseInfoBoxShown && (
        <InfoBox
          title={get('pages.renewal.infobox.title')}
          text={''}
          type={InfoBoxType.Success}
          testId="policy-renewal-infobox"
          onClose={() => setNewPurchaseInfoBoxShown(true)}
        />
      )}
      {cardUpdateAttempt && !cardUpdatedInfoBoxShown && (
        <InfoBox
          title={get(
            `pages.product.update_card_${cardUpdateStatus}.infobox.title`
          )}
          text={get(
            `pages.product.update_card_${cardUpdateStatus}.infobox.message`
          )}
          type={cardUpdateStatus}
          testId="update-card-status-infobox"
          onClose={() => setCardUpdatedInfoBoxShown(true)}
        />
      )}
      {recentlyCancelledPolicy &&
        configuration.selfServeCancellationsEnabled &&
        !policyRecentlyCancelledInfoBoxShown && (
          <InfoBox
            title={get(`pages.product.policy_cancelled.infobox.title`)}
            text={get(`pages.product.policy_cancelled.infobox.message`, {
              policyNumber: recentlyCancelledPolicy.policyNumber,
            })}
            type={InfoBoxType.Success}
            testId={`policy-cancelled-infobox`}
            onClose={() => setPolicyRecentlyCancelledInfoBoxShown(true)}
          />
        )}

      {activeQuoteInfoBoxShown && (
        <InfoBox
          title={get('pages.product.open_quotes.infobox.title')}
          text={get('pages.product.open_quotes.infobox.message', {
            quotesNumber: activeQuotes?.length,
            quotesLabel:
              activeQuotes?.length === 1
                ? get('pages.product.open_quotes.infobox.label_singular')
                : get('pages.product.open_quotes.infobox.label_plural'),
            link: (
              <Link
                to="#"
                className="ProductPage-infobox-message-link"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(ProductPageTab.QUOTES);
                }}
              >
                <String id="pages.product.open_quotes.infobox.link" />
              </Link>
            ),
          })}
          type={InfoBoxType.Info}
          testId="policy-open_quotes-infobox"
        />
      )}

      {unpaidInvoices?.map((invoice) => (
        <UnpaidInvoiceNotification
          invoice={invoice}
          key={invoice.invoiceNumber}
        />
      ))}
    </div>
  );
};

export default DashboardNotifications;
