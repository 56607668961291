import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { getRedirectPath } from 'utils/pageUtils';

import Page from 'components/Page/Page';
import Loader from 'components/Loader/Loader';
import { Centered } from 'components/Layout/Layout';
import { useLocale } from 'providers/LocaleProvider';
import { useQuery } from 'hooks';
import configuration from '../../../configuration';
import { getProductId } from '../../../utils/urlUtils';
import EventManager, { EventType } from '../../../utils/EventManager';

interface LoginParams {
  connections?: string[];
  productId?: string;
  code?: string;
  country?: string;
}

function LoginPage({ location }: RouteComponentProps) {
  const { lang: locale } = useLocale();

  const { login } = useAuth();
  const { connections, productId, code, country } = useQuery<LoginParams>();

  const redirect = getRedirectPath(location.search);
  const productIdRef = useRef(productId ?? getProductId(redirect) ?? '');
  EventManager.track({
    event: EventType.LoginPresented,
    label: EventType.LoginPresented,
    productId: productIdRef.current,
  });
  useEffect(() => {
    const connectionsArray =
      connections instanceof Array ? connections : [connections ?? ''];
    const validConnections = connectionsArray.filter(Boolean);
    const defaultConnections = configuration?.auth0.connection?.split(',');
    login(
      locale,
      productIdRef.current,
      code,
      redirect,
      validConnections.length ? validConnections : defaultConnections,
      country
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page name="LoginPage" slug="Login">
      <Centered>
        <Loader />
      </Centered>
    </Page>
  );
}

export default LoginPage;
