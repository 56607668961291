import * as yup from 'yup';

const MID_MAX_LENGTH = 39;
//todo translations
export const AddressSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .required()
    .max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  addressLine2: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  addressLine3: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  city: yup.string().required().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  county: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  postalCode: yup
    .string()
    .required()
    .max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  region: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  country: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  province: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
  state: yup.string().max(MID_MAX_LENGTH, `Max ${MID_MAX_LENGTH}`),
});
