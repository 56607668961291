import {
  IInvoice,
  useGetUserInvoicesLazyQuery,
} from 'shared/graphql/api/types';

export const useGetUserInvoices = (userId: string) => {
  const [getInvoices, { error, loading, data }] = useGetUserInvoicesLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      userId,
    },
  });

  const unpaidInvoices = data?.getUserInvoices.filter(
    (invoice: IInvoice) =>
      invoice.status !== 'paid' && invoice.status !== 'void'
  );
  return {
    getInvoices,
    error,
    loading,
    unpaidInvoices,
  };
};
