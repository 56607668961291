/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components/macro';
import { useView } from 'utils/themeUtils';
import { View } from 'themes';

export const StyledMessageBubble = styled.div<View>`
  font-weight: normal;
  font-size: ${15 / 16}rem;
  line-height: 1.33;
  letter-spacing: normal;

  ul {
    padding: 0 1rem;
    margin: 0;

    li {
      line-height: 1.2rem;
    }
  }

  strong {
    font-weight: 500;
  }
`;

export const ReceivedMessageBubble = styled(StyledMessageBubble)`
  color: var(--color-font-primary);
  background-color: var(--color-background-chatbot-bubble);
  ${useView((t) => t.chatbot.bubbles.received)};
`;

export const SentMessageBubble = styled(StyledMessageBubble)`
  color: var(--color-font-light);
  background-color: var(--color-background-secondary);
  font-weight: 500;
  ${useView((t) => t.chatbot.bubbles.sent)};
`;
