import React from 'react';

export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="Chatbot-dropdown-search-icon"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z" />
        <path
          stroke="#C3C7CF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.193 5.582c2.332 2.332 2.332 6.112 0 8.444-2.332 2.332-6.112 2.332-8.444 0-2.332-2.332-2.332-6.112 0-8.444 2.332-2.332 6.112-2.332 8.444 0M14.15 14.06L20 19.99"
        />
      </g>
    </svg>
  );
}
