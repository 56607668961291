import React from 'react';

export default function CheckmarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.088"
      height="20.015"
      viewBox="0 0 21.088 20.015"
    >
      <path
        fill="#ffffff"
        d="M691.325,381.773a10.22,10.22,0,1,1-2.778-4.267l-1.449,1.4a8.211,8.211,0,1,0,2.541,4.374l1.651-1.605c.011.032.025.062.035.094Zm1.572-3.75-1.337-1.347-9.721,9.418-3.4-3.395-1.337,1.337,4.741,4.742Z"
        transform="translate(-671.809 -374.895)"
      />
    </svg>
  );
}
