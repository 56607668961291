import React, { useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import Switch from 'components/Router/Switch';

import ProductPage from './ProductPage/ProductPage';
import QuoteRoute from './quote/QuoteRoute';
import MtaRoute from './quote/MtaPage/MtaRoute';
import configuration from 'configuration';
import ProductsPage from './ProductsPage/ProductsPage';

function ProductRoute({ match, history, location }: RouteComponentProps) {
  useEffect(() => {
    // Remove all temp query params
    const queryParams = new URLSearchParams(location.search);
    configuration.transientQueryParams.forEach((tempQueryParam) =>
      queryParams.delete(tempQueryParam)
    );
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={match.path} exact component={ProductsPage} />
      <Route path={`${match.path}/:id`} exact component={ProductPage} />
      <Route path={`${match.path}/:id/quote`} component={QuoteRoute} />
      <Route path={`${match.path}/:id/mta`} component={MtaRoute} />
    </Switch>
  );
}

export default ProductRoute;
