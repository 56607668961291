import {
  ICreateQuoteMutationVariables,
  useCreateQuoteMutation,
} from 'shared/graphql/api/types';

export const useCreateQuote = () => {
  const [createQuote, { loading, data, error }] = useCreateQuoteMutation();

  const create = (input: ICreateQuoteMutationVariables['input']) => {
    return createQuote({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  };

  return {
    create,
    loading,
    quote: data?.quote,
    error,
  };
};
