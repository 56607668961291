import React from 'react';

interface Props {
  text: JSX.Element;
}

export default function NewIcon({ text }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54.122"
      height="54.122"
      viewBox="0 0 54.122 54.122"
    >
      <g id="New" transform="translate(0 0)">
        <path
          id="Path_1838"
          data-name="Path 1838"
          d="M27.062,0,22.379,9.587,13.531,3.625l.739,10.645L3.626,13.53l5.961,8.85L0,27.061l9.587,4.683L3.626,40.591l10.643-.739L13.531,50.5l8.848-5.961,4.683,9.587,4.681-9.587L40.591,50.5l-.739-10.643,10.645.739-5.962-8.848,9.587-4.683-9.587-4.681L50.5,13.53l-10.645.74.739-10.645L31.743,9.587Z"
          transform="translate(0 0)"
          fill="#1fc4d6"
        />
        <text
          id="NEW-2"
          data-name="NEW"
          transform="translate(11.74 31.113)"
          fill="#fff"
          fontSize="10"
          fontFamily="Helvetica-BoldOblique, Helvetica"
          fontWeight="700"
          fontStyle="oblique"
        >
          <tspan x="0" y="0">
            {text}
          </tspan>
        </text>
      </g>
    </svg>
  );
}
