import { useMemo } from 'react';
import { useLocale } from 'providers/LocaleProvider';
import { formatCurrency } from 'utils/currencyUtils';

interface Props {
  value?: number;
  currency: string;
  digits?: number;
}

function Currency({ value = 0, currency, digits = 2 }: Props) {
  const { lang: locale } = useLocale();

  const formatted = useMemo(
    () => formatCurrency(value, locale, currency, digits),
    [value, currency, locale, digits]
  );

  return <>{formatted}</>;
}

export default Currency;
