import { useEffect, useReducer, useState } from 'react';
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from './steps';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import {
  FORM_INITIAL_VALUES,
  UBER_DEFAULTS,
} from './constants/FormInitialValues';
import { usePreQuote } from 'providers/QuoteProvider';
import QuoteFormHeader, { HeaderInfo } from './components/QuoteFormHeader';
import { StartIcon, ShieldIcon, CarIcon, ProfileIcon } from 'components/Icons';
import Loader from 'components/Loader/Loader';
import { IUserFragment } from 'shared/graphql/api/types';

import './QuoteForm.scss';

export enum formActionChoices {
  NEXT = 'next',
  PREV = 'prev',
  NOOP = 'noop',
}

export type FormAction = {
  data: any;
  action: formActionChoices;
};

type FormState = {
  data: Partial<typeof FORM_INITIAL_VALUES>;
  currentPage: number;
};

function reducer(state: FormState, action: FormAction) {
  let newState: any = {};
  switch (action.action) {
    case formActionChoices.NEXT: {
      newState.data = { ...state.data, ...action.data };
      newState.currentPage = state.currentPage + 1;
      break;
    }
    case formActionChoices.PREV: {
      newState.data = { ...state.data, ...action.data };
      newState.currentPage = state.currentPage - 1;
      break;
    }
    case formActionChoices.NOOP: {
      newState.data = { ...state.data, ...action.data };
      newState.currentPage = state.currentPage;
      break;
    }
    default: {
      console.log('Invalid action');
    }
  }
  return newState;
}

interface StepInfo extends HeaderInfo {
  stepTitle: string;
}

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  uber: IUserFragment['uber'];
}

function QuoteFormSteps() {
  const [state, dispatch] = useReducer(reducer, {
    currentPage: 0,
    data: FORM_INITIAL_VALUES,
  });
  const [initialize, setInitialize] = useState<boolean>(false);
  const { updateDataSheet, $ } = usePreQuote();

  const handlePrevStep = () => {
    dispatch({ action: formActionChoices.PREV, data: state.data });
  };

  const updateUser = (user: UserInfo) => {
    dispatch({
      action: formActionChoices.NOOP,
      data: {
        drivers: [
          {
            ...state.data.drivers[0],
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            telephone: user.telephone,
            uber: {
              ...state.data.drivers[0].uber,
              rewardsTier: user.uber?.tier || UBER_DEFAULTS.rewardsTier,
              rating: user.uber?.rating || UBER_DEFAULTS.rating,
              totalTrips: user.uber?.trips || UBER_DEFAULTS.totalTrips,
              averageMonthlyTrips: UBER_DEFAULTS.averageMonthlyTrips,
              email: user.email,
              documentUploadConsent: true,
            },
          },
        ],
        insured: {
          ...state.data.insured,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          telephone: user.telephone,
        },
      },
    });
  };

  useEffect(() => {
    updateDataSheet(state.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if ($.user.firstName && $.user.lastName) {
      updateUser({
        firstName: $.user.firstName,
        lastName: $.user.lastName,
        email: $.user.email,
        telephone: $.user.telephone,
        uber: $.user.uber,
      });
      setInitialize(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$.user]);

  const pageContentElement = document.querySelector('.Page-content');

  useEffect(() => {
    pageContentElement?.scrollTo(0, 0);
  }, [pageContentElement, state.currentPage]);

  const steps = [
    <StepOne data={state.data} dispatch={dispatch} />,
    <StepTwo data={state.data} dispatch={dispatch} />,
    <StepThree data={state.data} dispatch={dispatch} />,
    <StepFour data={state.data} dispatch={dispatch} />,
    <StepFive datasheet={state.data} />,
  ];

  const stepHeaders: StepInfo[] = [
    {
      title: 'form.steps.1.header.title',
      description: 'form.steps.1.header.description',
      icon: <StartIcon />,
      stepTitle: 'form.steps.1.progress.title',
    },
    {
      title: 'form.steps.3.header.title',
      icon: <ProfileIcon />,
      stepTitle: 'form.steps.3.progress.title',
    },
    {
      title: 'form.steps.2.header.title',
      icon: <CarIcon />,
      stepTitle: 'form.steps.2.progress.title',
    },
    {
      title: 'form.steps.4.header.title',
      icon: <ShieldIcon />,
      stepTitle: 'form.steps.4.progress.title',
    },
    {
      title: 'form.steps.5.header.title',
      stepTitle: 'form.steps.5.progress.title',
    },
  ];

  if (!initialize) {
    return <Loader />;
  }

  return (
    <div className="QuoteForm" id="QuoteForm">
      {state.currentPage < stepHeaders.length - 1 && (
        <>
          <ProgressBar
            handlePrevStep={state.currentPage > 0 ? handlePrevStep : undefined}
            currentStepText={stepHeaders[state.currentPage].stepTitle}
            currentStep={state.currentPage + 1}
            totalStepCount={stepHeaders.length - 1}
            nextStepText={stepHeaders[state.currentPage + 1].stepTitle}
          />

          <QuoteFormHeader
            title={stepHeaders[state.currentPage]?.title}
            description={stepHeaders[state.currentPage]?.description}
            icon={stepHeaders[state.currentPage]?.icon}
          />
        </>
      )}

      <div className="QuoteForm-step">{steps[state.currentPage]}</div>
    </div>
  );
}

export default QuoteFormSteps;
