import React from 'react';
import { gql, useQuery, ApolloError } from '@apollo/client';
import WalletBalanceColumn from './WalletBalanceColumn';

const CUSTOMER_WALLET = gql`
  query CustomerWallet($productId: String!) {
    wallet: walletByProductId(productId: $productId) {
      balance {
        formatted {
          amount
          when
        }
      }
    }
  }
`;

type WalletQuery = {
  wallet: {
    balance: {
      formatted: {
        amount: string;
        when: string;
      };
    };
  } | null;
};

const WalletBalanceField: React.FC<{
  isCustomerApiEnabled: boolean;
  productId: string;
  renderQueryError: (input: { error: ApolloError }) => React.ReactElement;
  renderWalletDoesntExist: () => React.ReactElement;
}> = (props) => {
  const { loading, error, data } = useQuery<WalletQuery>(CUSTOMER_WALLET, {
    variables: { productId: props.productId },
    context: { clientName: 'customer-api' },
  });
  const isNotReady = () => !loading && !data && !error;

  if (isNotReady()) {
    return null;
  }

  if (loading) {
    return null;
  }

  if (error) {
    return props.renderQueryError({
      error,
    });
  }

  if (data && data.wallet) {
    return (
      <WalletBalanceColumn>
        {data.wallet.balance.formatted.amount}{' '}
        <span className="ProductPage-info">
          ({data.wallet.balance.formatted.when})
        </span>
      </WalletBalanceColumn>
    );
  }

  return props.renderWalletDoesntExist();
};

export default WalletBalanceField;
