import React, { PropsWithChildren, useState } from 'react';
import { noop } from 'lodash';

interface ICurrentModal {
  type: IModal;
  props: any;
}

interface IModalContext {
  current?: ICurrentModal;
  display(type: IModal, props?: any): void;
  dismiss(): void;
}

export type IModal =
  | 'contact'
  | 'restart_confirmation'
  | 'info'
  | 'claims'
  | 'logout_confirmation'
  | 'mta_self_serve'
  | 'mta_terms'
  | 'vehicle_search_modal'
  | 'loading'
  | 'leave_page_confirmation'
  | 'existing_mta'
  | 'edit_response_confirmation'
  | 'documents'
  | 'edit_response_confirmation'
  | 'payment_schedule'
  | 'my_renewal'
  | 'cancel_policy';

export const ModalContext = React.createContext<IModalContext>({
  current: undefined,
  display: noop,
  dismiss: noop,
});

export const useModal = () => React.useContext(ModalContext);

function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [current, setCurrent] = useState<ICurrentModal>();

  function display(type: IModal, props?: any) {
    setCurrent({
      type,
      props,
    });
  }

  function dismiss() {
    setCurrent(undefined);
  }

  return (
    <ModalContext.Provider value={{ current, display, dismiss }}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
