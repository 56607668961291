import React from 'react';
import { useParams } from 'react-router-dom';

import { Full } from 'components/Layout/Layout';
import { useStrings, useQuote, useHistory } from 'hooks';
import { useModal } from 'providers/ModalProvider';
import Button from 'components/Buttons/Button';
import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import Loader from 'components/Loader/Loader';
import Page from 'components/Page/Page';
import String from 'components/String/String';

function PaymentErrorPage() {
  const { display, dismiss } = useModal();
  const { id: productId, quoteId } = useParams<{
    id: string;
    quoteId: string;
  }>();
  const { quote, loading } = useQuote(quoteId, true);
  const { get } = useStrings();
  const { push } = useHistory();

  function onShowContact() {
    display('contact', { onCancel: dismiss });
  }

  // @TODO => Event on loaded page

  if (loading) {
    return <Loader />;
  }

  return (
    <Page name="PaymentErrorPage" slug="Quote Error" padded={false}>
      <Full className="PaymentOverview">
        <div
          className="PaymentOverview-wrapper"
          data-testid="payment-overview"
          data-test-data={quote?.quoteNumber}
        >
          <h3 className="PaymentOverview-title">
            <String id="pages.quote.payment.error.title" />
          </h3>

          <div className="PaymentOverview-infobox-wrapper">
            <InfoBox
              title={get('pages.quote.payment.error.finalise_quote.title')}
              text={get('pages.quote.payment.error.finalise_quote.body')}
              type={InfoBoxType.Error}
              allowClose={false}
              testId="quote-error-infobox"
            />
          </div>

          {quote?.quoteNumber && (
            <div className="Overview">
              <div className="Overview-detail">
                <div className="Overview-detail-label">
                  <String id="pages.quote.summary.reference" />
                </div>

                <div className="Overview-detail-value">{quote.quoteNumber}</div>
              </div>
            </div>
          )}

          <div className="PaymentOverview-actions-help">
            <String id="pages.quote.payment.error.actions_help" />
          </div>

          <div className="PaymentOverview-actions-buttons">
            <Button
              tabIndex={2}
              variant="primary"
              onClick={() => onShowContact()}
              loaderColor="white"
              testId="show-support-contact"
            >
              <String id="pages.quote.payment.error.contact_us" />
            </Button>
            <Button
              tabIndex={2}
              variant="secondary"
              onClick={() => push(`/product/${productId}`)}
              testId="goto_dashboard-linkbutton"
              className="PaymentOverview-actions-button"
            >
              <String id="commons.back_to_dashboard" />
            </Button>
          </div>
        </div>
      </Full>
    </Page>
  );
}

export default PaymentErrorPage;
