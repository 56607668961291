import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { IEvent } from '../types/event';

const debug = require('debug')('customer-web:events');

class EventManager {
  initialize(gtmOptions: TagManagerArgs) {
    debug('initialize()', gtmOptions);
    TagManager.initialize(gtmOptions);
  }

  track(event: IEvent) {
    debug('track()', event.event, event);

    try {
      TagManager.dataLayer({ dataLayer: event });
    } catch (e) {
      debug('track(): error', e.message);
    }
  }
  set(dataLayer: Object) {
    try {
      TagManager.dataLayer({ dataLayer: dataLayer });
    } catch (e) {
      debug('set(): error', e.message);
    }
  }
}

export enum EventType {
  QuestionAnswered = 'QuestionAnswered',
  ResponseEdited = 'ResponseEdited',
  ResponseEditCanceled = 'ResponseEditCanceled',
  OpenQuoteConfirmed = 'OpenQuoteConfirmed',
  OpenQuoteChangeDetail = 'OpenQuoteChangeDetail',
  QuoteStarted = 'QuoteStarted',
  PolicyDocuments = 'PolicyDocuments',
  PreExistingQuote = 'PreExistingQuote',
  TermsShown = 'TermsShown',
  TermsAgreed = 'TermsAgreed',
  LoginPresented = 'LoginPresented',
  LoginSuccessful = 'LoginSuccessful',
  LoginFailed = 'LoginFailed',
  ApplicationStarted = 'ApplicationStarted',
}

const eventManagerInstance = new EventManager();

export default eventManagerInstance;
