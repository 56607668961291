import {
  IGetDashboardPoliciesQueryVariables,
  IGetPoliciesQueryVariables,
  useGetAllPoliciesQuery,
  useGetDashboardPoliciesQuery,
  useGetPoliciesQuery,
} from 'shared/graphql/api/types';

export const useGetPolicies = ({
  productId,
  statuses,
}: IGetPoliciesQueryVariables = {}) => {
  const { error, loading, data } = useGetPoliciesQuery({
    fetchPolicy: 'network-only',
    variables: {
      productId,
      statuses,
    },
  });

  return {
    error,
    loading,
    policies: data?.policies,
  };
};

export const useGetDashboardPolicies = ({
  productId,
  statuses,
}: IGetDashboardPoliciesQueryVariables = {}) => {
  const { error, loading, data, refetch } = useGetDashboardPoliciesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      productId,
      statuses,
    },
  });

  return {
    error,
    loading,
    policies: data?.policies,
    refetch,
  };
};

export const useGetAllPolicies = (productId?: string) => {
  const { error, loading, data } = useGetAllPoliciesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      productId,
    },
  });

  return {
    error,
    loading,
    allPolicies: data?.policiesAllVersions,
  };
};
