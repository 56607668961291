import React, { useMemo, useState } from 'react';
import Modal from 'components/Modal/Modal';
import Button from 'components/Buttons/Button';
import String from 'components/String/String';
import { EligibilityQuestion, Statement } from '@inshur/apis/steps';
import EligibilitySection from '../EligibilitySection/EligibilitySection';
import EventManager, { EventType } from '../../../../../utils/EventManager';
import { usePreQuote } from '../../../../../providers/QuoteProvider';

interface Props {
  active: boolean;
  onClose(): void;
  onAnswer(message: string, value: any): void;
  question: EligibilityQuestion;
  dataTestId?: string;
}

function EligibilityModal({
  active,
  onClose,
  onAnswer,
  question,
  dataTestId,
}: Props) {
  const {
    productHistory: { current: product },
  } = usePreQuote();

  const [shouldShowInputs, setShouldShowInputs] = useState(false);
  const [hasAnswered, setHasAnswered] = useState(false);

  const { statements, choices } = question;

  const yesChoice = choices.find((c) => c.value === true);
  const noChoice = choices.find((c) => c.value === false);

  const [title, setTitle] = useState(question.confirmationTitle);

  const agreedStatements: Map<string, boolean> = useMemo(() => {
    const result = new Map<string, boolean>();
    (Object.keys(statements) as Array<keyof typeof statements>).forEach(
      (key) => {
        const { values } = statements[key];
        if (values && values.length > 0) {
          values.forEach((v) => result.set(v.text, false));
        }
      }
    );
    return result;
  }, [statements]);

  const onAgree = (statement: Statement) => {
    const value = agreedStatements.get(statement.text);
    agreedStatements.set(statement.text, !value);
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: statement.gtm,
      productId: product.id,
      productCode: product.code,
    });
  };

  function onContinue() {
    //is eligible if all statements are true
    const isEligible =
      Array.from(agreedStatements.values()).find((value: boolean) => !value) ===
      undefined;
    if (isEligible) {
      setShouldShowInputs(false);
      setTitle(question.confirmationTitle);
    } else {
      onAnswer(noChoice?.text || '', false);
    }
  }

  function onNo() {
    if (!hasAnswered) {
      setTitle(question.statementTitle);
      setShouldShowInputs(true);
      setHasAnswered(true);
    } else {
      onAnswer(noChoice?.text ?? '', false);
    }
  }

  if (!active) {
    return null;
  }

  return (
    <Modal
      title={title}
      className="EligibilityModal"
      onClose={() => onClose()}
      dataTestId={dataTestId}
    >
      <div className="EligibilityModal-sections">
        {(Object.keys(statements) as Array<keyof typeof statements>).map(
          (key) => {
            const { title: statementTitle, values } = statements[key];
            if (values && values.length > 0) {
              return (
                <EligibilitySection
                  key={key}
                  title={statementTitle}
                  statements={values}
                  onAgree={onAgree}
                  showInputs={shouldShowInputs}
                />
              );
            }
            return null;
          }
        )}
      </div>
      <br />
      <div className="Modal-actions">
        {shouldShowInputs ? (
          <Button
            variant="regular"
            testId="eligibility-continue-button"
            onClick={onContinue}
          >
            <String id="pages.quote.options.continue" />
          </Button>
        ) : (
          <>
            <Button
              variant="regular"
              testId="eligibility-yes-button"
              onClick={() => onAnswer(yesChoice?.text ?? '', true)}
            >
              {yesChoice?.text}
            </Button>
            <Button
              variant="regular"
              testId="eligibility-no-button"
              onClick={() => onNo()}
            >
              {noChoice?.text}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default EligibilityModal;
