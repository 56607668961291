import React from 'react';
import { Tab } from './Tab';

import './Tabs.scss';

interface Props {
  tabs: string[];
  activeTab: string;
  setActive(tab: any): void;
}

export function Tabs({ tabs, activeTab, setActive }: Props) {
  return (
    <>
      <div className="Tabs">
        {tabs.map((tab) => (
          <Tab
            key={tab}
            titleId={tab}
            active={activeTab === tab}
            onClick={() => setActive(tab)}
          />
        ))}
      </div>
    </>
  );
}
