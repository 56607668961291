import React from 'react';

export default function AnnualIcon() {
  return (
    <svg
      data-testid="annual-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="53"
      viewBox="0 0 52.603 53.526"
    >
      <path
        fill="#1fc4d6"
        d="M474.593,307.582a24,24,0,1,1,23.533-23.993,23.794,23.794,0,0,1-23.533,23.993m0-50.756a26.767,26.767,0,1,0,26.3,26.763,26.566,26.566,0,0,0-26.3-26.763m12.541,13.888H482.55v-3.578a1.225,1.225,0,0,0-2.45,0v3.578H469.087v-3.578a1.226,1.226,0,0,0-2.451,0v3.578h-4.583a4.724,4.724,0,0,0-4.718,4.717v18.281a4.724,4.724,0,0,0,4.718,4.718h25.081a4.723,4.723,0,0,0,4.717-4.718V275.431A4.723,4.723,0,0,0,487.134,270.714Zm-25.081,2.451h4.583v1.306a1.226,1.226,0,0,0,2.451,0v-1.306H480.1v1.306a1.225,1.225,0,1,0,2.45,0v-1.306h4.584a2.268,2.268,0,0,1,2.266,2.266v2.45H459.786v-2.45A2.269,2.269,0,0,1,462.053,273.165Zm25.081,22.814H462.053a2.27,2.27,0,0,1-2.267-2.267v-13.38H489.4v13.38A2.269,2.269,0,0,1,487.134,295.979ZM465.605,284.66v-1.284a8.369,8.369,0,0,0,1.248-.12,1.751,1.751,0,0,0,.925-.5,1.661,1.661,0,0,0,.37-.689,1.608,1.608,0,0,0,.073-.384H469.8v9.6h-1.934V284.66Zm7.768,8.007.239.014a3.051,3.051,0,0,0,.529-.02.945.945,0,0,0,.424-.153,1.009,1.009,0,0,0,.308-.47A1.139,1.139,0,0,0,475,291.6l-2.653-7.537h2.1l1.576,5.325,1.49-5.325h2.007l-2.475,7.1a9.964,9.964,0,0,1-1.135,2.546,2.157,2.157,0,0,1-1.671.494c-.168,0-.3,0-.4,0s-.255-.01-.458-.024Zm11.269-8.772c.024,0,.078.006.162.01v1.934c-.119-.013-.225-.022-.318-.026s-.168-.007-.225-.007a1.566,1.566,0,0,0-1.53.742,2.881,2.881,0,0,0-.218,1.285v3.45h-1.9v-7.219h1.8v1.259a3.984,3.984,0,0,1,.762-.987,2.071,2.071,0,0,1,1.377-.444C484.588,283.892,484.617,283.893,484.642,283.895Z"
        transform="translate(-448.291 -256.826)"
      />
    </svg>
  );
}
