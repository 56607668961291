import { Auth0Client } from '@auth0/auth0-spa-js';
import configuration from 'configuration';

export interface ILoginStrategy {
  (
    appState: { [key: string]: any },
    productCode?: string,
    client?: Auth0Client,
    connections?: string[],
    locale?: string,
    country?: string
  ): Promise<void> | undefined;
}

// PAY-205: Remove once we stop supporting ProviderID
export const ukaEmailLogin: ILoginStrategy = function (
  appState,
  productCode,
  client,
  connections,
  locale,
  country
) {
  const validConnections = connections?.filter(Boolean) ?? [];
  const [connection] = validConnections;
  const { redirectUri } = appState;

  // If the user lands directly onto the CW UKA chatflow we have no idea if they've been through
  // the flex service auth flow. Propose the flex service adds a special query param when it redirects
  // to CW so we can understand if the user has gone through the flow. eg:
  // /int/product/4c048523-f414-4d86-a8d5-0120e84030ce/quote?force=true&lang=en&flexOnboardComplete=true
  // This param is then stripped out at the ProductRoute (as user may already be auth'd and not enter the login flow)
  const flexOnboardComplete = new URLSearchParams(
    redirectUri?.substring(redirectUri.indexOf('?'))
  ).get('flexOnboardComplete');

  if (productCode?.startsWith('UKA')) {
    // If the flexOnboardComplete flag is true then we'll assume the user has already set up an
    // auth session via the flex service and so auth0 will be able to restore the session.
    if (flexOnboardComplete) {
      // Calling loginWithRedirect with connection:'email' will only work if you've already established
      // a passwordless session on the auth0 domain with cookies (which is what the UKA flex service does).
      // If you attempt to do this without an existing session then auth0 will return a error.
      // UKA is the only scenario where we can assume an email session on auth0 already exists.
      return client?.loginWithRedirect({
        appState: appState,
        connection,
        language: locale,
        productCode,
        country,
      });
    } else {
      // If the flexCompleted query param is not present then we can assume the user is returning
      // to CW after their session has expired, in which case we want to send them back round the
      // flex service auth flow.
      const isQuoteOptionsUri = !!redirectUri.match(/options/);
      const flexServiceLoginLink = new URL(
        `${configuration.api.base}${configuration.api.flexLogin}`
      );
      // Short-term fix to pass the redirectUrl to the flex service if the URL is quote options. The Flex service currently controls
      // and configures the redirection (which is /quote page in all cases). This fix allows customers to click on the link
      // in their renew email to take them directly to the generated quote options. (otherwise they get directed to dashboard instead).
      // Long term fix is for us to send the redirectUrl in every case, so that the customers land back on the same route if
      // they need to re-authenticate.
      if (isQuoteOptionsUri)
        flexServiceLoginLink.searchParams.set(
          'customRedirectUrl',
          `${window.location.origin}/${configuration.region}${redirectUri}`
        );

      window.location.replace(flexServiceLoginLink.href);
    }
  }
};

export const uberPartnerLogin: ILoginStrategy = function (
  appState,
  productCode,
  client,
  connections,
  locale,
  country
) {
  const validConnections = connections?.filter(Boolean) ?? [];
  const { redirectUri } = appState;

  const url = new URL(
    window.location.origin + configuration.api.loginPartnerPath
  );
  url.searchParams.set('redirectUri', redirectUri);

  // Upon return from Auth0 this query param will be picked up by AuthRoute
  // and automatically set the terms as accepted, skipping the TermsPage.
  url.searchParams.set('agreeTerms', 'true');

  return client?.loginWithRedirect({
    appState: {
      ...appState,
      redirectUri: url.pathname + url.search,
    },
    connections: validConnections,
    language: locale,
    productCode,
    country,
  });
};

export const loginStategies: { [key: string]: ILoginStrategy } = {
  UKA: ukaEmailLogin,
  NYR: uberPartnerLogin,
  NYRS: uberPartnerLogin,
  AZR: uberPartnerLogin,
  USR: uberPartnerLogin,
  'US-R-CA': uberPartnerLogin,
  'US-R-GA': uberPartnerLogin,
  'US-R-CO': uberPartnerLogin,
  'US-R-NY': uberPartnerLogin,
  'US-R-WA': uberPartnerLogin,
  'US-R-TX': uberPartnerLogin,
};
