import React from 'react';
import { IProduct } from 'types/product';
import { useLocale } from 'providers/LocaleProvider';
import { ISchema, IValues, ISection } from './Schema';
import Section from './Section/Section';

interface BuilderProps {
  schemas: SchemaBuilder;
  values: IValues;
  product: IProduct;
}

type ProductCode = string;

export class SchemaBuilder {
  constructor(public schemas: Record<ProductCode, any>) {}

  static create(schemas: Record<ProductCode, any>) {
    return new SchemaBuilder(schemas);
  }
}

export function Builder({ schemas, values, product }: BuilderProps) {
  const { locale } = useLocale();
  return (
    <>
      <div id="hidden-code" data-product-code-hidden={product.code} />
      {renderSections(schemas, values, product, locale)}
    </>
  );
}

function renderSections(
  schemas: SchemaBuilder,
  content: IValues,
  product: IProduct,
  locale: string
) {
  const schema = findSchema(schemas, product.code);

  const { sections, translationKey } = schema;

  return sections.map((section: ISection) => (
    <Section
      key={section.name}
      section={section}
      content={content}
      product={product}
      locale={locale}
      translationKey={translationKey}
    />
  ));
}

function findSchema(schemas: SchemaBuilder, productCode: string): ISchema {
  let schema = schemas.schemas.schemas[productCode] as ISchema;
  if (!schema) {
    // check for an older version of the schema
    schema = schemas.schemas.schemas[productCode.replace(/\d/g, '')];
  }

  return schema;
}
