import React, { PropsWithChildren } from 'react';
import Button from 'components/Buttons/Button';
import Modal from '../Modal';
import Loader from 'components/Loader/Loader';
import ContactUsButton from 'components/Buttons/ContactUs/ContactUs';

import './LoadingModal.scss';

interface Props {
  closeText: string;
  errorText?: string;
  onCancel(): void;
  callback?(): void;
}

function LoadingModal({
  closeText,
  errorText,
  onCancel,
  callback,
}: PropsWithChildren<Props>) {
  return (
    <Modal
      title=""
      className="LoadingModal"
      onClose={onCancel}
      actions={
        <Button
          variant="secondary"
          onClick={() => {
            onCancel();
            callback && callback();
          }}
          testId="close-button"
        >
          {closeText}
        </Button>
      }
    >
      {errorText ? (
        <div className="LoadingModal-Error">
          <h4>{errorText}</h4>
          <ContactUsButton></ContactUsButton>
        </div>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}

export default LoadingModal;
