import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory, useQuote } from 'hooks';
import configuration from '../configuration';
import { hasQuoteExpired } from 'utils/quoteUtils';

export const useExpireQuote = (
  isMTA: boolean = false,
  redirectUrl?: string
) => {
  const { id: productId, quoteId } = useParams<{
    id: string;
    quoteId: string;
  }>();

  const { push } = useHistory();
  const { quote } = useQuote(quoteId, true);

  const expiredUrl = `/product/${productId}/quote/${quoteId}/${
    isMTA ? 'mta' : 'quote'
  }Expired`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (quote && hasQuoteExpired(quote)) {
        push(expiredUrl);
      } else if (redirectUrl) {
        push(redirectUrl);
      }
    }, configuration.expireMTAQuoteCheckInterval);

    return () => clearInterval(interval);
  });
};
