import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import Switch from 'components/Router/Switch';
import PaymentConfirmationPage from './PaymentConfirmationPage/PaymentConfirmationPage';
import PaymentPage from './PaymentPage/PaymentPage';
import PaymentErrorPage from './PaymentErrorPage/PaymentErrorPage';

function PaymentRoute({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={PaymentPage} />
      <Route
        path={`${match.path}/confirmation`}
        component={PaymentConfirmationPage}
      />
      <Route path={`${match.path}/error`} component={PaymentErrorPage} />
    </Switch>
  );
}

export default PaymentRoute;
