import React from 'react';

import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';

type Props = TextFieldProps;

function TextField({
  value,
  name,
  onChange,
  error,
  helperText,
  label,
  type,
  onBlur,
  ...props
}: Props) {
  return (
    <MuiTextField
      {...props}
      fullWidth
      variant="outlined"
      type={type}
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
    />
  );
}

export default TextField;
