import React from 'react';

import { useNavigation } from 'providers/NavigationProvider';
import { Logo } from 'components/Icons';

import './PageHeader.scss';

function PageHeader() {
  const { open, close, isOpen } = useNavigation();

  const toggleMenu = () => {
    isOpen ? close() : open();
  };

  return (
    <>
      <header className="Page-header">
        <div className="Page-header-title">
          <Logo />
        </div>
        <div onClick={toggleMenu} className="Page-header-menu-container">
          <div className="Page-header-menu" data-testid="Page-header-menu">
            <span />
          </div>
        </div>
      </header>
    </>
  );
}

export default PageHeader;
