import { IProductDetailsFragment } from 'shared/graphql/api/types';
import { LoadedProductHistory } from '../hooks';

export function getActiveProduct(
  inception: Date,
  productHistory: LoadedProductHistory
) {
  if (productHistory.current.productDeprecated?.from) {
    const nextProductTime = new Date(
      productHistory.current.productDeprecated.from
    ).getTime();
    return inception.getTime() > nextProductTime
      ? productHistory.latest
      : productHistory.current;
  }
  return productHistory.current;
}

/**
 * Returns an IETF BCP 47 locale for the given product, eg: "en-GB".
 * This is the common format used throughout browser and js runtimes and
 * is used in the html lang attribute and Intl.* methods.
 */
export function getProductLocale(product: IProductDetailsFragment): string {
  return `${product.language}-${product.location.country}`;
}
