import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  IApiCallVariables,
  useCallApiHandler,
} from 'providers/CallApiHandlerProvider';
import { ApiActionHandlerEnum } from '@inshur/apis/steps';
import Modal from 'components/Modal/Modal';
import FormTextField from './TextField';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Button from 'components/Buttons/Button';
import * as Sentry from '@sentry/react';
import { ApplicationError, ErrorCodes } from 'constants/errors';

import './FormDriver.scss';

export interface Values {
  dln: string;
}

interface Props {
  active: boolean;
  onClose(dlnDetails?: any): void;
  dataTestId?: string;
}

const HANDLER = ApiActionHandlerEnum.GET_DRIVER_DETAILS;

const ValidationSchema = yup.object({
  dln: yup
    .string()
    .required(
      'Please enter your driving licence number, this should be 16 characters with no spaces.'
    )
    .matches(
      /^[a-zA-Z0-9]{16}/g,
      'Please enter your driving licence number, this should be 16 characters with no spaces.'
    ),
});

interface DriverLicence {
  driversLicense: {
    dln: string;
    numberOfYearsHeld: string;
  };
  dob: string;
}

function FormDriverModal({ active, onClose, dataTestId }: Props) {
  const callApiHandler = useCallApiHandler();
  const [dlnDetails, setDlnDetails] = useState<DriverLicence | null>(null);
  const [error, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSearch(dln: string) {
    setLoading(true);
    const variables = {
      input: {
        context: 'NEW_BUSINESS',
        licenceNumber: dln,
        provider: 'MYLICENCE',
      },
    } as IApiCallVariables;

    const response = await callApiHandler(HANDLER, variables);

    setLoading(false);

    if (!response) {
      setErrors(
        'Oops, something went wrong retrieving the licence details. Please re-enter your licence number'
      );

      Sentry.captureException(
        new ApplicationError(
          'MYLICENCE Error No response',
          ErrorCodes.MYLICENCE_ERROR,
          'Mylicence Exception No response'
        )
      );

      return;
    }

    if (!response.success || !response.data) {
      setErrors(
        'Oops, something went wrong retrieving the licence details. Please re-enter your licence number'
      );

      Sentry.captureException(
        new ApplicationError(
          'MYLICENCE Error',
          ErrorCodes.MYLICENCE_ERROR,
          'Mylicence Exception: ' + response.toString()
        )
      );

      return;
    }

    setDlnDetails(response.data);
  }

  const onSubmit = () => {
    onClose(dlnDetails);
  };

  if (!active) {
    return null;
  }

  return (
    <Modal
      title="Driver Licence"
      className="dln-modal"
      onClose={() => onClose()}
      dataTestId={dataTestId}
    >
      <Formik<Values>
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        initialValues={{
          dln: '',
        }}
      >
        {({
          isValid,
          errors,
          handleChange,
          values,
          touched,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <>
              <div className="dln-modal-inputs">
                {!dlnDetails && (
                  <>
                    <FormTextField
                      className="dln-input"
                      value={values.dln}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="dln"
                      error={Boolean(touched.dln) && Boolean(errors.dln)}
                      onFocus={() => setErrors('')}
                    />

                    {touched.dln && errors.dln && (
                      <ErrorMessage message={errors.dln} />
                    )}

                    {error && <ErrorMessage message={error} />}

                    <Button
                      type="button"
                      testId="search-dln"
                      variant="primary"
                      disabled={
                        values.dln.length === 0 ||
                        !isValid ||
                        loading ||
                        !!error
                      }
                      onClick={() => handleSearch(values.dln)}
                    >
                      Search
                    </Button>
                  </>
                )}

                {dlnDetails && (
                  <>
                    <div>
                      <p>We have found some details:</p>
                      <p style={{ padding: '8px' }}>
                        Licence Number{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {dlnDetails.driversLicense.dln}
                        </span>{' '}
                        held for{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {dlnDetails.driversLicense.numberOfYearsHeld} years.
                        </span>
                      </p>
                      <p>Is this correct?</p>
                    </div>
                    <Button
                      className="dln-button"
                      type="button"
                      variant="primary"
                      testId="submit-dln"
                      onClick={onSubmit}
                    >
                      Confirm
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      testId="submit-dln"
                      onClick={() => {
                        setFieldValue('dln', '');
                        setDlnDetails(null);
                      }}
                    >
                      Search again
                    </Button>
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default FormDriverModal;
