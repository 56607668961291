import { useReducer as _useReducer } from 'react';

// Reducer type
type Reducer<S, A> = (prevState: S, action: A) => S;
// Infer the state type from a reducer
type ReducerState<R> = R extends Reducer<infer S, any> ? S : never;
// Infer the action type from a reducer
type ReducerAction<R> = R extends Reducer<any, infer A> ? A : never;

// Action creator function
type ActionCreator<A> = (...args: any[]) => A;
// Action create function map
type ActionCreatorMap<A> = Record<string, ActionCreator<A>>;

// Action dispatcher
type ActionDispatcher<
  R extends Reducer<any, any>,
  A extends ReducerAction<R> = ReducerAction<R>,
  Actions extends ActionCreatorMap<A> = ActionCreatorMap<A>
> = {
  [key in keyof Actions]: (...args: Parameters<Actions[key]>) => void;
};

// useReducer return type
type UseReducer<
  R extends Reducer<any, any>,
  A extends ReducerAction<R> = ReducerAction<R>,
  Actions extends ActionCreatorMap<A> = ActionCreatorMap<A>
> = [ReducerState<R>, ActionDispatcher<R, A, Actions>];

/**
 * useReducer is the same as React.useReducer, apart from you passed
 * it a set of action creators and it will map them to the dispatch
 * @param reducer
 * @param initial
 * @param actions
 */
function useReducer<
  R extends Reducer<any, any>,
  S extends ReducerState<R> = ReducerState<R>,
  A extends ReducerAction<R> = ReducerAction<R>,
  Actions extends ActionCreatorMap<A> = ActionCreatorMap<A>
>(reducer: R, initial: S, actions: Actions): UseReducer<R, A, Actions> {
  // Use standard reducer
  const [state, _dispatch] = _useReducer<R>(reducer, initial);

  // List all the actions and their property names
  const actionCreators: Array<[keyof Actions, ActionCreator<A>]> =
    Object.entries(actions);

  const dispatcher: ActionDispatcher<R, A, Actions> = {} as any;

  // For each action, create a method that wraps the dispatch call and the creators
  for (const [key, actionCreator] of actionCreators) {
    dispatcher[key] = (...args: any[]) => _dispatch(actionCreator(...args));
  }

  return [state, dispatcher];
}

export default useReducer;
