import React, { useEffect } from 'react';
import Loader from 'components/Loader/Loader';
import { useAuthClient } from 'hooks/useAuthClient';
import configuration from 'configuration';

function LogoutPage() {
  const authClient = useAuthClient();

  useEffect(() => {
    authClient?.logout({ returnTo: configuration.auth0.logoutUri });
  }, [authClient]);

  return <Loader />;
}

export default LogoutPage;
