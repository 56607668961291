import React from 'react';
import Checkbox from 'components/Form/Checkbox/Checkbox';

import './Agreement.scss';

interface Props {
  text: string;
  agreed: boolean;
  setAgreed(agreed: boolean): void;
  link: string;
  dataTestId: string;
}

function Agreement({ text, agreed, link, setAgreed, dataTestId }: Props) {
  return (
    <div className="Agreement">
      <Checkbox
        aria-label="agreement"
        isChecked={agreed}
        onChecked={setAgreed}
        dataTestId={dataTestId}
      />
      <a target="_blank" rel="noreferrer noopener" href={link}>
        {text}
      </a>
    </div>
  );
}

export default Agreement;
