import { useMemo } from 'react';

import Page from 'components/Page/Page';
import Button from 'components/Buttons/Button';
import Link from 'components/Router/Link';
import Loader from 'components/Loader/Loader';
import { formatTestId } from '../../../utils/testIdUtils';
import configuration from 'configuration';
import { useAuth } from 'providers/AuthProvider';
import { User } from '@auth0/auth0-spa-js';
import { useQuery } from 'hooks';
import String from 'components/String/String';
import {
  ProductOption,
  useProductsOptionsSearch,
} from 'hooks/useProductsOptionsSearch';

import './ProductsPage.scss';

interface ProductsFilter {
  productId?: string;
  code?: string;
}

const filterProducts = (
  products: ProductOption[] | undefined | null,
  user: User | undefined,
  productsFilter: ProductsFilter
): ProductOption[] => {
  if (!products) {
    return [];
  }

  if (productsFilter.productId) {
    return products.filter(
      (product) => product.productId === productsFilter.productId
    );
  }

  if (productsFilter.code) {
    return products.filter((product) => product.code === productsFilter.code);
  }

  if (user && user.sub) {
    const authProvider = getUserAuthProvider(user.sub);

    return products.filter((product) =>
      product.identities.some((identity) =>
        identity.provider.includes(authProvider)
      )
    );
  }

  return [];
};

function getUserAuthProvider(userId: string) {
  const user = userId.split('|');

  return user[0];
}

function Product({ product }: { product: ProductOption }) {
  const { productId, name, code } = product;

  return (
    <div className="Product">
      <h3 className="Product-name">
        {name} ({code})
      </h3>
      <div className="Product-actions">
        <Link key={`${productId}-quote`} to={`/product/${productId}/quote`}>
          <Button variant="tertiary" testId={formatTestId(`get-quote-${code}`)}>
            Get a quote
          </Button>
        </Link>
        <Link to={`/product/${productId}`}>
          <Button variant="secondary" testId={formatTestId(`login-${code}`)}>
            Login
          </Button>
        </Link>
      </div>
    </div>
  );
}

function ProductsPage() {
  const { user } = useAuth();
  const productsFilter = useQuery<ProductsFilter>();
  const { products: productsApi, loading: loadingProducts } =
    useProductsOptionsSearch(productsFilter.code || '');

  const products = useMemo(
    () => filterProducts(productsApi, user, productsFilter),
    [productsApi, user, productsFilter]
  );

  if (loadingProducts) {
    return (
      <Page name="ProductsPage" slug="Products">
        <Loader />
      </Page>
    );
  }

  return (
    <Page name="ProductsPage" slug="Products">
      {configuration.enableDisplayLatestProducts &&
      products &&
      products.length > 0 ? (
        <div>
          {products.map((product) => (
            <Product key={product.productId} product={product} />
          ))}
        </div>
      ) : (
        <div className="ProductsPage-info">
          <div className="ProductsPage-info-text">
            <String
              id="pages.product.landing.homepage"
              values={{
                url: configuration.marketingSiteURL,
              }}
            />
          </div>
        </div>
      )}
    </Page>
  );
}

export default ProductsPage;
