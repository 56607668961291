import React from 'react';
import { Item } from 'react-loqate';
import String from 'components/String/String';

interface Props {
  suggestion: Item;
}

function AddressSuggestion({ suggestion, ...props }: Props) {
  const { Text, Description, Type } = suggestion;

  return (
    <li {...props} className="AddressModal-item">
      <strong>{Text}</strong>
      <div>{Description}</div>
      {['Postcode', 'Street'].includes(Type) && (
        <small>
          <String id="pages.quote.address.expand" />
        </small>
      )}
    </li>
  );
}

export default AddressSuggestion;
