import React from 'react';
import { useParams } from 'react-router-dom';
import { Centered } from 'components/Layout/Layout';
import String from 'components/String/String';
import TRANSLATIONS from 'constants/translations';
import ContactUsButton from 'components/Buttons/ContactUs/ContactUs';
import Avatar from 'components/Avatar';
import { useHistory } from 'hooks';
import EventManager, { EventType } from '../../../../utils/EventManager';
import { QuoteType } from '../../../../providers/TrackingProvider';

import './MtaQuoteDeclined.scss';

function MtaQuoteDeclined() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  function onReturn() {
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: 'MTA Decline page - dashboard',
      quoteType: QuoteType.MTA,
      productId: id,
    });
    history.push(`/product/${id}`);
  }

  function onContactUs() {
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: 'MTA Decline page - contact us',
      quoteType: QuoteType.MTA,
      productId: id,
    });
  }

  return (
    <Centered className="MtaQuoteDeclined">
      <Avatar expression="sad" />
      <h3>
        <String
          id="pages.mta.changes_declined.title"
          defaultValue={TRANSLATIONS.en['pages.mta.changes_declined.msg']}
        />
      </h3>
      <div className="main-message">
        <String
          id="pages.mta.changes_declined.msg"
          defaultValue={TRANSLATIONS.en['pages.mta.changes_declined.msg']}
        />
      </div>
      <button
        onClick={onReturn}
        className="dashboard"
        data-testid="go_to_dashboard_btn"
      >
        <String
          id="pages.mta.changes_declined.btn"
          defaultValue={TRANSLATIONS.en['pages.mta.changes_declined.btn']}
        />
      </button>
      <ContactUsButton
        dataTestId="mta_decline_contact_us_btn"
        onClick={onContactUs}
      />
    </Centered>
  );
}

export default MtaQuoteDeclined;
