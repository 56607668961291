
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IPackageValuation": [
      "PackageValuation",
      "PolicyPackageValuation",
      "QuotePackageValuation"
    ],
    "IPaymentPlans": [
      "QuotePackageValuation"
    ],
    "PaymentPlanSummary": [
      "FinancePaymentPlan",
      "FlexWalletPaymentPlan",
      "InFullPaymentPlan",
      "InstalmentsPaymentPlan",
      "ManualPaymentPlan"
    ]
  }
};
      export default result;
    