import {
  ICreateMtaPolicyMutationVariables,
  useCreateMtaPolicyMutation,
} from 'shared/graphql/api/types';

export const useCreateMtaPolicy = () => {
  const [createPolicy, { loading, data }] = useCreateMtaPolicyMutation();

  const create = async (input: ICreateMtaPolicyMutationVariables['input']) => {
    const { data } = await createPolicy({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.policy;
  };

  return {
    create,
    loading,
    policy: data?.policy,
  };
};
