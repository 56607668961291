import {
  useCreateQuoteDocumentMutation,
  ICreateQuoteDocumentMutationVariables,
} from '../shared/graphql/api/types';

export const useCreateQuoteDocument = () => {
  const [createQuoteDocument, { loading, data, error }] =
    useCreateQuoteDocumentMutation();

  const create = async (
    input: ICreateQuoteDocumentMutationVariables['input']
  ) => {
    const { data } = await createQuoteDocument({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.document;
  };

  return {
    create,
    loading,
    document: data?.document,
    error,
  };
};
