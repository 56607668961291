import {
  useCreatePolicyDocumentMutation,
  ICreatePolicyDocumentMutationVariables,
} from 'shared/graphql/api/types';

export const useCreatePolicyDocument = () => {
  const [createPolicyDocument, { loading, data, error }] =
    useCreatePolicyDocumentMutation();

  const create = async (
    input: ICreatePolicyDocumentMutationVariables['input']
  ) => {
    const { data } = await createPolicyDocument({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });

    return data?.document;
  };

  return {
    create,
    loading,
    document: data?.document,
    error,
  };
};
