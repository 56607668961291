export const UBER_DEFAULTS = {
  rewardsTier: 'TIER_1',
  rating: 5.0,
  totalTrips: 0,
  averageMonthlyTrips: 0,
  documentUploadConsent: false,
  email: 'uberplaceholderemail@inshur.com',
};

export const FORM_INITIAL_VALUES = {
  drivers: [
    {
      uber: UBER_DEFAULTS,
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      relationshipToPolicyHolder: 'SELF',
      insuranceQualification: {
        isEligible: false,
        hasNegativeHistory: '',
      },
      previousClaims: {
        atFault: 0,
        fireAndTheft: 0,
        total: '',
      },
      driversLicense: {
        dln: '',
      },
      privateHireLicense: {
        authority: '',
        yearsHeld: '',
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        county: '',
        postalCode: '',
        country: '',
        region: '',
        city: '',
        state: '',
        province: '',
      },
    },
  ],
  insured: {
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      postalCode: '',
      country: '',
      region: '',
      city: '',
      state: '',
      province: '',
    },
    vehicleUse: 'PRIVATE_HIRE',
    preferredPaymentFrequency: 'ANNUALLY',
    insuranceQualification: {
      isEligible: false,
      hasNegativeHistory: '',
    },
    uber: {
      documentUploadConsent: false,
    },
    dob: '',
    privateHireLicense: {
      authority: '',
      yearsHeld: '',
    },
    previousClaims: {
      atFault: 0,
      fireAndTheft: 0,
      total: 0,
    },
    claimFreeYears: '',
    protectedNcd: false,
    referralCode: '',
  },
  vehicles: [
    {
      registrationNumber: '',
      make: '',
      model: '',
      yearOfManufacture: '',
      numberOfSeats: '',
      value: '',
      valueWhenNew: '',
      type: '',
      bodyType: '',
      transmissionType: '',
      grossWeightKgs: '',
      engineCapacityCc: '',
      fuelType: '',
      insuranceGroup: '',
      ageAtOnRisk: 0,
      keptAtDifferentAddressOvernight: '',
      ownershipStatus: '',
      overnightAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        county: '',
        postalCode: '',
        country: '',
        region: '',
        city: '',
        state: '',
        province: '',
      },
    },
  ],
  policy: {
    tenure: 0,
    daysToInception: 0,
    startDateTime: '',
  },
  utm: {
    utm_source: 'form',
    utm_medium: 'form',
    utm_campaign: 'form',
    utm_content: 'form',
  },
  billing: {
    paymentPlan: 'IN_FULL',
  },
};
