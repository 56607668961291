import styled from 'styled-components/macro';

export const Centered = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Full = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex1Col = styled.div`
  display: flex;
  width: 100%;
`;

export const Flex2Cols = styled.div`
  display: flex;
  width: 50%;
`;

export const Flex3Cols = styled.div`
  display: flex;
  width: 33.3%;
`;
