import React, { useState } from 'react';
import { object, number, boolean, array, string, SchemaOf } from 'yup';
import {
  Formik,
  Form,
  ErrorMessage as FormikErrorMessage,
  FieldArray,
  FormikProps,
} from 'formik';
import RadioButtonForm from '../components/RadioButton';
import VehicleSearch from '../components/VehicleSearch';
import RadioButton from '../components/RadioButton';
import Button from 'components/Buttons/Button';
import AddressSearch from '../components/AddressSearch';
import { OWNERSHIP } from '../constants/FormValues';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { IVehicle } from 'shared/graphql/api/types';
import ErrorBox from '../components/ErrorBox';
import { FormAction, formActionChoices } from '../QuoteFormSteps';
import { VEHICLE_ADDRESS } from '../constants/FormValues';
import UnstyledButton from 'components/Buttons/UnstyledButton';
import { FORM_INITIAL_VALUES } from '../constants/FormInitialValues';

interface VehicleValue {
  ageAtOnRisk?: number;
  keptAtDifferentAddressOvernight: boolean;
  valueWhenNew: number;
  overnightAddress: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city: string;
    county?: string;
    postalCode: string;
    region?: string;
    country?: string;
    province?: string;
    state?: string;
  };
  registrationNumber: string;
  vin?: string;
  type: string;
  bodyType: string;
  make: string;
  model: string;
  yearOfManufacture: string;
  transmissionType: string;
  grossWeightKgs: number;
  engineCapacityCc: number;
  value: number;
  numberOfSeats: number;
  fuelType?: string;
  insuranceGroup: string;
  ownershipStatus: string;
  modification?: {
    isModified?: boolean;
    modificationType?: string;
  };
  name: string;
  email: string;
}

interface Values {
  vehicles: VehicleValue[];
}

const stepValidationSchema: SchemaOf<Values> = object({
  vehicles: array().of(
    object()
      .shape({
        overnightAddress: object({
          addressLine1: string().required(
            'Please provide your address by clicking "Search Your Address" to continue.'
          ),
          addressLine2: string(),
          addressLine3: string(),
          city: string().required(
            'Please provide your address by clicking "Search Your Address" to continue.'
          ),
          county: string(),
          postalCode: string().required(
            'Please provide your address by clicking "Search Your Address" to continue.'
          ),
          region: string(),
          country: string(),
          province: string(),
          state: string(),
        }).required(
          'Please provide your address by clicking "Search Your Address" to continue.'
        ),
        registrationNumber: string().required(
          'Provide a value for Vehicle registration number.'
        ),
        ageAtOnRisk: number(),
        keptAtDifferentAddressOvernight: boolean().required(
          'Please provide the response "Yes" or "No" to continue.'
        ),
        vin: string(),
        type: string().required('Vehicle type info is required.'),
        bodyType: string().required('Vehicle body type info is required.'),
        make: string().required('Vehicle make info is required.'),
        model: string().required('Vehicle model info is required.'),
        yearOfManufacture: string().required(
          'Vehicle year of manufacture info is required.'
        ),
        transmissionType: string().required(
          'Vehicle transmission type info is required.'
        ),
        grossWeightKgs: number().required('Vehicle weight info is required.'),
        engineCapacityCc: number().required('Vehicle engine info is required.'),
        value: number()
          .min(1, 'Must be more than 1 characters')
          .positive('Please provide a vehicle value')
          .integer('Please provide a vehicle value')
          .required('Please provide a vehicle value'),
        valueWhenNew: number()
          .positive('Please provide a vehicle value when new')
          .integer('Please provide a vehicle value when new')
          .required('Please provide a vehicle value when new'),
        numberOfSeats: number().required('Number of seats is required.'),
        fuelType: string(),
        insuranceGroup: string().required('Vehicle info is required.'),
        ownershipStatus: string().required(
          'Provide a value for ownership status.'
        ),
        modification: object({
          isModified: boolean(),
          modificationType: string(),
        }),
      })
      .required()
  ),
});

interface Props {
  data: any;
  dispatch: React.Dispatch<FormAction>;
}

const QuoteFormTwo = ({ dispatch, data }: Props) => {
  const [submited, setSubmited] = useState(false);
  const handleSubmit = (values: Values) => {
    dispatch({ action: formActionChoices.NEXT, data: values });
  };

  function onSubmitClick() {
    setSubmited(true);
  }

  const handlePrevStep = (values: Values) => {
    dispatch({ action: formActionChoices.PREV, data: values });
  };

  return (
    <>
      <Formik
        validationSchema={stepValidationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          handleBlur,
          setFieldValue,
        }: FormikProps<Values>) => {
          function onSelectAddress(value: string) {
            if (value.includes('false')) {
              setFieldValue(
                'vehicles[0].overnightAddress',
                data.insured.address
              );
            }
            if (value.includes('true')) {
              setFieldValue('vehicles[0].overnightAddress.addressLine1', '');
            }
          }

          return (
            <Form>
              {submited && Object.keys(errors).length > 0 && <ErrorBox />}
              <div className="QuoteForm-step-wrapper">
                <h2 className="sectionTitle">Vehicle details</h2>
                <FieldArray name="vehicles">
                  {() => (
                    <div>
                      {values.vehicles.length > 0 &&
                        values.vehicles.map((vehicle, index) => (
                          <div key={index}>
                            {!vehicle.registrationNumber && (
                              <>
                                <p>
                                  Please enter the registration number of the
                                  vehicle you want to insure
                                </p>

                                <VehicleSearch
                                  setField={(vehicle: IVehicle) =>
                                    setFieldValue('vehicles[0]', {
                                      ...values.vehicles[0],
                                      ...vehicle,
                                    })
                                  }
                                  name={`vehicles.${index}.registrationNumber`}
                                  onBlur={handleBlur}
                                />
                                <FormikErrorMessage
                                  name={`vehicles.${index}.registrationNumber`}
                                  render={(message) => (
                                    <ErrorMessage message={message} />
                                  )}
                                />
                              </>
                            )}

                            {vehicle.registrationNumber && (
                              <>
                                <h3>Vehicle</h3>
                                <p>
                                  {vehicle.yearOfManufacture} {vehicle.make}{' '}
                                  {vehicle.model} <br /> (
                                  {vehicle.registrationNumber})
                                </p>

                                <UnstyledButton
                                  className="VehicleChange-action"
                                  onClick={() =>
                                    setFieldValue(
                                      `vehicles.${index}`,
                                      FORM_INITIAL_VALUES.vehicles[index]
                                    )
                                  }
                                  data-testid="change-vehicle-button"
                                >
                                  Change Vehicle
                                </UnstyledButton>

                                <div className="Question">
                                  <div className="Question-title">
                                    What is the ownership status of your
                                    vehicle?
                                  </div>
                                  <p className="Question-subtitle">
                                    Ownership Status
                                  </p>

                                  {OWNERSHIP.map((option) => (
                                    <RadioButton
                                      handleChange={setFieldValue}
                                      key={option.value}
                                      value={option.value}
                                      label={option.label}
                                      fieldValue={vehicle.ownershipStatus}
                                      name={`vehicles.${index}.ownershipStatus`}
                                    />
                                  ))}

                                  <FormikErrorMessage
                                    name={`vehicles.${index}.ownershipStatus`}
                                    render={(message) => (
                                      <ErrorMessage message={message} />
                                    )}
                                  />
                                </div>

                                <div className="Question">
                                  <div className="Question-title">
                                    Where is your vehicle kept when not in use?
                                  </div>

                                  <p className="Question-subtitle">
                                    Correspondence Address
                                  </p>
                                  <p>
                                    {data.insured.address.addressLine1} <br />
                                    {data.insured.address.county} <br />
                                    {data.insured.address.postalCode}
                                  </p>
                                  <div className="SubQuestion">
                                    <div className="SubQuestion-title">
                                      Is this where you keep your vehicle at
                                      night?
                                    </div>
                                    <div role="group">
                                      {VEHICLE_ADDRESS.map((option) => (
                                        <RadioButtonForm
                                          handleChange={setFieldValue}
                                          key={option.value}
                                          id={option.id}
                                          value={option.value}
                                          fieldValue={vehicle.keptAtDifferentAddressOvernight.toString()}
                                          label={option.label}
                                          name="vehicles[0].keptAtDifferentAddressOvernight"
                                          onClickButton={onSelectAddress}
                                        />
                                      ))}
                                    </div>
                                    <FormikErrorMessage
                                      name={`vehicles.${index}.keptAtDifferentAddressOvernight`}
                                      render={(message) => (
                                        <ErrorMessage message={message} />
                                      )}
                                    />
                                  </div>
                                  {!vehicle.overnightAddress.addressLine1 &&
                                    vehicle.keptAtDifferentAddressOvernight && (
                                      <>
                                        <AddressSearch
                                          setField={setFieldValue}
                                          filled={false}
                                          fields={[
                                            'vehicles[0].overnightAddress',
                                          ]}
                                        />
                                        <FormikErrorMessage
                                          name={`vehicles.${index}.overnightAddress.addressLine1`}
                                          render={(message) => (
                                            <ErrorMessage message={message} />
                                          )}
                                        />
                                      </>
                                    )}
                                  {vehicle.overnightAddress.addressLine1 &&
                                    vehicle.keptAtDifferentAddressOvernight && (
                                      <>
                                        <p className="Question-subtitle">
                                          Vehicle Address
                                        </p>
                                        <p>
                                          {
                                            vehicle.overnightAddress
                                              .addressLine1
                                          }{' '}
                                          <br />
                                          {vehicle.overnightAddress.county}{' '}
                                          <br />
                                          {vehicle.overnightAddress.postalCode}
                                        </p>

                                        <AddressSearch
                                          setField={setFieldValue}
                                          filled={true}
                                          fields={[
                                            'vehicles[0].overnightAddress',
                                          ]}
                                        />
                                      </>
                                    )}
                                  {vehicle.overnightAddress.addressLine1 &&
                                    !vehicle.keptAtDifferentAddressOvernight && (
                                      <>
                                        <p className="Question-subtitle">
                                          Vehicle Address
                                        </p>
                                        <p>
                                          {
                                            vehicle.overnightAddress
                                              .addressLine1
                                          }{' '}
                                          <br />
                                          {vehicle.overnightAddress.county}{' '}
                                          <br />
                                          {vehicle.overnightAddress.postalCode}
                                        </p>
                                      </>
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </FieldArray>
              </div>
              {values.vehicles[0].registrationNumber && (
                <div className="QuoteForm-step-actions">
                  <Button
                    onClick={onSubmitClick}
                    variant="primary"
                    type="submit"
                    testId="form-vehicle-next"
                    track={{ questionId: 'form-confirm-step-3-vehicle' }}
                  >
                    Continue
                  </Button>

                  <Button
                    onClick={() => handlePrevStep(values)}
                    variant="secondary"
                    type="submit"
                    testId="form-vehicle-back"
                    track={{ questionId: 'form-back-step-3-vehicle' }}
                  >
                    Back
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default QuoteFormTwo;
