import { ISchema, ISchemaConfiguration } from '../types/quote';

/**
 * Transforms the string names to follow array format
 * Example: from driver.0.dob to driver[0].dob
 * @param {string} path path to update
 *
 */
export const renamePath = (path: string) => {
  const pathRename: string[] = [];
  const pathTree = path.split('.');

  pathTree.forEach((val, i) => {
    if (!isNaN(parseInt(val, 10))) {
      pathRename[pathRename.length - 1] += `[${val}]`;
    } else {
      pathRename.push(val);
    }
  });

  return pathRename.join('.');
};

/**
 * Get the schema for a property based on it's path
 * Example answer_path: $.datasheet.insured.firstName
 * @param path
 * @param schema
 */
export function getSchemaConfiguration(
  path: string | string[] | undefined,
  schema: ISchema
): ISchemaConfiguration | null {
  if (!schema) {
    return null;
  }

  if (typeof path === 'undefined') {
    return null;
  }

  const paths = Array.isArray(path) ? path : [renamePath(path)];

  if (!paths.length) {
    return null;
  }

  // TODO: This must be changed or agreed. How do we handle two answer paths?
  for (const path of paths.filter(Boolean)) {
    // Do we have an index in our answer_path property
    const regex = /\[\d+\]/;
    if (regex.exec(path)) {
      return getConfigurationForArrayValue(path, schema);
    }
    const configuration = schema[path.replace(/\$\.datasheet\./im, '')];

    if (configuration) {
      return configuration;
    }
  }

  return null;
}

/**
 * Get the schema for a property based on its path when the path contains an indexed array
 * Example answer_path: $.datasheet.drivers[2].firstName
 * @param path
 * @param schema
 */
function getConfigurationForArrayValue(
  path: string,
  schema: ISchema
): ISchemaConfiguration | null {
  const updatedPath = substituteIndexForWildcard(path);
  const configuration = schema[updatedPath.replace(/\$\.datasheet\./im, '')];

  if (configuration) {
    return configuration;
  }
  return null;
}

function substituteIndexForWildcard(path: string): string {
  return path.replace(/\d+/g, '*');
}

/**
 * Get all the defaults from a schema. If a schema config contains 1 enum,
 * set it at all the default value
 * @param schema
 */
export function getSchemaDefaults(schema: ISchema) {
  // Loop through all the schema entries, for each where there is only 1 enum, return it as a default
  return Object.entries(schema).reduce((result, [path, configuration]) => {
    // If the configuration is not a string, leave
    if (configuration.type !== 'string') {
      return result;
    }

    const { enums } = configuration;

    // If there are no enums, or if there isn't a single item, leave
    if (!enums || enums?.length !== 1) {
      return result;
    }

    result.push([path, enums[0]]);

    return result;
  }, [] as [string, string][]);
}
