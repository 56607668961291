import React, { useMemo } from 'react';
import { IMessage } from 'types/message';
import MessageGroup from './MessageGroup/MessageGroup';
import { groupMessageBySender } from './utils/groupMessages';
import ChatRoller from '../ChatRoller/ChatRoller';
import Avatar from 'components/Avatar';
import EvilAvatar from 'components/Avatar/EvilAvatar';
import String from 'components/String/String';
import Loader from 'components/Loader/Loader';
import { usePreQuote } from 'providers/QuoteProvider';
import MessageBubble from './Message/MessageBubble';
import Feature from '@inshur/nowucit/features/RenderFeature';

import './Messages.scss';

interface Props {
  messages: IMessage[];
  showInput?: boolean;
  avatarDirection?: 'normal' | 'down' | 'right';
  scrollRef: React.RefObject<HTMLDivElement>;
  onLeaveButtonClick?: () => void;
}

const Messages = ({
  messages,
  avatarDirection = 'normal',
  scrollRef,
  onLeaveButtonClick,
}: Props) => {
  const messageGroups = useMemo(
    () => groupMessageBySender([...messages]),
    [messages]
  );

  const { isApiLoading } = usePreQuote();

  return (
    <ChatRoller className="Chatbot-messages" scrollRef={scrollRef}>
      <div className="Chatbot-introduction">
        <Feature name="evil_avatar">
          {(enabled: boolean) =>
            enabled ? (
              <EvilAvatar direction={avatarDirection} />
            ) : (
              <Avatar direction={avatarDirection} />
            )
          }
        </Feature>

        <div className="Chatbot-introduction-title">
          <String id="pages.quote.chatbot.introduction.title" />
        </div>
        <div className="Chatbot-introduction-description">
          <String id="pages.quote.chatbot.introduction.description" />
        </div>
        {onLeaveButtonClick && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            data-testid="Chatbot-leave-button"
            onClick={onLeaveButtonClick}
            className="Chatbot-leave-button"
          >
            <i className="Chatbot-leave-button-content">Close</i>
          </a>
        )}
      </div>

      {messageGroups.map((group, index) => (
        <MessageGroup
          key={index}
          group={group}
          avatarDirection={
            index === messageGroups.length - 1 ? avatarDirection : 'normal'
          }
        />
      ))}

      {isApiLoading && (
        <div className="Message-group received">
          <div className="Message-group-icon icon">
            <Feature name="evil_avatar">
              {(enabled: boolean) =>
                enabled ? (
                  <EvilAvatar direction={avatarDirection} />
                ) : (
                  <Avatar direction={avatarDirection} />
                )
              }
            </Feature>
          </div>
          <div className="Messages-api-loader">
            <MessageBubble received>
              <Loader size={8} color="#757575" />
            </MessageBubble>
          </div>
        </div>
      )}
    </ChatRoller>
  );
};

export default Messages;
