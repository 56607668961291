import React from 'react';
import classnames from 'classnames';
import Feature from '@inshur/nowucit/features/RenderFeature';

import Avatar from 'components/Avatar';
import EvilAvatar from 'components/Avatar/EvilAvatar';
import { IMessageGroup } from 'types/message';
import Message from '../Message/Message';

import './MessageGroup.scss';

interface Props {
  group: IMessageGroup;
  waitingResponse?: boolean;
  avatarDirection: 'normal' | 'right' | 'down';
}

function MessageGroup({ group, avatarDirection }: Props) {
  const { received, messages } = group;

  return (
    <div className={classnames('Message-group', { received })}>
      {received && (
        <div className="Message-group-icon icon">
          <Feature name="evil_avatar">
            {(enabled: boolean) =>
              enabled ? (
                <EvilAvatar direction={avatarDirection} />
              ) : (
                <Avatar direction={avatarDirection} />
              )
            }
          </Feature>
        </div>
      )}
      <div className="Message-group-messages">
        {messages.map((message) => (
          <Message key={message.date.toISOString()} detail={message} />
        ))}
      </div>
    </div>
  );
}

export default MessageGroup;
