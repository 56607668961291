import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface INavigationContext {
  isOpen: boolean;
  open(): void;
  close(): void;
}

export const NavigationContext = createContext<INavigationContext>({} as any);

export const useNavigation = () => useContext(NavigationContext);

interface Props {
  defaultValue?: any;
}

function NavigationProvider({ children }: PropsWithChildren<Props>) {
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <NavigationContext.Provider value={{ isOpen, open, close }}>
      {children}
    </NavigationContext.Provider>
  );
}

export default NavigationProvider;
