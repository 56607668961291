import React from 'react';
import classnames from 'classnames';

import './Tabs.scss';
import { useStrings } from '../../hooks';

interface Props {
  titleId: string;
  active: boolean;
  onClick(): void;
}

export function Tab({ titleId, active, onClick }: Props) {
  const { get } = useStrings();

  const title = get(titleId);

  return (
    <div
      className={classnames({ 'Tabs-tab': !active, 'Tabs-tab-active': active })}
      onClick={onClick}
      data-testid={`${title.toLowerCase()}-tab-button`}
    >
      <p
        className={classnames({
          'Tabs-title': !active,
          'Tabs-title-active': active,
        })}
      >
        {title}
      </p>
    </div>
  );
}
