import React, { useState } from 'react';
import { VehicleQuestion } from 'shared/types/question';

import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';

import String from 'components/String/String';

interface Props {
  question: VehicleQuestion;
  onAnswer: AnswerQuestion;
  vehicles?: Record<string, string[]>;
}

function VehicleModelInput({ onAnswer, vehicles: validVehicles }: Props) {
  const [active, setActive] = useState(false);
  const { $ } = usePreQuote();
  const { vehicles } = $.datasheet;

  if (!validVehicles) {
    console.error('A list of vehicles should be present in the configuration');
    return null;
  }

  if (!vehicles?.[0]) {
    console.error('A vehicle should be present in the datasheet');
    return null;
  }

  const [{ make }] = vehicles;

  if (!make) {
    console.error('A vehicle make should be present in the datasheet');
    return null;
  }

  const models = validVehicles[make];

  function onSelect(model: string) {
    setActive(false);
    onAnswer(model, model);
  }

  return (
    <>
      {active && (
        <div className="Chatbot-dropdown">
          {models.map((model, index) => (
            <div
              key={model + index}
              className="Chatbot-dropdown-item"
              data-testid={`vehicle-model-input-${make}"`}
              onClick={() => onSelect(model)}
            >
              {model}
            </div>
          ))}
        </div>
      )}
      <div className="Chatbot-actions">
        <div
          className="Chatbot-action"
          onClick={() => setActive(true)}
          data-testid="vehicle-make-input-button"
        >
          <String id="pages.quote.chatbot.select_option" />
        </div>
      </div>
    </>
  );
}

export default VehicleModelInput;
