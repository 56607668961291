import React, { useState } from 'react';

import UnstyledButton from 'components/Buttons/UnstyledButton';
import String from 'components/String/String';
import AddressModal, {
  Address,
} from 'components/Chatbot/ChatbotInput/AddressInput/AddressModal/AddressModal';
import { usePreQuote } from 'providers/QuoteProvider';

import './AddressSearch.scss';

interface Props {
  setField: (fieldName: string, address: Address) => void;
  filled: boolean;
  fields: string[];
}

const countries: { [locale: string]: string } = {
  en: 'GBR',
  nl: 'NL',
  us: 'USA',
};

function AddressInput({ setField, filled, fields }: Props) {
  const [displayModal, setDisplayModal] = useState(false);
  const {
    productHistory: { current: product },
  } = usePreQuote();

  const onClose = (address: Address) => {
    setDisplayModal(false);

    if (!address) {
      return;
    }

    fields.forEach((field) => setField(field, address));
  };

  return (
    <div className={`AddressSearch ${filled ? ' AddressSearch-filled' : ''}`}>
      <AddressModal
        active={displayModal}
        onClose={onClose}
        countries={[countries[product.location.country.toLowerCase()]]}
        dataTestId="address-modal"
      />
      <UnstyledButton
        className={`${
          filled ? ' AddressSearch-action-filled' : 'AddressSearch-action'
        }`}
        onClick={() => setDisplayModal(true)}
        data-testid="address-modal-button"
      >
        {filled ? (
          'Change Address'
        ) : (
          <String id="pages.quote.chatbot.search_address" />
        )}
      </UnstyledButton>
    </div>
  );
}

export default AddressInput;
