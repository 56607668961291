import React, { createContext, PropsWithChildren } from 'react';
import { useGetApplication } from 'hooks';
import Loader from '../components/Loader/Loader';
import { ErrorMessage } from '../components/Error/Error';
import { IGetApplicationQuery } from 'shared/graphql/api/types';

export type IApplicationContext = [
  IGetApplicationQuery['application'] | undefined,
  boolean
];

export const useApplication = () => React.useContext(ApplicationContext);

export const ApplicationContext = createContext<IApplicationContext>([
  undefined,
  true,
]);

interface Props {
  defaultValue?: any;
}

function ApplicationProvider({ children }: PropsWithChildren<Props>) {
  const { loading, application } = useGetApplication();

  if (loading) {
    return <Loader />;
  }

  if (!loading && !application) {
    return <ErrorMessage />;
  }

  return (
    <ApplicationContext.Provider value={[application, loading]}>
      {children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationProvider;
