import { useEffect, useMemo, useRef, useState } from 'react';
import String from '../String/String';
import Document from './Document';
import {
  IDocumentFragment,
  IDocumentTypeFragment,
  IPolicyDetailFragment,
  useGetPolicyDocumentsLazyQuery,
} from 'shared/graphql/api/types';
import configuration from '../../configuration';
import Loader from '../Loader/Loader';
import { useCreatePolicyDocument, useStrings } from '../../hooks';
import ContactUsButton from '../Buttons/ContactUs/ContactUs';
import {
  getDocumentPdf,
  getExpectedDocumentTypes,
  getMissingDocumentTypes,
} from '../../utils/documentUtils';
import { useAuth } from 'providers/AuthProvider';
import DocumentType from './DocumentType';

interface Props {
  policy: IPolicyDetailFragment;
}

function PolicyDocuments({ policy }: Props) {
  const { get } = useStrings();
  const { accessToken } = useAuth();
  const { pollInterval, pollTimeout } = configuration.documents;
  const interval = useRef<number>();
  const timeout = useRef<NodeJS.Timeout>();
  const [pollingTimeout, setPollingTimeout] = useState(false);

  const [getDocuments, { data: documents, error, loading }] =
    useGetPolicyDocumentsLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { id: policy.id, version: policy.version! },
    });

  const { create: createDocument, error: createError } =
    useCreatePolicyDocument();

  const expectedDocumentTypes = useMemo(
    () => getExpectedDocumentTypes(policy),
    [policy]
  );
  const missingDocuments = useMemo(
    () => getMissingDocumentTypes(documents, expectedDocumentTypes),
    [documents, expectedDocumentTypes]
  );

  // Document visibility defines whether the document should be presented to the
  // customer. The property is optional. If null or undefined then assume TRUE.
  const visibleDocuments = useMemo(() => {
    return documents?.documents.filter(
      (document) => document.visibleToCustomer !== false
    );
  }, [documents]);

  const visibleMissingDocuments = useMemo(() => {
    return missingDocuments.filter(
      (documentType) => documentType.defaultCustomerVisibilty !== false
    );
  }, [missingDocuments]);

  function stopPolling() {
    if (interval.current) {
      clearInterval(interval.current);
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (!documents) {
      setPollingTimeout(true);
    }
  }

  useEffect(() => {
    if (documents) {
      stopPolling();
      return;
    }
    getDocuments();
    interval.current = setInterval(getDocuments, pollInterval);
    timeout.current = setTimeout(stopPolling, pollTimeout);

    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const pollingError = pollingTimeout && !documents;

  if (error || createError || pollingError)
    return (
      <div>
        <h4>
          <String id="pages.error.message" />
        </h4>
        <ContactUsButton></ContactUsButton>
      </div>
    );

  if (loading) return <Loader />;

  function newDocument(document: IDocumentFragment) {
    const name = document.documentType?.name || get('modal.documents.unknown');

    async function getDocument() {
      await getDocumentPdf(document.id, name, document.uri, accessToken || '');
    }

    return (
      <Document
        key={document.id}
        getDocument={getDocument}
        productCode={policy.product.code}
        name={name}
        track={{
          productId: policy.product.id,
          label: `open-document-${name}`,
        }}
      />
    );
  }

  function newDocumentType(documentType: IDocumentTypeFragment) {
    function getDocument(documentId: string, uri: string) {
      return getDocumentPdf(
        documentId,
        documentType.name,
        uri,
        accessToken || ''
      );
    }

    function generateDocument(): Promise<IDocumentFragment | undefined> {
      return createDocument({
        documentTypeId: documentType.id,
        version: policy.version!,
        policyId: policy.id,
      });
    }

    return (
      <DocumentType
        key={documentType.id}
        getDocument={getDocument}
        generateDocument={generateDocument}
        productCode={policy.product.code}
        name={documentType.name}
        track={{
          productId: policy.product.id,
          label: `create-document-${documentType.name}`,
        }}
      />
    );
  }

  return (
    <div className="PaymentOverview-links">
      <h4>
        <String id="pages.quote.summary.please_review" />
      </h4>
      {visibleDocuments?.map(newDocument)}
      {visibleMissingDocuments.map(newDocumentType)}
    </div>
  );
}

export default PolicyDocuments;
