import React, {
  ChangeEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import Toggle from 'react-toggle';
import LinkButton from 'components/LinkButton/LinkButton';
import String from 'components/String/String';
import Modal from '../Modal';

import './RenewalModal.scss';
import 'react-toggle/style.css';
import { createKey } from 'utils/quoteUtils';
import Button from 'components/Buttons/Button';
import {
  useStrings,
  useUpdateAutoRenewalTrackingOptIn,
  useGetPolicyAutoRenew,
  useGetPolicy,
} from 'hooks';
import { isPolicyWithinAutoRenewalDeadline } from 'utils/policyUtils';

interface Props {
  onCancel(): void;
  allowManualRenewal: boolean;
  policyId: string;
}

function RenewalTrackingModal({
  onCancel,
  allowManualRenewal,
  policyId,
}: PropsWithChildren<Props>) {
  // get latest policy to avoid dashboard staleness
  const { policy, loading: policyLoading } = useGetPolicy(policyId);

  const { policyAutoRenew, loading: policyAutoRenewLoading } =
    useGetPolicyAutoRenew(policyId);

  const [toggleState, setToggleState] = useState<boolean>(false);
  const allowAutoRenewal = policyAutoRenew?.autoRenewable;
  const isAutoRenewTooLate = !isPolicyWithinAutoRenewalDeadline(policy!);
  const isUKA = policy?.product.code === 'UKA';

  const { get } = useStrings();

  const {
    update,
    loading: autoRenewalOptInLoading,
    error,
  } = useUpdateAutoRenewalTrackingOptIn();

  useEffect(() => {
    if (error) {
      setToggleState((prevToggleState) => !prevToggleState);
      return;
    }
    if (policyAutoRenew) {
      const optInStatus: boolean = policyAutoRenew.autoRenewalOptIn;
      setToggleState(optInStatus);
    }
  }, [error, policyAutoRenew]);

  async function onAutoRenewalChange(event: ChangeEvent<HTMLInputElement>) {
    const newToggleState = event.target.checked;
    setToggleState(newToggleState);
    await update({
      policyId: policy!.id,
      optIn: newToggleState,
    });
  }

  return (
    <Modal
      title={get('pages.product.dashboard.modal.my_renewal.title')}
      className="RenewalModal"
      onClose={onCancel}
      dataTestId="renewal-modal"
      loading={policyAutoRenewLoading || policyLoading}
      actions={
        <>
          <Button
            variant="secondary"
            onClick={onCancel}
            testId="renewal_modal_done"
          >
            <String id="commons.done" />
          </Button>
        </>
      }
    >
      <>
        <section data-testid="auto-renewal-section">
          <h4>
            {' '}
            <String id="pages.product.dashboard.modal.renewal.auto_renewal.heading" />{' '}
          </h4>

          {!allowAutoRenewal ? (
            <String
              id={`pages.product.dashboard.modal.renewal.auto_renewal.message.unavailable.${
                isUKA ? 'uka' : 'std'
              }`}
            />
          ) : (
            <>
              <label>
                <String id="pages.product.dashboard.modal.renewal.auto_renewal.toggle.label" />{' '}
              </label>
              <Toggle
                id="autoRenewalToggle"
                checked={toggleState}
                onChange={(event) => void onAutoRenewalChange(event)}
                className="RenewalModal-toggle"
                disabled={
                  autoRenewalOptInLoading ||
                  policyAutoRenewLoading ||
                  policyLoading ||
                  isAutoRenewTooLate
                }
                data-testid="auto-renewal-toggle"
              />
            </>
          )}

          {allowAutoRenewal && !isAutoRenewTooLate && (
            <p className="RenewalModal-optIn-message">
              <String
                id={`pages.product.dashboard.modal.renewal.auto_renewal.toggle.message.${
                  toggleState ? 'optIn' : 'optOut'
                }`}
              />
            </p>
          )}

          {allowAutoRenewal && isAutoRenewTooLate && (
            <p className="RenewalModal-optIn-message">
              <String id="pages.product.dashboard.modal.renewal.auto_renewal.toggle.message.optIn.late" />{' '}
            </p>
          )}
        </section>

        {(!allowAutoRenewal || !toggleState) && (
          <section data-testid="manual-renewal-section">
            <h4>
              <String id="pages.product.dashboard.modal.renewal.manual_renewal.heading" />
            </h4>
            <String
              id={`pages.product.dashboard.modal.renewal.manual_renewal.${
                !allowManualRenewal ? 'disabled' : 'enabled'
              }.message`}
            />
            <LinkButton
              testId="renew-linkbutton"
              to={`/product/${policy?.product.id}/quote`}
              query={{
                renewalOf: createKey(policy?.id, policy?.version),
              }}
              variant={allowManualRenewal ? 'activated' : 'secondary'}
              disabled={!allowManualRenewal}
              handleClick={onCancel}
              className="RenewalModal-renew-button"
            >
              <String id="pages.product.dashboard.modal.renewal.button" />
            </LinkButton>
          </section>
        )}
      </>
    </Modal>
  );
}

export default RenewalTrackingModal;
