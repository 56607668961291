import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useExpireQuote,
  useQuote,
  useGetPolicyVersion,
} from '../../../../hooks';
import Loader from '../../../../components/Loader/Loader';
import Page from '../../../../components/Page/Page';
import {
  Centered,
  Flex3Cols,
  FlexRow,
} from '../../../../components/Layout/Layout';
import String from '../../../../components/String/String';
import LinkButton from '../../../../components/LinkButton/LinkButton';
import _ from 'lodash';
import { updatedDiff } from 'deep-object-diff';
import './ReviewMtaPage.scss';
import { formatValue } from '../../../../utils/quoteUtils';
import { useLocale } from '../../../../providers/LocaleProvider';
import EventManager from '../../../../utils/EventManager';
import {
  getLabelTextFromKeyArray,
  getTranslationIdFromKeyArray,
  getTranslationIdFromValue,
} from '../../../../utils/stringUtils';
import { QuoteType } from '../../../../providers/TrackingProvider';
import DateFormat from 'components/Date/Date';
import { getProductLocale } from 'utils/productUtils';

const debug = require('debug')('customer-web:mta');

const flatten = (obj: any, roots: any[] = [], sep: string = '.'): {} => {
  return (
    Object
      // find props of given object
      .keys(obj)
      // return an object by iterating props
      .reduce(
        (memo, prop) =>
          Object.assign(
            // create a new object
            {},
            // include previously returned object
            memo,
            Object.prototype.toString.call(obj[prop]) === '[object Object]'
              ? // keep working if value is an object
                flatten(obj[prop], roots.concat([prop]))
              : // include current prop and value and prefix prop with the roots
                { [roots.concat([prop]).join(sep)]: obj[prop] }
          ),
        {}
      )
  );
};

const HIDDEN_DATASHEET_FIELDS = ['policy.activeDateTime'];

interface ChangedFieldValue {
  value: string;
  formattedValue: string;
}

interface ChangedField {
  keys: string[];
  original: ChangedFieldValue;
  changed: ChangedFieldValue;
}

function ReviewMtaPage() {
  const { id: productId, quoteId } = useParams<{
    id: string;
    quoteId: string;
  }>();
  const { lang } = useLocale();
  const { quote, loading: loadingQuote } = useQuote(quoteId, true);
  const product = quote?.product;

  const { policy, loading: loadingPolicy } = useGetPolicyVersion(
    quote?.mtaOf?.id ?? '',
    quote?.mtaOf?.version ?? '',
    { skip: !quote?.mtaOf }
  );

  const loading = loadingQuote || loadingPolicy;

  const changedFields = useMemo(() => {
    debug('useMemo()', quote, policy, product);
    if (quote && policy && product) {
      const { datasheet: originalDatasheet } = policy;
      const { datasheet: newDatasheet } = quote;

      const diff = updatedDiff(originalDatasheet, newDatasheet);
      const original = updatedDiff(newDatasheet, originalDatasheet);

      const flat = flatten(diff);
      const result: ChangedField[] = [];
      const { schema, currency } = product;
      Object.keys(flat).forEach((key) => {
        if (HIDDEN_DATASHEET_FIELDS.includes(key)) {
          return;
        }

        const originalValue = _.get(original, key);
        const formattedOriginal = formatValue(
          originalValue,
          schema,
          key,
          lang,
          currency
        );

        const changedValue = _.get(diff, key);
        const formattedDiff = formatValue(
          changedValue,
          schema,
          key,
          lang,
          currency
        );

        result.push({
          keys: [...key.split('.')],
          original: { value: originalValue, formattedValue: formattedOriginal },
          changed: { value: changedValue, formattedValue: formattedDiff },
        });
      });

      return result;
    }
    //eslint-disable-next-line
  }, [policy, quote, product]);

  useExpireQuote(true);
  debug('Changed Fields', changedFields);

  const getPageValue = (value: ChangedFieldValue): JSX.Element =>
    value.value && typeof value.value === 'string' ? (
      <String
        id={getTranslationIdFromValue(value.value, 'pages.review_mta.choice.')}
        defaultValue={value.formattedValue}
      />
    ) : (
      <>{value.value}</>
    );

  function PageBody() {
    if (loading) {
      return (
        <Centered>
          <Loader />
        </Centered>
      );
    }

    const trackMtaChangesConfirmed = () => {
      EventManager.track({
        productId: productId,
        event: 'MtaChangesConfirmed',
        quoteType: QuoteType.MTA,
        productCode: 'UK2.0',
      });
    };

    return (
      <>
        <h2 className="ReviewMta-intro">
          <String id="pages.review_mta.title" />
        </h2>
        <div key={quoteId} className="ReviewMta-policy">
          <input type="hidden" value={policy?.id} id="policy" />
          {changedFields &&
            changedFields.length > 0 &&
            changedFields.map((data: ChangedField, index: number) => {
              return (
                <FlexRow className="ReviewMta-details" key={index}>
                  <Flex3Cols>
                    <p className="field">
                      <String
                        id={getTranslationIdFromKeyArray(
                          data.keys,
                          'pages.review_mta.'
                        )}
                        defaultValue={getLabelTextFromKeyArray(data.keys)}
                      />
                    </p>
                  </Flex3Cols>
                  <Flex3Cols>
                    <p className="originalValue">
                      {getPageValue(data.original)}
                    </p>
                  </Flex3Cols>
                  <Flex3Cols>
                    <p className="changedValue">{getPageValue(data.changed)}</p>
                  </Flex3Cols>
                </FlexRow>
              );
            })}
          {quote?.datasheet.policy?.activeDateTime && (
            <h4 className="ReviewMta-intro">
              <String
                id="pages.review_mta.active_date_time"
                values={{
                  date: (
                    <DateFormat
                      date={new Date(quote.datasheet.policy.activeDateTime)}
                      timeZone={product.timezone}
                      locale={getProductLocale(product)}
                      showTime
                    />
                  ),
                }}
              />
            </h4>
          )}
          <h4 className="ReviewMta-intro">
            <String id="pages.review_mta.not_right" />
          </h4>
          <FlexRow className="ReviewMta-action">
            <LinkButton
              testId="confirm-linkbutton"
              className="ReviewMta-button"
              to={`/product/${productId}/quote/${quoteId}/payment`}
              variant="primary"
              handleClick={() => {
                trackMtaChangesConfirmed();
              }}
            >
              <String id="pages.review_mta.confirm" />
            </LinkButton>
          </FlexRow>
        </div>
      </>
    );
  }

  return (
    <Page name="ReviewMta" slug="ReviewMta" padded={false}>
      <PageBody />
    </Page>
  );
}

export default ReviewMtaPage;
