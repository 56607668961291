/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components/macro';
import { ButtonProps } from './Button';
import { useView } from '../../utils/themeUtils';
import UnstyledButton from './UnstyledButton';

export type ButtonVariants =
  | 'regular'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'activated';

interface ButtonInputProps extends ButtonProps {
  variant: ButtonVariants;
}

export const StyledButton = styled(UnstyledButton)<ButtonInputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.disabled ? '' : '')};
  flex: 1;
  padding: 1rem 1rem;
  letter-spacing: normal;
  font-weight: bold;
  font-size: 16px;
  border-radius: ${7 / 16}rem;
  position: relative;
  border: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background: #f2f2f2;
  color: #2e2e2e;
  height: 50px;
  ${useView((theme, { variant }) => theme.buttons[variant])};

  &:active {
    background: #dcdcdc;
  }

  &[disabled] {
    background: #f2f2f2;
    color: #bbbbbb;
  }

  img {
    margin-right: 0.5rem;
  }

  .Button-loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
