import React, { PropsWithChildren, useState } from 'react';
import './AccordionSection.scss';
import classnames from 'classnames';

interface Props {
  title: string;
}

function AccordionSection({ title, children }: PropsWithChildren<Props>) {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);

  return (
    <div className="Accordion">
      <div
        className="Accordion-wrap"
        data-testid="Accordion-wrap"
        onClick={() => {
          setAccordionIsOpen(!accordionIsOpen);
        }}
      >
        <button
          className={classnames('Accordion-button', {
            open: accordionIsOpen,
          })}
        >
          <span></span>
          <span></span>
        </button>

        <h2 className="Accordion-title" data-testid="Accordion-title">
          {title}
        </h2>
      </div>
      <div
        className={classnames('Accordion-content', {
          'Accordion-content-open': accordionIsOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default AccordionSection;
