import React, { PropsWithChildren } from 'react';

import './Cross.scss';

interface Props {
  title?: string;
}

function Cross({ children }: PropsWithChildren<Props>) {
  return (
    <div className="CrossContent">
      <span className="Cross"></span>
      {children}
    </div>
  );
}

export default Cross;

export function CrossLabel({ children }: PropsWithChildren<Props>) {
  return <span className="CrossContent-label">{children}</span>;
}
