import React, { ChangeEvent, PropsWithChildren, useState } from 'react';
import Button from 'components/Buttons/Button';
import String from '../../String/String';
import EventManager, { EventType } from '../../../utils/EventManager';

import { useStrings } from 'hooks';
import Modal from '../Modal';
import { getMtaPolicyId } from 'utils/urlUtils';
import { useGetPolicy } from 'hooks/useGetPolicy';
import './VehicleSearchModal.scss';
import {
  IVehicle,
  useGetVehicleDetailsMutation,
} from 'shared/graphql/api/types';
import Loader from '../../Loader/Loader';
import { QuoteType } from '../../../providers/TrackingProvider';

interface Props {
  onCancel(): void;
  setVehicle(vehicle: IVehicle): void;
}

function VehicleSearchModal({
  onCancel,
  setVehicle,
}: PropsWithChildren<Props>) {
  const { get } = useStrings();
  const policyId = getMtaPolicyId(window.location.pathname) ?? '';
  const { policy } = useGetPolicy(policyId);
  const [getVehicleDetails] = useGetVehicleDetailsMutation();

  const [vehicleReg, setVehicleReg] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchHandler = async () => {
    EventManager.track({
      event: EventType.QuestionAnswered,
      productId: policy?.product.id ?? '',
      quoteType: QuoteType.MTA,
      questionId: 'MTA Edit vehicle - confirm',
    });

    setLoading(true);

    const variables = { input: { registrationNumber: vehicleReg } };

    const { data, errors } = await getVehicleDetails({ variables } as any);

    setLoading(false);

    if (errors?.length || data?.response.error) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
      setVehicle(data?.response.data);
    }
  };

  const cancelHandler = () => {
    EventManager.track({
      event: EventType.QuestionAnswered,
      productId: policy?.product.id ?? '',
      quoteType: QuoteType.MTA,
      questionId: 'MTA Edit vehicle - cancel',
    });
    onCancel();
  };

  return (
    <Modal
      title={get('pages.product.mta.modal.edit_vehicle.title')}
      className="MtaSelfServeModal"
      data-testid="mta-self-serve-modal"
      onClose={onCancel}
      actions={
        <>
          <input
            type="text"
            data-testid="mta_search_vehicle_input"
            className={`VehicleSearch-input ${
              hasErrors ? 'VehicleSearch-input-error' : ''
            }`}
            value={vehicleReg}
            title="VehicleSearchInput"
            onKeyDown={() => setHasErrors(false)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setVehicleReg(e.target.value);
            }}
          />

          <span className="VehicleSearch-error">
            {hasErrors && (
              <String id="pages.product.mta.modal.edit_vehicle.error" />
            )}
          </span>

          <Button
            onClick={searchHandler}
            variant="primary"
            disabled={vehicleReg.trim().length < 1 || loading}
            testId="mta_vehicle_change_confirm"
          >
            {loading ? (
              <Loader />
            ) : (
              <String id="pages.product.mta.modal.edit_vehicle.confirm" />
            )}
          </Button>

          <Button
            variant="secondary"
            onClick={cancelHandler}
            testId="mta_vehicle_change_cancel"
          >
            <String id="pages.product.mta.modal.edit_vehicle.cancel" />
          </Button>
        </>
      }
      actionsFlow="row"
    >
      <p>
        <String id="pages.product.mta.modal.edit_vehicle.message" />
      </p>
    </Modal>
  );
}

export default VehicleSearchModal;
