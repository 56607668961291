import React, { useState } from 'react';
import './InfoBox.scss';
import { CheckmarkIcon, AlertIcon, InfoIcon } from 'components/Icons';

interface Props {
  type: InfoBoxType;
  text: string | JSX.Element;
  title: string | JSX.Element;
  testId: string;
  allowClose?: boolean;
  onClose?(): void;
}

export enum InfoBoxType {
  Success = 'success',
  Warn = 'warn',
  Error = 'error',
  Info = 'info',
}

function InfoBox({
  type,
  text,
  title,
  testId,
  allowClose = true,
  onClose,
}: Props) {
  const [show, setShow] = useState(true);

  const renderIcon = () => {
    switch (type) {
      case InfoBoxType.Success:
        return <CheckmarkIcon />;
      case InfoBoxType.Error:
        return <AlertIcon />;
      case InfoBoxType.Warn:
        return <InfoIcon />;
      case InfoBoxType.Info:
        return <InfoIcon />;
    }
  };

  return (
    <>
      {show && (
        <div className={`InfoBox InfoBox-${type}`} data-testid={testId}>
          <div className={`InfoBox-polygon InfoBox-polygon-${type}`}>
            <div className="InfoBox-icon">{renderIcon()}</div>
          </div>
          <div className="InfoBox-heading-row">
            <h3 className="InfoBox-title">{title}</h3>
            <p className={`InfoBox-message InfoBox-message-${type}`}>{text}</p>

            {allowClose && (
              <button
                className="InfoBox-close"
                onClick={() => {
                  setShow(false);
                  onClose && onClose();
                }}
                data-testid="infobox-close"
              >
                &#x2715;
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InfoBox;
