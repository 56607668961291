import _ from 'lodash';
import DOMPurify from 'dompurify';

export function toTitleCase(value: string) {
  return _.startCase(_.camelCase(value));
}

export function sanitize(string: string) {
  return DOMPurify.sanitize(string);
}

export function getLabelTextFromKeyArray(keys: string[]) {
  return toTitleCase(getStringFromKeyArray(keys));
}

export const getTranslationIdFromKeyArray = (
  keys: string[],
  prefix: string = ''
) => {
  return `${prefix}${getStringFromKeyArray(keys, '.')}`;
};

export const getTranslationIdFromValue = (
  value: string,
  prefix: string = ''
) => {
  return `${prefix}${value.toLowerCase()}`;
};

const getStringFromKeyArray = (
  keys: string[],
  separator: string = ' '
): string => {
  //remove final s from array keys (i.e vehicles becomes vehicle)
  //concat all the keys together to a human readable label
  const isArray = !Number.isNaN(Number(keys[1]));
  return isArray
    ? keys[0]
        .substr(0, keys[0].length - 1)
        .concat(separator, [...keys].splice(2, keys.length - 1).join(separator))
    : keys.join(separator);
};
