import React from 'react';

export default function ThirtyDaysIcon() {
  return (
    <svg
      data-testid="thirty-day-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="52.961"
      height="52.96"
      viewBox="0 0 52.961 52.96"
    >
      <path
        fill="#1fc4d6"
        d="M406.062,257.037a26.48,26.48,0,1,0,26.48,26.48A26.51,26.51,0,0,0,406.062,257.037Zm0,50.013a23.533,23.533,0,1,1,23.532-23.533A23.56,23.56,0,0,1,406.062,307.05Zm-3.618-19.441a1.913,1.913,0,0,0,.556-1.477,1.663,1.663,0,0,0-1.032-1.7,5.221,5.221,0,0,0-1.851-.241v-1.821a4.807,4.807,0,0,0,1.726-.241,1.489,1.489,0,0,0,.845-1.518,1.7,1.7,0,0,0-.432-1.2,1.58,1.58,0,0,0-1.215-.464,1.538,1.538,0,0,0-1.323.571,2.435,2.435,0,0,0-.4,1.527h-2.375a6.4,6.4,0,0,1,.33-1.83,4.135,4.135,0,0,1,.981-1.4,3.418,3.418,0,0,1,1.186-.7,5.118,5.118,0,0,1,1.685-.241,4.485,4.485,0,0,1,2.992.96,3.2,3.2,0,0,1,1.137,2.575,2.844,2.844,0,0,1-.679,1.928,2.315,2.315,0,0,1-.892.669,1.838,1.838,0,0,1,1,.6,3.2,3.2,0,0,1,.973,2.464,4.156,4.156,0,0,1-1.136,2.888,4.335,4.335,0,0,1-3.367,1.245,4.07,4.07,0,0,1-3.816-1.794,5.33,5.33,0,0,1-.625-2.5h2.5a2.92,2.92,0,0,0,.25,1.286,1.706,1.706,0,0,0,1.683.937A1.867,1.867,0,0,0,402.444,287.609Zm8.983,2.584a3.662,3.662,0,0,0,3.468-1.732,12.763,12.763,0,0,0,0-9.881,4.325,4.325,0,0,0-6.936,0,12.751,12.751,0,0,0,0,9.881A3.66,3.66,0,0,0,411.427,290.193Zm-1.5-10.131a1.462,1.462,0,0,1,1.5-1.125,1.44,1.44,0,0,1,1.486,1.125,12.491,12.491,0,0,1,.352,3.463,12.632,12.632,0,0,1-.352,3.414,1.452,1.452,0,0,1-1.486,1.183,1.473,1.473,0,0,1-1.5-1.183,12.185,12.185,0,0,1-.366-3.414A12.049,12.049,0,0,1,409.927,280.062Zm12.979,3.455a16.847,16.847,0,0,1-32.076,7.2,1.228,1.228,0,0,1,2.22-1.051,14.413,14.413,0,1,0-.958-9.536l.891-.891a1.228,1.228,0,0,1,1.737,1.737l-3.4,3.4a1.1,1.1,0,0,1-.761.34,1.052,1.052,0,0,1-.109.022,1.02,1.02,0,0,1-.11-.022,1.1,1.1,0,0,1-.761-.34l-3.4-3.4a1.228,1.228,0,0,1,1.737-1.737l1.55,1.551a16.835,16.835,0,0,1,33.449,2.725Z"
        transform="translate(-379.581 -257.037)"
      />
    </svg>
  );
}
