import React, { Fragment, memo, ReactElement, useMemo } from 'react';
import { useStrings } from 'hooks';
import ReactMarkdown from 'react-markdown';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  defaultValue?: string;
  values?: Record<string, string | JSX.Element | number>;
}

export function renderToString(
  value: string | Array<string | ReactElement> | ReactElement
): ReactElement | Array<ReactElement> {
  if (typeof value === 'string') {
    return (
      <ReactMarkdown
        children={value}
        linkTarget="_blank"
        components={{ p: Fragment }}
      />
    );
  }

  if (Array.isArray(value)) {
    return value.map((v, index) => {
      if (typeof v === 'string') {
        return (
          <ReactMarkdown
            key={index}
            children={v}
            linkTarget="_blank"
            components={{ p: Fragment }}
          />
        );
      }

      return <Fragment key={index}>{v}</Fragment>;
    });
  }

  if (React.isValidElement(value)) {
    return <Fragment>{value}</Fragment>;
  }

  return <Fragment />;
}

function String({ id, values, defaultValue }: ButtonProps): ReactElement {
  const { get } = useStrings();
  const string = useMemo<ReactElement | ReactElement[]>(() => {
    const value = get(id, values, defaultValue);

    if (typeof value === 'object' && value !== null) {
      return Object.entries(value).map(([key, val], index) => {
        if (typeof val === 'string' || React.isValidElement(val)) {
          return <Fragment key={index}>{val}</Fragment>;
        }
        return (
          <Fragment key={index}>
            <>{val}</>
          </Fragment>
        );
      });
    }

    return renderToString(value);
  }, [get, id, values, defaultValue]);

  return <>{string}</>;
}

export default memo(String);
