import configuration from 'configuration';
import { IQuotePackageValuation } from 'types/quote';
import { ILineItem } from 'shared/graphql/api/types';

export function getFeeIdFromType(type: string): string {
  return type.replace(/_(paid|unpaid|waived)$/, '');
}

export function getFeesLineItems(
  valuation?: IQuotePackageValuation | null
): ILineItem[] {
  if (!valuation) return [];

  const feesLineItems = valuation?.lineItems?.breakdown?.find(
    (item) => item.id === 'all-fees'
  )?.breakdown;

  return configuration.enableLineItems && feesLineItems
    ? feesLineItems
    : valuation?.addons
        ?.filter((addon) => addon.isSelected === true)
        .map(({ type, total, name }) => ({
          id: getFeeIdFromType(type),
          type: type,
          value: total,
          name: name,
        })) || [];
}
