import React from 'react';
import { ICurrency, ILineItem } from 'shared/graphql/api/types';
import { useStrings } from 'hooks';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { QuoteBreakdown } from 'utils/quoteUtils';
import BreakdownDetails from './BreakdownDetails';

interface Props {
  currency: ICurrency;
  breakdown: QuoteBreakdown;
  hasAllAddOns: boolean;
  protectedNcd: boolean;
  showIpt: boolean;
  fees: ILineItem[];
}

function QuotePackageOverview({
  currency,
  breakdown,
  hasAllAddOns,
  protectedNcd,
  showIpt,
  fees,
}: Props) {
  const { get } = useStrings();

  return (
    <div className="QuoteOption-accordion">
      <AccordionSection title={get('pages.quote.policy_breakdown')}>
        <BreakdownDetails
          currency={currency}
          breakdown={breakdown}
          hasAllAddOns={hasAllAddOns}
          protectedNcd={protectedNcd}
          showIpt={showIpt}
          fees={fees}
        />
      </AccordionSection>
    </div>
  );
}

export default QuotePackageOverview;
