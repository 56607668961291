import './Table.scss';
import DateFormat from '../Date/Date';

export enum TABLE_ORIENTATION {
  Vertical = 'column',
  Horizontal = 'row',
}

export interface TableDataItem {
  heading: string;
  content: Array<any>;
}

interface Props {
  orientation?: TABLE_ORIENTATION;
  timezone?: string;
  locale?: string;
  data: Array<TableDataItem>;
}

function Table({
  orientation = TABLE_ORIENTATION.Vertical,
  timezone,
  locale,
  data,
}: Props) {
  return (
    <div className="Table-wrapper">
      {data.map((item, idx) => {
        return (
          <div
            className={`Table-${orientation} Table-item`}
            key={`item-${idx}`}
          >
            <div className={`Table-header-${orientation} Table-header`}>
              {item.heading}
            </div>
            {item.content.map((value, i) => {
              return (
                <div
                  className={`Table-content-${orientation} Table-content`}
                  key={`content-${i}`}
                >
                  {value && !isNaN(Date.parse(value)) ? (
                    <DateFormat
                      date={new Date(value)}
                      timeZone={timezone}
                      locale={locale}
                    />
                  ) : (
                    value
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Table;
