import { ApolloError } from '@apollo/client';
import { useGetProductOptionsSearchQuery } from 'shared/graphql/api/types';

export interface ProductOption {
  productId: string;
  name: string;
  code: string;
  identities: { connection: string; provider: string }[];
}

interface UseProductsOptionsSearch {
  loading: boolean;
  products: ProductOption[];
  error: ApolloError | undefined;
}

export const useProductsOptionsSearch = (
  search: string
): UseProductsOptionsSearch => {
  const { loading, data, error } = useGetProductOptionsSearchQuery({
    variables: {
      search,
    },
  });

  return {
    loading,
    products: data?.productOptionsSearch || [],
    error,
  };
};
