import React from 'react';
import Send from 'components/Icons/Send/Send';
import SendDisabled from 'components/Icons/Send/SendDisabled';

import './SendButton.scss';

export interface Props {
  onClick?(): void;
  disabled?: boolean;
  tabIndex?: number;
  dataTestId?: string;
}

function SendButton({ onClick, disabled, tabIndex, dataTestId }: Props) {
  return (
    <button
      tabIndex={tabIndex}
      className="SendButton"
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {disabled ? <SendDisabled /> : <Send />}
    </button>
  );
}

export default SendButton;
