import React, { ChangeEvent, useMemo, useState } from 'react';
import { get } from 'lodash';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { TextQuestion, TextQuestionTemplateEnum } from 'shared/types/question';

import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';
import { getSchemaConfiguration } from 'utils/schemaUtils';
import { useRef } from 'hooks';
import SendButton from 'components/Buttons/Send/SendButton';
import { scrollToBottom } from 'components/Chatbot/ChatRoller/ChatRoller';
import config from 'configuration';

import 'react-phone-number-input/style.css';
import './TextInput.scss';
import { sanitize } from '../../../../utils/stringUtils';

interface Props {
  question: TextQuestion;
  onAnswer: AnswerQuestion;
  placeholder?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

function TextInput({ question, onAnswer, placeholder, scrollRef }: Props) {
  const { template, answer_path, name } = question;
  const [element, ref] = useRef<HTMLInputElement>();

  const {
    $,
    productHistory: { current: product },
  } = usePreQuote();

  const [text, setText] = useState(() => {
    if (!answer_path) {
      return '';
    }

    return get({ $ }, answer_path) ?? '';
  });

  const configuration = useMemo(
    () => getSchemaConfiguration(question.answer_path, product.schema),
    [question.answer_path, product.schema]
  );

  function getValue() {
    switch (template) {
      case TextQuestionTemplateEnum.numeric:
        return Number(text);
      case TextQuestionTemplateEnum.alphanumeric:
      case TextQuestionTemplateEnum.alpha:
      case TextQuestionTemplateEnum.phone:
      default:
        return sanitize(text);
    }
  }

  const valid = useMemo(() => {
    // Fail validation if ref is not set yet
    if (!element) {
      return false;
    }

    // Fail validation if ValidityState is not valid
    if (!element.validity.valid) {
      return false;
    }

    // Fail validation if nothing has been entered
    if (!text) {
      return false;
    }

    // Pass validation if type and input are numbers
    if (template === TextQuestionTemplateEnum.numeric && /[^0-9]/.test(text)) {
      return false;
    }

    // Pass validation if there is no configuration
    if (!configuration) {
      return true;
    }

    // Fail validation if type is string and text doesnt match the pattern
    if (
      (configuration.type === 'string' || configuration.type === 'integer') &&
      configuration.pattern &&
      !text.match(configuration.pattern)
    ) {
      return false;
    }

    // Otherwise true
    return true;
  }, [element, configuration, text, template]);

  function onSend() {
    const value = getValue();
    onAnswer(value.toString(), value);
    setText('');
  }

  function onChange({ target }: ChangeEvent<HTMLInputElement>) {
    const { value } = target;

    setText(value);
  }

  function pattern() {
    if (template === TextQuestionTemplateEnum.numeric) {
      return '[0-9]*';
    }

    if (configuration?.type === 'string' && configuration.pattern !== '') {
      return configuration.pattern;
    }

    return undefined;
  }

  const type =
    template === TextQuestionTemplateEnum.email
      ? 'email'
      : template === TextQuestionTemplateEnum.phone
      ? 'tel'
      : 'text';

  if (template === TextQuestionTemplateEnum.phone) {
    return (
      <div className="TextInput">
        <PhoneInput
          international
          defaultCountry={product.location.country}
          value={text}
          onChange={setText}
          aria-label="text-input"
          className="TextInput-input"
          data-testid={`${type}-input`}
        />
        <SendButton
          tabIndex={1}
          onClick={onSend}
          disabled={!isValidPhoneNumber(text || '')}
          dataTestId={`${type}-input-button`}
        />
      </div>
    );
  }

  return (
    <div className="TextInput">
      <input
        ref={ref}
        type={type}
        aria-label="text-input"
        autoFocus
        name={name}
        pattern={pattern()}
        className="TextInput-input"
        data-testid={`${type}-input`}
        autoComplete={config.enableAutoComplete ? 'on' : 'off'}
        value={text}
        onChange={onChange}
        onFocus={() =>
          scrollRef && setTimeout(() => scrollToBottom(scrollRef.current), 500)
        }
        placeholder={placeholder ? placeholder : ''}
      />
      <SendButton
        tabIndex={1}
        onClick={onSend}
        disabled={!valid}
        dataTestId={`${type}-input-button`}
      />
    </div>
  );
}

export default TextInput;
