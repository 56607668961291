import React, { useCallback, useEffect, useState } from 'react';
import * as FirebaseApp from 'firebase/app';
import * as FirebaseStore from 'firebase/firestore';

import type { Feature } from '@inshur/nowucit/features/Feature';
import FeaturesFirebaseClient from '@inshur/nowucit/features/FeaturesFirebaseClient';
import FeaturesProvider from '@inshur/nowucit/features/FeaturesProvider';
import FeaturesContext from '@inshur/nowucit/features/FeaturesContext';

import FlagsEditor from '../components/FlagsEditor/FlagsEditor';
import configuration from 'configuration';
import { getLocalFlags } from 'utils/featureFlagUtils';
import { useQuery } from '../hooks';

const client = FeaturesFirebaseClient.fromConfig({
  collectionName: configuration.firebase.featureFlags.collection,
  options: {
    apiKey: configuration.firebase.key.apiKey,
    authDomain: configuration.firebase.key.authDomain,
    projectId: configuration.firebase.key.projectId,
    storageBucket: configuration.firebase.key.storageBucket,
    messagingSenderId: configuration.firebase.key.messagingSenderId,
    appId: configuration.firebase.key.appId,
    measurementId: configuration.firebase.key.measurementId,
  },
  appMethods: FirebaseApp,
  storeMethods: FirebaseStore,
});

export const FlagsProvider = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  const devEnabled = !!configuration.enableDevTools;
  const [localFlags, setLocalFlags] = useState<Array<Feature>>([]);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const { features } = useQuery<{ features: Feature[] }>();

  const onKeyDown = useCallback(({ key, shiftKey, ctrlKey }: KeyboardEvent) => {
    if (shiftKey && ctrlKey && key === 'F') {
      setShowEditor((v) => !v);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    const overrides = getLocalFlags(
      configuration.firebase.featureFlags.collection
    );

    if (devEnabled && typeof overrides === 'undefined' && features) {
      setLocalFlags(features);
    }
  }, [devEnabled, features]);

  return (
    <>
      <FeaturesProvider client={client} overrides={localFlags}>
        <FeaturesContext.Consumer>
          {({ features }) => (
            <>
              {children}
              {devEnabled && showEditor && (
                <FlagsEditor
                  flags={features.map((feature) => ({
                    type: 'feature',
                    name: feature.name,
                    value: feature.value,
                  }))}
                  onToggle={setLocalFlags}
                />
              )}
            </>
          )}
        </FeaturesContext.Consumer>
      </FeaturesProvider>
    </>
  );
};
