import React from 'react';
import { useField } from 'formik';
import Input from 'components/Form/Input/Input';
import classnames from 'classnames';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

function Field({ name, className, ...props }: Props) {
  const [field, { error, touched }] = useField(name);

  return (
    <Input
      className={classnames({ error: error && touched }, className)}
      {...field}
      {...props}
    />
  );
}

export default Field;
