import React from 'react';
import classnames from 'classnames';

import String from 'components/String/String';
import { Flex1Col } from 'components/Layout/Layout';
import './RenewalBanner.scss';

interface Props {
  sticky?: boolean;
}

export function RenewalBanner({ sticky = true }: Props) {
  return (
    <Flex1Col className={classnames({ sticky: sticky })}>
      <div className="RenewalBanner" data-testid="RenewalBanner">
        <strong>
          <String
            id="pages.product.renewal_banner.text"
            className="RenewalBanner-text"
          />
        </strong>
      </div>
    </Flex1Col>
  );
}
