import * as _ from 'lodash';
import { parseISO } from 'date-fns';
import React from 'react';

import { IProduct } from 'types/product';
import { toTitleCase } from 'utils/stringUtils';
import { RulesEngine } from 'utils/rulesEngine';

import {
  ComponentType,
  IComponent,
  Content,
  IValues,
  ConditionalType,
} from '../Schema';
import Currency from '../../Currency/Currency';
import DateFormat from '../../Date/Date';
import String from '../../String/String';

export function getValue(component: IComponent, values: IValues) {
  const { content } = component;
  const type = getType(component, values);
  if (type === null) return null;

  try {
    if ('logic' in content) {
      const { logic, _false, _true } = content;
      content.text = RulesEngine(logic, values) ? _true : _false;
    }

    if ('function' in content && content.function) {
      let value = calcValue(type, content, values);

      if (value === null) return null;

      return getFunctionValue({
        transform: content.function,
        value,
      });
    }

    return calcValue(type, content, values);
  } catch (e) {
    console.log('Failed to render component: ', component.label);
    return null;
  }
}

export function getType(
  component: IComponent,
  values: IValues
): ComponentType | null {
  const { type } = component;

  if (typeof type !== 'object') return type;

  if (type === null) return null;

  const { logic, _false, _true } = type;

  let dog = RulesEngine(logic, values) ? _true : _false;
  return dog;
}

export function calcValue(
  type: ComponentType | string | null,
  content: Content | ConditionalType,
  values: IValues
) {
  let { text } = content as Content;

  if (type === null || text === null) return null;

  if (type === 'translation') {
    return text;
  }

  if (type === 'enum_translation') {
    return _.get(values, text as string);
  }

  return Array.isArray(text)
    ? text.map((s) => _.get(values, s))
    : _.get(values, text!);
}

export function getFunctionValue({
  transform,
  value,
}: {
  transform: string;
  value: string | string[];
}) {
  let result = '';

  if (Array.isArray(value) && transform === 'join') {
    result = value.join(' ');
  } else {
    result = value as string;
  }

  if (transform === 'toTitleCase') {
    return toTitleCase(result);
  }

  if (transform === 'getDurationText') {
    return `enums.policy_duration.${result}`;
  }

  if (transform === 'OWNING_TYPES') {
    return OWNING_TYPES[result];
  }

  if (transform === 'BODY_TYPES') {
    return BODY_TYPES[result];
  }

  if (transform === 'TRANSMISSION_TYPES') {
    return TRANSMISSION_TYPES[result];
  }

  if (transform === 'MODIFICATION_TYPES') {
    return MODIFICATION_TYPES[result];
  }

  if (transform === 'GOODS_CARRIED') {
    return GOODS_CARRIED[result];
  }

  if (transform === 'VEHICLE_USE') {
    return VEHICLE_USE[result];
  }

  return result;
}

export const OWNING_TYPES: { [key: string]: string } = {
  OWN: 'pages.quote.overview_page.owning_types.own',
  OWNED: 'pages.quote.overview_page.owning_types.own',
  RENTED: 'pages.quote.overview_page.owning_types.rented',
  SPOUSE_PARTNER_COMPANY: 'pages.quote.overview_page.owning_types.spouse',
  LONG_TERM_LEASE: 'pages.quote.overview_page.owning_types.long_term_lease',
  SHORT_TERM_LEASE: 'pages.quote.overview_page.owning_types.short_term_lease',
  OTHER: 'pages.quote.overview_page.owning_types.other',
};

export const BODY_TYPES: { [key: string]: string } = {
  HATCHBACK: 'pages.quote.overview_page.body_types.hatchback',
  MPV: 'pages.quote.overview_page.body_types.mpv',
  SEDAN: 'pages.quote.overview_page.body_types.sedan',
  OTHER: 'pages.quote.overview_page.body_types.other',
};

export const TRANSMISSION_TYPES: { [key: string]: string } = {
  AUTOMATIC: 'pages.quote.overview_page.transmission_types.automatic',
  MANUAL: 'pages.quote.overview_page.transmission_types.manual',
  OTHER: 'pages.quote.overview_page.transmission_types.other',
};

export const GOODS_CARRIED: { [key: string]: string } = {
  PARCELS: 'pages.quote.overview_page.goods_carried.parcels',
  NON_PALLETISED_GOODS:
    'pages.quote.overview_page.goods_carried.non_palletised',
  FURNITURE: 'pages.quote.overview_page.goods_carried.furniture',
  FAST_FOOD: 'pages.quote.overview_page.goods_carried.fast_food',
  FAST_FOOD_AND_PARCELS:
    'pages.quote.overview_page.goods_carried.fast_food_parcels',
  PARCELS_NON_PALLETISED_GOODS_FURNITURE:
    'pages.quote.overview_page.goods_carried.parcels_non_palletised_furniture',
  HAZARDOUS_GOODS: 'pages.quote.overview_page.goods_carried.hazardous',
  SCRAP_METAL: 'pages.quote.overview_page.goods_carried.scrap_metal',
  OTHER: 'pages.quote.overview_page.goods_carried.other',
};

export const MODIFICATION_TYPES: { [key: string]: string } = {
  LIVERY: 'pages.quote.overview_page.modification_types.livery',
  STRUCTURAL_CHANGES: 'pages.quote.overview_page.modification_types.structural',
  SEAT_REMOVAL: 'pages.quote.overview_page.modification_types.seat_removal',
  RACKING: 'pages.quote.overview_page.modification_types.racking',
  LIVERY_SIGNWRITING:
    'pages.quote.overview_page.modification_types.livery_signwriting',
  LIVERY_SIGNWRITING_AND_RACKING:
    'pages.quote.overview_page.modification_types.livery_signwriting_racking',
  OTHER: 'pages.quote.overview_page.modification_types.other',
};

export const VEHICLE_USE: { [key: string]: string } = {
  COURIER: 'pages.quote.overview_page.vehicle_use.courier',
  FAST_FOOD: 'pages.quote.overview_page.vehicle_use.fast_food',
  COURIER_AND_FAST_FOOD:
    'pages.quote.overview_page.vehicle_use.courier_fast_food',
  CARRIAGE_OF_OWN_GOODS:
    'pages.quote.overview_page.vehicle_use.carriage_of_own_goods',
  OTHER: 'pages.quote.overview_page.vehicle_use.other',
};

export function format({
  type,
  value,
  product,
  values,
  locale,
  variables,
  translationKey,
}: {
  type: ComponentType;
  value: any;
  product: IProduct;
  values: IValues;
  locale?: string;
  translationKey: string;
  variables?: string | Record<string, string | number>;
}) {
  try {
    if (type === 'address') {
      return (
        <>
          {value?.addressLine1} {value?.addressLine2} {value?.city}{' '}
          {value?.county} {value?.postalCode}
        </>
      );
    }

    if (type === 'date') {
      return (
        <DateFormat
          date={parseISO(value)}
          locale={locale}
          timeZone={product?.timezone}
        />
      );
    }

    if (type === 'currency') {
      return <Currency currency={product?.currency} value={value} />;
    }

    if (type === 'enum_translation') {
      return <String id={value} />;
    }

    if (type === 'translation') {
      let vars: any;

      if (_.isObject(variables)) {
        vars = {};
        Object.entries(variables).forEach(
          ([k, v]) => (vars[k] = _.get(values, v))
        );
        return <String id={value.text} values={vars} />;
      }

      let translationValue = _.get(values, variables as string);

      return (
        <String
          id={`${translationKey}.${value}`}
          values={{ v: translationValue }}
        />
      );
    }
  } catch (e) {
    console.log('Failed to format value:', value);
    console.error(e);
  }

  return value;
}
