import React from 'react';
import {
  VehicleQuestion,
  VehicleQuestionTemplateEnum,
} from 'shared/types/question';
import { AnswerQuestion } from 'providers/QuoteProvider';

import VehicleMakeInput from './VehicleMakeInput/VehicleMakeInput';
import VehicleModelInput from './VehicleModelInput/VehicleModelInput';

interface Props {
  question: VehicleQuestion;
  onAnswer: AnswerQuestion;
  vehicles?: Record<string, string[]>;
}

function VehicleInput({ question, onAnswer, vehicles }: Props) {
  if (question.template === VehicleQuestionTemplateEnum.make) {
    return (
      <VehicleMakeInput
        question={question}
        onAnswer={onAnswer}
        vehicles={vehicles}
      />
    );
  }

  return (
    <VehicleModelInput
      question={question}
      onAnswer={onAnswer}
      vehicles={vehicles}
    />
  );
}

export default VehicleInput;
