import React, { useEffect, useMemo, useRef, useState } from 'react';
import configuration from 'configuration';
import {
  IDocumentTypeDataModelEnum,
  useGetQuoteDocumentsLazyQuery,
} from 'shared/graphql/api/types';
import { IQuote } from 'types/quote';
import LoadingModal from 'components/Modal/LoadingModal/LoadingModal';
import { useAuth } from 'providers/AuthProvider';
import UnstyledButton from 'components/Buttons/UnstyledButton';
import { FileHandlerMethod, fileHandlerMethod } from 'utils/fileUtils';
import InfoModal from '../Modal/InfoModal/InfoModal';
import { useCreateQuoteDocument, useStrings } from '../../hooks';
import { getDocumentPdf } from 'utils/documentUtils';

interface Props {
  quote: IQuote;
  name: string;
  typeId: string;
  buttonClassName?: string;
}

const FILE_HANDLER_METHOD = fileHandlerMethod();

interface Document {
  id: string;
  typeId: string;
  uri: string;
  documentType?:
    | {
        id: string;
        name: string;
        dataModel?: IDocumentTypeDataModelEnum | null | undefined;
        packageId?: string | null | undefined;
      }
    | null
    | undefined;
}

function QuoteDocument({
  quote,
  name,
  typeId,
  children,
  buttonClassName,
}: React.PropsWithChildren<Props>) {
  const [getDocs, { data: documents }] = useGetQuoteDocumentsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { create: createDocument, error: errorCreateDocument } =
    useCreateQuoteDocument();
  const interval = useRef<NodeJS.Timeout>();
  const { get } = useStrings();
  const timeout = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const { accessToken } = useAuth();
  const [document, setDocument] = useState<Document | undefined>(undefined);

  // Call to get the documents
  function getDocuments() {
    getDocs({ variables: { id: quote.id } });
  }

  // Start polling for the documents
  function startPolling() {
    const { pollInterval, pollTimeout } = configuration.documents;

    // Perform action to avoid the built in delay of setInterval
    getDocuments();

    interval.current = setInterval(getDocuments, pollInterval);

    // Timeout after x seconds to save the API if there is an issue
    timeout.current = setTimeout(stopPolling, pollTimeout);
  }

  // Stop polling for the documents
  function stopPolling() {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => {
    // Find the document based on it's documentTypeId
    const doc = documents?.documents?.find(({ typeId: id }) => id === typeId);
    setDocument(doc);
  }, [documents, typeId]);

  const isAvailable = useMemo(() => Boolean(document), [document]);

  useEffect(() => {
    if (isAvailable) {
      stopPolling();
      return;
    }
    startPolling();

    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  async function openDocument(documentId: string, uri: string) {
    await getDocumentPdf(documentId, name, uri, accessToken || '');

    if (FILE_HANDLER_METHOD === FileHandlerMethod.DOWNLOAD) {
      setMessage(get('pages.quote.summary.document.download.success.message'));
    }
  }

  async function getDocument(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!document) {
      try {
        setLoading(true);
        const doc = await createDocument({
          documentTypeId: typeId,
          quoteId: quote.id,
        });

        if (doc) {
          setDocument(doc);
          await openDocument(doc.id, doc.uri);
          setLoading(false);
        }
        return;
      } catch (err) {
        throw new Error(String(err));
      }
    }

    await openDocument(document.id, document.uri);
  }

  return (
    <>
      {loading && !isAvailable && (
        <LoadingModal
          closeText={get('pages.quote.summary.document.download.loading.close')}
          errorText={errorCreateDocument ? get('pages.error.message') : ''}
          onCancel={() => setLoading(false)}
        />
      )}
      {message && (
        <InfoModal
          title={get('pages.quote.summary.document.download.success.title')}
          closeText={get('pages.quote.summary.document.download.success.close')}
          message={message}
          onCancel={() => setMessage(undefined)}
        />
      )}
      <UnstyledButton
        className={buttonClassName}
        data-document-id={document?.id}
        onClick={getDocument}
        testId="open-document"
      >
        {children}
      </UnstyledButton>
    </>
  );
}

export default QuoteDocument;
