import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { ILocale } from 'shared/graphql/api/types';
import { useQuery } from '../hooks';
import Redirect from '../components/Router/Redirect';
import { useLocation } from 'react-router-dom';
import LocalStorage from '../utils/LocalStorage';

export const LocaleContext = createContext<{
  lang: ILocale;
  locale: IFullLocale;
}>({ lang: 'en', locale: 'en-GB' });

export const useLocale = () => useContext(LocaleContext);

interface Props {
  defaultValue: ILocale;
}

export type IFullLocale = 'en-GB' | 'nl-NL' | 'en-US';

const LOCALE_TO_FULL_LOCALE = {
  en: 'en-GB',
  nl: 'nl-NL',
  us: 'en-US',
};

/**
 * This will give a { lang, locale } this is a stop gap until the back end can understand full locales
 * @param defaultValue
 * @param children
 * @constructor
 */
function LocaleProvider({ defaultValue, children }: PropsWithChildren<Props>) {
  const { lang } = useQuery<{ lang?: string }>();
  const { pathname } = useLocation();

  const cachedLang: string = useMemo(() => {
    // Get lang from localStorage
    const saved: string | undefined = LocalStorage.get('locale');

    // If lang as set, but is different, update localStorage
    if (lang && lang !== saved) {
      LocalStorage.set('locale', lang);
    }

    // Return lang, otherwise, return saved, otherwise return default
    return lang ?? saved ?? defaultValue;
  }, [lang, defaultValue]);

  // If there's no lang, update the url to include the lang
  if (!lang) {
    return (
      <Redirect to={pathname} preserveQuery query={{ lang: cachedLang }} />
    );
  }

  const value = { lang, locale: LOCALE_TO_FULL_LOCALE[lang as ILocale] } as {
    lang: ILocale;
    locale: IFullLocale;
  };
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

export default LocaleProvider;
