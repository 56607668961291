import String from 'components/String/String';
import {
  ICurrency,
  IFinancePaymentPlan,
  ILineItem,
} from 'shared/graphql/api/types';
import { convertToBaseAddonType } from 'components/QuoteOption/QuoteOptionPricing';
import Currency from '../Currency/Currency';
import configuration from 'configuration';

interface FinancePlaymentPlanBreakdownProps {
  paymentPlan: IFinancePaymentPlan;
  currency: ICurrency;
  fees: ILineItem[];
}

export function FinancePlaymentPlanBreakdown({
  paymentPlan,
  currency,
  fees,
}: FinancePlaymentPlanBreakdownProps) {
  return (
    <div
      className="PaymentPlanBreakdown"
      data-testid="payment-plan-breakdown-finance"
    >
      <h1 className="QuoteOption-price">
        {
          <Currency
            currency={currency}
            value={paymentPlan.financeInstalment.total}
          />
        }{' '}
        {paymentPlan.financeInstalment.period && (
          <span className="QuoteOption-duration">
            <String
              id="pages.quote.payment.overview.payment_plan.finance.per_period"
              values={{
                period: paymentPlan.financeInstalment.period,
              }}
            />
          </span>
        )}
      </h1>
      <div className="PaymentPlanBreakdown-financeBreakdown">
        {paymentPlan.financeDownpayment && (
          <p>
            <String
              id="pages.quote.payment.overview.payment_plan.finance.deposit"
              values={{
                amount: (
                  <Currency
                    currency={currency}
                    value={paymentPlan.financeDownpayment.total}
                  />
                ),
              }}
            />
          </p>
        )}
        <p>
          <String
            id="pages.quote.payment.overview.payment_plan.finance.instalments"
            values={{
              amount: (
                <Currency
                  currency={currency}
                  value={paymentPlan.financeInstalment.total}
                />
              ),
              period: paymentPlan.financeInstalment.period ?? '',
              count: paymentPlan.financeInstalment.number,
            }}
          />
        </p>
        <p>
          <strong>
            <String
              id="pages.quote.payment.overview.payment_plan.finance.total"
              values={{
                amount: (
                  <Currency currency={currency} value={paymentPlan.total} />
                ),
              }}
            />
          </strong>
        </p>
        <p className="QuoteOption-tax">
          <String
            id="pages.quote.payment.overview.payment_plan.finance.ipt"
            values={{
              amount: <Currency currency={currency} value={paymentPlan.tax} />,
            }}
          />
        </p>
        {fees.length > 0 &&
          fees.map(({ value, id, name }) => (
            <p
              className="QuoteOption-tax"
              key={id}
              data-testid={`QuoteOption-price-addon-${id}`}
            >
              {!configuration.enableTranslationsFees ? (
                <String
                  id={`pages.quote.options.addon.${convertToBaseAddonType(id)}`}
                  values={{
                    total: <Currency currency={currency} value={value} />,
                  }}
                />
              ) : (
                <String
                  id={`pages.quote.options.addon`}
                  values={{
                    total: <Currency currency={currency} value={value} />,
                    feeName: name,
                  }}
                />
              )}
            </p>
          ))}
      </div>
      <ul>
        <li>
          <String
            id="pages.quote.payment.overview.payment_plan.finance.interest_rate"
            values={{
              amount: paymentPlan.financeInstalment.interestPercentage,
            }}
          />
        </li>
        {paymentPlan.aprPercentage && (
          <li>
            <String
              id="pages.quote.payment.overview.payment_plan.finance.apr"
              values={{
                amount: paymentPlan.aprPercentage,
              }}
            />
          </li>
        )}
        {paymentPlan.agreementTerm && (
          <li>
            <String
              id="pages.quote.payment.overview.payment_plan.finance.agreement_term"
              values={{
                term: paymentPlan.agreementTerm,
              }}
            />
          </li>
        )}
        <li>
          <String
            id="pages.quote.payment.overview.payment_plan.finance.cash_price"
            values={{
              amount: (
                <Currency currency={currency} value={paymentPlan.cashPrice} />
              ),
            }}
          />
        </li>
        <li>
          <String
            id="pages.quote.payment.overview.payment_plan.finance.credit_cost"
            values={{
              amount: (
                <Currency
                  currency={currency}
                  value={paymentPlan.costOfCredit}
                />
              ),
            }}
          />
        </li>
      </ul>
      <div className="PaymentPlanBreakdown-difference">
        <String
          id="pages.quote.payment.overview.payment_plan.finance.difference"
          values={{
            amount: (
              <Currency currency={currency} value={paymentPlan.costOfCredit} />
            ),
          }}
        />
      </div>
      <p className="PaymentPlanBreakdown-footnote">
        <String id="pages.quote.payment.overview.payment_plan.finance.footnote" />
      </p>
    </div>
  );
}
