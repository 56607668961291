import React, { createContext, PropsWithChildren, useContext } from 'react';
import { IEvent } from '../types/event';
import EventManager from '../utils/EventManager';

export enum QuoteType {
  NEW_BUSINESS = 'New Business',
  NEW_BUSINESS_PRE = 'pre-existing quote',
  RENEWAL_PRE = 'Renewal - pre-existing quote',
  RENEWAL = 'Renewal',
  MTA = 'MTA',
  MIGRATION = 'Migration',
}

interface ITrackingContext {
  track(event: IEvent['event'], eventArgs: any): void;
}

export const TrackingContext = createContext<ITrackingContext>({
  track(): void {},
});

export const useTracking = () => useContext(TrackingContext);

interface Props {
  defaultArguments?: any;
}

function TrackingProvider({
  defaultArguments,
  children,
}: PropsWithChildren<Props>) {
  function track(event: IEvent['event'], eventArgs: any) {
    EventManager.track({
      event,
      ...defaultArguments,
      ...eventArgs,
    });
  }

  return (
    <TrackingContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}

export default TrackingProvider;
