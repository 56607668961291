import React from 'react';

import { ICurrency, ILineItem } from 'shared/graphql/api/types';

import String from 'components/String/String';
import Currency from 'components/Currency/Currency';
import { QuoteBreakdown } from 'utils/quoteUtils';

import './BreakdownDetails.scss';
import { convertToBaseAddonType } from './QuoteOptionPricing';
import configuration from 'configuration';

interface Props {
  currency: ICurrency;
  breakdown: QuoteBreakdown;
  hasAllAddOns: boolean;
  protectedNcd: boolean;
  showIpt: boolean;
  fees: ILineItem[];
}

function getTaxAndTotal(
  breakdown: QuoteBreakdown,
  hasAllAdons: boolean,
  protectedNcd: boolean
): {
  tax: number;
  total: number;
} {
  if (hasAllAdons) {
    return {
      total: protectedNcd
        ? breakdown.insurancePolicyTotal
        : breakdown.insurancePolicyTotalWithPLNoNcd,
      tax: protectedNcd
        ? breakdown.insurancePolicyTax
        : breakdown.insurancePolicyTaxWithPLNoNcd,
    };
  }
  return {
    total: protectedNcd
      ? breakdown.insurancePolicyTotalNoPublicLiability
      : breakdown.premiumTotal,
    tax: protectedNcd
      ? breakdown.insurancePolicyTaxNoPublicLiability
      : breakdown.premiumTax,
  };
}

function BreakdownDetails({
  currency,
  breakdown,
  hasAllAddOns,
  protectedNcd,
  showIpt,
  fees,
}: Props) {
  const { tax, total } = getTaxAndTotal(breakdown, hasAllAddOns, protectedNcd);
  return (
    <>
      <div className="Breakdown-item">
        <span className="Breakdown-item-title">
          <String id="pages.quote.summary.insurance_policy" />
        </span>
        <span className="Breakdown-item-content">
          <span className="Breakdown-item-content-main">
            <Currency currency={currency} value={total} />
          </span>
          {showIpt && (
            <span className="Breakdown-item-content-info">
              <String
                id="pages.quote.summary.inc_ipt"
                values={{
                  ipt: <Currency currency={currency} value={tax} />,
                }}
              />
            </span>
          )}
        </span>
      </div>
      {fees.length > 0 &&
        fees.map(({ id, value, name }) => (
          <div className="Breakdown-item">
            <span className="Breakdown-item-title">
              {!configuration.enableTranslationsFees ? (
                <String id={`pages.addon.name.${convertToBaseAddonType(id)}`} />
              ) : (
                name
              )}
            </span>
            <span className="Breakdown-item-content">
              <span className="Breakdown-item-content-main">
                <Currency currency={currency} value={value} />
              </span>
            </span>
          </div>
        ))}
      {hasAllAddOns && (
        <>
          <div className="Breakdown-item">
            <span className="Breakdown-item-title">
              <String id="pages.quote.summary.public_liability" />
            </span>
            <span className="Breakdown-item-content">
              <span className="Breakdown-item-content-main">
                <Currency
                  currency={currency}
                  value={breakdown.publicLiabilityTotal}
                />
              </span>
              {showIpt && (
                <span className="Breakdown-item-content-info">
                  <String
                    id="pages.quote.summary.inc_ipt"
                    values={{
                      ipt: (
                        <Currency
                          currency={currency}
                          value={breakdown.publicLiabilityTax}
                        />
                      ),
                    }}
                  />
                </span>
              )}
            </span>
          </div>
        </>
      )}
      {protectedNcd && (
        <>
          <div className="Breakdown-item">
            <span className="Breakdown-item-title">
              <String id="pages.quote.summary.ncd_protection" />
            </span>
            <span className="Breakdown-item-content">
              <div className="Breakdown-item-content-main">
                <Currency
                  currency={currency}
                  value={breakdown.ncdProtectionTotal}
                />
              </div>
              {showIpt && (
                <div className="Breakdown-item-content-info">
                  <String
                    id="pages.quote.summary.inc_ipt"
                    values={{
                      ipt: (
                        <Currency
                          currency={currency}
                          value={breakdown.ncdProtectionTax}
                        />
                      ),
                    }}
                  />
                </div>
              )}
            </span>
          </div>
        </>
      )}

      {hasAllAddOns && (
        <>
          <div className="Breakdown-item">
            <span className="Breakdown-item-title">
              <String id="pages.quote.summary.key_cover" />
            </span>
            <span className="Breakdown-item-content">
              <span className="Breakdown-item-content-text">
                <String id="pages.quote.summary.included" />
              </span>
            </span>
          </div>

          <div className="Breakdown-title">
            <span>
              <String id="pages.quote.summary.additional_cover" />
            </span>
          </div>

          <div className="Breakdown-item">
            <span className="Breakdown-item-title">
              <String id="pages.quote.summary.additional_cover.detail" />
            </span>
            <span className="Breakdown-item-content">
              <span className="Breakdown-item-content-text">
                <String id="pages.quote.summary.included" />
              </span>
            </span>
          </div>
        </>
      )}

      {showIpt && (
        <>
          <div className="Breakdown-item">
            <span className="Breakdown-item-content">
              <String id="pages.quote.options.inclusive_prices_breakdown" />
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default BreakdownDetails;
