import { IMessage } from '../types/message';

export function createMessage(
  text: string,
  sender: string = 'some-sender',
  received: boolean = true,
  data: any = {},
  date = new Date()
): IMessage {
  return {
    type: 'text',
    text,
    sender,
    received,
    date,
    data,
  };
}

/**
 * Returns a delay in milliseconds based on a message length
 * @param message
 */
export function getMessageDelay(message: string) {
  const minimum = 1000;
  const maximum = 1500;
  const delay = message.length * 10;

  return Math.max(minimum, Math.min(delay, maximum));
}
