import React from 'react';
import { useExpireQuote, useHistory, useQuery, useQuote } from 'hooks';
import { useParams } from 'react-router-dom';
import { useUser } from 'providers/UserProvider';
import { IPackage } from 'types/quote';
import { ErrorCodes, QuoteError } from 'constants/errors';
import Loader from 'components/Loader/Loader';
import Page from 'components/Page/Page';
import QuoteOverview from 'components/Chatbot/ChatbotInput/PolicyInput/QuoteOverview/QuoteOverview';

function PackagesPage() {
  const { renewalOf } = useQuery<{ renewalOf?: string }>();

  const { id: productId, quoteId } = useParams<{
    id: string;
    quoteId: string;
  }>();

  const { push } = useHistory();
  const { quote, loading } = useQuote(quoteId, true);

  useExpireQuote(true);

  const user = useUser();

  if (loading || !user) {
    return <Loader />;
  }

  if (!quote) {
    throw new QuoteError(ErrorCodes.PAYMENT_NO_QUOTE);
  }

  const { product } = quote;

  function onRestart() {
    if (renewalOf) {
      return push(`/product/${productId}/quote?renewalOf=${renewalOf}`);
    }

    push(`/product/${productId}/quote`);
  }

  function onSelect(value: { package: IPackage }) {
    push(`/product/${productId}/quote/${quoteId}/payment`, {
      renewalOf,
      packageId: value.package.id,
    });
  }

  return (
    <Page
      name="PackagePage"
      slug="Packages"
      padded={false}
      onRestart={onRestart}
    >
      <QuoteOverview
        product={product}
        quotes={[quote]}
        loading={false}
        currency={product.currency}
        onClose={onSelect}
      />
    </Page>
  );
}

export default PackagesPage;
