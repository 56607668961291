import React, { useState } from 'react';
import { formatTestId } from 'utils/testIdUtils';

import './EligibilityStatement.scss';
import Checkbox from '../../../../Form/Checkbox/Checkbox';

interface Props {
  text: string;
  showInput: boolean;
  onAgreed(agreed: boolean): void;
}

function EligibilityStatement({ text, showInput, onAgreed }: Props) {
  const [agreed, setAgreed] = useState(false);

  function onCheck() {
    setAgreed(!agreed);
    onAgreed(!agreed);
  }

  return (
    <div className="EligibilityStatement">
      {showInput && (
        <Checkbox
          isChecked={agreed}
          onChecked={onCheck}
          dataTestId={`statement-${formatTestId(text)}`}
        />
      )}
      <span
        className={`EligibilityStatement-text${showInput ? '-indent' : ''}`}
      >
        {text}
      </span>
      <div className="EligibilityStatement-separator" />
    </div>
  );
}

export default EligibilityStatement;
