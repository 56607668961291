import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Loqate from 'react-loqate';

import { useStrings } from 'hooks';
import configuration from 'configuration';

import Modal from 'components/Modal/Modal';
import Button from 'components/Buttons/Button';
import String from 'components/String/String';
import Field from 'components/Form/Field/Field';

import AddressSuggestion from './AddressSuggestion';
import { AddressSchema } from './Address.schema';

import './AddressModal.scss';

export interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  postalCode: string;
  region: string;
  country: string;
  province: string;
  state: string;
}

interface Props {
  active: boolean;
  onClose(address?: Address): void;
  countries: string[];
  dataTestId?: string;
}

function AddressModal({ active, onClose, countries, dataTestId }: Props) {
  const { get } = useStrings();
  const [formHidden, setFormHidden] = useState(true);

  const cleanUpAddress = (values: Address) => {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value.trim()])
    );
  };

  const getValues = (values: Address) => {
    const state = values.county;
    const region = values.county;
    const province = values.county;

    return { ...values, state, region, province };
  };

  const onSubmit = (values: Address) => {
    cleanUpAddress(values);
    onClose(getValues(values));
  };

  if (!active) {
    return null;
  }

  return (
    <Modal
      title={get('pages.quote.address.title')}
      className="AddressModal"
      onClose={() => onClose()}
      dataTestId={dataTestId}
    >
      <Formik<Address>
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={AddressSchema}
        initialValues={{
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          city: '',
          county: '',
          postalCode: '',
          country: '',
          region: '',
          province: '',
          state: '',
        }}
      >
        {({ setValues, isValid, errors, dirty }) => (
          <>
            {formHidden && (
              <button
                type="button"
                className="pencil"
                onClick={(_event) => setFormHidden(false)}
                data-testid="address-modal-manual-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                </svg>
              </button>
            )}
            <div className="AddressModal-inputs">
              <Loqate
                className="Loqate"
                inputClassname="AddressModal-search"
                locale="en"
                apiKey={configuration.loqate.key}
                countries={countries}
                listClassname="AddressModal-results"
                onSelect={(address) => {
                  setValues({
                    addressLine1: address.Line1,
                    addressLine2: address.Line2,
                    addressLine3: address.Line3,
                    city: address.City,
                    county: address.Province,
                    postalCode: address.PostalCode,
                    country: address.CountryName,
                    region: address.Province,
                    province: address.Province,
                    state: address.Province,
                  });
                  setFormHidden(false);
                }}
                components={{
                  Input: AddressSearch,
                  ListItem: AddressSuggestion,
                }}
              />
            </div>
            <br />
            <Form>
              <div className="AddressModal-inputs AddressModal-fields">
                <Field
                  hidden={formHidden}
                  name="addressLine1"
                  required
                  placeholder={get('pages.quote.address.address1')}
                  data-testid="address-modal-address-line1"
                  autoComplete="off"
                  className={errors.addressLine1}
                />
                <Field
                  hidden={formHidden}
                  name="addressLine2"
                  placeholder={get('pages.quote.address.address2')}
                  data-testid="address-modal-address-line2"
                  autoComplete="off"
                />
                <Field
                  hidden={formHidden}
                  name="addressLine3"
                  placeholder={get('pages.quote.address.address3')}
                  data-testid="address-modal-address-line3"
                  autoComplete="off"
                />
                <Field
                  hidden={formHidden}
                  name="city"
                  placeholder={get('pages.quote.address.city')}
                  data-testid="address-modal-city"
                  autoComplete="off"
                />
                <Field
                  hidden={formHidden}
                  name="county"
                  placeholder={get('pages.quote.address.county')}
                  data-testid="address-modal-county"
                  autoComplete="off"
                />
                <Field
                  hidden={formHidden}
                  name="postalCode"
                  required
                  placeholder={get('pages.quote.address.postalCode')}
                  data-testid="address-modal-postal-code"
                  autoComplete="off"
                />
                <Field
                  hidden={formHidden}
                  name="country"
                  placeholder={get('pages.quote.address.country')}
                  data-testid="address-modal-country"
                  autoComplete="off"
                />
              </div>
              <div className="Modal-actions">
                <Button
                  hidden={formHidden}
                  type="submit"
                  variant="primary"
                  disabled={!isValid || !dirty}
                  testId="address-modal-button"
                >
                  <String id="pages.quote.address.save" />
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

function AddressSearch(props: React.PropsWithChildren<unknown>) {
  const { get } = useStrings();

  return (
    <input
      type="search"
      placeholder={get('pages.quote.address.search')}
      data-testid="address-modal-search"
      {...props}
    />
  );
}

export default AddressModal;
