import React, { useEffect, useRef } from 'react';
import {
  usePremiumCreditFitsInitiateLoanApplication,
  isPremiumCreditFitsInitiateLoanApplicationError,
  isPremiumCreditFitsInitiateLoanApplicationReady,
  isPremiumCreditFitsInitiateLoanApplicationFetching,
} from 'hooks';
import Loader from 'components/Loader/Loader';
import { PremiumFinanceError } from 'constants/errors';

interface Props {
  quoteId: string;
  packageValuationId: string;
}

export function PremiumFinanceFrame({ quoteId, packageValuationId }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const state = usePremiumCreditFitsInitiateLoanApplication({
    quoteId,
    packageValuationId,
  });

  useEffect(() => {
    if (isPremiumCreditFitsInitiateLoanApplicationReady(state)) {
      formRef.current && formRef.current.submit();
    }
  }, [state]);

  if (isPremiumCreditFitsInitiateLoanApplicationFetching(state)) {
    return <Loader />;
  }

  if (isPremiumCreditFitsInitiateLoanApplicationError(state)) {
    throw new PremiumFinanceError();
  }

  if (isPremiumCreditFitsInitiateLoanApplicationReady(state)) {
    return (
      <>
        <form
          data-testid="PremiumFinanceFrame_form"
          ref={formRef}
          target="FITSIFrame"
          id="frmSendForm"
          method="post"
          action={state.redirectUrl}
        >
          <input
            type="hidden"
            name="__REQID"
            data-testid="PremiumFinanceFrame_input:reqId"
            value={state.reqId}
          />
          <input
            type="hidden"
            name="__FITK"
            data-testid="PremiumFinanceFrame_input:fitk"
            value={state.fitk}
          />
          <input
            type="hidden"
            name="__CANCELURL"
            data-testid="PremiumFinanceFrame_input:cancelUrl"
            value={state.cancelUrl}
          />
          <input
            type="hidden"
            name="__ERRORURL"
            data-testid="PremiumFinanceFrame_input:errorUrl"
            value={state.errorUrl}
          />
        </form>
        <iframe
          id="ifrmFitsIframe"
          title="FITSIFrame"
          name="FITSIFrame"
          style={{ height: '100%', border: 'none' }}
        />
      </>
    );
  }

  return null;
}
