import React, { PropsWithChildren, useContext } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useLocale } from './LocaleProvider';
import { StringContext } from './StringProvider';

function IntlProvider({ children }: PropsWithChildren<unknown>) {
  const { lang: locale } = useLocale();
  const strings = useContext(StringContext);
  return (
    <ReactIntlProvider locale={locale} messages={strings} defaultLocale="en">
      {children}
    </ReactIntlProvider>
  );
}

export default IntlProvider;
