import { useState } from 'react';
import {
  useCreatePolicy,
  useCreateMtaPolicy,
  useExpireQuote,
  useHistory,
  useQuote,
  useQuery,
  useStrings,
  useGetPolicyVersion,
} from 'hooks';
import { PaymentIntent } from '@stripe/stripe-js';
import qs from 'qs';
import { useLocation, useParams } from 'react-router-dom';

import configuration from 'configuration';
import EventManager from 'utils/EventManager';
import { getQuoteType, hasQuoteExpired } from 'utils/quoteUtils';
import { IContext } from 'types/quote';
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent';
import { useCreatePaymentEvent } from 'hooks/useCreatePaymentEvent';
import { useUser } from 'providers/UserProvider';

import PaymentForm from 'components/PaymentForm/PaymentForm';
import PaymentStatus from 'components/PaymentForm/PaymentStatus/PaymentStatus';
import { ErrorCodes, QuoteError } from 'constants/errors';
import { Centered } from 'components/Layout/Layout';
import Modal from 'components/Modal/Modal';
import String from 'components/String/String';
import Loader from 'components/Loader/Loader';
import { useLocale } from 'providers/LocaleProvider';
import Page from 'components/Page/Page';
import PaymentOverview from 'components/PaymentOverview/PaymentOverview';

import './PaymentPage.scss';
import { PaymentPlanEnum } from '../../../../../enums/PaymentPlan';
import { IPolicy } from 'shared/graphql/api/types';

interface QueryParams {
  packageId?: string;
  renewalOf: string;
  migrationOf: string;
  payment_intent_client_secret: string;
  _ga: string;
  isOpenQuote: boolean;
  fits: string;
  payment_amount: number;
  redirect_status: string;
}

function PaymentPage() {
  const {
    packageId,
    renewalOf,
    migrationOf,
    payment_amount,
    payment_intent_client_secret,
    _ga,
    isOpenQuote,
    fits,
    redirect_status,
  } = useQuery<QueryParams>();

  const { quoteId } = useParams<{ quoteId: string }>();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { quote, loading, refetch } = useQuote(quoteId, true);

  useExpireQuote(true);
  const user = useUser();
  const { lang: locale } = useLocale();

  const { policy, loading: policyLoading } = useGetPolicyVersion(
    quote?.mtaOf?.id ?? '',
    quote?.mtaOf?.version ?? '',
    { skip: !quote?.mtaOf }
  );

  const [intentSecret, setIntentSecret] = useState<string | undefined>(
    payment_intent_client_secret
  );
  const [futureUsage, setFutureUsage] = useState<string | null>();

  const [showPaymentModal, setShowPaymentModal] = useState(
    // if we are being redicted back after a 3rd party payment, render the modal
    redirect_status !== undefined
  );
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(PaymentPlanEnum.IN_FULL);
  const [paymentAmount, setPaymentAmount] = useState<number>(
    payment_amount ? Number(payment_amount) : 0
  );

  const { get } = useStrings();

  const { create: createIntent, error: createIntentError } =
    useCreatePaymentIntent();
  const { create: createEvent } = useCreatePaymentEvent();
  const { create: createPolicy } = useCreatePolicy();
  const { create: createMtaPolicy } = useCreateMtaPolicy();

  const mtaOf = quote?.mtaOf;

  const quoteType = getQuoteType(renewalOf, mtaOf, migrationOf);
  if (loading || policyLoading || !user) {
    return <Loader />;
  }

  if (!quote) {
    throw new QuoteError(ErrorCodes.PAYMENT_NO_QUOTE);
  }
  const { datasheet, packages, product } = quote;

  const _package =
    packages.find((p) => p.id === packageId) ??
    (mtaOf && packages.length === 1 ? packages[0] : undefined);

  if (createIntentError?.message?.includes('[019]')) {
    push(`${pathname}/error`, undefined, {
      preserveQuery: true,
    });
    return <Loader />;
  }

  async function onCreateIntent(amount: number, paymentPlan: PaymentPlanEnum) {
    setIntentSecret(undefined);
    setPaymentAmount(amount);
    setPaymentPlan(paymentPlan);
    setShowPaymentModal(true);
    const intent = await createIntent({
      packageId: _package!.id,
      quoteId: quote!.id,
      currency: product!.currency,
      amount: amount,
      paymentPlan: paymentPlan,
    });

    if (!intent) {
      return;
    }

    setIntentSecret(intent.secret);
    setFutureUsage(intent.setupFutureUsage);
  }

  async function onPaymentSubmit() {
    EventManager.track({
      event: 'PaymentSubmitted',
      productId: product!.id,
      duration: quote?.datasheet?.policy?.duration,
      quoteType,
      amount: paymentAmount,
      currency: product!.currency,
    });
  }

  async function onPaymentFailure(code?: string) {
    EventManager.track({
      event: 'PaymentFailed',
      productId: product!.id,
      duration: quote?.datasheet?.policy?.duration,
      quoteType,
      amount: paymentAmount,
      currency: product!.currency,
      errorCode: code,
    });
  }

  async function onPaymentSuccess(intent?: PaymentIntent) {
    EventManager.track({
      event: 'PaymentSucceeded',
      productId: product!.id,
      duration: quote?.datasheet?.policy?.duration,
      quoteType,
      amount: paymentAmount / 100,
      currency: product!.currency,
    });

    EventManager.track({
      event: 'PolicyPurchased',
      duration: quote?.datasheet?.policy?.duration,
      transactionId: quoteId,
      transactionTotal: paymentAmount / 100,
      transactionProducts: [
        {
          name: `${product?.name}:${_package?.name}`,
          sku: `${product?.id}:${_package?.name}`,
          price: paymentAmount / 100,
          quantity: 1,
        },
      ],
    });

    // If an intent is returned, create a payment event
    if (intent) {
      await createEvent({
        packageId: _package!.id,
        quoteId: quote!.id,
        data: intent,
        paymentPlan: paymentPlan,
      });
    }

    setShowPaymentModal(false);

    const confirmationUrl = `/product/${product.id}/quote/${quoteId}/payment/confirmation`;

    push(confirmationUrl, {
      packageId: packageId || _package!.id,
      renewalOf,
      status: 'succeeded',
      isOpenQuote,
    });
  }

  function onCancel() {
    push(`/product/${product.id}/quote/${quoteId}/payment`, {
      packageId,
      renewalOf,
      fits,
    });

    setShowPaymentModal(false);
  }

  if (!_package) {
    throw new QuoteError(ErrorCodes.PAYMENT_NO_PACKAGE);
  }
  if (!_package.valuation) {
    throw new QuoteError(ErrorCodes.PAYMENT_NO_PACKAGE_VALUATION);
  }

  const $: IContext = {
    datasheet,
    user,
    package: {
      id: _package.id,
      packageValuationId: _package.packageValuationId ?? undefined,
      name: _package.name,
      status: _package.status,
      valuation: _package.valuation as any,
      paymentPlans: _package.paymentPlans as any,
      pricingModel: _package.pricingModel,
    },
    quote,
  };

  const returnUrl = `${window.location.origin}${
    window.location.pathname
  }?${qs.stringify({
    lang: locale,
    packageId,
    quoteId,
    productId: product.id,
    renewalOf,
    agreeTerms: true,
    _ga,
    payment_amount: paymentAmount,
  })}`;

  function onRestart() {
    if (renewalOf) {
      return push(`/product/${product.id}/quote?renewalOf=${renewalOf}`);
    }

    push(`/product/${product.id}/quote`);
  }

  async function onCreatePolicy() {
    if (!configuration.policy.disableCreatePolicy) {
      setShowPolicyModal(true);

      if (quote.mtaOf) {
        await createMtaPolicy({
          packageId: _package!.id,
          quoteId: quote!.id,
          mtaOf: { id: quote.mtaOf.id },
        });

        setShowPolicyModal(false);

        push(`/product/${product.id}/quote/${quoteId}/mta/success`);
      }

      if (quote.renewalOf) {
        await createPolicy({
          packageId: _package!.id,
          quoteId: quote!.id,
        });

        setShowPolicyModal(false);

        push(`/product/${product.id}/quote/${quoteId}/renewal/success`);
      }
    }
  }

  const validateQuote = async () => {
    const expiredUrl = `/product/${product.id}/quote/${quoteId}/mtaExpired`;
    return refetch().then((data) => {
      if (hasQuoteExpired(data.data.quote)) {
        push(expiredUrl);
        return false;
      }

      return true;
    });
  };

  if (loading || policyLoading || !user) {
    return <Loader />;
  }

  if (!quote) {
    throw new QuoteError(ErrorCodes.PAYMENT_NO_QUOTE);
  }
  return (
    <Page
      name="PaymentPage"
      slug="Payment"
      padded={false}
      onRestart={onRestart}
    >
      <PaymentOverview
        aria-hidden={showPaymentModal}
        onMakePayment={onCreateIntent}
        onCreatePolicy={onCreatePolicy}
        context={$}
        product={product}
        isOpenQuote={isOpenQuote}
        fitsStatus={fits}
        policy={policy as IPolicy}
      />
      {showPaymentModal && _package && (
        <Modal title={get('pages.quote.payment.title')}>
          <PaymentStatus
            productId={product.id}
            quote={quote}
            packageId={_package.id}
            intentSecret={intentSecret}
          />
          {intentSecret && (
            <PaymentForm
              quote={quote}
              currency={product.currency}
              amount={paymentAmount}
              clientSecret={intentSecret}
              futureUsage={futureUsage}
              onSuccess={onPaymentSuccess}
              onFailure={onPaymentFailure}
              onSubmit={onPaymentSubmit}
              onBeforePayment={validateQuote}
              onCancel={onCancel}
              returnTo={returnUrl}
            />
          )}
        </Modal>
      )}
      {showPolicyModal && (
        <Modal title={get('pages.quote.policy.title')}>
          <Centered>
            <div>
              <String id="pages.quote.policy.creating" />
            </div>
            <br />
            <Loader />
          </Centered>
        </Modal>
      )}
    </Page>
  );
}

export default PaymentPage;
