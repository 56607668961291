import { DependencyList, useEffect } from 'react';

export function useEffectAsync<T>(
  fn: () => Promise<T> | T,
  deps?: DependencyList
) {
  useEffect(() => {
    async function invoke() {
      await fn();
    }

    invoke().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
