import React from 'react';
import Page from 'components/Page/Page';
import String from 'components/String/String';

function PasswordChangedPage() {
  return (
    <Page name="PasswordChangedPage" slug="Password Changed" showLogout={false}>
      <div className="auth0-redirect-page-wrapper">
        <div id="success-tick">
          <div className="success-tick-bg">
            <div className="success-tick-tick"></div>
          </div>
        </div>
        <h1>
          <String id="pages.auth0.email.password_reset.title" />
        </h1>
        <p>
          <String id="pages.auth0.email.password_reset.message.line1" />
        </p>
        <p>
          <String id="pages.auth0.email.password_reset.message.line2" />
        </p>
      </div>
    </Page>
  );
}

export default PasswordChangedPage;
