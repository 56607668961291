import {
  differenceInDays,
  differenceInHours,
  format as _format,
  isAfter as _isAfter,
  isBefore as _isBefore,
  isValid as isValidDate,
  addMinutes,
  Locale,
  parseISO,
} from 'date-fns';

import enLocale from 'date-fns/locale/en-GB';
import nlLocale from 'date-fns/locale/nl';

import { ILocale } from '../graphql/api/types';

const LOCALES: Record<ILocale, Locale> = {
  en: enLocale,
  nl: nlLocale,
};

export function getDateLocale(locale?: ILocale) {
  if (!locale) {
    return enLocale;
  }

  return LOCALES[locale];
}

/**
 * Formats a date
 * @param value
 * @param format
 * @param locale
 */

export function formatDate(value: Date, format: string, locale?: ILocale) {
  try {
    return _format(value, format, {
      locale: getDateLocale(locale),
    });
  } catch (e) {
    console.error(e);

    throw new Error(String(e));
  }
}

/**
 * Formats a date
 * @param value
 * @param format
 * @param locale
 */
export function formatDateString(
  value: string,
  format: string,
  locale?: ILocale
) {
  try {
    const date = parseISO(value);

    return formatDate(date, format, locale);
  } catch (e) {
    console.error(e);

    throw new Error(String(e));
  }
}

/**
 * Gets days between 2 dates
 * @param from
 * @param to
 */
export function daysBetween(from: Date | string, to: Date | string) {
  return differenceInDays(toDate(to), toDate(from));
}

/**
 * Takes in two utc date strings and calculates the difference in full days between them
 * Helpful for doing client side diffing of server generated UTC timestamps
 * @param from starting date
 * @param to ending date
 * @returns days difference as an integer
 */
export function getFullDaysBetween(from: string, to: string) {
  // Parse the date strings into Date objects
  const startDate = new Date(from);
  const endDate = new Date(to);

  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffInMillis = endDate.getTime() - startDate.getTime();

  // Convert the difference from milliseconds to days
  const millisPerDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  const diffInDays = diffInMillis / millisPerDay;
  return Math.ceil(diffInDays);
}

/**
 * Gets hours between 2 dates
 * @param from
 * @param to
 */
export function hoursBetween(from: Date | string, to: Date | string) {
  return differenceInHours(toDate(to), toDate(from));
}

/**
 * Date is after another date
 * @param a
 * @param b
 */
export function isAfter(a: Date | string, b: Date | string) {
  return _isAfter(toDate(a), toDate(b));
}

/**
 * Date is before another date
 * @param a
 * @param b
 */
export function isBefore(a: Date | string, b: Date | string) {
  return _isBefore(toDate(a), toDate(b));
}

export function toDate(date: Date | number | string) {
  return typeof date === 'string' ? parseISO(date) : date;
}

/**
 * Checks if date is valid
 * @param date
 */
export function isValid(date: Date | null): date is Date {
  if (!date) {
    return false;
  }

  try {
    return isValidDate(date);
  } catch (e) {
    return false;
  }
}

/**
 * Removes the timezone enabling comparision of dates
 *
 * Intended for test cases, rather than production code
 * @param data
 */
export function getDateWithoutTimezone(data: Date): Date {
  let result: Date = new Date(data);
  let stringDate = result.toString();

  // Check if timezone offest is present
  if (stringDate.indexOf('GMT+') > 0) {
    // Timezone offest is present, we need to change the date object
    result.setHours(result.getHours() - result.getTimezoneOffset() / 60);
  }

  return result;
}

/**
 * Takes in a date and then adds back on the minute offset caused by the timezone to return the UTC timezoned date
 * Useful for when we want absolute dates for comparison across timezones
 * @param date The date to be adjusted
 */
export function adjustDateForTimezone(date: Date): Date {
  const offset: number = date.getTimezoneOffset();

  return addMinutes(date, offset);
}
