import React, { ChangeEvent, PropsWithChildren, useState } from 'react';

import Button from 'components/Buttons/Button';
import {
  IVehicle,
  useGetVehicleDetailsMutation,
} from 'shared/graphql/api/types';
import Loader from 'components/Loader/Loader';
import String from 'components/String/String';
import * as Sentry from '@sentry/react';
import { ApplicationError, ErrorCodes } from 'constants/errors';
import Modal from 'components/Modal/Modal';
import FormTextField from './TextField';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { Formik, Form } from 'formik';
import { object, number, SchemaOf, string } from 'yup';
import CurrencyInput from './CurrencyInput';

import './VehicleSearch.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  setField: (vehicle: IVehicle) => void;
}

type VehicleFormValues = Pick<
  IVehicle,
  'value' | 'valueWhenNew' | 'numberOfSeats' | 'model'
>;

const validationSchema: SchemaOf<VehicleFormValues> = object({
  value: number()
    .positive('Please provide a vehicle value')
    .integer('Please provide a vehicle value')
    .required('Please provide a vehicle value'),
  valueWhenNew: number()
    .positive('Please provide a vehicle value when new')
    .integer('Please provide a vehicle value when new')
    .required('Please provide a vehicle value when new'),
  numberOfSeats: number()
    .positive('Please provide the number of seats')
    .integer('Please provide the number of seats')
    .required('Please provide the number of seats'),
  model: string().required('Please provide the vehicle model'),
});

function VehicleSearch({ setField, name }: PropsWithChildren<Props>) {
  const [getVehicleDetails] = useGetVehicleDetailsMutation();
  const [vehicleReg, setVehicleReg] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [vehicle, setVehicle] = useState<IVehicle>({
    bodyType: '',
    engineCapacityCc: 0,
    grossWeightKgs: 0,
    insuranceGroup: '',
    make: '',
    model: '',
    numberOfSeats: 0,
    registrationNumber: '',
    transmissionType: '',
    type: '',
    value: 0,
    valueWhenNew: 0,
    vin: '',
    yearOfManufacture: '',
  });

  function handleSubmit(values: VehicleFormValues) {
    setField({ ...vehicle, ...values });
    setOpen(false);
  }

  const searchHandler = async () => {
    setLoading(true);

    const variables = { input: { registrationNumber: vehicleReg } };

    const { data, errors } = await getVehicleDetails({ variables } as any);

    setLoading(false);

    if (errors?.length || data?.response.error || !data) {
      setHasErrors(true);
      Sentry.captureException(
        new ApplicationError(
          'Vehicle Error',
          ErrorCodes.VEHICLE_ERROR,
          'Vehicle Exception: ' + errors?.toString()
        )
      );
    } else {
      setHasErrors(false);
      const vehicleData = data.response.data as IVehicle;
      setVehicle(vehicleData);
      if (
        vehicleData.numberOfSeats > 0 &&
        vehicleData.value > 0 &&
        vehicleData.valueWhenNew > 0
      ) {
        setField(vehicleData);
      } else {
        setOpen(true);
      }
    }
  };

  return (
    <>
      {open && (
        <Modal title="Vehicle">
          Please provide the following information about your vehicle:
          <Formik
            initialValues={vehicle}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
          >
            {({ values, handleChange, handleBlur, setFieldValue, errors }) => {
              return (
                <Form>
                  <div className="VehicleModalForm-question">
                    <div className="VehicleModalForm-question-title">
                      Vehicle Model
                    </div>
                    <FormTextField
                      name="model"
                      value={values.model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.model)}
                    />
                    {errors.model && <ErrorMessage message={errors.model} />}
                  </div>

                  <div className="VehicleModalForm-question">
                    <div className="VehicleModalForm-question-title">
                      Number of seats
                    </div>
                    <FormTextField
                      name="numberOfSeats"
                      value={values.numberOfSeats}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      error={Boolean(errors.numberOfSeats)}
                    />

                    {errors.numberOfSeats && (
                      <ErrorMessage message={errors.numberOfSeats} />
                    )}
                  </div>

                  <div className="VehicleModalForm-question">
                    <div className="VehicleModalForm-question-title">
                      Current Vehicle Value
                    </div>
                    <CurrencyInput
                      name="value"
                      initialValue={values.value}
                      setField={(input) => setFieldValue('value', input)}
                      onBlur={handleBlur}
                      error={Boolean(errors.value)}
                    />
                    {errors.value && <ErrorMessage message={errors.value} />}
                  </div>

                  <div className="VehicleModalForm-question">
                    <div className="VehicleModalForm-question-title">
                      Vehicle Value When New
                    </div>

                    <CurrencyInput
                      name="valueWhenNew"
                      initialValue={values.valueWhenNew}
                      setField={(input) => setFieldValue('valueWhenNew', input)}
                      onBlur={handleBlur}
                      error={Boolean(errors.valueWhenNew)}
                    />

                    {errors.valueWhenNew && (
                      <ErrorMessage message={errors.valueWhenNew} />
                    )}
                  </div>
                  <div className="Modal-actions">
                    <Button
                      variant="primary"
                      type="submit"
                      testId="submit-update-vehicle-fields"
                      track={{ questionId: 'submit-update-vehicle-fields' }}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
      <div className="VehicleSearch-input-wrapper">
        <input
          type="text"
          data-testid="mta_search_vehicle_input"
          className={`VehicleSearch-input ${
            hasErrors ? 'VehicleSearch-input-error' : ''
          }`}
          name={name}
          value={vehicleReg}
          title="VehicleSearchInput"
          onKeyDown={() => setHasErrors(false)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setVehicleReg(e.target.value);
          }}
        />
      </div>

      <span className="VehicleSearch-error">
        {hasErrors && (
          <String id="pages.product.mta.modal.edit_vehicle.error" />
        )}
      </span>

      <div className="VehicleSearch-actions">
        <Button
          onClick={searchHandler}
          variant="primary"
          disabled={vehicleReg.trim().length < 1 || loading}
          testId="mta_vehicle_change_confirm"
        >
          {loading ? (
            <Loader />
          ) : (
            <String id="pages.product.mta.modal.edit_vehicle.confirm" />
          )}
        </Button>
      </div>
    </>
  );
}

export default VehicleSearch;
