import React, { useMemo } from 'react';
import String from '../String/String';
import { ICurrency, IInstalmentsPaymentPlan } from 'shared/graphql/api/types';
import './InstalmentsBreakdown.scss';
import classnames from 'classnames';
import Currency from '../Currency/Currency';

interface Props {
  paymentPlan: IInstalmentsPaymentPlan;
  currency: ICurrency;
  textToShow?: 'less' | 'equal' | 'more' | undefined;
  variant?: 'default' | 'compact';
  instalmentDifference?: number;
  productCode: string;
}

export function InstalmentsBreakdown({
  paymentPlan,
  currency,
  textToShow = undefined,
  variant = 'default',
  instalmentDifference = 0,
  productCode,
}: Props) {
  const isMta = textToShow !== undefined;

  const isOwedRemainderToday =
    textToShow === 'less' && paymentPlan.numberOfInstalments === 1;

  const initialPaymentString = useMemo(() => {
    switch (productCode) {
      case 'AZR':
        return 'pages.quote.payment.overview.payment_plan.instalments.initial.AZR';
      case 'NYRS':
        return 'pages.quote.payment.overview.payment_plan.instalments.initial.NYRS';
      case 'NYR':
        return 'pages.quote.payment.overview.payment_plan.instalments.initial.NYR';
      default:
        return 'pages.quote.payment.overview.payment_plan.instalments.initial';
    }
  }, [productCode]);
  return (
    <div
      className={classnames(
        'PaymentPlanBreakdown',
        `${variant === 'compact' ? 'InstalmentsBreakdown-compact' : ''}`
      )}
      data-testid="payment-plan-breakdown-instalments"
    >
      <ul>
        {isMta ? (
          <>
            <li>
              <span>
                <String
                  id={
                    paymentPlan.downpayment.total < 0
                      ? 'pages.quote.payment.overview.payment_plan.instalments.initial.less'
                      : 'pages.quote.payment.overview.payment_plan.instalments.initial.more'
                  }
                />
              </span>
            </li>
            <li>
              <span className="amount">
                <strong>
                  {
                    <Currency
                      currency={currency}
                      value={paymentPlan.downpayment.total}
                    />
                  }
                </strong>
              </span>
            </li>
          </>
        ) : (
          <li>
            <span>
              <String
                id={initialPaymentString}
                values={{
                  value: (
                    <Currency
                      currency={currency}
                      value={paymentPlan.downpayment.total}
                    />
                  ),
                }}
              />
            </span>
          </li>
        )}
        <li>
          <span>
            <String
              id={`pages.quote.payment.overview.payment_plan.instalments.additional${
                isMta ? `.remaining` : ''
              }`}
              values={{
                numberOfInstalments: paymentPlan.numberOfInstalments,
                amount: (
                  <Currency
                    currency={currency}
                    value={paymentPlan.instalment.total}
                  />
                ),
                fee: paymentPlan.instalment.fee,
                formattedFee: (
                  <Currency
                    currency={currency}
                    value={paymentPlan.instalment.fee}
                  />
                ),
              }}
            />
          </span>
        </li>
        {!isMta && (
          <li>
            <span>
              <String
                id={`pages.quote.payment.overview.payment_plan.instalments.recurring`}
              />
            </span>
          </li>
        )}
      </ul>
      {!isMta && (
        <p>
          <String
            id={`pages.quote.payment.overview.payment_plan.instalments.total`}
            values={{
              value: <Currency currency={currency} value={paymentPlan.total} />,
            }}
          />
        </p>
      )}
      {isOwedRemainderToday && instalmentDifference > 0 && (
        <ul>
          <li>
            <span>
              <String
                id={`pages.quote.payment.overview.payment_plan.instalments.remainder.today`}
              />
            </span>
          </li>
          <li>
            <span className="amount">
              <strong>
                {<Currency currency={currency} value={instalmentDifference} />}
              </strong>
            </span>
          </li>
        </ul>
      )}
    </div>
  );
}
