import React, { useState } from 'react';
import { object, string, SchemaOf, number, array } from 'yup';
import {
  Formik,
  Form,
  ErrorMessage as FormikErrorMessage,
  getIn,
  FormikProps,
} from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormTextField from '../components/TextField';
import Accordion from '../components/Accordion';
import FormSelect from '../components/Select';
import FormDriver from '../components/FormDriver';
import AddressSearch from '../components/AddressSearch';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Button from 'components/Buttons/Button';
import {
  LICENSE_AUTHORITIES,
  PRIVATE_HIRE_YEARS,
} from '../constants/FormValues';
import Checkbox from '../components/Checkbox';
import ErrorBox from '../components/ErrorBox';
import { FormAction, formActionChoices } from '../QuoteFormSteps';
import { useUpdateMarketingPreferencesMutation } from 'shared/graphql/api/types';

interface Values {
  insured: {
    telephone: string;
    email: string;
    dob: string;
    privateHireLicense: {
      authority: string;
      yearsHeld: number;
    };
    address: {
      addressLine1: string;
      addressLine2?: string;
      addressLine3?: string;
      city: string;
      county?: string;
      postalCode: string;
      region?: string;
      country?: string;
      province?: string;
      state?: string;
    };
  };
  drivers: {
    uber: {
      rewardsTier?: string;
      rating?: number;
      totalTrips?: number;
      averageMonthlyTrips?: number;
    };
    firstName?: string;
    lastName?: string;
    email?: string;
    telephone?: string;
    driversLicense: {
      dln: string;
    };
  }[];
}

const stepValidationSchema: SchemaOf<Values> = object({
  insured: object({
    telephone: string()
      .required('Phone number is required.')
      .matches(
        /^[+]?[0-9]{8,12}$/g,
        'Invalid phone number. Example +447656754676.'
      ),
    email: string().email('Invalid email').required('Email is required.'),
    dob: string().required('Date of birth is required.'),
    address: object({
      addressLine1: string().required(
        'Please provide your address by clicking "Search Your Address" to continue.'
      ),
      addressLine2: string(),
      addressLine3: string(),
      city: string().required(
        'Please provide your address by clicking "Search Your Address" to continue.'
      ),
      county: string(),
      postalCode: string().required(
        'Please provide your address by clicking "Search Your Address" to continue.'
      ),
      region: string(),
      country: string(),
      province: string(),
      state: string(),
    }),
    privateHireLicense: object().shape({
      authority: string().required(
        'Please select the authority under which you are licenced to drive.'
      ),
      yearsHeld: number().required(
        'Please provide the number of years you have held your private hire licence.'
      ),
    }),
  }).required(),
  drivers: array().of(
    object().shape({
      uber: object({
        rewardsTier: string(),
        rating: number(),
        totalTrips: number(),
        averageMonthlyTrips: number(),
      }),
      firstName: string(),
      lastName: string(),
      email: string(),
      telephone: string(),
      driversLicense: object({
        dln: string().required(
          'Please enter your driving licence number, this should be 16 characters with no spaces.'
        ),
      }),
    })
  ),
});

interface Props {
  data: any;
  dispatch: React.Dispatch<FormAction>;
}

const QuoteFormThree = ({ dispatch, data }: Props) => {
  const [setMarketingPreferences] = useUpdateMarketingPreferencesMutation();
  const [marketingPreferences, setMktPreferences] = useState(false);
  const [submited, setSubmited] = useState(false);
  const handleSubmit = async (values: Values) => {
    await submitMarketingPreferences(values.insured.email);
    dispatch({ action: formActionChoices.NEXT, data: values });
  };

  const handlePrevStep = (values: Values) => {
    dispatch({ action: formActionChoices.PREV, data: values });
  };

  function onSubmitClick() {
    setSubmited(true);
  }

  async function submitMarketingPreferences(email: string) {
    const variables = { input: { email, optIn: marketingPreferences } };
    // @TODO: errors
    await setMarketingPreferences({ variables });
  }

  /**
   * Take user's input if the telephone field is not auto popuated
   * @param e
   * @param values
   * @param handleChange
   * @param setFieldValue
   */
  function handleTelephoneChange(
    e: { target: { value: any } },
    values: any,
    handleBlur: (arg0: any) => void,
    setFieldValue: (arg0: string, arg1: any) => void
  ) {
    handleBlur(e);
    setFieldValue(
      'drivers[0].telephone',
      data.drivers[0].telephone === ''
        ? e.target.value
        : data.drivers[0].telephone
    );
  }

  return (
    <Formik
      validationSchema={stepValidationSchema}
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
      }: FormikProps<Values>) => {
        return (
          <Form>
            <>
              {submited && Object.keys(errors).length > 0 && <ErrorBox />}
              <div className="QuoteForm-step-wrapper">
                <h2 className="sectionTitle">Personal details</h2>

                <>
                  <div className="Question">
                    <h3 className="Question-Questionsubtitle">Full Name</h3>
                    <Accordion
                      title="Not your name?"
                      content="Some details were passed to us from Uber. The name on
                        your insurance policy must match the name you have registered with Uber. To update your name contact Uber at uber.com/gb/en/drive/contact and your details will update with us."
                    />
                    <p>
                      {data?.insured?.firstName} {data?.insured?.lastName}
                    </p>
                  </div>

                  <div className="Question">
                    <div className="Question-subtitle">Phone number</div>
                    <Accordion
                      title="Why do we need your phone number?"
                      content="Some details were passed to us from Uber. The phone number on your insurance policy must match the number you have registered with Uber. To update your number contact Uber at uber.com/gb/en/drive/contact and your details will update with us"
                    />

                    <FormTextField
                      name="insured.telephone"
                      value={values.insured.telephone}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleTelephoneChange(
                          e,
                          values,
                          handleBlur,
                          setFieldValue
                        );
                      }}
                      error={
                        Boolean(getIn(touched, 'insured.telephone')) &&
                        Boolean(getIn(errors, 'insured.telephone'))
                      }
                      placeholder="+447656754676"
                    />
                    <FormikErrorMessage
                      name="insured.telephone"
                      render={(message) => <ErrorMessage message={message} />}
                    />
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      What email address would you like us to send your policy
                      documents to?
                    </div>
                    <div className="Question-subtitle">Email</div>

                    <FormTextField
                      value={values.insured.email}
                      onChange={handleChange}
                      name="insured.email"
                      onBlur={handleBlur}
                      error={
                        Boolean(getIn(touched, 'insured.email')) &&
                        Boolean(getIn(errors, 'insured.email'))
                      }
                    />
                    <FormikErrorMessage
                      name="insured.email"
                      render={(message) => <ErrorMessage message={message} />}
                    />
                  </div>

                  <div className="Question">
                    <div className="Question-title">
                      Your correspondence address
                    </div>
                    <div>
                      This can be different to the vehicle address and will be
                      used to send you any printed communications
                    </div>
                    <div className="Question-subtitle">Address</div>

                    {!values.insured.address.addressLine1 && (
                      <>
                        <AddressSearch
                          setField={setFieldValue}
                          filled={false}
                          fields={['insured.address', 'drivers[0].address']}
                        />
                        <FormikErrorMessage
                          name={`insured.address.addressLine1`}
                          render={(message) => (
                            <ErrorMessage message={message} />
                          )}
                        />
                      </>
                    )}

                    {values.insured.address.addressLine1 && (
                      <>
                        <p>
                          {values.insured.address.addressLine1} <br />
                          {values.insured.address.county} <br />
                          {values.insured.address.postalCode}
                        </p>
                        <AddressSearch
                          setField={setFieldValue}
                          filled={true}
                          fields={['insured.address', 'drivers[0].address']}
                        />
                      </>
                    )}
                  </div>
                </>

                <h2 className="sectionTitle">Driving Experience</h2>

                <div className="Question">
                  <div className="Question-title">
                    What is your driving licence number?
                  </div>
                  <div className="Question-subtitle">
                    Driving Licence Number
                  </div>

                  <FormDriver
                    initialValue={values.drivers[0].driversLicense.dln}
                    handleDriversDetails={(driverData) => {
                      setFieldValue('drivers[0]', {
                        ...values.drivers[0],
                        ...driverData,
                      });
                      setFieldValue('insured.dob', driverData.dob);
                    }}
                  />
                  <FormikErrorMessage
                    name={`drivers.0.driversLicense.dln`}
                    render={(message) => <ErrorMessage message={message} />}
                  />
                </div>

                <div className="Question">
                  <div className="Question-title">
                    Which licensing authority provided you with a private hire
                    driver licence?
                  </div>
                  <div className="Question-subtitle">
                    Private Hire Licensing Authority
                  </div>

                  <Autocomplete
                    disablePortal
                    id="insured.privateHireLicense.authority"
                    options={LICENSE_AUTHORITIES}
                    getOptionLabel={(option) => option.text}
                    onChange={(_e, option) => {
                      setFieldValue(
                        'insured.privateHireLicense.authority',
                        option !== null ? option.value : ''
                      );
                      setFieldValue(
                        'drivers[0].privateHireLicense.authority',
                        option !== null ? option.value : ''
                      );
                    }}
                    renderInput={(params) => (
                      <FormTextField
                        {...params}
                        name="insured.privateHireLicense.authority"
                        value={values.insured.privateHireLicense.authority}
                        error={Boolean(
                          Boolean(
                            getIn(
                              touched,
                              'insured.privateHireLicense.authority'
                            )
                          ) &&
                            Boolean(
                              getIn(
                                errors,
                                'insured.privateHireLicense.authority'
                              )
                            )
                        )}
                      />
                    )}
                  />

                  <FormikErrorMessage
                    name="insured.privateHireLicense.authority"
                    render={(message) => <ErrorMessage message={message} />}
                  />

                  {values.insured.privateHireLicense.authority && (
                    <div className="SubQuestion">
                      <div className="SubQuestion-title">
                        How many years have you held a private hire licence for?
                      </div>
                      <div className="SubQuestion-subtitle">
                        Private Hire Licence Years
                      </div>
                      <FormSelect
                        options={PRIVATE_HIRE_YEARS}
                        onChange={(e) => {
                          setFieldValue(
                            'insured.privateHireLicense.yearsHeld',
                            e.target.value
                          );
                          setFieldValue(
                            'drivers[0].privateHireLicense.yearsHeld',
                            e.target.value
                          );
                        }}
                        name="insured.privateHireLicense.yearsHeld"
                        value={values.insured.privateHireLicense.yearsHeld.toString()}
                        error={Boolean(
                          Boolean(
                            getIn(
                              touched,
                              'insured.privateHireLicense.yearsHeld'
                            )
                          ) &&
                            getIn(
                              errors,
                              'insured.privateHireLicense.yearsHeld'
                            )
                        )}
                      />
                      <FormikErrorMessage
                        name="insured.privateHireLicense.yearsHeld"
                        render={(message) => <ErrorMessage message={message} />}
                      />
                    </div>
                  )}
                </div>

                <div className="QuoteForm-step-conditions">
                  <Checkbox
                    checked={marketingPreferences}
                    name="marketingPreferences"
                    onChange={(_e, checked) => setMktPreferences(checked)}
                    label="Would you like to receive exclusive offers and updates from INSHUR?"
                  />
                </div>
              </div>

              <div className="QuoteForm-step-actions">
                <Button
                  onClick={onSubmitClick}
                  variant="primary"
                  type="submit"
                  testId="form-insured-next"
                  track={{ questionId: 'form-confirm-step-2-insured' }}
                >
                  Continue
                </Button>

                <Button
                  onClick={() => handlePrevStep(values)}
                  variant="secondary"
                  type="submit"
                  testId="form-insured-back"
                  track={{ questionId: 'form-back-step-2-insured' }}
                >
                  Back
                </Button>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default QuoteFormThree;
