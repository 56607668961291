import { useParams } from 'react-router-dom';

import { useNavigation } from 'providers/NavigationProvider';
import { useModal } from 'providers/ModalProvider';
import { useHistory, useProduct, useStrings } from 'hooks';

import './Menu.scss';
import Button from '../Buttons/Button';
import { usePWA } from 'providers/PWAProvider';
import EventManager from 'utils/EventManager';
import { useLocale } from 'providers/LocaleProvider';
import { useAuth } from 'providers/AuthProvider';

interface Props {
  onRestart?(): void;
  showLogout?: boolean;
}

function Menu({ onRestart, showLogout }: Props) {
  const { id } = useParams<{ id: string }>();
  const { get } = useStrings();
  const { close } = useNavigation();
  const { display, dismiss } = useModal();
  const { push } = useHistory();
  const { isAuthenticated } = useAuth();

  const { lang: locale } = useLocale();
  const {
    productHistory: { current: product },
  } = useProduct(id, locale);
  const { prompt, promptToInstall } = usePWA();

  function restart() {
    onRestart?.();
    close();
    dismiss();
  }

  function onCancel() {
    close();
    dismiss();
  }

  function logout() {
    push('/logout');
    close();
    dismiss();
  }

  function onShowContact() {
    display('contact', {
      onCancel,
    });
  }

  function onDownloadPWA() {
    EventManager.track({
      event: 'ButtonPressed',
      productId: id,
      questionId: 'PWA - Menu',
    });
    promptToInstall();
  }

  function onShowConfirmation() {
    display('restart_confirmation', {
      title: get('pages.menu.restart_quote'),
      message: get('pages.menu.restart_confirmation'),
      onCancel,
      actions: (
        <>
          <Button
            variant="secondary"
            onClick={onCancel}
            testId="confirmation-cancel"
          >
            {get('pages.menu.restart_confirmation.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={restart}
            testId="confirmation-confirm"
          >
            {get('pages.menu.restart_confirmation.confirm')}
          </Button>
        </>
      ),
    });
  }

  function onShowLogoutConfirmation() {
    display('logout_confirmation', {
      title: get('pages.menu.logout'),
      message: get('pages.menu.logout.confirmation'),
      onCancel,
      actions: (
        <>
          <Button variant="secondary" onClick={onCancel} testId="logout-cancel">
            {get('pages.menu.logout.cancel')}
          </Button>
          <Button variant="primary" onClick={logout} testId="logout-confirm">
            {get('pages.menu.logout.confirm')}
          </Button>
        </>
      ),
    });
  }

  return (
    <>
      <div className="Menu">
        {onRestart && !product?.location.country && (
          <div
            className="Menu-item"
            onClick={onShowConfirmation}
            data-testid="menu-restart-quote"
          >
            {get('pages.menu.restart_quote')}
          </div>
        )}
        <div
          className="Menu-item"
          onClick={onShowContact}
          data-testid="menu-contact-us"
        >
          {get('pages.menu.contact_us')}
        </div>
        {prompt && (
          <div
            className="Menu-item"
            onClick={onDownloadPWA}
            data-testid="menu-download-app"
          >
            {get('commons.download_app')}
          </div>
        )}
        {showLogout && isAuthenticated && (
          <div
            className="Menu-item"
            onClick={onShowLogoutConfirmation}
            data-testid="menu-logout"
          >
            {get('pages.menu.logout')}
          </div>
        )}
      </div>
    </>
  );
}

export default Menu;
