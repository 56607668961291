import { useGetPolicyAutoRenewQuery } from 'shared/graphql/api/types';

export const useGetPolicyAutoRenew = (id: string) => {
  const { error, loading, data } = useGetPolicyAutoRenewQuery({
    fetchPolicy: 'no-cache',
    variables: {
      policyId: id,
    },
  });

  return {
    error,
    loading,
    policyAutoRenew: data?.policyAutoRenew,
  };
};
