import Page from '../../../../components/Page/Page';
import { useParams } from 'react-router-dom';
import React from 'react';
import { useExpireQuote } from 'hooks';

function EmailLandingPage() {
  const { id: productId } = useParams<{ id: string }>();

  useExpireQuote(false, `/product/${productId}/quote`);

  function PageBody() {
    return <></>;
  }

  return (
    <Page name="EmailLandingPage" slug="Email Landing" padded={false}>
      <PageBody />
    </Page>
  );
}

export default EmailLandingPage;
