import React from 'react';

export default function CarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <path
        id="car-icon"
        data-name="car-icon"
        d="M8.954,53.494A31.5,31.5,0,1,1,63,31.5,31.5,31.5,0,0,1,8.954,53.494ZM3.15,31.5A28.35,28.35,0,0,0,51.494,51.6,28.35,28.35,0,1,0,3.15,31.5Zm35.6,10.763H24.245a5.231,5.231,0,0,1-10.007,0H10.331a1.326,1.326,0,0,1-1.326-1.326V29.207a1.336,1.336,0,0,1,.166-.643s3.012-5.42,4.8-8.623a5.916,5.916,0,0,1,5.273-2.976h15.26a6.871,6.871,0,0,1,5.79,2.964c.848,1.5,3.605,6.53,4.447,8.068l8.36,2.881a1.324,1.324,0,0,1,.893,1.252v8.808a1.326,1.326,0,0,1-1.326,1.326H48.76a5.23,5.23,0,0,1-10.005,0ZM41.141,40.7a2.615,2.615,0,1,0,2.616-2.616A2.619,2.619,0,0,0,41.141,40.7Zm-24.514,0a2.615,2.615,0,1,0,2.616-2.616A2.619,2.619,0,0,0,16.627,40.7Zm32.282-1.089h2.434V33.077l-7.087-2.445v.011h-32.6V35.58h6.4a5.173,5.173,0,0,1,1.188-.147,5.274,5.274,0,0,1,5.151,4.179H38.606a5.264,5.264,0,0,1,10.3,0Zm-37.252,0h2.434a5.2,5.2,0,0,1,.527-1.381H11.657ZM25.992,27.991H41.716c-1.16-2.114-3.053-5.559-3.73-6.76a4.352,4.352,0,0,0-3.48-1.615H25.992Zm-9.706-6.758c-1.122,2.011-2.723,4.89-3.761,6.758H23.34V19.616H19.246A3.315,3.315,0,0,0,16.286,21.233Z"
        transform="translate(0.5 0.5)"
        fill="#1fc4d6"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    </svg>
  );
}
