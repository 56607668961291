import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => theme.global}
  
  body {
    margin: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  html,
  body,
  #root {
    display: flex;
    justify-content: center;
    flex: 1;
    min-height: 100%;
    height: 100%;
  }
  
  #root {
  }
  
  #modal {
    z-index: 250;
  }
  
  .pcatext {
    max-width: 350px !important;
  }
`;

export default GlobalStyle;
