import { get } from 'lodash';
import { IContext, IQuote } from '../types/quote';
import {
  IPackage,
  IPolicy,
  IQuoteDetailsFragment,
  IPolicyDetailFragment,
} from 'shared/graphql/api/types';

export type IConditionals = ICondition[];

export interface ICondition {
  path: string;

  $exists?: boolean;
  $equals?: string | number | boolean | null;
  $in?: Array<string | number | boolean | null>;
  $greater?: string | number | boolean | null;
  $greaterThanInclusive?: string | number | boolean | null;
}

export function RulesEngine(
  conditionals: IConditionals,
  lookup: {
    policy?: IPolicy | IPolicyDetailFragment;
    quote?: IQuote | IQuoteDetailsFragment;
    selectedPackage?: IPackage;
    context?: IContext;
  }
) {
  if (!lookup.policy && !lookup.quote) {
    return false;
  }

  if (!conditionals) {
    return false;
  }

  let logicalEvaluation = false;

  // If any operators fail then the property will be filtered out
  for (const { path, ...operators } of conditionals) {
    // Get lookup value
    const value = get(lookup, path);

    if (operators)
      if ('$exists' in operators) {
        // If $exists, check if value is truthy
        const exists = value !== null && value !== undefined;
        const result = exists === operators.$exists;

        if (!result) {
          return (logicalEvaluation = false);
        }
      }

    // If $equals, check if values are equal
    if ('$equals' in operators) {
      const equal = value === operators.$equals;
      if (!equal) {
        return (logicalEvaluation = false);
      }
    }

    // If $in, check if value is present in the array
    if ('$in' in operators && operators.$in) {
      const present = operators.$in.some((v) => v === value);

      if (!present) {
        return (logicalEvaluation = false);
      }
    }

    // If $greater, check if value is greater than path
    if ('$greater' in operators && operators.$greater) {
      const greater = value >= operators.$greater;
      if (!greater) {
        return (logicalEvaluation = false);
      }
    }

    // If $greater, check if value is greater than path
    if (
      '$greaterThanInclusive' in operators &&
      operators.$greaterThanInclusive
    ) {
      const greaterThanInclusive = value >= operators.$greaterThanInclusive;
      if (!greaterThanInclusive) {
        return (logicalEvaluation = false);
      }
    }

    logicalEvaluation = true;
  }

  return logicalEvaluation;
}
