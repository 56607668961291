import React, { createContext, PropsWithChildren } from 'react';
import {
  useAddToHomescreenPrompt,
  IBeforeInstallPromptEvent,
} from 'hooks/useAddToHomescreenPrompt';
import { noop } from 'lodash';

interface IPWAContext {
  prompt: IBeforeInstallPromptEvent | null;
  promptToInstall: () => void;
}

export const PWAContext = createContext<IPWAContext>({
  prompt: null,
  promptToInstall: noop,
});

export const usePWA = () => React.useContext(PWAContext);

export function PWAProvider({ children }: PropsWithChildren<unknown>) {
  const { prompt, promptToInstall } = useAddToHomescreenPrompt();

  return (
    <PWAContext.Provider value={{ prompt, promptToInstall }}>
      {children}
    </PWAContext.Provider>
  );
}

export default PWAProvider;
