import React, { PropsWithChildren } from 'react';

import './Tick.scss';

interface Props {
  title?: string;
}

function Tick({ children }: PropsWithChildren<Props>) {
  return (
    <div className="TickContent">
      <span className="Tick"></span>
      {children && children}
    </div>
  );
}

export default Tick;

export function TickLabel({ children }: PropsWithChildren<Props>) {
  return <span className="TickContent-label">{children}</span>;
}
