import React from 'react';
import classNames from 'classnames';

import './Banner.scss';

interface Props {
  message: JSX.Element | string;
  severity?: 'alert' | 'warning' | 'info';
}

function Banner({ message, severity = 'warning' }: Props) {
  return (
    <>
      <header
        className={classNames('Banner', {
          BannerAlert: severity === 'alert',
          BannerWarning: severity === 'warning',
          BannerInfo: severity === 'info',
        })}
      >
        <div className="Banner-container">{message}</div>
      </header>
    </>
  );
}

export default Banner;
