import { Action, Step } from 'shared/types/question';
import { IContext, IDatasheet } from '../types/quote';

export function answerQuestion(
  snapshotVersion: string,
  id: string,
  answer: string | object
): QuoteAnswerQuestionAction {
  return {
    type: 'quote/ANSWER_QUESTION',
    payload: {
      snapshotVersion,
      id,
      answer,
    },
  };
}

export function toggleInput(
  show: boolean,
  delay?: number
): QuoteToggleInputAction {
  return {
    type: 'quote/TOGGLE_INPUT',
    payload: {
      show,
      delay,
    },
  };
}

export function setStep(step: Step): QuoteSetStepAction {
  return {
    type: 'quote/SET_STEP',
    payload: {
      step,
      snapshotVersion: 'v1',
    },
  };
}

export function processAction(
  snapshotVersion: string,
  action: Action
): QuoteProcessActionAction {
  return {
    type: 'quote/PROCESS_ACTION',
    payload: {
      action,
      snapshotVersion,
    },
  };
}

export function updateDatasheet(
  snapshotVersion: string,
  datasheet: IDatasheet
): UpdateDatasheetAction {
  return {
    type: 'quote/UPDATE_DATASHEET',
    payload: {
      datasheet,
      snapshotVersion,
    },
  };
}

export function updateContext(context: IContext): UpdateContextAction {
  return {
    type: 'quote/UPDATE_CONTEXT',
    payload: {
      context,
    },
  };
}

export function mergeDatasheet(
  snapshotVersion: string,
  datasheet: IDatasheet
): MergeDatasheetAction {
  return {
    type: 'quote/MERGE_DATASHEET',
    payload: {
      datasheet,
      snapshotVersion,
    },
  };
}

export function undo(snapshotVersion: string): QuoteUndoAction {
  return {
    type: 'quote/UNDO',
    payload: {
      snapshotVersion,
    },
  };
}

export const actions = {
  answerQuestion,
  toggleInput,
  setStep,
  processAction,
  updateDatasheet,
  updateContext,
  mergeDatasheet,
  undo,
};

export interface QuoteAnswerQuestionAction {
  type: 'quote/ANSWER_QUESTION';
  payload: {
    snapshotVersion: string;
    id: string;
    answer: string | object;
    recordUpdate?: boolean;
  };
}

export interface QuoteUndoAction {
  type: 'quote/UNDO';
  payload: {
    snapshotVersion: string;
  };
}

export interface QuoteToggleInputAction {
  type: 'quote/TOGGLE_INPUT';
  payload: {
    show: boolean;
    delay?: number;
  };
}

export interface QuoteSetStepAction {
  type: 'quote/SET_STEP';
  payload: {
    step: Step;
    snapshotVersion: string;
  };
}

export interface QuoteProcessActionAction {
  type: 'quote/PROCESS_ACTION';
  payload: {
    action: Action;
    snapshotVersion: string;
  };
}

export interface UpdateDatasheetAction {
  type: 'quote/UPDATE_DATASHEET';
  payload: {
    snapshotVersion: string;
    datasheet: IDatasheet;
  };
}

export interface UpdateContextAction {
  type: 'quote/UPDATE_CONTEXT';
  payload: {
    context: IContext;
  };
}

export interface MergeDatasheetAction {
  type: 'quote/MERGE_DATASHEET';
  payload: {
    snapshotVersion: string;
    datasheet: IDatasheet;
  };
}

export type IQuoteActions =
  | QuoteAnswerQuestionAction
  | QuoteToggleInputAction
  | QuoteSetStepAction
  | QuoteProcessActionAction
  | UpdateDatasheetAction
  | UpdateContextAction
  | MergeDatasheetAction
  | QuoteUndoAction;
