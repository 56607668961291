import String from '../String/String';
import configuration from 'configuration';
import { IPackage, IQuote } from 'types/quote';
import { useLocale } from 'providers/LocaleProvider';
import { FileHandlerMethod, fileHandlerMethod } from '../../utils/fileUtils';
import QuoteDocument from './QuoteDocument';
import { getDisplayName } from 'utils/documentUtils';

interface Props {
  quote: IQuote;
  package: IPackage;
}

const FILE_HANDLER_METHOD = fileHandlerMethod();

function QuoteDocuments({ quote, package: _package }: Props) {
  const { lang: language } = useLocale();
  const { duration } = quote.datasheet.policy;
  const { documentTypes } = quote.product;
  const isUkProduct = quote.product.location.country === 'GB';

  const quoteDocumentTypes = documentTypes.filter(
    (docType) =>
      docType.dataModel === 'QUOTE' &&
      (docType.packageId === _package.id || !docType.packageId)
  );

  return (
    <div className="PaymentOverview-links">
      <h4>
        <String id="pages.quote.summary.please_review" />
      </h4>
      {isUkProduct && (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="PaymentOverview-links-wrapper"
          data-testid="ipid-document"
          href={`${configuration.documents.url}/products/${quote.product.code}/packages/${_package.id}/ipid.${duration}.${language}.pdf`}
        >
          <div className="PaymentOverview-link-text">
            <String id="pages.quote.summary.view_ipid" />
          </div>
          <i className="link-icon" />
        </a>
      )}
      {quoteDocumentTypes.map((doc) => (
        <QuoteDocument
          typeId={doc.id}
          quote={quote}
          name={doc.name}
          key={doc.id}
          buttonClassName="PaymentOverview-links-wrapper"
        >
          <div className="PaymentOverview-link-text">
            {doc.packageId ? (
              <String id="pages.quote.summary.view_ipid" />
            ) : (
              <String
                id={
                  FILE_HANDLER_METHOD === FileHandlerMethod.DOWNLOAD
                    ? 'pages.quote.summary.download_quote_document'
                    : 'pages.quote.summary.view_quote_document'
                }
                values={{
                  name: getDisplayName(doc.name, quote.product.code),
                }}
              />
            )}
          </div>
          <i className="link-icon" />
        </QuoteDocument>
      ))}
    </div>
  );
}

export default QuoteDocuments;
