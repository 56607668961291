import React, { PropsWithChildren } from 'react';

import Modal from '../Modal';

import './ConfirmationModal.scss';

interface Props {
  title: string;
  message: string;
  onCancel(): void;
  actions: JSX.Element;
}

function ConfirmationModal({
  title,
  message,
  onCancel,
  actions,
}: PropsWithChildren<Props>) {
  return (
    <Modal
      title={title}
      className="ConfirmationModal"
      data-testid="confirmation-modal"
      onClose={onCancel}
      actions={actions}
      actionsFlow="column"
    >
      {message}
    </Modal>
  );
}

export default ConfirmationModal;
