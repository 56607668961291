import React, { ReactElement, useState } from 'react';
import UnstyledButton from '../../../Buttons/UnstyledButton';
import String from '../../../String/String';
import { AnswerQuestion } from '../../../../providers/QuoteProvider';
import { EligibilityQuestion } from '@inshur/apis/steps';
import EligibilityModal from './EligibilityModal/EligibilityModal';

interface Props {
  question: EligibilityQuestion;
  onAnswer: AnswerQuestion<any>;
}

function EligibilityInput({ question, onAnswer }: Props): ReactElement {
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <div className="Chatbot-actions">
      <EligibilityModal
        active={displayModal}
        onClose={() => setDisplayModal(false)}
        onAnswer={onAnswer}
        question={question}
      />
      <UnstyledButton
        className="Chatbot-action"
        onClick={() => setDisplayModal(true)}
        data-testid="eligibility-modal-button"
      >
        <String id="pages.quote.options.continue" />
      </UnstyledButton>
    </div>
  );
}

export default EligibilityInput;
