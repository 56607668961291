import React from 'react';

import InfoBox, { InfoBoxType } from 'components/InfoBox/InfoBox';
import { useStrings } from 'hooks';

function ErrorBox() {
  const { get } = useStrings();

  return (
    <div className="QuoteForm-step-infobox">
      <div className="InfoBox-wrapper">
        <InfoBox
          title={get('pages.quote.form.error.title')}
          text={get('pages.quote.form.error.body')}
          type={InfoBoxType.Error}
          testId="quote-form-infobox"
          allowClose={false}
        />
      </div>
    </div>
  );
}

export default ErrorBox;
