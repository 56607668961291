import {
  ApiParam,
  ConditionStep,
  ConditionStepTypeEnum,
  DefaultActionTypeEnum,
  EligibilityQuestionTypeEnum,
  ForkAction,
  ForkActionTypeEnum,
  StaticApiParam,
  SegmentStep,
  SegmentStepTypeEnum,
} from '@inshur/apis/steps/api';
import {
  IContext,
  IContextLookup,
  IDatasheet,
  IKey,
  IQuote,
  ISchema,
  ISchemaConfiguration,
} from 'types/quote';
import { get, first } from 'lodash';

import {
  Action,
  ActionStep,
  ActionStepTypeEnum,
  ChoiceQuestion,
  ChoiceQuestionTypeEnum,
  CopyActionTypeEnum,
  Question,
  QuestionStep,
  QuestionStepTypeEnum,
  SetActionTypeEnum,
  Step,
} from 'shared/types/question';
import {
  getUpdatedValue,
  getValueAtPath,
  setValueAtPath,
} from './contextUtils';
import { getSchemaConfiguration, getSchemaDefaults } from './schemaUtils';
import { IUser } from 'types/user';
import { formatDateString, isAfter, isBefore } from 'shared/utils/dateUtils';
import {
  IFinancePaymentPlan,
  IGetProductQuery,
  IInstalmentsPaymentPlan,
  ILocale,
  IPackageValuationFragment,
  IProductDetailsFragment,
  IQuotePackageFragment,
  IFlexWalletPaymentPlan,
  IInFullPaymentPlan,
  IPolicyDetailFragment,
} from 'shared/graphql/api/types';
import { IProduct } from '../types/product';
import EventManager from './EventManager';
import { QuoteType } from '../providers/TrackingProvider';
import { toTitleCase } from './stringUtils';
import { formatCurrency, getValuationRangeString } from './currencyUtils';
import configuration from 'configuration';
import { IFullLocale } from 'providers/LocaleProvider';
import { InstalmentPrices } from 'components/OpenQuote/OpenQuotePlanPrices';
import { PaymentPlanEnum } from 'enums/PaymentPlan';
import { LocationCountryEnum } from '@inshur/apis/product';

const debug = require('debug')('customer-web:quote');

/**
 * Is the question a Choice?
 * @param question
 */
export function isChoice(question: Question): question is ChoiceQuestion {
  return question.type === ChoiceQuestionTypeEnum.choice;
}

/**
 * Is the step a Question?
 * @param step
 */
export function isQuestion(step: Step): step is QuestionStep {
  return step.type === QuestionStepTypeEnum.question;
}

/**
 * Is the step an Action?
 * @param step
 */
export function isAction(step: Step): step is ActionStep {
  return step.type === ActionStepTypeEnum.action;
}

/**
 * Is the step a Condition?
 * @param step
 */
export function isCondition(step: Step): step is ConditionStep {
  return step.type === ConditionStepTypeEnum.condition;
}

/**
 * Is the action a Fork?
 * @param action
 */
export function isFork(action: Action): action is ForkAction {
  return action.type === ForkActionTypeEnum.fork;
}

/**
 * Is the step a Segment?
 * @param step
 */
export function isSegment(step: Step): step is SegmentStep {
  return step.type === SegmentStepTypeEnum.segment;
}

/**
 * Create an initial context for a quote based on the schema and user
 * @param schema
 * @param user
 * @param utm local storage object
 * @param initialValue
 */
export function createInitialContext(
  schema: any,
  user: IUser,
  utm: any = {},
  initialValue?: any
): IContext {
  const initial = {
    user,
    utm,
    datasheet: {
      ...initialValue,
    },
  };

  for (const [path, value] of getSchemaDefaults(schema)) {
    initial.datasheet = setValueAtPath(initial.datasheet, path, value);
  }

  return initial;
}

/**
 * Updates a context object using the current question and an answer
 * @param context
 * @param step
 * @param answer
 */
export function updateContext(
  context: IContext,
  step: Step,
  answer: any
): IContext {
  if (!isQuestion(step)) {
    return context;
  }

  const { question } = step;
  const { answer_path } = question;

  // If there is no answer path, leave
  if (!answer_path) {
    return context;
  }

  const lookup: IContextLookup = { $: { ...context } };

  // If answer_path is not an array, make it an array
  const paths = Array.isArray(answer_path) ? answer_path : [answer_path];

  const { $ } = paths.reduce((lookup, path) => {
    const updated = getUpdatedValue(lookup, path, question, answer);

    return setValueAtPath(lookup, path, updated);
  }, lookup);

  return $;
}

/**
 * Process an Action against the context
 * @param context
 * @param action
 */
export function processAction(context: IContext, action: Action) {
  // If action is COPY
  if (action.type === CopyActionTypeEnum.copy) {
    debug('processAction() - COPY - ', action);
    const { from, to } = action;
    // Get the value `from` the context object
    const value = getValueAtPath({ $: context }, from);

    // Update the value `to` in the context object
    const { $ } = setValueAtPath({ $: context }, to, value);

    return $;
  }

  // If action is SET
  if (
    action.type === SetActionTypeEnum.set ||
    action.type === DefaultActionTypeEnum.setDefault
  ) {
    const { path, value } = action;

    // Set a value at `path` from the action
    const { $ } = setValueAtPath({ $: context }, path, value);

    return $;
  }

  return context;
}

/**
 * Get the next step based on the response from the previous step
 * @param steps
 * @param current
 * @param productId
 * @param quoteType
 * @param response
 */
export function getNextStep(
  steps: Step[],
  current: Step,
  productId: string,
  quoteType: QuoteType,
  response?: string
) {
  // If there is no current, get the first step
  if (!current) {
    return steps[0];
  }

  // Get the question GoTo based on the response
  const goTo = getGoTo(current, response);

  switch (goTo) {
    // If NEXT, find the next item (only for testing)
    case 'NEXT': {
      return steps[steps.indexOf(current) + 1];
    }
    case 'END_QUOTE': {
      // TODO: handle this
      EventManager.track({
        event: 'QuoteDeclined',
        productId: productId,
        quoteType,
      });
      return undefined;
    }
    case 'GO_TO_DASHBOARD': {
      return undefined;
    }
    default: {
      return steps.find((q) => q.id === goTo);
    }
  }
}

/**
 * Map a schema type to a JS type
 * @param type
 */
export function getSchemaType(type: string) {
  // Map integer from server, to number in JS
  if (type === 'integer') {
    return 'number';
  }

  return type;
}

/**
 * Validate an answer against it's schema configuration
 * @param value
 * @param configuration
 */
export function validateAnswer(
  value: any,
  configuration: ISchemaConfiguration
) {
  // Map the schema type to JS type
  const type = getSchemaType(configuration.type);

  // If there's no value and it's not required, pass
  if (!configuration.required && typeof value === 'undefined') {
    return true;
  }

  // If it is required and value is undefined, fail
  if (configuration.required && typeof value === 'undefined') {
    return false;
  }

  // If the type is not the same, and it's not an array, fail (array type === 'object')
  if (
    typeof value !== type &&
    (configuration.type !== 'array' || !Array.isArray(value))
  ) {
    return false;
  }

  // If is string and is not in enum array, fail
  if (
    configuration.type === 'string' &&
    configuration.enums &&
    !configuration.enums.includes(value)
  ) {
    return false;
  }

  // If is type string and the regex doesn't match, fail
  if (
    (configuration.type === 'string' || configuration.type === 'integer') &&
    configuration.pattern &&
    !new RegExp(configuration.pattern).test(value)
  ) {
    return false;
  }

  // Return true if all pass
  return true;
}

/**
 * Validate all datasheet properties against the datasheet schema
 * @param datasheet
 * @param schema
 */
export function validateDatasheet(datasheet: IDatasheet, schema: ISchema) {
  const pass: any[] = [];
  const fail: any[] = [];

  for (const [path, configuration] of Object.entries(schema)) {
    const value = get(datasheet, path);

    const valid = validateAnswer(value, configuration);

    if (!valid) {
      fail.push({
        path,
        configuration,
        value,
      });
    } else {
      if (
        !value ||
        configuration.type === 'object' ||
        configuration.type === 'array'
      ) {
        continue;
      }

      pass.push({
        path,
        configuration,
        value,
      });
    }
  }

  debug('validate()', { pass, fail });

  return fail.length === 0;
}

/**
 * Go the GoTo value of a step based on the value of the current step
 * @param current
 * @param value
 */
export function getGoTo(current: Step, value?: string): string {
  if (isAction(current)) {
    if (!isFork(current.action)) {
      return current.goTo;
    }

    const { options } = current.action;
    let option =
      options.find((o) => o.value === value) ||
      options.find((o) => o.value === undefined);

    if (!option) {
      throw new Error(`No option found for value: ${value}`);
    }

    return option.goTo;
  }

  if (isCondition(current)) {
    const { condition } = current;
    const { if: ifCondition, else: elseAction } = condition;

    if ('$empty' in ifCondition && ifCondition.$empty) {
      return value === undefined || value === null || value === ''
        ? ifCondition.goTo
        : elseAction;
    }

    if ('$equals' in ifCondition && ifCondition.$equals) {
      return value === ifCondition.$equals.value
        ? ifCondition.goTo
        : elseAction;
    }

    if ('$before' in ifCondition && ifCondition.$before) {
      return value !== null &&
        value !== undefined &&
        isBefore(new Date(value), new Date(ifCondition.$before.value))
        ? ifCondition.goTo
        : elseAction;
    }

    if ('$after' in ifCondition && ifCondition.$after) {
      return value !== null &&
        value !== undefined &&
        isAfter(new Date(value), new Date(ifCondition.$after.value))
        ? ifCondition.goTo
        : elseAction;
    }

    if ('$greater' in ifCondition && ifCondition.$greater) {
      return value !== null &&
        value !== undefined &&
        Number(value) > Number(ifCondition.$greater.value)
        ? ifCondition.goTo
        : elseAction;
    }

    if ('$lessThan' in ifCondition && ifCondition.$lessThan) {
      return value !== null &&
        value !== undefined &&
        Number(value) < Number(ifCondition.$lessThan.value)
        ? ifCondition.goTo
        : elseAction;
    }

    return elseAction;
  }

  if (isSegment(current)) {
    // We assume that if a segment step is left in the chatflow then it's one
    // that should be skipped.
    return current.segment.onFinishGoTo;
  }

  if (
    !(
      isChoice(current.question) ||
      current.question.type === EligibilityQuestionTypeEnum.eligibility
    )
  ) {
    return current.question.goTo;
  }

  const { choices } = current.question;
  const choice = choices.find((f) => f.text === value);

  if (!choice) {
    throw new Error('No choice found for that answer');
  }

  return choice.goTo;
}

const MESSAGE_VARIABLES = /{([^}]+)}/gim;
const WHITE_SPACE_REGEX = /^\s+$/gm;

/**
 * Extract all variables from a string
 * Example: Hello, {$.user.firstName} => ["$.user.firstName"]
 * @param string
 */
function getVariables(string: string) {
  const matches = [];

  let match = MESSAGE_VARIABLES.exec(string);

  while (match != null) {
    const [replace, path] = match;

    matches.push({
      replace,
      path,
    });

    match = MESSAGE_VARIABLES.exec(string);
  }

  return matches;
}

/**
 * Gets the formatted value from a path based on the schema type
 * @param context
 * @param schema
 * @param path
 * @param locale
 */
export function getFormattedValue(
  context: IContextLookup,
  schema: ISchema,
  path: string,
  locale: ILocale
) {
  let value = getValueAtPath(context, path);
  return formatValue(value, schema, path, locale);
}

export function formatValue(
  value: string,
  schema: ISchema,
  path: string,
  locale: ILocale,
  currency?: string
) {
  const configuration = getSchemaConfiguration(path, schema);
  if (isEnum(value, configuration)) {
    return toTitleCase(value);
  }

  if (
    (path.includes('vehicles') && path.includes('value')) ||
    path.includes('amount')
  ) {
    if (value && currency) {
      return formatCurrency(Number(value), locale, currency);
    }
  }

  if (!configuration) {
    return value;
  }

  if (configuration.type === 'string' && configuration.format === 'date') {
    return formatDateString(value, 'dd/MM/yyyy', locale);
  }

  if (configuration.type === 'string' && configuration.format === 'date-time') {
    return formatDateString(value, 'dd/MM/yyyy', locale);
  }

  return value;
}

function isEnum(value?: string, configuration?: ISchemaConfiguration | null) {
  if (value && configuration) {
    if (
      configuration.type === 'string' &&
      configuration.enums &&
      configuration.enums.includes(value)
    ) {
      return true;
    }
  }

  if (
    value &&
    value.toString().toUpperCase() === value.toString() &&
    value.toString().includes('_')
  ) {
    return true;
  }

  return false;
}

export function formatLine(
  line: string,
  variables: { replace: string; value: string }[]
) {
  let formatted_line = line;

  // Loop through all the variables in the line and replace them
  for (const { replace, value } of variables) {
    if (value === undefined) {
      formatted_line = formatted_line.replace(replace, `**${replace}**`);
    } else {
      formatted_line = formatted_line.replace(replace, value);
    }
  }

  return formatted_line;
}

/**
 * Format a message for a question.
 * Example: Hello {$.user.firstName}, how are you?
 * @param message
 * @param $
 * @param schema
 * @param locale
 */
export function formatMessage(
  message: string,
  $: IContext,
  schema: ISchema,
  locale: ILocale
) {
  const context = { $ };

  // Get all variables and their values in the message
  const variables = getVariables(message).map((variable) => {
    return {
      ...variable,
      value: getFormattedValue(context, schema, variable.path, locale),
    };
  });

  const formatted_lines = [];
  const lines = message.split('\n');

  // Loop through all the lines
  for (const line of lines) {
    const has_variables = line.match(MESSAGE_VARIABLES);

    // If the line doesn't have a variable, ignore it
    if (!has_variables) {
      formatted_lines.push(line);
      continue;
    }

    const formatted = formatLine(line, variables);

    // If there is only a white space left after the variables are parsed, remove the line
    if (!formatted || formatted.match(WHITE_SPACE_REGEX)) {
      continue;
    }

    // Add the formatted line to the result
    formatted_lines.push(formatted);
  }

  // Join all the lines back by a carriage return
  return formatted_lines.join('\n');
}

/**
 * Returns is a product has a valid steps configuration
 * @param product
 */
export function hasValidSteps(
  product: IGetProductQuery['product']
): product is IProduct {
  return !!product.steps?.length;
}

export const KEY_SEPARATOR = '~';

/**
 * Create a key
 * @param id
 * @param version
 */
export function createKey(id?: string, version?: string | null): string {
  return [id, KEY_SEPARATOR, version].join('');
}

/**
 * Parse key query string
 * @param key
 */
export function parseKey<K extends IKey>(key?: string): K | undefined {
  if (!key) {
    return undefined;
  }

  const [id, version] = key.split(KEY_SEPARATOR);

  if (!id) {
    return undefined;
  }

  // TODO: Re-add when API fully deployed
  // if (!version) {
  //   throw new ApplicationError(
  //     'Key error',
  //     ErrorCodes.ASSERTION_ERROR,
  //     "Missing required 'version'"
  //   );
  // }

  return {
    id,
    // TODO: Remove fallback when API fully deployed
    version: version || null,
  } as K;
}

/**
 * Creates an api input from a params array
 * @param $
 * @param params
 */
export function createApiInput(
  $: IContext,
  params: Array<StaticApiParam | ApiParam> = []
) {
  return params.reduce((params, param) => {
    const { name } = param;

    const value = getParamValue(param, $);

    return {
      ...params,
      [name]: value,
    };
  }, {});
}

export function openQuotesRenewalsEnabled() {
  return configuration.enableOpenQuotesRenewals;
}

export function getParamValue(param: StaticApiParam | ApiParam, $: IContext) {
  if ('valuePath' in param && param.valuePath) {
    return getValueAtPath({ $ }, param.valuePath);
  }

  if ('value' in param) {
    return param.value;
  }

  return undefined;
}

/**
 * Return quote type based on URL parameters
 * @param renewalOf
 * @param mtaOf
 * @param migrationOf
 */
export function getQuoteType(
  renewalOf?: string | IKey | null,
  mtaOf?: string | IKey | null,
  migrationOf?: string
): QuoteType {
  if (renewalOf) {
    return QuoteType.RENEWAL;
  }
  if (mtaOf) {
    return QuoteType.MTA;
  }

  if (migrationOf) {
    return QuoteType.MIGRATION;
  }

  return QuoteType.NEW_BUSINESS;
}

export const hasQuoteExpired = (quote: IQuote) =>
  quote && new Date() > new Date(quote.validUntil);

export function getValuationRange(
  packages: Array<IQuotePackageFragment>,
  locale: IFullLocale,
  product: IProductDetailsFragment
) {
  const valuationRange = packages
    .filter((_package) => _package.status !== 'DECLINED')
    .map((_package) => _package.valuation) as Array<IPackageValuationFragment>;
  return getValuationRangeString(valuationRange, locale, product.currency);
}

export function findInstalmentsPaymentPlan(
  quotePackage: IQuotePackageFragment
) {
  return quotePackage?.paymentPlans?.find((plan) => {
    return plan?.paymentPlan === 'INSTALMENTS';
  }) as IInstalmentsPaymentPlan | undefined;
}

export function findFinancePaymentPlan(quotePackage: IQuotePackageFragment) {
  return quotePackage?.paymentPlans?.find((plan) => {
    return plan?.paymentPlan === 'FINANCE';
  }) as IFinancePaymentPlan | undefined;
}

export function findFlexPaymentPlan(quotePackage: IQuotePackageFragment) {
  return quotePackage?.paymentPlans?.find((plan) => {
    return plan?.paymentPlan === 'FLEX_WALLET';
  }) as IFlexWalletPaymentPlan | undefined;
}

export function findInFullPaymentPlan(quotePackage: IQuotePackageFragment) {
  return quotePackage?.paymentPlans?.find((plan) => {
    return plan?.paymentPlan === PaymentPlanEnum.IN_FULL;
  }) as IInFullPaymentPlan | undefined;
}

// Temp FE logic until moved to product config
export function getDefaultPaymentPlan(quotePackage: IQuotePackageFragment) {
  return (
    (findInFullPaymentPlan(quotePackage)?.paymentPlan as PaymentPlanEnum) ??
    (first(quotePackage?.paymentPlans)?.paymentPlan as
      | PaymentPlanEnum
      | undefined)
  );
}

export function getDepositTax(paymentPlan: IFlexWalletPaymentPlan) {
  // Temp solution until the BE can send us the raw Wakam values (or send the IPT value to FE)
  const totalPremium = paymentPlan.policyBindAmount;
  const totalIpt = totalPremium * (12 / 112);
  return totalIpt;
}

export const getLowestPricedPackage = (packages: IQuotePackageFragment[]) =>
  packages.reduce((previousItem, currentItem) => {
    const validCurrentItem =
      currentItem &&
      currentItem.status !== 'DECLINED' &&
      currentItem.valuation?.total
        ? currentItem.valuation?.total
        : undefined;

    const validPreviousItem =
      previousItem &&
      previousItem.status !== 'DECLINED' &&
      previousItem.valuation?.total
        ? previousItem.valuation?.total
        : undefined;

    if (
      (validCurrentItem &&
        validPreviousItem &&
        validCurrentItem < validPreviousItem) ||
      (validCurrentItem && !validPreviousItem)
    ) {
      return currentItem;
    }

    return previousItem;
  }, packages[0]);

export const getLowestFinancePlanPrices = (
  packages: Array<IQuotePackageFragment>
) => {
  const quotePackage = getLowestPricedPackage(packages);
  const paymentPlan = findFinancePaymentPlan(quotePackage);

  if (!paymentPlan || quotePackage.status === 'DECLINED') {
    return { inFullAmount: 0, instalmentAmount: 0 };
  }

  return {
    inFullAmount: quotePackage.valuation?.total ?? 0,
    instalmentAmount: paymentPlan.financeInstalment.total,
  };
};

export const getLowestInstalmentPlanPrices = (
  packages: Array<IQuotePackageFragment>
): InstalmentPrices => {
  const quotePackage = getLowestPricedPackage(packages);
  const paymentPlan = findInstalmentsPaymentPlan(quotePackage);

  if (!paymentPlan || quotePackage.status === 'DECLINED') {
    return { inFullAmount: 0, instalmentAmount: 0 };
  }
  return {
    inFullAmount: quotePackage.valuation?.total ?? 0,
    instalmentAmount: paymentPlan.instalment.total,
  };
};

// Sorry, this is horrible but it's only a temporary fix until these values come
// from the backend or some other permanent solution is agreed on
export function getPriceBreakdown(totalPremium: number, isAnnual: boolean) {
  const premiumExcludingTax = totalPremium / 1.12;
  const premiumTax = totalPremium - premiumExcludingTax;
  const premiumExcludingCommission = premiumExcludingTax * 0.84;
  const commission = premiumExcludingTax * 0.16;

  const publicLiabilityAnnualExcludingTax = 5000;
  const publicLiability30DaysExcludingTax =
    (publicLiabilityAnnualExcludingTax / 365) * 30;

  const initialisedBreakdown = {
    totalPremium,
    premiumTax,
    premiumExcludingTax,
    premiumExcludingCommission,
    commission: commission,
    publicLiabilityExcludingTax: isAnnual
      ? publicLiabilityAnnualExcludingTax
      : publicLiability30DaysExcludingTax,
    publicLiabilityCommission: 0,
    publicLiabilityTax: 0,
    publicLiabilityTotal: 0,
    premiumExcludingCommissionAndLiability: 0,
    ncdProtectionTotal: 0,
    ncdProtectionTax: 0,
    insurancePolicyTotal: 0,
    insurancePolicyTax: 0,
    insurancePolicyTotalNoPublicLiability: 0,
    insurancePolicyTaxNoPublicLiability: 0,
    insurancePolicyTotalWithPLNoNcd: 0,
    insurancePolicyTaxWithPLNoNcd: 0,
  };

  const breakdown: QuoteBreakdown = calculateBreakdown(initialisedBreakdown);

  Object.keys(breakdown).forEach((key: any) => {
    // @ts-ignore
    breakdown[key] = Math.round(breakdown[key]);
  });
  return breakdown;
}

function calculateBreakdown(initialisedBreakdown: any): QuoteBreakdown {
  const breakdown = Object.assign({}, initialisedBreakdown);

  const publicLiabilityCommission =
    initialisedBreakdown.publicLiabilityExcludingTax / 0.84 -
    initialisedBreakdown.publicLiabilityExcludingTax;
  const publicLiabilityTax =
    (initialisedBreakdown.publicLiabilityExcludingTax +
      publicLiabilityCommission) *
    0.12;
  const publicLiabilityTotal =
    initialisedBreakdown.publicLiabilityExcludingTax +
    publicLiabilityCommission +
    publicLiabilityTax;
  const premiumExcludingCommissionAndLiability =
    initialisedBreakdown.premiumExcludingCommission -
    initialisedBreakdown.publicLiabilityExcludingTax;
  const ncdProtectionExcludingCommissionAndTax =
    premiumExcludingCommissionAndLiability * 0.05;
  const ncdProtectionCommission =
    ncdProtectionExcludingCommissionAndTax / 0.84 -
    ncdProtectionExcludingCommissionAndTax;
  const ncdProtectionTax =
    (ncdProtectionExcludingCommissionAndTax + ncdProtectionCommission) * 0.12;
  const ncdProtectionTotal =
    ncdProtectionExcludingCommissionAndTax +
    ncdProtectionCommission +
    ncdProtectionTax;
  const insurancePolicyTotal =
    breakdown.totalPremium - publicLiabilityTotal - ncdProtectionTotal;
  const insurancePolicyTax =
    breakdown.premiumTax - publicLiabilityTax - ncdProtectionTax;
  const insurancePolicyTotalNoPublicLiability =
    breakdown.totalPremium - ncdProtectionTotal;
  const insurancePolicyTaxNoPublicLiability =
    breakdown.premiumTax - ncdProtectionTax;
  const insurancePolicyTotalWithPLNoNcd =
    breakdown.totalPremium - publicLiabilityTotal;
  const insurancePolicyTaxWithPLNoNcd =
    breakdown.premiumTax - publicLiabilityTax;

  breakdown.publicLiabilityCommission = publicLiabilityCommission;
  breakdown.publicLiabilityTax = publicLiabilityTax;
  breakdown.publicLiabilityTotal = publicLiabilityTotal;
  breakdown.premiumExcludingCommissionAndLiability =
    premiumExcludingCommissionAndLiability;
  breakdown.ncdProtectionTax = ncdProtectionTax;
  breakdown.ncdProtectionTotal = ncdProtectionTotal;
  breakdown.insurancePolicyTotal = insurancePolicyTotal;
  breakdown.insurancePolicyTax = insurancePolicyTax;
  breakdown.insurancePolicyTotalNoPublicLiability =
    insurancePolicyTotalNoPublicLiability;
  breakdown.insurancePolicyTaxNoPublicLiability =
    insurancePolicyTaxNoPublicLiability;
  breakdown.insurancePolicyTotalWithPLNoNcd = insurancePolicyTotalWithPLNoNcd;
  breakdown.insurancePolicyTaxWithPLNoNcd = insurancePolicyTaxWithPLNoNcd;

  return getDisplayValues(breakdown);
}

// Strips out values we don't currently require for display but were needed
// for calculations above;
function getDisplayValues(breakdown: any): QuoteBreakdown {
  return {
    insurancePolicyTotal: breakdown.insurancePolicyTotal,
    insurancePolicyTax: breakdown.insurancePolicyTax,
    insurancePolicyTotalNoPublicLiability:
      breakdown.insurancePolicyTotalNoPublicLiability,
    insurancePolicyTaxNoPublicLiability:
      breakdown.insurancePolicyTaxNoPublicLiability,
    insurancePolicyTotalWithPLNoNcd: breakdown.insurancePolicyTotalWithPLNoNcd,
    insurancePolicyTaxWithPLNoNcd: breakdown.insurancePolicyTaxWithPLNoNcd,
    publicLiabilityTotal: breakdown.publicLiabilityTotal,
    publicLiabilityTax: breakdown.publicLiabilityTax,
    ncdProtectionTotal: breakdown.ncdProtectionTotal,
    ncdProtectionTax: breakdown.ncdProtectionTax,
    premiumTotal: breakdown.totalPremium,
    premiumTax: breakdown.premiumTax,
  };
}
// Fix start and end dates if they are in the past
export function adjustedQuoteStartDate(
  quote: Pick<IQuote, 'startDate' | 'endDate'>
): Date {
  return quote.startDate && new Date(quote.startDate) > new Date()
    ? new Date(quote.startDate)
    : new Date();
}

export function adjustedQuoteEndDate(
  quote: Pick<IQuote, 'startDate' | 'endDate' | 'mtaOf'>
): Date | undefined {
  if (quote.endDate && quote.startDate) {
    if (quote.mtaOf) return new Date(quote.endDate);
    const correctedStartDate = adjustedQuoteStartDate(quote);

    const policyDuration =
      new Date(quote.endDate).getTime() - new Date(quote.startDate).getTime();

    return new Date(correctedStartDate.getTime() + policyDuration);
  }
}

export function isInstalmentPaymentPlan(policy: IPolicyDetailFragment) {
  return policy.package.paymentPlan?.paymentPlan === 'INSTALMENTS';
}

export function isUSProduct(product: IProductDetailsFragment) {
  return product.location.country === LocationCountryEnum.US;
}

interface ProductDeprecation {
  from: string;
  successorId: string;
}

export function isProductDeprecated(
  productDeprecation?: ProductDeprecation | null
): boolean {
  if (!productDeprecation) return false;

  return new Date() >= new Date(productDeprecation.from);
}

export interface QuoteBreakdown {
  premiumTotal: number;
  premiumTax: number;
  publicLiabilityTotal: number;
  publicLiabilityTax: number;
  ncdProtectionTotal: number;
  ncdProtectionTax: number;
  insurancePolicyTotal: number;
  insurancePolicyTax: number;
  insurancePolicyTotalNoPublicLiability: number;
  insurancePolicyTaxNoPublicLiability: number;
  insurancePolicyTotalWithPLNoNcd: number;
  insurancePolicyTaxWithPLNoNcd: number;
}
