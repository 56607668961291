import { processAction, updateContext } from 'utils/quoteUtils';
import { IQuoteActions } from 'actions/quoteActions';
import { Step } from 'shared/types/question';
import { IContext } from 'types/quote';
import deepmerge from 'deepmerge';
import { cloneDeep, last } from 'lodash';

export interface QuoteState {
  steps: Step[];
  currentStep?: Step;
  showInput: boolean;
  delay?: number;
  snapshotVersions: Array<{ id: string; context: IContext }>;
}

export function context(state: QuoteState) {
  return last(state.snapshotVersions)!.context;
}

function quoteReducer(state: QuoteState, action: IQuoteActions): QuoteState {
  switch (action.type) {
    case 'quote/ANSWER_QUESTION': {
      const { steps } = state;
      const $ = context(state);

      const { id, answer } = action.payload;

      const step = steps.find((q) => q.id === id);

      if (!step || step.type === 'action') {
        return state;
      }

      const updated = updateContext($, step, answer);

      return {
        ...state,
        snapshotVersions: [
          ...state.snapshotVersions,
          { id: action.payload.snapshotVersion, context: updated },
        ],
      };
    }

    case 'quote/TOGGLE_INPUT': {
      const { show, delay } = action.payload;

      return {
        ...state,
        showInput: show,
        delay: delay,
      };
    }

    case 'quote/SET_STEP': {
      const { step } = action.payload;

      return {
        ...state,
        currentStep: step,
      };
    }

    case 'quote/PROCESS_ACTION': {
      const $ = context(state);

      const updated = processAction($, action.payload.action);

      return {
        ...state,
        snapshotVersions: [
          ...state.snapshotVersions,
          { id: action.payload.snapshotVersion, context: updated },
        ],
      };
    }

    case 'quote/UPDATE_DATASHEET': {
      const { datasheet } = action.payload;

      const $ = context(state);
      const cloned = cloneDeep(state);

      const updated = {
        ...$,
        datasheet,
      };

      cloned.snapshotVersions.push({
        id: action.payload.snapshotVersion,
        context: updated,
      });

      return cloned;
    }

    case 'quote/UPDATE_CONTEXT': {
      const { context } = action.payload;

      last(state.snapshotVersions)!.context = context;

      return cloneDeep(state);
    }

    case 'quote/MERGE_DATASHEET': {
      const $ = context(state);
      const { datasheet } = action.payload;

      const cloned = cloneDeep(state);

      const updated = {
        ...$,
        datasheet: deepmerge($.datasheet, datasheet),
      };

      cloned.snapshotVersions.push({
        id: action.payload.snapshotVersion,
        context: updated,
      });

      return cloned;
    }

    case 'quote/UNDO': {
      const { snapshotVersions } = state;

      const index = snapshotVersions.findIndex(
        (i) => i.id === action.payload.snapshotVersion
      );

      const v = [...snapshotVersions];

      v.splice(index);

      return {
        ...state,
        snapshotVersions: v,
      };
    }

    default:
      return state;
  }
}

export default quoteReducer;
