import { css, DefaultTheme } from 'styled-components/macro';
import { ViewStyle } from '../themes';

/**
 * Create CSS based on a theme path.
 * @param viewFn
 */
export const useView = <Props extends { theme: DefaultTheme }>(
  viewFn: (theme: DefaultTheme, props: Props) => ViewStyle
) => {
  return (props: Props) => {
    const view = viewFn(props.theme, props);

    // Get all the styles that are not nested objects
    const styles = Object.entries(view).filter(
      ([, value]) => typeof value !== 'object'
    );

    // Join all the styles together, e.g. background-color: red
    const mapped = styles.map(([key, value]) => {
      return [key, value].join(':');
    });

    return css`
      ${mapped.join(';')};
    `;
  };
};
