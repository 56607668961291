import { useMemo } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';

export function useQuery<T>(): T {
  const { search } = useLocation();

  return useMemo(() => {
    const parsed = querystring.parse(search, {
      arrayFormat: 'comma',
    });

    return parsed as any;
  }, [search]);
}
