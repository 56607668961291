import React, { MouseEvent } from 'react';

import { ButtonVariants } from './styled';
import { useTracking } from '../../providers/TrackingProvider';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  track?: Record<string, string | undefined | number>;
  trackEvent?: string;
  testId?: string;
}

function UnstyledButton({
  children,
  onClick,
  trackEvent = 'ButtonPressed',
  track: trackArguments,
  testId,
  ...buttonProps
}: ButtonProps) {
  const { track } = useTracking();

  const handleClick = (event: MouseEvent<HTMLButtonElement, any>) => {
    if (trackArguments) {
      track(trackEvent, trackArguments);
    }

    onClick?.(event);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      data-testid={testId}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

export default UnstyledButton;
