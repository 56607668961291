import { IMessage, IMessageGroup } from '../../../../types/message';

/*
Groups a list of messages into chunks by whether they've received or not
 */
export function groupMessageBySender(messages: IMessage[]): IMessageGroup[] {
  const groups = [];

  // Loop through all the messages
  while (messages.length) {
    // Take the first from the list
    const current = messages.shift() as IMessage;

    // Create a group with the initial message
    const group = {
      received: current.received,
      messages: [current],
    };

    // Loop through a copy of the remaining messages
    for (const message of [...messages]) {
      // If the next message isn't from the same sender, break and complete the group
      if (message.received !== current.received) {
        break;
      }

      // If this message is from the same sender, add it to the group and test the next message
      group.messages.push(messages.shift()!);
    }

    // Add the group of messages to the top level group
    groups.push(group);
  }

  return groups;
}
