import React from 'react';

import './RadioButton.scss';

interface Props {
  id: string;
  value: string;
  name: string;
  label: string;
  onClick?(id: string): void;
  selected: boolean;
  testId?: string;
  disabled?: boolean;
}

function RadioButton({
  id,
  value,
  name,
  label,
  onClick,
  selected,
  testId,
  disabled = false,
}: Props) {
  return (
    <div className="RadioButton" data-testid={testId || id}>
      <input
        onClick={() => onClick && onClick(id)}
        className="RadioButton-radio"
        checked={selected}
        type="radio"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onChange={() => {}}
      />
      <div className="RadioButton-radio-check">
        <div className="RadioButton-radio-check-inner"></div>
      </div>

      <label className="RadioButton-radio-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default RadioButton;
