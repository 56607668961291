import React, { useMemo } from 'react';
import { Redirect as RouterRedirect, RedirectProps } from 'react-router-dom';
import querystring from 'query-string';
import { useQuery } from 'hooks';

interface Props extends RedirectProps {
  to: string;
  query?: Record<string, string | undefined | string[]>;
  preserveQuery?: boolean;
}

interface RedirectQuery {
  lang?: string;
  _ga?: string;
}

function Redirect({ to, query, preserveQuery, ...props }: Props) {
  const { lang, _ga, ...rest } = useQuery<RedirectQuery>();

  const path = useMemo(() => {
    const q = preserveQuery
      ? { ...rest, lang, _ga, ...query }
      : { lang, _ga, ...query };

    const string = querystring.stringify(q, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'comma',
    });

    return `${to}?${string}`;
  }, [preserveQuery, rest, lang, _ga, query, to]);

  return <RouterRedirect to={path} {...props} />;
}

export default Redirect;
