import React from 'react';
import { ChoiceQuestion } from 'shared/types/question';
import { AnswerQuestion } from 'providers/QuoteProvider';
import { formatTestId } from '../../../../../utils/testIdUtils';
import { scrollToBottom } from '../../../ChatRoller/ChatRoller';

interface Props {
  question: ChoiceQuestion;
  onAnswer: AnswerQuestion;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

function ChoiceButtonInput({ question, onAnswer, scrollRef }: Props) {
  const { choices } = question;

  return (
    <div className="Chatbot-actions">
      {choices.map((choice) => (
        <button
          autoFocus
          key={choice.text}
          className="Chatbot-action"
          data-testid={`choice-button-${formatTestId(choice.text)}`}
          onClick={() => onAnswer(choice.text, choice.value)}
          onFocus={() =>
            scrollRef &&
            setTimeout(() => scrollToBottom(scrollRef.current), 500)
          }
        >
          {choice.text}
        </button>
      ))}
    </div>
  );
}

export default ChoiceButtonInput;
