import { Option } from '../components/Select';

export const NCD = [
  {
    id: 'NCD - true',
    label: 'Yes',
    value: 'true',
  },
  {
    id: 'NCD - false',
    label: 'No',
    value: 'false',
  },
];

export const NEGATIVE_HISTORY = [
  {
    id: 'History - true',
    label: 'Yes',
    value: 'true',
  },
  {
    id: 'History - false',
    label: 'No',
    value: 'false',
  },
];

export const VEHICLE_ADDRESS = [
  {
    id: 'Vehicle Address - false',
    label: "Yes, it's the same address",
    value: 'false',
  },
  {
    id: 'Vehicle Address - true',
    label: "No, it's a different address",
    value: 'true',
  },
];

export const TOTAL_CLAIMS: Option[] = [
  {
    text: 'None',
    value: 0,
  },
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
  {
    text: '3',
    value: 3,
  },
  {
    text: '4',
    value: 4,
  },
  {
    text: '5',
    value: 5,
  },
  {
    text: 'More than 5',
    value: 6,
  },
];

export const CLAIMS_AMOUNT = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: 'More than 5',
    value: 6,
  },
];

export const OWNERSHIP = [
  {
    value: 'OWN',
    label: 'I own my vehicle',
  },
  {
    value: 'SPOUSE_PARTNER_COMPANY',
    label: 'Owned by spouse/ partner/ own company',
  },
  {
    value: 'LONG_TERM_LEASE',
    label: 'Long term lease / rental (1 year or more)',
  },
  {
    value: 'SHORT_TERM_LEASE',
    label: 'Short term lease / rental (less than 1 year)',
  },
  {
    value: 'OTHER',
    label: 'Any other owner',
  },
];

export const PRIVATE_HIRE_YEARS: Option[] = [
  {
    text: 'Less than 1 year',
    value: 0,
  },
  {
    text: '1 year',
    value: 1,
  },
  {
    text: '2 years',
    value: 2,
  },
  {
    text: '3 years',
    value: 3,
  },
  {
    text: '4 years',
    value: 4,
  },
  {
    text: '5 years',
    value: 5,
  },
  {
    text: '6 years',
    value: 6,
  },
  {
    text: '7 years',
    value: 7,
  },
  {
    text: '8 years',
    value: 8,
  },
  {
    text: '9 years',
    value: 9,
  },
  {
    text: 'More than 10 years',
    value: 10,
  },
];

export const CLAIM_FREE_YEARS: Option[] = [
  {
    text: 'None',
    value: 0,
  },
  {
    text: '1 year',
    value: 1,
  },
  {
    text: '2 years',
    value: 2,
  },
  {
    text: '3 years',
    value: 3,
  },
  {
    text: '4 years',
    value: 4,
  },
  {
    text: '5 years',
    value: 5,
  },
  {
    text: 'More than 5 years',
    value: 6,
  },
];

export const LICENSE_AUTHORITIES: Option[] = [
  {
    text: 'Aberdeen City',
    value: 'Aberdeen City',
  },
  {
    text: 'Aberdeenshire Central',
    value: 'Aberdeenshire Central',
  },
  {
    text: 'Aberdeenshire North',
    value: 'Aberdeenshire North',
  },
  {
    text: 'Aberdeenshire South',
    value: 'Aberdeenshire South',
  },
  {
    text: 'Aberdeenshire',
    value: 'Aberdeenshire',
  },
  {
    text: 'Adur',
    value: 'Adur',
  },
  {
    text: 'Allerdale',
    value: 'Allerdale',
  },
  {
    text: 'Alnwick',
    value: 'Alnwick',
  },
  {
    text: 'Amber Valley',
    value: 'Amber Valley',
  },
  {
    text: 'Angus',
    value: 'Angus',
  },
  {
    text: 'Argyll and Bute',
    value: 'Argyll and Bute',
  },
  {
    text: 'Arun',
    value: 'Arun',
  },
  {
    text: 'Ashfield',
    value: 'Ashfield',
  },
  {
    text: 'Ashford',
    value: 'Ashford',
  },
  {
    text: 'Aylesbury Vale',
    value: 'Aylesbury Vale',
  },
  {
    text: 'Babergh',
    value: 'Babergh',
  },
  {
    text: 'Badenoch & Strathspey (Highland)',
    value: 'Badenoch & Strathspey (Highland)',
  },
  {
    text: 'Barnsley',
    value: 'Barnsley',
  },
  {
    text: 'Burrow-In-Furness',
    value: 'Burrow-In-Furness',
  },
  {
    text: 'Basildon',
    value: 'Basildon',
  },
  {
    text: 'Basingstoke & Dean',
    value: 'Basingstoke & Dean',
  },
  {
    text: 'Bassetlaw',
    value: 'Bassetlaw',
  },
  {
    text: 'Bath & North East Somerset',
    value: 'Bath & North East Somerset',
  },
  {
    text: 'Bedford',
    value: 'Bedford',
  },
  {
    text: 'Belfast',
    value: 'Belfast',
  },
  {
    text: 'Berwick upon Tweed',
    value: 'Berwick upon Tweed',
  },
  {
    text: 'Birmingham',
    value: 'Birmingham',
  },
  {
    text: 'Blaby',
    value: 'Blaby',
  },
  {
    text: 'Blackburn With Darwen',
    value: 'Blackburn With Darwen',
  },
  {
    text: 'Blackpool',
    value: 'Blackpool',
  },
  {
    text: 'Blanau Gwent',
    value: 'Blanau Gwent',
  },
  {
    text: 'Blyth Valley',
    value: 'Blyth Valley',
  },
  {
    text: 'Bolsover',
    value: 'Bolsover',
  },
  {
    text: 'Bolton',
    value: 'Bolton',
  },
  {
    text: 'Boston',
    value: 'Boston',
  },
  {
    text: 'Bournemouth',
    value: 'Bournemouth',
  },
  {
    text: 'Bracknell Forest',
    value: 'Bracknell Forest',
  },
  {
    text: 'Bradford',
    value: 'Bradford',
  },
  {
    text: 'Braintree District Council',
    value: 'Braintree District Council',
  },
  {
    text: 'Breckland',
    value: 'Breckland',
  },
  {
    text: 'Brentwood',
    value: 'Brentwood',
  },
  {
    text: 'Bridgend',
    value: 'Bridgend',
  },
  {
    text: 'Bridgenorth',
    value: 'Bridgenorth',
  },
  {
    text: 'Brighton & Hove',
    value: 'Brighton & Hove',
  },
  {
    text: 'Bristol',
    value: 'Bristol',
  },
  {
    text: 'Broadland',
    value: 'Broadland',
  },
  {
    text: 'Bromsgrove',
    value: 'Bromsgrove',
  },
  {
    text: 'Broxbourne',
    value: 'Broxbourne',
  },
  {
    text: 'Broxtowe',
    value: 'Broxtowe',
  },
  {
    text: 'Burnley',
    value: 'Burnley',
  },
  {
    text: 'Bury',
    value: 'Bury',
  },
  {
    text: 'Caerphilly',
    value: 'Caerphilly',
  },
  {
    text: 'Caithness (Highland)',
    value: 'Caithness (Highland)',
  },
  {
    text: 'Calderdale',
    value: 'Calderdale',
  },
  {
    text: 'Cambridge',
    value: 'Cambridge',
  },
  {
    text: 'Cannock Chase',
    value: 'Cannock Chase',
  },
  {
    text: 'Canterbury',
    value: 'Canterbury',
  },
  {
    text: 'Caradon',
    value: 'Caradon',
  },
  {
    text: 'Cardiff',
    value: 'Cardiff',
  },
  {
    text: 'Carlisle',
    value: 'Carlisle',
  },
  {
    text: 'Carmarthenshire',
    value: 'Carmarthenshire',
  },
  {
    text: 'Carrick',
    value: 'Carrick',
  },
  {
    text: 'Castle Morpeth',
    value: 'Castle Morpeth',
  },
  {
    text: 'Castle Point',
    value: 'Castle Point',
  },
  {
    text: 'Central Bedfordshire',
    value: 'Central Bedfordshire',
  },
  {
    text: 'Ceredigion',
    value: 'Ceredigion',
  },
  {
    text: 'Channel Islands',
    value: 'Channel Islands',
  },
  {
    text: 'Charnwood',
    value: 'Charnwood',
  },
  {
    text: 'Chelmsford',
    value: 'Chelmsford',
  },
  {
    text: 'Cheltenham',
    value: 'Cheltenham',
  },
  {
    text: 'Cherwell',
    value: 'Cherwell',
  },
  {
    text: 'Cheshire East Council',
    value: 'Cheshire East Council',
  },
  {
    text: 'Cheshire West and Chester',
    value: 'Cheshire West and Chester',
  },
  {
    text: 'Chester City Council',
    value: 'Chester City Council',
  },
  {
    text: 'Chester le Street',
    value: 'Chester le Street',
  },
  {
    text: 'Chesterfield',
    value: 'Chesterfield',
  },
  {
    text: 'Chichester',
    value: 'Chichester',
  },
  {
    text: 'Chiltern',
    value: 'Chiltern',
  },
  {
    text: 'Chorley',
    value: 'Chorley',
  },
  {
    text: 'Christchurch Borough Council',
    value: 'Christchurch Borough Council',
  },
  {
    text: 'City of Newcastle - Upon - Tyne',
    value: 'City of Newcastle - Upon - Tyne',
  },
  {
    text: 'City of York Council',
    value: 'City of York Council',
  },
  {
    text: 'City and Count of Swansea Cnl, SA1 3SN',
    value: 'City and Count of Swansea Cnl, SA1 3SN',
  },
  {
    text: 'City of Birmingham, B6 5RQ',
    value: 'City of Birmingham, B6 5RQ',
  },
  {
    text: 'City of Bradford, BD7 1PU',
    value: 'City of Bradford, BD7 1PU',
  },
  {
    text: 'City of Coventry, CV3 4AR',
    value: 'City of Coventry, CV3 4AR',
  },
  {
    text: 'City of Edinburgh, EH1 1YJ',
    value: 'City of Edinburgh, EH1 1YJ',
  },
  {
    text: 'City of Leeds, LS9 7RY',
    value: 'City of Leeds, LS9 7RY',
  },
  {
    text: 'City of Liverpool, L2 2DH',
    value: 'City of Liverpool, L2 2DH',
  },
  {
    text: 'City of Manchester, M60 2LA',
    value: 'City of Manchester, M60 2LA',
  },
  {
    text: 'City of Newcastle-Upon-Tyne, NE6 5BD',
    value: 'City of Newcastle-Upon-Tyne, NE6 5BD',
  },
  {
    text: 'City of Sheffield, S1 2HH',
    value: 'City of Sheffield, S1 2HH',
  },
  {
    text: 'City of Sunderland, SR2 7DN',
    value: 'City of Sunderland, SR2 7DN',
  },
  {
    text: 'City of Wakefield, WF4 5DA',
    value: 'City of Wakefield, WF4 5DA',
  },
  {
    text: 'City of York Council, YO10 3DS',
    value: 'City of York Council, YO10 3DS',
  },
  {
    text: 'Clackmannanshire',
    value: 'Clackmannanshire',
  },
  {
    text: 'Colchester Borough Council',
    value: 'Colchester Borough Council',
  },
  {
    text: 'Congleton Borough Council',
    value: 'Congleton Borough Council',
  },
  {
    text: 'Conwy',
    value: 'Conwy',
  },
  {
    text: 'Copeland',
    value: 'Copeland',
  },
  {
    text: 'Corby Borough Council',
    value: 'Corby Borough Council',
  },
  {
    text: 'Cotswold District Council',
    value: 'Cotswold District Council',
  },
  {
    text: 'Coventry City Council',
    value: 'Coventry City Council',
  },
  {
    text: 'Craven District Council',
    value: 'Craven District Council',
  },
  {
    text: 'Crawley',
    value: 'Crawley',
  },
  {
    text: 'Crewe and Nantwich Borough Council',
    value: 'Crewe and Nantwich Borough Council',
  },
  {
    text: 'Dacorum Borough Council',
    value: 'Dacorum Borough Council',
  },
  {
    text: 'Darlington Borough Council',
    value: 'Darlington Borough Council',
  },
  {
    text: 'Dartford Borough Council',
    value: 'Dartford Borough Council',
  },
  {
    text: 'Daventry District Council',
    value: 'Daventry District Council',
  },
  {
    text: 'Denbighshire County Council',
    value: 'Denbighshire County Council',
  },
  {
    text: 'Derby',
    value: 'Derby',
  },
  {
    text: 'Derbyshire Dales District Council',
    value: 'Derbyshire Dales District Council',
  },
  {
    text: 'Derwentside District Council',
    value: 'Derwentside District Council',
  },
  {
    text: 'Doncaster Metropolitan Borough Council',
    value: 'Doncaster Metropolitan Borough Council',
  },
  {
    text: 'Dorset County Council',
    value: 'Dorset County Council',
  },
  {
    text: 'Dover District Council',
    value: 'Dover District Council',
  },
  {
    text: 'Dudley Metropolitan Borough Council',
    value: 'Dudley Metropolitan Borough Council',
  },
  {
    text: 'Dumfries and Galloway',
    value: 'Dumfries and Galloway',
  },
  {
    text: 'Dundee',
    value: 'Dundee',
  },
  {
    text: 'Durham City Council',
    value: 'Durham City Council',
  },
  {
    text: 'Easington District Council',
    value: 'Easington District Council',
  },
  {
    text: 'East Ayrshire',
    value: 'East Ayrshire',
  },
  {
    text: 'East Cambridgeshire District Council',
    value: 'East Cambridgeshire District Council',
  },
  {
    text: 'East Devon District Council',
    value: 'East Devon District Council',
  },
  {
    text: 'East Dorset District Council',
    value: 'East Dorset District Council',
  },
  {
    text: 'East Dunbartonshire',
    value: 'East Dunbartonshire',
  },
  {
    text: 'East Hampshire District Council',
    value: 'East Hampshire District Council',
  },
  {
    text: 'East Hertfordshire District Council',
    value: 'East Hertfordshire District Council',
  },
  {
    text: 'East Kilbride',
    value: 'East Kilbride',
  },
  {
    text: 'East Lindsey District Council',
    value: 'East Lindsey District Council',
  },
  {
    text: 'East Lothian',
    value: 'East Lothian',
  },
  {
    text: 'East Northamptonshire Council',
    value: 'East Northamptonshire Council',
  },
  {
    text: 'East Renfrewshire',
    value: 'East Renfrewshire',
  },
  {
    text: 'East Riding of Yorkshire Council',
    value: 'East Riding of Yorkshire Council',
  },
  {
    text: 'East Staffordshire',
    value: 'East Staffordshire',
  },
  {
    text: 'Eastbourne',
    value: 'Eastbourne',
  },
  {
    text: 'Eastleigh Borough Council',
    value: 'Eastleigh Borough Council',
  },
  {
    text: 'Eden District Council',
    value: 'Eden District Council',
  },
  {
    text: 'Edinburgh',
    value: 'Edinburgh',
  },
  {
    text: 'Ellesmere Port and Neston Borough Council',
    value: 'Ellesmere Port and Neston Borough Council',
  },
  {
    text: 'Elmbridge Borough Council',
    value: 'Elmbridge Borough Council',
  },
  {
    text: 'Epping Forest District Council',
    value: 'Epping Forest District Council',
  },
  {
    text: 'Epsom and Ewell Borough Council',
    value: 'Epsom and Ewell Borough Council',
  },
  {
    text: 'Erewash Borough Council',
    value: 'Erewash Borough Council',
  },
  {
    text: 'Exeter City Council',
    value: 'Exeter City Council',
  },
  {
    text: 'Falkirk',
    value: 'Falkirk',
  },
  {
    text: 'Fareham',
    value: 'Fareham',
  },
  {
    text: 'Fenland District Council',
    value: 'Fenland District Council',
  },
  {
    text: 'Fife',
    value: 'Fife',
  },
  {
    text: 'Flintshire',
    value: 'Flintshire',
  },
  {
    text: 'Forest Heath District Council',
    value: 'Forest Heath District Council',
  },
  {
    text: 'Forest of Dean District Council',
    value: 'Forest of Dean District Council',
  },
  {
    text: 'Fylde Borough Council',
    value: 'Fylde Borough Council',
  },
  {
    text: 'Gateshead Council',
    value: 'Gateshead Council',
  },
  {
    text: 'Gedling',
    value: 'Gedling',
  },
  {
    text: 'Gillingham Borough Council',
    value: 'Gillingham Borough Council',
  },
  {
    text: 'Glasgow',
    value: 'Glasgow',
  },
  {
    text: 'Gloucester City Council',
    value: 'Gloucester City Council',
  },
  {
    text: 'Gosport',
    value: 'Gosport',
  },
  {
    text: 'Gravesham Borough Council',
    value: 'Gravesham Borough Council',
  },
  {
    text: 'Great Yarmouth Borough Council',
    value: 'Great Yarmouth Borough Council',
  },
  {
    text: 'Greenwich',
    value: 'Greenwich',
  },
  {
    text: 'Guernsey',
    value: 'Guernsey',
  },
  {
    text: 'Guildford',
    value: 'Guildford',
  },
  {
    text: 'Gwynedd',
    value: 'Gwynedd',
  },
  {
    text: 'Halton Borough Council',
    value: 'Halton Borough Council',
  },
  {
    text: 'Hambleton District Council',
    value: 'Hambleton District Council',
  },
  {
    text: 'Hampshire',
    value: 'Hampshire',
  },
  {
    text: 'Harborough District Council',
    value: 'Harborough District Council',
  },
  {
    text: 'Harlow Council',
    value: 'Harlow Council',
  },
  {
    text: 'Harrogate',
    value: 'Harrogate',
  },
  {
    text: 'Hart District Council',
    value: 'Hart District Council',
  },
  {
    text: 'Hartlepool',
    value: 'Hartlepool',
  },
  {
    text: 'Hastings',
    value: 'Hastings',
  },
  {
    text: 'Havant',
    value: 'Havant',
  },
  {
    text: 'Herefordshire',
    value: 'Herefordshire',
  },
  {
    text: 'Hertsmere',
    value: 'Hertsmere',
  },
  {
    text: 'High Peak',
    value: 'High Peak',
  },
  {
    text: 'Highland',
    value: 'Highland',
  },
  {
    text: 'Hinckley and Bosworth Borough Council',
    value: 'Hinckley and Bosworth Borough Council',
  },
  {
    text: 'Horsham',
    value: 'Horsham',
  },
  {
    text: 'Huntingdonshire District Council',
    value: 'Huntingdonshire District Council',
  },
  {
    text: 'Hyndburn',
    value: 'Hyndburn',
  },
  {
    text: 'Inverclyde',
    value: 'Inverclyde',
  },
  {
    text: 'Ipswich',
    value: 'Ipswich',
  },
  {
    text: 'Isle of Anglesey',
    value: 'Isle of Anglesey',
  },
  {
    text: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    text: 'Isle of Wight',
    value: 'Isle of Wight',
  },
  {
    text: 'Isles of Scilly',
    value: 'Isles of Scilly',
  },
  {
    text: 'Jersey',
    value: 'Jersey',
  },
  {
    text: 'Kennet',
    value: 'Kennet',
  },
  {
    text: 'Kerrier',
    value: 'Kerrier',
  },
  {
    text: 'Kettering Borough Council',
    value: 'Kettering Borough Council',
  },
  {
    text: 'Kings Lynn and West Norfolk',
    value: 'Kings Lynn and West Norfolk',
  },
  {
    text: 'Kingston Upon Hull',
    value: 'Kingston Upon Hull',
  },
  {
    text: 'Kirklees Metropolitan Council',
    value: 'Kirklees Metropolitan Council',
  },
  {
    text: 'Knowsley',
    value: 'Knowsley',
  },
  {
    text: 'Lancaster',
    value: 'Lancaster',
  },
  {
    text: 'Leeds',
    value: 'Leeds',
  },
  {
    text: 'Leicester City Council',
    value: 'Leicester City Council',
  },
  {
    text: 'Leicestershire County Council',
    value: 'Leicestershire County Council',
  },
  {
    text: 'Leominster District Council',
    value: 'Leominster District Council',
  },
  {
    text: 'Lewes District Council',
    value: 'Lewes District Council',
  },
  {
    text: 'Lincoln City Council',
    value: 'Lincoln City Council',
  },
  {
    text: 'Litchfield',
    value: 'Litchfield',
  },
  {
    text: 'Liverpool City Council',
    value: 'Liverpool City Council',
  },
  {
    text: 'London PCO',
    value: 'London PCO',
  },
  {
    text: 'Luton',
    value: 'Luton',
  },
  {
    text: 'Macclesfield Borough Council',
    value: 'Macclesfield Borough Council',
  },
  {
    text: 'Macclesfield Cheshire East Council',
    value: 'Macclesfield Cheshire East Council',
  },
  {
    text: 'Maidstone',
    value: 'Maidstone',
  },
  {
    text: 'Maldon District Council',
    value: 'Maldon District Council',
  },
  {
    text: 'Malvern Hills',
    value: 'Malvern Hills',
  },
  {
    text: 'Manchester',
    value: 'Manchester',
  },
  {
    text: 'Mansfield District Council',
    value: 'Mansfield District Council',
  },
  {
    text: 'Medway',
    value: 'Medway',
  },
  {
    text: 'Melton Borough Council',
    value: 'Melton Borough Council',
  },
  {
    text: 'Mendip',
    value: 'Mendip',
  },
  {
    text: 'Merthyr Tydfil',
    value: 'Merthyr Tydfil',
  },
  {
    text: 'Mid Bedfordshire District Council',
    value: 'Mid Bedfordshire District Council',
  },
  {
    text: 'Mid Devon District Council',
    value: 'Mid Devon District Council',
  },
  {
    text: 'Mid Suffolk',
    value: 'Mid Suffolk',
  },
  {
    text: 'Mid Sussex',
    value: 'Mid Sussex',
  },
  {
    text: 'Middlesborough',
    value: 'Middlesborough',
  },
  {
    text: 'Midlothian',
    value: 'Midlothian',
  },
  {
    text: 'Milton Keynes',
    value: 'Milton Keynes',
  },
  {
    text: 'Mole Valley',
    value: 'Mole Valley',
  },
  {
    text: 'Monmouthshire',
    value: 'Monmouthshire',
  },
  {
    text: 'Moray',
    value: 'Moray',
  },
  {
    text: 'Nairn',
    value: 'Nairn',
  },
  {
    text: 'Neath & Port Talbot',
    value: 'Neath & Port Talbot',
  },
  {
    text: 'New Forest District Council',
    value: 'New Forest District Council',
  },
  {
    text: 'Newark and Sherwood District Council',
    value: 'Newark and Sherwood District Council',
  },
  {
    text: 'Newbury District Council',
    value: 'Newbury District Council',
  },
  {
    text: 'Newcastle Under Lyme',
    value: 'Newcastle Under Lyme',
  },
  {
    text: 'Newport',
    value: 'Newport',
  },
  {
    text: 'North Ayrshire',
    value: 'North Ayrshire',
  },
  {
    text: 'North Cornwall District Council',
    value: 'North Cornwall District Council',
  },
  {
    text: 'North Devon Council',
    value: 'North Devon Council',
  },
  {
    text: 'North Dorset',
    value: 'North Dorset',
  },
  {
    text: 'North East Derbyshire',
    value: 'North East Derbyshire',
  },
  {
    text: 'North East Lincolnshire Council',
    value: 'North East Lincolnshire Council',
  },
  {
    text: 'North Hertfordshire District Council',
    value: 'North Hertfordshire District Council',
  },
  {
    text: 'North Kesteven District Council',
    value: 'North Kesteven District Council',
  },
  {
    text: 'North Lanarkshire',
    value: 'North Lanarkshire',
  },
  {
    text: 'North Lincolnshire Council',
    value: 'North Lincolnshire Council',
  },
  {
    text: 'North Norfolk District Council',
    value: 'North Norfolk District Council',
  },
  {
    text: 'North Shropshire',
    value: 'North Shropshire',
  },
  {
    text: 'North Somerset Council',
    value: 'North Somerset Council',
  },
  {
    text: 'North Tyneside Council',
    value: 'North Tyneside Council',
  },
  {
    text: 'North Warwickshire Borough Council',
    value: 'North Warwickshire Borough Council',
  },
  {
    text: 'North West Leicestershire',
    value: 'North West Leicestershire',
  },
  {
    text: 'North Wiltshire',
    value: 'North Wiltshire',
  },
  {
    text: 'Northampton Borough Council',
    value: 'Northampton Borough Council',
  },
  {
    text: 'Northern Ireland',
    value: 'Northern Ireland',
  },
  {
    text: 'Nortumberland',
    value: 'Nortumberland',
  },
  {
    text: 'Norwich City',
    value: 'Norwich City',
  },
  {
    text: 'Nottingham',
    value: 'Nottingham',
  },
  {
    text: 'Nuneaton and Bedworth Borough Council',
    value: 'Nuneaton and Bedworth Borough Council',
  },
  {
    text: 'Oadby & Wigston',
    value: 'Oadby & Wigston',
  },
  {
    text: 'Oldham',
    value: 'Oldham',
  },
  {
    text: 'Orkney',
    value: 'Orkney',
  },
  {
    text: 'Oswestry',
    value: 'Oswestry',
  },
  {
    text: 'Oxford',
    value: 'Oxford',
  },
  {
    text: 'Pembrokeshire',
    value: 'Pembrokeshire',
  },
  {
    text: 'Pendle',
    value: 'Pendle',
  },
  {
    text: 'Penwith',
    value: 'Penwith',
  },
  {
    text: 'Perth & Kinross',
    value: 'Perth & Kinross',
  },
  {
    text: 'Peterborough City Council',
    value: 'Peterborough City Council',
  },
  {
    text: 'Plymouth',
    value: 'Plymouth',
  },
  {
    text: 'Poole',
    value: 'Poole',
  },
  {
    text: 'Portsmouth',
    value: 'Portsmouth',
  },
  {
    text: 'Powys',
    value: 'Powys',
  },
  {
    text: 'Preston',
    value: 'Preston',
  },
  {
    text: 'Purbeck District Council',
    value: 'Purbeck District Council',
  },
  {
    text: 'Reading',
    value: 'Reading',
  },
  {
    text: 'Redbridge',
    value: 'Redbridge',
  },
  {
    text: 'Redcar & Cleveland',
    value: 'Redcar & Cleveland',
  },
  {
    text: 'Redditch',
    value: 'Redditch',
  },
  {
    text: 'Reigate and Banstead Borough Council',
    value: 'Reigate and Banstead Borough Council',
  },
  {
    text: 'Renfrewshire',
    value: 'Renfrewshire',
  },
  {
    text: 'Restormel',
    value: 'Restormel',
  },
  {
    text: 'Rhondda-Cynon-Taff',
    value: 'Rhondda-Cynon-Taff',
  },
  {
    text: 'Ribble Valley',
    value: 'Ribble Valley',
  },
  {
    text: 'Richmondshire District Council',
    value: 'Richmondshire District Council',
  },
  {
    text: 'Rochdale',
    value: 'Rochdale',
  },
  {
    text: 'Rochford',
    value: 'Rochford',
  },
  {
    text: 'Ross & Cromarty (Dingwall)',
    value: 'Ross & Cromarty (Dingwall)',
  },
  {
    text: 'Rossendale',
    value: 'Rossendale',
  },
  {
    text: 'Rother',
    value: 'Rother',
  },
  {
    text: 'Rotherham',
    value: 'Rotherham',
  },
  {
    text: 'Rugby Borough Council',
    value: 'Rugby Borough Council',
  },
  {
    text: 'Runneymede Borough Council',
    value: 'Runneymede Borough Council',
  },
  {
    text: 'Rusclidde',
    value: 'Rusclidde',
  },
  {
    text: 'Rushmoor',
    value: 'Rushmoor',
  },
  {
    text: 'Rutland County Council',
    value: 'Rutland County Council',
  },
  {
    text: 'Ryedale',
    value: 'Ryedale',
  },
  {
    text: 'Salford',
    value: 'Salford',
  },
  {
    text: 'Salisbury',
    value: 'Salisbury',
  },
  {
    text: 'Sandwell Metropolitan Borough Council',
    value: 'Sandwell Metropolitan Borough Council',
  },
  {
    text: 'Scarborough',
    value: 'Scarborough',
  },
  {
    text: 'Scottish Borders',
    value: 'Scottish Borders',
  },
  {
    text: 'Sedgefield Borough Council',
    value: 'Sedgefield Borough Council',
  },
  {
    text: 'Sedgemoor',
    value: 'Sedgemoor',
  },
  {
    text: 'Sefton Metropolition Borough Council',
    value: 'Sefton Metropolition Borough Council',
  },
  {
    text: 'Selby',
    value: 'Selby',
  },
  {
    text: 'Sevenoaks',
    value: 'Sevenoaks',
  },
  {
    text: 'Sheffield',
    value: 'Sheffield',
  },
  {
    text: 'Shepway District Council',
    value: 'Shepway District Council',
  },
  {
    text: 'Shetland',
    value: 'Shetland',
  },
  {
    text: 'Shewsbury & Atcham',
    value: 'Shewsbury & Atcham',
  },
  {
    text: 'Shropshire Council',
    value: 'Shropshire Council',
  },
  {
    text: 'Skye & Lochalsh (Isle of Skye)',
    value: 'Skye & Lochalsh (Isle of Skye)',
  },
  {
    text: 'Slough',
    value: 'Slough',
  },
  {
    text: 'Solihull',
    value: 'Solihull',
  },
  {
    text: 'South Ayrshire',
    value: 'South Ayrshire',
  },
  {
    text: 'South Bedfordshire',
    value: 'South Bedfordshire',
  },
  {
    text: 'South Bucks District Council',
    value: 'South Bucks District Council',
  },
  {
    text: 'South Cambrideshire',
    value: 'South Cambrideshire',
  },
  {
    text: 'South Derbushire District Council',
    value: 'South Derbushire District Council',
  },
  {
    text: 'South Gloucestershire Councl',
    value: 'South Gloucestershire Councl',
  },
  {
    text: 'South Hams',
    value: 'South Hams',
  },
  {
    text: 'South Herefordshire',
    value: 'South Herefordshire',
  },
  {
    text: 'South Holland District Council',
    value: 'South Holland District Council',
  },
  {
    text: 'South Kesteven District Council',
    value: 'South Kesteven District Council',
  },
  {
    text: 'South Lakeland',
    value: 'South Lakeland',
  },
  {
    text: 'South Lanarkshire',
    value: 'South Lanarkshire',
  },
  {
    text: 'South Norfolk Council',
    value: 'South Norfolk Council',
  },
  {
    text: 'South Northamptonshire',
    value: 'South Northamptonshire',
  },
  {
    text: 'South Oxfordshire',
    value: 'South Oxfordshire',
  },
  {
    text: 'South Ribble Borough Council',
    value: 'South Ribble Borough Council',
  },
  {
    text: 'South Shropshire',
    value: 'South Shropshire',
  },
  {
    text: 'South Somerset',
    value: 'South Somerset',
  },
  {
    text: 'South Staffordshire',
    value: 'South Staffordshire',
  },
  {
    text: 'South Tyneside Council',
    value: 'South Tyneside Council',
  },
  {
    text: 'Southampton City Council',
    value: 'Southampton City Council',
  },
  {
    text: 'Southend On Sea',
    value: 'Southend On Sea',
  },
  {
    text: 'Spelthorne',
    value: 'Spelthorne',
  },
  {
    text: 'St Albans',
    value: 'St Albans',
  },
  {
    text: 'St Edmundsbury Borough Council',
    value: 'St Edmundsbury Borough Council',
  },
  {
    text: 'St Helens Council',
    value: 'St Helens Council',
  },
  {
    text: 'Stafford',
    value: 'Stafford',
  },
  {
    text: 'Stafford Moorlands',
    value: 'Stafford Moorlands',
  },
  {
    text: 'Stevenage Borough Council',
    value: 'Stevenage Borough Council',
  },
  {
    text: 'Stirling',
    value: 'Stirling',
  },
  {
    text: 'Stockport',
    value: 'Stockport',
  },
  {
    text: 'Stockton On Tees',
    value: 'Stockton On Tees',
  },
  {
    text: 'Stoke On Trent',
    value: 'Stoke On Trent',
  },
  {
    text: 'Stratford-on-Avon District Council',
    value: 'Stratford-on-Avon District Council',
  },
  {
    text: 'Stoud District Council',
    value: 'Stoud District Council',
  },
  {
    text: 'Suffolk Costal',
    value: 'Suffolk Costal',
  },
  {
    text: 'Sunderland City Council',
    value: 'Sunderland City Council',
  },
  {
    text: 'Surrey Heath Borough Council',
    value: 'Surrey Heath Borough Council',
  },
  {
    text: 'Sutherland (Golspie)',
    value: 'Sutherland (Golspie)',
  },
  {
    text: 'Swale',
    value: 'Swale',
  },
  {
    text: 'Swansea',
    value: 'Swansea',
  },
  {
    text: 'Swindon',
    value: 'Swindon',
  },
  {
    text: 'Tameside',
    value: 'Tameside',
  },
  {
    text: 'Tamworth',
    value: 'Tamworth',
  },
  {
    text: 'Tandridge District Council',
    value: 'Tandridge District Council',
  },
  {
    text: 'Taunton Deane',
    value: 'Taunton Deane',
  },
  {
    text: 'Teesdale District Council',
    value: 'Teesdale District Council',
  },
  {
    text: 'Teignbridge District Council',
    value: 'Teignbridge District Council',
  },
  {
    text: 'Telford & Wrekin',
    value: 'Telford & Wrekin',
  },
  {
    text: 'Tendring District Council',
    value: 'Tendring District Council',
  },
  {
    text: 'Test Valley Borough Council',
    value: 'Test Valley Borough Council',
  },
  {
    text: 'Tewkesbury Borough Council',
    value: 'Tewkesbury Borough Council',
  },
  {
    text: 'Thamesdown Borough Council',
    value: 'Thamesdown Borough Council',
  },
  {
    text: 'Thanet District Council',
    value: 'Thanet District Council',
  },
  {
    text: 'Three Rivers',
    value: 'Three Rivers',
  },
  {
    text: 'Thurrock',
    value: 'Thurrock',
  },
  {
    text: 'Tonbridge & Malling',
    value: 'Tonbridge & Malling',
  },
  {
    text: 'Torbay',
    value: 'Torbay',
  },
  {
    text: 'Torfaen',
    value: 'Torfaen',
  },
  {
    text: 'Torridge District Council',
    value: 'Torridge District Council',
  },
  {
    text: 'Trafford Council',
    value: 'Trafford Council',
  },
  {
    text: 'Tunbridge Wells',
    value: 'Tunbridge Wells',
  },
  {
    text: 'Tynedale Council',
    value: 'Tynedale Council',
  },
  {
    text: 'Uttlesford District Council',
    value: 'Uttlesford District Council',
  },
  {
    text: 'Vale of Glamorgan',
    value: 'Vale of Glamorgan',
  },
  {
    text: 'Vale of White Horse',
    value: 'Vale of White Horse',
  },
  {
    text: 'Vale Royal Borough Council',
    value: 'Vale Royal Borough Council',
  },
  {
    text: 'Wakefield',
    value: 'Wakefield',
  },
  {
    text: 'Walsall',
    value: 'Walsall',
  },
  {
    text: 'Wansbeck District Council',
    value: 'Wansbeck District Council',
  },
  {
    text: 'Warrington Borough Council',
    value: 'Warrington Borough Council',
  },
  {
    text: 'Warwick District Council',
    value: 'Warwick District Council',
  },
  {
    text: 'Watford',
    value: 'Watford',
  },
  {
    text: 'Waveney District Council',
    value: 'Waveney District Council',
  },
  {
    text: 'Waverley Borough Council',
    value: 'Waverley Borough Council',
  },
  {
    text: 'Wealden',
    value: 'Wealden',
  },
  {
    text: 'Wear Valley District Council',
    value: 'Wear Valley District Council',
  },
  {
    text: 'Wellingborough Borough Council',
    value: 'Wellingborough Borough Council',
  },
  {
    text: 'Welwyn Hatfield',
    value: 'Welwyn Hatfield',
  },
  {
    text: 'West Berkshire',
    value: 'West Berkshire',
  },
  {
    text: 'West Devon Borough Council',
    value: 'West Devon Borough Council',
  },
  {
    text: 'West Dorset',
    value: 'West Dorset',
  },
  {
    text: 'West Dunbartonshire',
    value: 'West Dunbartonshire',
  },
  {
    text: 'West Lancashire',
    value: 'West Lancashire',
  },
  {
    text: 'West Lindsey District Council',
    value: 'West Lindsey District Council',
  },
  {
    text: 'West Lothian',
    value: 'West Lothian',
  },
  {
    text: 'West Oxfordshire',
    value: 'West Oxfordshire',
  },
  {
    text: 'West Somerset',
    value: 'West Somerset',
  },
  {
    text: 'West Wiltshire',
    value: 'West Wiltshire',
  },
  {
    text: 'Western Isles',
    value: 'Western Isles',
  },
  {
    text: 'Weymouth & Portland',
    value: 'Weymouth & Portland',
  },
  {
    text: 'Wigan',
    value: 'Wigan',
  },
  {
    text: 'Wiltshire District Council',
    value: 'Wiltshire District Council',
  },
  {
    text: 'Winchester City Council',
    value: 'Winchester City Council',
  },
  {
    text: 'Windsor & Maidenhead',
    value: 'Windsor & Maidenhead',
  },
  {
    text: 'Wirral Metropolitan Borough Council',
    value: 'Wirral Metropolitan Borough Council',
  },
  {
    text: 'Woking Borough Council',
    value: 'Woking Borough Council',
  },
  {
    text: 'Wokingham',
    value: 'Wokingham',
  },
  {
    text: 'Wolverhampton',
    value: 'Wolverhampton',
  },
  {
    text: 'Worcester',
    value: 'Worcester',
  },
  {
    text: 'Worthing',
    value: 'Worthing',
  },
  {
    text: 'Wrekin District Council',
    value: 'Wrekin District Council',
  },
  {
    text: 'Wrexham',
    value: 'Wrexham',
  },
  {
    text: 'Wychavon',
    value: 'Wychavon',
  },
  {
    text: 'Wycombe District Council',
    value: 'Wycombe District Council',
  },
  {
    text: 'Wyre Borough Council',
    value: 'Wyre Borough Council',
  },
  {
    text: 'Wyre Forest District Council',
    value: 'Wyre Forest District Council',
  },
  {
    text: 'York',
    value: 'York',
  },
];
