import React, { ChangeEvent, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useRef } from 'hooks';

import { CurrencyQuestion } from 'shared/types/question';

import { AnswerQuestion, usePreQuote } from 'providers/QuoteProvider';
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyValue,
} from 'utils/currencyUtils';

import './CurrencyInput.scss';
import SendButton from '../../../Buttons/Send/SendButton';
import { scrollToBottom } from 'components/Chatbot/ChatRoller/ChatRoller';

interface Props {
  question: CurrencyQuestion;
  onAnswer: AnswerQuestion;
  placeholder?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const CURRENCY_REGEX = /^[0-9,.\b]+$/;

function CurrencyInput({ question, onAnswer, placeholder, scrollRef }: Props) {
  const { answer_path } = question;
  const [element, ref] = useRef<HTMLInputElement>();

  const {
    $,
    productHistory: { current: product },
  } = usePreQuote();
  const symbol = getCurrencySymbol(product.language, product.currency);

  const [text, setText] = useState(() => {
    if (!answer_path) {
      return '';
    }

    const value = get({ $ }, answer_path);

    // If value is a number, convert it to a decimal value and format
    return !isNaN(value) ? formatValue(String(value / 100)) : '';
  });

  const valid = useMemo(() => {
    // Fail validation if ref is not set yet
    if (!element) {
      return false;
    }

    // Fail validation if nothing has been entered
    if (!text) {
      return false;
    }

    // Pass validation if input is numeric
    if (/[^0-9,.]/.test(text)) {
      return false;
    }

    // Otherwise true
    return true;
  }, [element, text]);

  function onSend() {
    const value = getCurrencyValue(text, product.language, product.currency);
    const formatted = formatCurrency(value, product.language, product.currency);

    onAnswer(formatted, value);

    setText('');
  }

  function formatValue(value: string) {
    if (value === '' || CURRENCY_REGEX.test(value)) {
      const sanitised =
        getCurrencyValue(value, product.language, product.currency) / 100;
      const formatted = sanitised.toLocaleString(product.language);

      return parseInt(formatted) > 0 ? formatted : '';
    }

    return value;
  }

  function onChange({ target }: ChangeEvent<HTMLInputElement>) {
    const value = formatValue(target.value);

    setText(value);
  }

  return (
    <div className="CurrencyInput">
      <h4 className="CurrencyInput-symbol">{symbol}</h4>
      <input
        ref={ref}
        type="text"
        aria-label="currency-input"
        pattern="[0-9]*"
        className="CurrencyInput-input"
        data-testid="currency-input"
        value={text}
        onChange={onChange}
        placeholder={placeholder ?? ''}
        onFocus={() =>
          scrollRef && setTimeout(() => scrollToBottom(scrollRef.current), 500)
        }
        title="CurrencyInput"
      />
      <SendButton
        onClick={onSend}
        disabled={!valid}
        dataTestId="currency-input-button"
      />
    </div>
  );
}

export default CurrencyInput;
