import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

export function scrollToBottom(element: HTMLDivElement | null) {
  if (!element) {
    return;
  }

  const { scrollHeight, scrollTop, clientHeight } = element;
  const hiddenHeight = scrollHeight - clientHeight - scrollTop;

  if (hiddenHeight > 0) {
    element.scrollTo({ top: scrollHeight, behavior: 'smooth' });
  }
}

function ChatRoller({
  children,
  className,
  scrollRef,
}: React.PropsWithChildren<{
  className?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
}>) {
  return (
    <div className={`Chatbot-roller ${className}`} ref={scrollRef}>
      <ReactResizeDetector
        handleHeight
        onResize={() => scrollToBottom(scrollRef.current)}
      >
        <div>{children}</div>
      </ReactResizeDetector>
    </div>
  );
}

export default ChatRoller;
