import React, { useState } from 'react';

import { usePreQuote } from 'providers/QuoteProvider';
import { useCreateQuote } from 'hooks/useCreateQuote';
import { useEffectAsync } from 'hooks';
import EventManager from 'utils/EventManager';
import { IQuote, IQuotePackage, ISchemaString } from 'types/quote';

import QuoteOverview from './QuoteOverview';
import * as Sentry from '@sentry/react';
import { useGetQuoteGroupLazyQuery } from 'shared/graphql/api/types';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { getSchemaConfiguration } from '../../../../../utils/schemaUtils';
import { getActiveProduct } from '../../../../../utils/productUtils';

const debug = require('debug')('customer-web:quote');

interface Props {
  onClose(value: { package: IQuotePackage; quote: IQuote }): void;
}

function QuoteOverviewModal({ onClose }: Props) {
  const { $, productHistory, quoteType, renewalOf } = usePreQuote();
  const [loading, setLoading] = useState(true);
  const { create, error } = useCreateQuote();

  const product = getActiveProduct(
    new Date($.datasheet.policy.startDateTime),
    productHistory
  );
  const groupId = uuidv4();

  const [getQuoteGroup, { data: quotesQuery, loading: loadingQuotes }] =
    useGetQuoteGroupLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const quotes = quotesQuery?.quoteGroup || undefined;

  useEffectAsync(async () => {
    setLoading(true);

    EventManager.track({
      event: 'QuoteFlowEnd',
      productId: product.id,
      label: 'chat',
    });

    const trackQuoteDeclined = () => {
      EventManager.track({
        event: 'QuoteDeclined',
        productId: product.id,
        quoteType,
      });
    };

    const trackOptionsPresented = () => {
      EventManager.track({
        event: 'PolicyOptionsPresented',
        productId: product.id,
        quoteType,
      });
    };

    try {
      debug('createQuote()');

      Sentry.addBreadcrumb({
        category: 'quote',
        message: `create`,
        level: Sentry.Severity.Info,
      });

      let declined = false;

      let datasheet = $.datasheet;
      const path = 'policy.duration';
      const configuration = getSchemaConfiguration(path, product.schema);

      const values = (configuration as ISchemaString).enums ?? [];

      for (const dur of values) {
        datasheet = _.set(datasheet, path, dur);
        const { data } = await create({
          product: {
            id: product.id,
            version: product.version,
          },
          datasheet,
          renewalOf,
          quoteGroupId: groupId,
        });
        if (data?.quote.status === 'DECLINED') {
          declined = true;
          break;
        }
      }

      // Track event if quote is declined
      if (declined) {
        trackQuoteDeclined();
      } else {
        // Track event options presented
        trackOptionsPresented();
      }

      await getQuoteGroup({
        variables: { groupId: groupId },
      });
    } catch (e) {
      debug('createQuote(): error', e.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuoteOverview
      product={product}
      quotes={quotes}
      error={error}
      currency={product.currency}
      loading={loading || loadingQuotes}
      onClose={onClose}
    />
  );
}

export default QuoteOverviewModal;
