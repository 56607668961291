import React, { PropsWithChildren } from 'react';
import Button from 'components/Buttons/Button';
import String from '../../String/String';
import EventManager, { EventType } from '../../../utils/EventManager';
import { QuoteType } from 'providers/TrackingProvider';
import { useStrings } from '../../../hooks';
import Modal from '../Modal';

interface Props {
  onCancel(): void;
  onVehicleChange(): void;
  onOtherChanges(): void;
  productId: string;
  isPremiumFinancePolicy: boolean;
}

function MtaSelfServeModal({
  onCancel,
  onVehicleChange,
  onOtherChanges,
  productId,
  isPremiumFinancePolicy,
}: PropsWithChildren<Props>) {
  const { get } = useStrings();

  const vehicleChange = () => {
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: 'MTA Type of Change - Vehicle',
      quoteType: QuoteType.MTA,
      productId,
    });

    onVehicleChange();
  };

  const otherChanges = () => {
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: 'MTA Type of Change - Other',
      quoteType: QuoteType.MTA,
      productId,
    });
    onOtherChanges();
  };

  const cancel = () => {
    EventManager.track({
      event: EventType.QuestionAnswered,
      questionId: 'MTA Type of Change - Cancel',
      quoteType: QuoteType.MTA,
      productId,
    });
    onCancel();
  };

  return (
    <Modal
      title={get('pages.product.dashboard.modal.self_serve.title')}
      className="MtaSelfServeModal"
      data-testid="mta-self-serve-modal"
      onClose={onCancel}
      actions={
        <>
          {!isPremiumFinancePolicy && (
            <Button
              onClick={vehicleChange}
              variant="secondary"
              testId="self-serve-vehicle"
            >
              <String id="pages.product.dashboard.modal.self_serve.vehicleChange" />
            </Button>
          )}

          <Button
            variant="secondary"
            onClick={otherChanges}
            testId="self-serve-other"
          >
            <String id="pages.product.dashboard.modal.self_serve.otherChange" />
          </Button>

          <Button
            variant="secondary"
            onClick={cancel}
            testId="self-serve-close"
          >
            <String id="pages.general.cancel" />
          </Button>
        </>
      }
      actionsFlow="row"
    >
      <p>
        <String id="pages.product.dashboard.modal.self_serve.message" />
      </p>
    </Modal>
  );
}

export default MtaSelfServeModal;
