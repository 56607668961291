import {
  ILineItem,
  IPackageValuationFragment,
  IProductDetailsFragment,
  IQuotePackageFragment,
} from 'shared/graphql/api/types';
import classnames from 'classnames';
import RadioButton from '../Form/RadioButton/RadioButton';
import { formatTestId } from '../../utils/testIdUtils';
import String from '../String/String';
import { useStrings } from '../../hooks';
import { InstalmentsBreakdown } from './InstalmentsBreakdown';
import { FinancePlaymentPlanBreakdown } from './FinancePaymentPlanBreakdown';
import { getProductParameters } from './paymentOverviewUtils';
import {
  findFinancePaymentPlan,
  findFlexPaymentPlan,
  findInFullPaymentPlan,
  findInstalmentsPaymentPlan,
} from 'utils/quoteUtils';
import { PaymentPlanEnum } from 'enums/PaymentPlan';

import './PaymentPlanSelection.scss';
import { FlexPaymentPlanBreakdown } from './FlexPaymentPlanBreakdown';
import { PackageValuation, PaymentPlan } from '@inshur/apis/billing';
import {
  FinancePaymentPlanPaymentPlanEnum,
  InstalmentsPaymentPlanPaymentPlanEnum,
} from '@inshur/apis/quote';
import { convertToBaseAddonType } from 'components/QuoteOption/QuoteOptionPricing';
import Currency from '../Currency/Currency';
import { getFeesLineItems } from 'utils/lineItems';
import configuration from 'configuration';

interface Props {
  product: IProductDetailsFragment;
  quotePackage: IQuotePackageFragment;
  paymentOptions: PackageValuation[];
  onClick: (plan: PaymentPlanEnum) => void;
  selectedPlan?: PaymentPlanEnum;
  disabledPlans: PaymentPlanEnum[];
}

function BillingPaymentPlanSelection({
  quotePackage,
  onClick,
  paymentOptions,
  product,
  selectedPlan,
  disabledPlans,
}: Props) {
  const { get } = useStrings();
  const { currency } = product;

  const { showIpt } = getProductParameters(
    product.code,
    product.location.country
  );

  function mapToIQuotePackageFragment(
    allPaymentOptions: PackageValuation[]
  ): IQuotePackageFragment {
    const packageValuations = allPaymentOptions || [];
    const packageId = quotePackage.id;

    const selectedPackage = packageValuations?.find(
      (retrievePackage: any) => retrievePackage.packageId === packageId
    );

    const mappedPaymentPlans = selectedPackage?.paymentPlans
      ? selectedPackage.paymentPlans.map((plan: any) => {
          switch (plan.paymentPlan) {
            case FinancePaymentPlanPaymentPlanEnum.FINANCE:
              return {
                paymentPlan: PaymentPlan.FINANCE,
                aprPercentage: plan.aprPercentage,
                agreementTerm: plan.agreementTerm,
                total: plan.total,
                tax: plan.tax,
                costOfCredit: plan.costOfCredit,
                cashPrice: plan.cashPrice,
                policyBindAmount: plan.policyBindAmount,
                financeDownpayment: plan.downpayment,
                financeInstalment: plan.instalment,
              };
            case InstalmentsPaymentPlanPaymentPlanEnum.INSTALMENTS:
              return {
                paymentPlan: PaymentPlan.INSTALMENTS,
                policyBindAmount: plan.policyBindAmount,
                total: plan.total,
                downpayment: plan.downpayment,
                instalment: plan.instalment,
                numberOfInstalments: plan.numberOfInstalments,
              };
            default:
              return {
                paymentPlan: plan.paymentPlan || null,
                policyBindAmount: plan.policyBindAmount || 0,
                total: plan.total || 0,
              };
          }
        })
      : null;

    return {
      id: selectedPackage?.packageId ?? '',
      packageValuationId: selectedPackage?.packageId ?? null,
      name: selectedPackage?.name ?? '',
      valuation: quotePackage?.valuation ?? null,
      paymentPlans: mappedPaymentPlans,
    };
  }

  const instalmentsPaymentPlanBilling = findInstalmentsPaymentPlan(
    mapToIQuotePackageFragment(paymentOptions)
  );
  const financePaymentPlanBilling = findFinancePaymentPlan(
    mapToIQuotePackageFragment(paymentOptions)
  );
  const inFullPaymentPlanBilling = findInFullPaymentPlan(
    mapToIQuotePackageFragment(paymentOptions)
  );
  const flexWalletPaymentPlanBilling = findFlexPaymentPlan(
    mapToIQuotePackageFragment(paymentOptions)
  );

  const isInstalmentsPaymentPlanDisabled = disabledPlans.includes(
    PaymentPlanEnum.INSTALMENTS
  );
  const isFinancePaymentPlanDisabled = disabledPlans.includes(
    PaymentPlanEnum.FINANCE
  );

  const fees: ILineItem[] = getFeesLineItems(quotePackage.valuation);

  function handleClick(paymentPlan: PaymentPlanEnum) {
    if (!disabledPlans.includes(paymentPlan)) {
      onClick(paymentPlan);
    }
  }

  return (
    <div className="PaymentOverview-paymentPlan">
      <h4>
        <String id="pages.quote.payment.overview.payment_plan.title" />
      </h4>
      {(quotePackage.paymentPlans?.length ?? 1) > 1 && (
        <p>
          <String id="pages.quote.payment.overview.payment_plan.description" />
        </p>
      )}
      <div
        className={classnames(
          `QuoteOption-color`,
          quotePackage.name!.toLowerCase()
        )}
      />

      {inFullPaymentPlanBilling && (
        <div
          className="QuoteOption"
          onClick={() => handleClick(PaymentPlanEnum.IN_FULL)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.IN_FULL}
            id="billing_payment-option-in_full"
            value="billing_payment-option-in_full"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.one_time.title'
            )}
            testId={formatTestId(`billing_payment-option-select-in_full`)}
          />
          <div
            className="PaymentPlanBreakdown-inFull"
            data-testid="billing_payment-plan-breakdown-in_full"
          >
            <div>
              <String id="pages.quote.payment.overview.payment_plan.one_time.price_title" />
            </div>
            <h1
              className="QuoteOption-price"
              data-testid="billing_payment-plan-breakdown-price"
            >
              {
                <Currency
                  currency={currency}
                  value={inFullPaymentPlanBilling.total}
                />
              }
            </h1>
            {showIpt && quotePackage.valuation?.tax && (
              <p
                className="QuoteOption-tax"
                data-testid="billing_payment-plan-breakdown-ipt"
              >
                <String
                  id="pages.quote.payment.overview.payment_plan.one_time.ipt"
                  values={{
                    amount: (
                      <Currency
                        currency={currency}
                        value={quotePackage.valuation.tax}
                      />
                    ),
                  }}
                />
              </p>
            )}
            {fees.length > 0 &&
              fees.map(({ value, id, name }) => (
                <p
                  className="QuoteOption-tax"
                  key={id}
                  data-testid={`QuoteOption-price-addon-${id}`}
                >
                  {!configuration.enableTranslationsFees ? (
                    <String
                      id={`pages.quote.options.addon.${convertToBaseAddonType(
                        id
                      )}`}
                      values={{
                        total: <Currency currency={currency} value={value} />,
                      }}
                    />
                  ) : (
                    <String
                      id={`pages.quote.options.addon`}
                      values={{
                        total: <Currency currency={currency} value={value} />,
                        feeName: name,
                      }}
                    />
                  )}
                </p>
              ))}
          </div>
        </div>
      )}

      {instalmentsPaymentPlanBilling && (
        <div
          className={classnames({
            QuoteOption: true,
            'QuoteOption-disabled': isInstalmentsPaymentPlanDisabled,
          })}
          onClick={() => handleClick(PaymentPlanEnum.INSTALMENTS)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.INSTALMENTS}
            id="payment-option-instalments"
            value="payment-option-instalments"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.instalments.title'
            )}
            testId={formatTestId(`payment-option-select-instalments`)}
            disabled={isInstalmentsPaymentPlanDisabled}
          />
          <InstalmentsBreakdown
            paymentPlan={instalmentsPaymentPlanBilling}
            currency={currency}
            productCode={product.code}
          />
        </div>
      )}

      {financePaymentPlanBilling && (
        <div
          className={classnames({
            QuoteOption: true,
            'QuoteOption-disabled': isFinancePaymentPlanDisabled,
          })}
          onClick={() => handleClick(PaymentPlanEnum.FINANCE)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.FINANCE}
            id="payment-option-finance"
            value="payment-option-finance"
            name={quotePackage.id}
            label={get(
              'pages.quote.payment.overview.payment_plan.finance.title'
            )}
            testId={formatTestId(`billing_payment-option-select-finance`)}
            disabled={isFinancePaymentPlanDisabled}
          />
          <FinancePlaymentPlanBreakdown
            paymentPlan={financePaymentPlanBilling}
            currency={currency}
            fees={fees}
          />
        </div>
      )}

      {flexWalletPaymentPlanBilling && (
        <div
          className="QuoteOption"
          onClick={() => handleClick(PaymentPlanEnum.FLEX_WALLET)}
        >
          <RadioButton
            selected={selectedPlan === PaymentPlanEnum.FLEX_WALLET}
            id="payment-option-flex_wallet"
            value="payment-option-flex_wallet"
            name={quotePackage.id}
            label={get(
              'pages.quote.billing_payment.overview.payment_plan.flex_wallet.title'
            )}
            testId={formatTestId(`billing-payment-option-select-flex_wallet`)}
          />
          <FlexPaymentPlanBreakdown
            paymentPlan={flexWalletPaymentPlanBilling}
            currency={currency}
            valuation={quotePackage.valuation as IPackageValuationFragment}
          />
        </div>
      )}
    </div>
  );
}

export default BillingPaymentPlanSelection;
