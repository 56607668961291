import { FormEvent, useEffect, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';

import { ICurrency } from 'shared/graphql/api/types';

import Button from 'components/Buttons/Button';
import Currency from 'components/Currency/Currency';
import String from 'components/String/String';

import withStripe, { WithStripeProps } from './withStripe';

import './PaymentForm.scss';

import { useQuery, useStrings } from 'hooks';
import * as Sentry from '@sentry/react';
import { PaymentFormError } from '../../constants/errors';

export interface PaymentFormProps extends WithStripeProps {
  clientSecret: string;
  amount: number;
  currency: ICurrency;
  onBeforePayment(): Promise<boolean>;
  onCancel(): void;
  onSubmit(): void;
  onFailure(code?: string): void;
  onSuccess(intent?: PaymentIntent): void;
  returnTo?: string;
}

export function PaymentForm({
  clientSecret,
  onSuccess,
  onSubmit,
  onFailure,
  currency,
  amount,
  onCancel,
  onBeforePayment,
  returnTo,
}: PaymentFormProps) {
  const query = useQuery<{
    payment_intent?: string;
    paymentMethod?: string;
    redirect_status?: string;
  }>();
  const { get } = useStrings();
  const stripe = useStripe();
  const elements = useElements();

  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(() => {
    if (query.redirect_status === 'failed') {
      return get('pages.error.message');
    }

    return '';
  });

  useEffect(() => {
    if (query.redirect_status === 'succeeded') {
      // As the iDEAL flow doesn't give us the final intent data, we need to
      // construct an object to report back to the API.
      setComplete(true);
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.redirect_status]);

  async function confirmPayment() {
    const submitResult = await elements?.submit();
    if (submitResult?.error) {
      throw submitResult?.error;
    }
    if (!elements || !stripe) {
      throw new Error(get('pages.error.message'));
    }
    const { paymentIntent: intent, error } = await stripe.confirmPayment({
      clientSecret,
      elements,
      confirmParams: {
        return_url: returnTo ?? '',
      },
      redirect: 'if_required',
    });

    if (error) {
      throw error;
    }

    return intent;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (processing || complete) return;

    setProcessing(true);
    const quoteHasNotExpired = await onBeforePayment();
    if (quoteHasNotExpired) {
      // Fire an on submit event to the parent
      onSubmit();
      setError('');
    } else {
      setProcessing(false);
      setError(get('pages.error.message'));
      return;
    }

    try {
      const intent = await confirmPayment();
      onSuccess(intent);
      setComplete(true);
    } catch (e) {
      Sentry.captureException(e);
      onFailure(e.code);

      if (e instanceof PaymentFormError) {
        setError(get('pages.error.message'));
      } else {
        setError(e.message);
      }

      setProcessing(false);
    }
  }

  return (
    <div className="PaymentForm">
      <form data-testid="payment-form" onSubmit={handleSubmit}>
        {error && (
          <div data-testid="paymentform-error" className="PaymentForm-error">
            {error}
          </div>
        )}
        <PaymentElement />
        <div className="Modal-actions Modal-actions-row">
          <Button
            variant="primary"
            type="submit"
            disabled={processing || complete}
            loading={processing}
            testId="payment-pay"
          >
            <Currency currency={currency} value={amount} /> -{' '}
            <String id="pages.quote.payment.pay_now" />
          </Button>
          <Button
            type="button"
            variant="secondary"
            disabled={processing || complete}
            onClick={onCancel}
            testId="payment-cancel"
          >
            <String id="pages.quote.payment.cancel" />
          </Button>
        </div>
      </form>
    </div>
  );
}

export default withStripe(PaymentForm);
