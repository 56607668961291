import React, { MouseEvent } from 'react';

import { LinkButtonVariants, StyledLinkButton } from './styled';
import { LinkProps } from '../Router/Link';

export interface LinkButtonProps extends LinkProps {
  variant?: LinkButtonVariants;
  disabled?: boolean;
  handleClick?: (event: MouseEvent) => void;
}

function LinkButton({
  to,
  variant = 'regular',
  disabled,
  handleClick,
  children,
  testId,
  ...props
}: LinkButtonProps & { testId: string }) {
  return (
    <StyledLinkButton
      {...props}
      variant={variant}
      to={to}
      disabled={disabled ? disabled : false}
      onClick={(ev: MouseEvent) => {
        disabled && ev.preventDefault();
        handleClick && handleClick(ev);
      }}
      data-testid={testId}
    >
      {children}
    </StyledLinkButton>
  );
}

export default LinkButton;
