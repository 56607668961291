import { MouseEvent, useCallback, useMemo } from 'react';
import { last } from 'lodash';
import LinkButton from 'components/LinkButton/LinkButton';
import EventManager, { EventType } from 'utils/EventManager';
import { FlexColumn } from 'components/Layout/Layout';
import { createKey } from 'utils/quoteUtils';
import String from 'components/String/String';
import { useModal } from 'providers/ModalProvider';
import { QuoteType } from 'providers/TrackingProvider';
import { Configuration } from 'configuration';
import { useHistory } from 'hooks/useHistory';
import {
  IPolicyDetailFragment,
  IQuoteDetailsFragment,
} from 'shared/graphql/api/types';
import Button from 'components/Buttons/Button';
import { PaymentPlanEnum } from 'enums/PaymentPlan';
import {
  hasPremiumFinancePaymentPlan,
  wasPremiumFinance,
  canSelfServeCancellation,
  getCustomerPortalLink,
  getZendeskLink,
} from 'utils/policyUtils';

import { useGetRenewalTracking } from 'hooks/useGetRenewalTracking';

interface Props {
  policy: IPolicyDetailFragment;
  productId?: string;
  productCode?: string;
  quotes?: IQuoteDetailsFragment[];
  isActive: boolean;
  mtaQuote?: IQuoteDetailsFragment;
  version?: string | null;
  mtaEnabled: boolean;
  allowManualRenewal: boolean;
  index: number;
  hasPendingMtaPolicy: boolean;
  policyAllVersions: IPolicyDetailFragment[];
  hasPendingRenewed: boolean;
  policiesRefetch: () => void;
}

function PolicyActions({
  policy,
  version,
  isActive,
  productId,
  productCode,
  quotes,
  mtaQuote,
  mtaEnabled,
  allowManualRenewal,
  hasPendingMtaPolicy,
  policyAllVersions,
  hasPendingRenewed,
  policiesRefetch = () => {},
}: Props) {
  const configuration = Configuration.get();

  const { renewalTracking } = useGetRenewalTracking(policy.id);

  const isPremiumFinancePolicy =
    hasPremiumFinancePaymentPlan(policy) ||
    wasPremiumFinance(policyAllVersions);

  const isInstalmentsPolicy =
    policy.package.paymentPlan?.paymentPlan === PaymentPlanEnum.INSTALMENTS;

  const canCancelPolicy =
    configuration.selfServeCancellationsEnabled &&
    canSelfServeCancellation(policy);

  const policyEmail = policy.datasheet.insured.email;

  const autoRenewalQuoteDoesNotExist =
    renewalTracking && typeof renewalTracking.autoRenewalQuoteId !== 'string';

  // Using product on the policy - since you can view policies on the dashboard of same
  // auth0 connection type (e.g. UKO and UKA)
  const hasCPA =
    policy.product.paymentProvider.continuousPaymentAuthority ?? false;

  const latestQuoteStatusIsDeclined = last(quotes)?.status === 'DECLINED';

  const history = useHistory();
  const { display, dismiss } = useModal();

  const canRenew = useMemo(
    () =>
      isActive &&
      !hasPendingRenewed &&
      productCode &&
      !configuration.discontinuedProducts.includes(productCode),
    [isActive, hasPendingRenewed, productCode, configuration]
  );

  const canSelfServe = useMemo(
    () =>
      mtaEnabled &&
      productCode &&
      !configuration.disableSelfServe &&
      configuration.selfServeProductCodes.includes(productCode) &&
      !hasPendingMtaPolicy &&
      configuration.blockMtasWithinRenewalWindow
        ? autoRenewalQuoteDoesNotExist
        : true,
    [
      configuration,
      hasPendingMtaPolicy,
      mtaEnabled,
      productCode,
      autoRenewalQuoteDoesNotExist,
    ]
  );

  const canContinueWithMtaQuote = useMemo(
    () =>
      mtaEnabled &&
      mtaQuote?.mtaOf?.version === version &&
      !latestQuoteStatusIsDeclined &&
      !isPremiumFinancePolicy &&
      !hasPendingMtaPolicy,
    [
      hasPendingMtaPolicy,
      isPremiumFinancePolicy,
      latestQuoteStatusIsDeclined,
      mtaEnabled,
      mtaQuote,
      version,
    ]
  );

  const zendeskRedirect = useCallback(() => {
    window.location.href = getZendeskLink(policy);
  }, [policy]);

  const proceedWithMta = useCallback(
    (policy: IPolicyDetailFragment) => {
      EventManager.track({
        event: EventType.QuestionAnswered,
        questionId: 'MTA Making a Change - accept terms',
        quoteType: QuoteType.MTA,
        productId: policy.product.id,
      });
      dismiss();
      const policyKey = createKey(policy.id, policy.version);
      history.push(`/product/${productId}/mta/${policyKey}/vehicle`);
    },
    [dismiss, history, productId]
  );

  const trackMtaChangesViewed = useCallback(() => {
    if (productId) {
      EventManager.track({
        productId: productId,
        event: 'MtaChangesViewed',
        quoteType: QuoteType.MTA,
        productCode: 'UK2.0',
      });
    }
    dismiss();
  }, [dismiss, productId]);

  const handleAmend = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (mtaQuote && !latestQuoteStatusIsDeclined) {
        display('existing_mta', {
          onContinue: () => {
            dismiss();
            history.push(`/product/${productId}/quote/${mtaQuote.id}/review`);
          },
          onCancel: dismiss,
        });
      } else {
        display('mta_self_serve', {
          onVehicleChange: () => proceedWithMta(policy),
          onOtherChanges: () =>
            display('contact', {
              onCancel: dismiss,
            }),
          onCancel: dismiss,
          isPremiumFinancePolicy,
          productId: policy.product.id,
        });
      }
    },
    [
      dismiss,
      display,
      history,
      isPremiumFinancePolicy,
      latestQuoteStatusIsDeclined,
      mtaQuote,
      policy,
      proceedWithMta,
      productId,
    ]
  );

  const onShowMyRenewalsModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      display('my_renewal', {
        policyId: policy.id,
        allowManualRenewal: allowManualRenewal,
        onCancel: dismiss,
      });
    },
    [dismiss, display, policy, allowManualRenewal]
  );

  const onShowClaimsContactModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      display('claims', {
        product: policy.product,
        onCancel: dismiss,
      });
    },
    [dismiss, display, policy]
  );

  const onShowDocumentsModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      display('documents', {
        policy: policy,
        onCancel: dismiss,
      });
      EventManager.track({
        productId,
        event: 'ButtonPressed',
        questionId: 'Product Page - Open Documents Modal',
      });
    },
    [dismiss, display, policy, productId]
  );

  const onShowCancelModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      display('cancel_policy', {
        policy: policy,
        onCancel: dismiss,
        onSuccess: policiesRefetch,
      });
      EventManager.track({
        productId,
        event: 'ButtonPressed',
        questionId: 'Product Page - Open Cancel Policy Modal',
      });
    },
    [dismiss, display, policiesRefetch, policy, productId]
  );

  const manageCardPortalRedirect = useCallback(() => {
    const portalUrl = new URL(getCustomerPortalLink(productCode) || '');
    portalUrl.searchParams.set('prefilled_email', policyEmail);
    window.location.href = portalUrl.href;
  }, [productCode, policyEmail]);

  return (
    <>
      {canRenew && (
        <FlexColumn className="ProductPage-action">
          {
            <Button
              testId="my_renewal-linkbutton"
              className="ProductPage-button"
              variant={allowManualRenewal ? 'activated' : 'secondary'}
              onClick={onShowMyRenewalsModal}
            >
              <String id="pages.product.dashboard.modal.my_renewal.title" />
            </Button>
          }
        </FlexColumn>
      )}
      {mtaQuote && canContinueWithMtaQuote && (
        <FlexColumn className="ProductPage-action">
          <LinkButton
            testId="continue-linkbutton"
            className="ProductPage-button"
            to={`/product/${productId}/quote/${mtaQuote.id}/review`}
            variant="primary"
            handleClick={trackMtaChangesViewed}
          >
            <String id="pages.product.dashboard.continue" />
          </LinkButton>
        </FlexColumn>
      )}
      {canSelfServe && (
        <FlexColumn className="ProductPage-action">
          <LinkButton
            testId="self-serve-button"
            className="ProductPage-button"
            to={`#`}
            variant="secondary"
            handleClick={handleAmend}
          >
            <String id="pages.product.dashboard.amend" />
          </LinkButton>
        </FlexColumn>
      )}
      {!canSelfServe && !hasPendingMtaPolicy && (
        <FlexColumn className="ProductPage-action">
          <Button
            testId="amend-linkbutton"
            className="ProductPage-button"
            onClick={zendeskRedirect}
            variant="secondary"
          >
            <String id="pages.product.dashboard.amend" />
          </Button>
        </FlexColumn>
      )}
      {hasCPA && (
        <FlexColumn className="ProductPage-action">
          <Button
            testId="update-card-linkbutton"
            className="ProductPage-button"
            onClick={manageCardPortalRedirect}
            variant="secondary"
          >
            <String id="pages.product.dashboard.update.card" />
          </Button>
        </FlexColumn>
      )}

      {isActive && (
        <FlexColumn className="ProductPage-action">
          <LinkButton
            testId="claim-linkbutton"
            className="ProductPage-button"
            to={`#`}
            variant="secondary"
            handleClick={onShowClaimsContactModal}
          >
            <String id="pages.product.dashboard.claim" />
          </LinkButton>
        </FlexColumn>
      )}

      <FlexColumn className="ProductPage-action">
        <LinkButton
          testId="documents-linkbutton"
          className="ProductPage-button"
          to={`#`}
          variant="secondary"
          handleClick={onShowDocumentsModal}
        >
          <String id="pages.product.dashboard.documents" />
        </LinkButton>
      </FlexColumn>

      {isInstalmentsPolicy && (
        <FlexColumn className="ProductPage-action">
          <Button
            testId="payment-schedule-linkbutton"
            className="ProductPage-button"
            variant="secondary"
            onClick={() =>
              display('payment_schedule', {
                policy,
                onCancel: dismiss,
              })
            }
          >
            <String id="pages.product.dashboard.payment_schedule" />
          </Button>
        </FlexColumn>
      )}

      <FlexColumn className="ProductPage-action">
        <Button
          onClick={canCancelPolicy ? onShowCancelModal : zendeskRedirect}
          testId="cancel-linkbutton"
          className="ProductPage-button"
          variant="secondary"
        >
          <String id="pages.product.dashboard.cancel" />
        </Button>
      </FlexColumn>
    </>
  );
}

export default PolicyActions;
