import styled, { DefaultTheme } from 'styled-components';
import { ViewStyle } from '../../themes';
import { useView } from '../../utils/themeUtils';

interface StyledProps {
  view(theme: DefaultTheme): ViewStyle;
}

export const Styled = styled.div<StyledProps>`
  ${(props) => useView(props.view)}
`;
