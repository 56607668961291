import { IQuote } from 'types/quote';
import { IQuotePackageFragment } from 'shared/graphql/api/types';
import configuration from 'configuration';

export interface IPackageFeatureGroupSchema {
  values: string[];
  strategy: string;
  packageId?: string;
  included: boolean;
}

export interface IPackageFeatureSchema {
  features: IPackageFeatureGroupSchema[];
}

export interface IFeatureStrategy {
  (
    quote: IQuote,
    currentPackage: IQuotePackageFragment,
    featureSchema: IPackageFeatureGroupSchema
  ): boolean;
}

// Strategy Functions
const showProtectedNCD: IFeatureStrategy = function (quote) {
  return quote.datasheet?.insured?.protectedNcd;
};
const showAllAddOns: IFeatureStrategy = function (
  quote,
  currentPackage,
  feature
) {
  const { packageId } = feature;
  if (!packageId) return false;
  if (packageId !== currentPackage.id) return false;

  return configuration.allAddonsPackageIds.includes(feature.packageId!);
};
const showForPackage: IFeatureStrategy = function (
  quote,
  currentPackage,
  feature
) {
  return currentPackage.id === feature.packageId;
};
const showStatic: IFeatureStrategy = function () {
  return true;
};

//

export const featureStrategies: { [key: string]: IFeatureStrategy } = {
  protectedNCD: showProtectedNCD,
  hasAllAddOns: showAllAddOns,
  packageId: showForPackage,
  static: showStatic,
};
