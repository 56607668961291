import configuration from '../configuration';
import { IPaymentProviderAccount } from '../shared/graphql/api/types';

/**
 * returns a specific stripe account based on the productCode.
 * @param productCode
 */
export function getStripeAccountValue(
  productCode: string
): IPaymentProviderAccount {
  return configuration.stripe.stripeAccount[
    productCode as keyof typeof configuration.stripe.stripeAccount
  ] as IPaymentProviderAccount;
}
