import React, { PropsWithChildren, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from 'components/Buttons/Button';
import Modal from '../Modal';
import { useStrings } from '../../../hooks';
import String from '../../String/String';
import { IProduct } from 'shared/graphql/api/types';

import './ClaimsContactModal.scss';
import { LocationCountryEnum } from '@inshur/apis/product';

interface Props {
  product: IProduct;
  onCancel(): void;
}

function ClaimsContactModal({ product, onCancel }: PropsWithChildren<Props>) {
  const { get } = useStrings();

  const claimsStringId = useMemo(() => {
    if (product.location.country === LocationCountryEnum.US) {
      switch (product.code) {
        case 'NYR':
        case 'NYRS':
          return 'pages.product.dashboard.model.claims.networkadjusters';
        default:
          return 'pages.product.dashboard.model.claims.coaction';
      }
    }
    return 'pages.product.dashboard.model.claims.default';
  }, [product]);

  return (
    <Modal
      title={get('pages.menu.contact.title')}
      className="ClaimsContactModal"
      data-testid="claims-contact-modal"
      onClose={onCancel}
      actions={
        <Button
          variant="secondary"
          onClick={onCancel}
          testId="claims-contact-close"
        >
          <String id="pages.menu.contact.button.close" />
        </Button>
      }
    >
      <section>
        <ReactMarkdown children={get(claimsStringId)} />
      </section>
    </Modal>
  );
}

export default ClaimsContactModal;
