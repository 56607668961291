import {
  ILocale,
  IStepsType,
  useGetProductStepsQuery,
} from 'shared/graphql/api/types';

export function useProductSteps(
  productId: string,
  productVersion: string,
  locale: ILocale,
  type: IStepsType
) {
  const { loading, data, error } = useGetProductStepsQuery({
    variables: { productId, locale, productVersion, stepsType: type },
  });

  return {
    loading,
    steps: data?.steps ?? [],
    error,
  };
}
