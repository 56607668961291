import { Route, RouteProps, useLocation } from 'react-router-dom';
import React from 'react';
import { useAuth } from 'providers/AuthProvider';

import Loader from 'components/Loader/Loader';
import LocalStorage from 'utils/LocalStorage';
import { createRedirectPath } from 'utils/pageUtils';
import Redirect from './Redirect';
import { useQuery } from 'hooks';
import UserProvider from 'providers/UserProvider';
import { getProductId } from 'utils/urlUtils';

interface AuthQuery {
  agreeTerms?: boolean;
}

function AuthRoute({ component, render, ...props }: RouteProps) {
  const location = useLocation();
  const { agreeTerms } = useQuery<AuthQuery>();
  const { isAuthenticated, claims } = useAuth();

  return (
    <Route
      {...props}
      render={(props) => {
        if (isAuthenticated === undefined) {
          return <Loader />;
        }

        if (!isAuthenticated) {
          const redirect = createRedirectPath(location);
          const productId = getProductId(location.pathname);

          if (!productId) {
            return <Redirect to="/login" query={{ redirect }} />;
          }

          return (
            <Redirect to={`/product/${productId}/login`} query={{ redirect }} />
          );
        }

        if (claims === undefined) {
          return <Loader />;
        }

        const { sub: id } = claims;

        if (agreeTerms) {
          LocalStorage.set(`${id}:terms-agreed`, true);
          LocalStorage.set(`${id}:privacy-agreed`, true);
        }

        const termsAgreed =
          LocalStorage.get(`${id}:terms-agreed`) &&
          LocalStorage.get(`${id}:privacy-agreed`);

        const isTermsPage = location.pathname
          .toLowerCase()
          .startsWith('/terms');

        if (!termsAgreed && !isTermsPage) {
          const redirect = createRedirectPath(location);

          return <Redirect to="/terms" query={{ redirect }} />;
        }

        return (
          <UserProvider>
            <Route component={component} render={render} {...props} />
          </UserProvider>
        );
      }}
    />
  );
}

export default AuthRoute;
