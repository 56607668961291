import React, { useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import querystring from 'query-string';
import { useQuery } from '../../hooks';

export interface LinkProps extends RouterLinkProps {
  to: string;
  query?: Record<string, string | number | boolean>;
}

function Link({ to, query, ...props }: LinkProps) {
  const { lang } = useQuery<{ lang?: string }>();

  const path = useMemo(() => {
    const string = querystring.stringify(
      { lang, ...query },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return `${to}?${string}`;
  }, [lang, to, query]);

  return <RouterLink to={path} {...props} />;
}

export default Link;
