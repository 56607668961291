import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import Button from 'components/Buttons/Button';
import { Step } from '@inshur/apis/steps';
import datasheet from './datasheet.json';

import LocalStorage from 'utils/LocalStorage';

import './DevTools.scss';
import deepmerge from 'deepmerge';
import { startOfTomorrow } from 'date-fns';

interface Props {
  id: string;
  json: any;
  onChange?: any;
  steps: Step[];
  jumpTo(id: string, force?: boolean): void;
}

export default function DevTools(props: Props) {
  const [showJumper, setShowJumper] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const cacheKey = `${props.id}:datasheet`;

  function setDatasheet() {
    const data = deepmerge(LocalStorage.get(cacheKey, datasheet), {
      policy: {
        startDateTime: startOfTomorrow().toISOString(),
      },
    });

    props.onChange?.(data);
  }

  useEffect(() => {
    LocalStorage.set(cacheKey, props.json);
  }, [props.json, cacheKey]);

  const onKeyDown = useCallback(
    ({ key, shiftKey, ctrlKey }: KeyboardEvent) => {
      if (!shiftKey || !ctrlKey) {
        return;
      }

      switch (key) {
        case 'J': {
          setShowJumper((v) => !v);
          return;
        }
        case 'H': {
          setShowHelp((v) => !v);
          return;
        }
        case 'X': {
          setDatasheet();
          return;
        }
        case 'S': {
          LocalStorage.set(cacheKey, props.json);
          return;
        }
        case 'D': {
          LocalStorage.remove(cacheKey);
          return;
        }
        case 'O': {
          setDatasheet();
          jumpToType('policies');
          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.json, props.onChange]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  function onClose() {
    setShowJumper(false);
    setShowHelp(false);
  }

  function jumpTo(step?: Step) {
    if (!step) {
      return;
    }

    props.jumpTo(step.id, true);
    onClose();
  }

  function jumpToType(type: any) {
    const step = props.steps.find(
      (s) => s.type === 'question' && s.question.type === type
    );

    jumpTo(step);
  }

  return (
    <>
      {showJumper && (
        <Modal title="Jump to" className="Jumper-modal" onClose={onClose}>
          <Button
            tabIndex={1}
            variant="primary"
            onClick={() => jumpToType('address')}
            testId="address"
          >
            Address
          </Button>
          <Button
            tabIndex={2}
            variant="primary"
            onClick={() => jumpToType('policies')}
            testId="coverage-options"
          >
            Coverage options
          </Button>
          <Button
            tabIndex={2}
            variant="primary"
            onClick={() => jumpToType('payment')}
            testId="payment"
          >
            Payment
          </Button>
        </Modal>
      )}
      {showHelp && (
        <Modal title="Help" className="Help-modal" onClose={onClose}>
          <h4>Save Datasheet: CTRL + SHIFT + S</h4>
          <h4>Set Datasheet: CTRL + SHIFT + X</h4>
          <h4>Delete Datasheet: CTRL + SHIFT + D</h4>
          <h4>Jump To: CTRL + SHIFT + J</h4>
          <h4>Go To Options: CTRL + SHIFT + O</h4>
        </Modal>
      )}
    </>
  );
}
