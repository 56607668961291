import {
  IQuoteDetailsFragment,
  IQuotePackageFragment,
} from 'shared/graphql/api/types';
import {
  IPackageFeatureGroupSchema,
  featureStrategies,
  IPackageFeatureSchema,
} from '../utils/feature/PackageFeatureStrategy';
import { default as PackageFeatureSchemas } from '../utils/feature/feature-schemas';

export function useGetPackageFeatures() {
  function getFeatures(
    productCode: string,
    currentPackage: IQuotePackageFragment,
    quote: IQuoteDetailsFragment
  ) {
    const includedFeatures: string[] = [];
    const excludedFeatures: string[] = [];

    const schema = PackageFeatureSchemas.schemas[
      productCode
    ] as IPackageFeatureSchema;
    if (!schema) return { includedFeatures, excludedFeatures };

    const { features } = schema;

    features.forEach((feature: IPackageFeatureGroupSchema) => {
      const { values, strategy, included } = feature;
      const strategyFunc = featureStrategies[strategy];
      if (strategyFunc && strategyFunc(quote, currentPackage, feature)) {
        included
          ? includedFeatures.push(...values)
          : excludedFeatures.push(...values);
      }
    });
    return { includedFeatures, excludedFeatures };
  }

  return {
    getFeatures,
  };
}
