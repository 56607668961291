import React from 'react';
import * as FirebaseApp from 'firebase/app';
import * as FirebaseStore from 'firebase/firestore';

import MessagesFirebaseClient from '@inshur/nowucit/messages/MessagesFirebaseClient';
import Provider from '@inshur/nowucit/messages/MessagesProvider';

import configuration from 'configuration';

const client = MessagesFirebaseClient.fromConfig({
  collectionName: configuration.firebase.featureFlags.collection,
  options: {
    apiKey: configuration.firebase.key.apiKey,
    authDomain: configuration.firebase.key.authDomain,
    projectId: configuration.firebase.key.projectId,
    storageBucket: configuration.firebase.key.storageBucket,
    messagingSenderId: configuration.firebase.key.messagingSenderId,
    appId: configuration.firebase.key.appId,
    measurementId: configuration.firebase.key.measurementId,
  },
  appMethods: FirebaseApp,
  storeMethods: FirebaseStore,
});

export const MessagesProvider = ({
  children,
}: React.PropsWithChildren<{}>): JSX.Element => {
  return <Provider client={client}>{children}</Provider>;
};
