import React from 'react';
import classnames from 'classnames';

import './Avatar.scss';

interface Props {
  direction?: 'normal' | 'down' | 'right';
  expression?: 'happy' | 'sad';
}

function EvilAvatar({ direction = 'normal', expression = 'happy' }: Props) {
  if (expression === 'happy') {
    return (
      <svg
        width="80"
        height="100px"
        viewBox="0 0 80 100"
        className={classnames('Avatar', `Avatar-${direction}`)}
        preserveAspectRatio="xMidYMax"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="Avatar-tail">
          <path
            fill="none"
            stroke="#d61fae"
            d="M 38.779 22.694 C 39.025 22.848 42.202 36.422 48.736 16 C 53.457 1.245 33.919 18.137 16.365 16.182 C 13.985 15.917 11.908 12.081 18.639 9.418"
          />
          <path
            fill={'#d61fae'}
            stroke={'#d61fae'}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M 14.131 7.826 C 17.624 7.663 20.076 9.101 19.928 13.248 C 20.622 9.215 21.643 7.501 24.623 4.528 C 20.837 6.973 14.146 8.035 14.131 7.826 Z"
          />
        </g>
        <g fill="none" fillRule="evenodd" transform="matrix(1, 0, 0, 1, 0, 20)">
          <circle
            cx="40"
            cy="40"
            r="40"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'#C7F0F5'}
          />
          <g>
            <path
              d="M0 0H40V26H0z"
              transform="translate(20 27)"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              className="Avatar-face"
              d="M 20 25.51 C 14.329 25.51 9.643 21.344 8.802 15.91 L 31.198 15.91 C 30.357 21.344 25.671 25.51 20 25.51 Z M 35.91 12.273 C 33.65 12.273 30.725 11.395 30.725 9.136 C 30.725 6.876 44.226 1.405 42.179 3.683 C 40.132 5.961 40 5.92 40 8.18 C 40 10.44 38.168 12.272 35.91 12.272 L 35.91 12.273 Z M 4.09 12.273 C 1.833 12.273 0 10.44 0 8.182 C 0 5.922 1.825 6.455 -2.306 3.702 C -6.437 0.949 12.257 7.082 10.401 9.627 C 8.545 12.172 6.35 12.274 4.09 12.274 L 4.09 12.273 Z"
              transform="translate(20 27)"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={'#0E203E'}
            />
            <g className="Avatar-horns">
              <path
                fill={'#d61fae'}
                stroke={'rgb(0, 0, 0)'}
                strokeWidth="0px"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 11.645 17.545 C 7.75 13.5 5.818 9.94 7.41 2.752 C 12.509 10.592 16.6 6.893 22.754 9.17 C 24.863 11.106 15.193 20.801 11.645 17.545 Z"
              />
              <path
                fill={'#d61fae'}
                stroke={'rgb(0, 0, 0)'}
                strokeWidth="0px"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 60.447 3.49 C 56.552 7.535 54.62 11.095 56.212 18.283 C 61.311 10.443 65.402 14.142 71.556 11.865 C 73.665 9.929 63.995 0.234 60.447 3.49 Z"
                transform="matrix(-1, 0, 0, -1, 127.506683, 21.110076)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width="80"
      height="100px"
      viewBox="0 0 80 100"
      className={classnames('Avatar', `Avatar-${direction}`)}
      preserveAspectRatio="xMidYMax"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="Avatar-tail">
        <path
          fill="none"
          stroke="#d61fae"
          d="M 38.779 22.694 C 39.025 22.848 42.202 36.422 48.736 16 C 53.457 1.245 33.919 18.137 16.365 16.182 C 13.985 15.917 11.908 12.081 18.639 9.418"
        />
        <path
          fill={'#d61fae'}
          stroke={'#d61fae'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 14.131 7.826 C 17.624 7.663 20.076 9.101 19.928 13.248 C 20.622 9.215 21.643 7.501 24.623 4.528 C 20.837 6.973 14.146 8.035 14.131 7.826 Z"
        />
      </g>
      <g fill="none" fillRule="evenodd" transform="matrix(1, 0, 0, 1, 0, 20)">
        <circle
          cx="40"
          cy="40"
          r="40"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={'#C7F0F5'}
        />
        <g>
          <path
            d="M0 0H40V26H0z"
            transform="translate(20 27)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="Avatar-face"
            d="M 20 25.51 C 14.329 25.51 9.643 21.344 8.802 15.91 L 31.198 15.91 C 30.357 21.344 25.671 25.51 20 25.51 Z M 35.91 12.273 C 33.65 12.273 30.725 11.395 30.725 9.136 C 30.725 6.876 44.226 1.405 42.179 3.683 C 40.132 5.961 40 5.92 40 8.18 C 40 10.44 38.168 12.272 35.91 12.272 L 35.91 12.273 Z M 4.09 12.273 C 1.833 12.273 0 10.44 0 8.182 C 0 5.922 1.825 6.455 -2.306 3.702 C -6.437 0.949 12.257 7.082 10.401 9.627 C 8.545 12.172 6.35 12.274 4.09 12.274 L 4.09 12.273 Z"
            transform="translate(20 27)"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'#0E203E'}
          />
          <g className="Avatar-horns">
            <path
              fill={'#d61fae'}
              stroke={'rgb(0, 0, 0)'}
              strokeWidth="0px"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M 11.645 17.545 C 7.75 13.5 5.818 9.94 7.41 2.752 C 12.509 10.592 16.6 6.893 22.754 9.17 C 24.863 11.106 15.193 20.801 11.645 17.545 Z"
            />
            <path
              fill={'#d61fae'}
              stroke={'rgb(0, 0, 0)'}
              strokeWidth="0px"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M 60.447 3.49 C 56.552 7.535 54.62 11.095 56.212 18.283 C 61.311 10.443 65.402 14.142 71.556 11.865 C 73.665 9.929 63.995 0.234 60.447 3.49 Z"
              transform="matrix(-1, 0, 0, -1, 127.506683, 21.110076)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EvilAvatar;
