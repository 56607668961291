import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  setLocalFlag,
  getLocalFlags,
  setLocalFlags as setFlagsToLocalStorage,
} from 'utils/featureFlagUtils';
import { useQuery } from 'hooks';
import { uniqBy } from 'lodash';
import { SwipeableDrawer } from '@material-ui/core';
import styled from 'styled-components';
import configuration from 'configuration';

import './FlagsEditor.scss';

const Container = styled.div`
  padding: 15px;
`;

const FlagLabel = styled.label`
  font-size: 14px;
  font-family: monospace;
`;

const FlagWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
  margin-bottom: 4px;
`;

type FeatureFlag = {
  type: 'feature';
  name: string;
  value: boolean;
};

interface FlagsEditorProps {
  flags: FeatureFlag[];
  onToggle: (flags: FeatureFlag[]) => void;
}

export default function FlagsEditor({
  flags: serverFlags,
  onToggle,
}: FlagsEditorProps) {
  const { features } = useQuery<{ features?: string }>();

  const collectionName = configuration.firebase.featureFlags.collection;
  const [localFlags, setLocalFlags] = useState<FeatureFlag[]>([]);
  const [expandEditor, setExpandEditor] = useState<boolean>(false);

  const getAllLocalFlagsForDisplay = (baseFlags: FeatureFlag[] = []) => {
    const flags = uniqBy(
      [
        ...(getLocalFlags(collectionName) || []),
        ...baseFlags.map((item) => ({ ...item, value: item.value })),
      ],
      'name'
    );
    return flags;
  };

  useEffect(() => {
    let urlFeatures: FeatureFlag[] = [];
    const featuresToMap = typeof features === 'string' ? [features] : features;
    if (Array.isArray(featuresToMap) && featuresToMap.length) {
      urlFeatures = featuresToMap.map((item) => ({
        name: item.trim(),
        value: true,
        type: 'feature',
      }));
    }

    if (urlFeatures.length) {
      setFlagsToLocalStorage(urlFeatures, collectionName);
      setLocalFlags(urlFeatures);
      onToggle(urlFeatures);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const flags = getAllLocalFlagsForDisplay([...serverFlags]);
    setFlagsToLocalStorage(flags, collectionName);
    setLocalFlags(flags);
    onToggle(flags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalFlag = ({ flag }: { flag: FeatureFlag }) => {
    setLocalFlag(flag, collectionName);
    setLocalFlags(getAllLocalFlagsForDisplay(serverFlags));
    onToggle(getAllLocalFlagsForDisplay(serverFlags));
  };

  const toggleFlag = (flag: ChangeEvent<HTMLInputElement>) => {
    const { name, value, dataset } = flag.target;
    if (dataset.location === 'server') {
      return;
    }
    console.log('value', value);
    const flagObj: FeatureFlag = {
      name,
      value: !(value === 'true'),
      type: 'feature',
    };
    updateLocalFlag({ flag: flagObj });
  };

  const Flag = ({ flag, location, disabled = false }: any) => {
    return (
      <FlagWrap data-testid="flag_editor">
        <input
          onChange={(e) => toggleFlag(e)}
          type="checkbox"
          id={flag.name}
          data-location={location}
          checked={!!flag.value}
          value={flag.value}
          name={flag.name}
          disabled={disabled}
        />
        <FlagLabel htmlFor={flag.name}>{flag.name}</FlagLabel>
      </FlagWrap>
    );
  };

  return serverFlags?.length || localFlags?.length ? (
    <>
      <SwipeableDrawer
        anchor="left"
        open={expandEditor}
        onOpen={() => setExpandEditor(true)}
        onClose={() => setExpandEditor(false)}
        swipeAreaWidth={10}
        ModalProps={{
          keepMounted: true,
        }}
        style={{ overflow: 'visible' }}
      >
        <button
          className={'openFlags ' + (expandEditor && 'open')}
          onClick={() => setExpandEditor(!expandEditor)}
        ></button>
        <Container>
          <h4>Server Feature Flags</h4>
          {!!serverFlags?.length &&
            serverFlags.map((flag: FeatureFlag) => {
              return (
                <Flag
                  location="server"
                  flag={flag}
                  key={`server-flag-${flag.name}`}
                  disabled
                />
              );
            })}

          <h4>Local Feature Flags</h4>
          {!!localFlags?.length &&
            localFlags.map((flag: FeatureFlag) => {
              return (
                <Flag
                  location="local"
                  flag={flag}
                  key={`local-flag-${flag.name}`}
                />
              );
            })}
        </Container>
      </SwipeableDrawer>
    </>
  ) : (
    <div />
  );
}
