import { useAuth } from 'providers/AuthProvider';
import Page from 'components/Page/Page';
import Button from 'components/Buttons/Button';
import String from 'components/String/String';
import { useStrings } from 'hooks';
import './PartnerLoginPage.scss';

const PartnerLoginPage = () => {
  const { hasLoginWithPartner, handleLoginWithPartner } = useAuth();
  const { get } = useStrings();

  const handlePartnerLogin = () => {
    const url = window.location.href;
    if (hasLoginWithPartner(url)) {
      handleLoginWithPartner(url);
    }
  };

  return (
    <Page name="PartnerLoginPage" slug={get('pages.partner.slug')}>
      <div className="container">
        <p>
          <strong>
            <String id="pages.partner.title" />
          </strong>
        </p>
        <p>
          <String id="pages.partner.line1" />
        </p>
        <p>
          <String id="pages.partner.line2" />
        </p>

        <Button
          variant="primary"
          onClick={handlePartnerLogin}
          testId="partner-page-button"
        >
          <String id="pages.partner.button" />
        </Button>
      </div>
    </Page>
  );
};

export default PartnerLoginPage;
