import React from 'react';
import classnames from 'classnames';

import './Avatar.scss';

interface Props {
  onClick?(): void;
  direction?: 'normal' | 'down' | 'right';
  expression?: 'happy' | 'sad';
}

function Avatar({
  onClick,
  direction = 'normal',
  expression = 'happy',
}: Props) {
  if (expression === 'happy') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        onClick={onClick}
        className={classnames('Avatar', `Avatar-${direction}`)}
      >
        <g fill="none" fillRule="evenodd">
          <circle cx="40" cy="40" r="40" fill="#C7F0F5" />
          <g>
            <path d="M0 0H40V26H0z" transform="translate(20 27)" />
            <path
              className="Avatar-face"
              fill="#0E203E"
              d="M20 25.51c-5.671 0-10.357-4.166-11.198-9.6h22.396c-.841 5.434-5.527 9.6-11.198 9.6zm15.91-13.237c-2.26 0-4.092-1.832-4.092-4.091 0-2.26 1.832-4.091 4.091-4.091C38.17 4.09 40 5.92 40 8.18s-1.832 4.092-4.09 4.092zm-31.82 0C1.833 12.273 0 10.44 0 8.182 0 5.922 1.832 4.09 4.09 4.09c2.26 0 4.092 1.83 4.092 4.09S6.35 12.274 4.09 12.274z"
              transform="translate(20 27)"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 56 56"
      onClick={onClick}
      className={classnames('Avatar', `Avatar-${direction}`)}
      data-testid={`avatar-${direction}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="28" cy="28" r="28" fill="#C7F0F5" />
        <g>
          <path d="M0 0H28V18H0z" transform="translate(14 22)" />
          <path
            fill="#0E203E"
            d="M14 10.818c-3.97 0-7.25 2.916-7.839 6.72H21.84c-.59-3.804-3.87-6.72-7.839-6.72zm11.136-2.545c-1.581 0-2.863-1.282-2.863-2.864 0-1.582 1.282-2.864 2.863-2.864C26.718 2.545 28 3.827 28 5.41c0 1.582-1.282 2.864-2.864 2.864zm-22.272 0C1.282 8.273 0 6.99 0 5.409c0-1.582 1.282-2.864 2.864-2.864 1.581 0 2.863 1.282 2.863 2.864 0 1.582-1.282 2.864-2.863 2.864z"
            transform="translate(14 22)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Avatar;
