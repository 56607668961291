import React from 'react';

import { ButtonVariants, StyledButton } from './styled';
import Loader from '../Loader/Loader';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  testId: string;
  variant?: ButtonVariants;
  icon?: string;
  loading?: boolean;
  loaderColor?: string;
  track?: Record<string, string>;
}

function Button({
  variant = 'regular',
  icon,
  children,
  loading,
  loaderColor,
  track,
  testId,
  ...buttonProps
}: ButtonProps) {
  return (
    <StyledButton
      variant={variant}
      type="button"
      track={track}
      testId={testId}
      {...buttonProps}
    >
      {loading && (
        <div className="Button-loader">
          <Loader color={loaderColor} />
        </div>
      )}
      {!loading && children}
    </StyledButton>
  );
}

export default Button;
