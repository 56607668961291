import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getRedirectPath } from 'utils/pageUtils';
import Page from 'components/Page/Page';
import Loader from 'components/Loader/Loader';
import { Centered } from 'components/Layout/Layout';
import { getProductId } from 'utils/urlUtils';
import { assert } from 'utils/assertUtils';
import { useHistory, useProductOptions } from 'hooks';

function ProductLoginPage({ location }: RouteComponentProps) {
  const { push } = useHistory();

  const path = location.pathname;
  const productId = getProductId(path);
  const redirect = getRedirectPath(location.search);

  assert(productId, 'Product ID is not defined');

  const { productOptions } = useProductOptions(productId);

  useEffect(() => {
    if (productOptions) {
      const identities = productOptions?.identities ?? [];
      const connections = identities.map((i) => i.connection).filter(Boolean);
      const code = productOptions?.code;
      const country = productOptions?.location.country;

      push('/login', {
        redirect,
        connections,
        productId,
        code,
        country,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOptions]);

  return (
    <Page name="ProductLoginPage" slug="Login">
      <Centered>
        <Loader />
      </Centered>
    </Page>
  );
}

export default ProductLoginPage;
