import React, { useState } from 'react';

import './Accordion.scss';

interface Props {
  title: string;
  content: string;
}

const Accordion = ({ title, content }: Props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="AccordionForm">
      <div
        className="AccordionForm-title"
        onClick={() => setIsActive(!isActive)}
      >
        {title}{' '}
        <span className="AccordionForm-title-icon">{isActive ? '^' : '>'}</span>
      </div>
      {isActive && <div className="AccordionForm-content">{content}</div>}
    </div>
  );
};

export default Accordion;
