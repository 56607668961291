const TRANSLATIONS: Record<string, Record<string, string>> = {
  nl: {
    'links.contact.email': 'help@inshur.com',
    'pages.error.message': 'Er is iets mis gegaan',
    'pages.error.try_again': 'Probeer het later nog een keer',
    'pages.error.contact_us': 'Contact',
    'pages.not_found.message': 'Pagina niet gevonden',
    'pages.menu.restart_quote': 'Bereken opnieuw',
    'pages.menu.contact_us': 'Contact',
    'pages.menu.restart_confirmation':
      'Weet je het zeker? Je ingevulde gegevens worden niet bewaard...',
    'pages.menu.restart_confirmation.confirm': 'Ja',
    'pages.menu.restart_confirmation.cancel': 'Beëindig',
    'pages.menu.contact.opening_hours': 'Openingstijden',
    'pages.menu.contact.need_help': 'Hulp nodig?',
    'pages.menu.contact.ok': 'OK',
    'pages.maintenance.title': 'Wij zijn even gestopt voor gepland onderhoud',
    'pages.maintenance.message':
      'Onze techneuten werken aan de ‘fine-tuning’ van de INSHUR software om ons platform nog beter te maken.\n\nBinnenkort zijn we weer op weg - probeer het later nog eens.',
    'pages.dropdown.search.text': 'Zoeken',
    'pages.dropdown.search.no_result': 'Geen resultaat gevonden',
  },
  en: {
    'links.contact.email': 'help@inshur.com',
    'pages.error.message': 'Something went wrong',
    'pages.error.try_again': 'Try again',
    'pages.error.contact_us': 'Contact Us',
    'pages.not_found.message': 'Page not found',
    'pages.menu.restart_quote': 'Restart Quote',
    'pages.menu.contact_us': 'Contact Us',
    'pages.menu.restart_confirmation':
      'Are you sure? All progress will be lost...',
    'pages.menu.restart_confirmation.confirm': 'Yes',
    'pages.menu.restart_confirmation.cancel': 'Cancel',
    'pages.menu.contact.opening_hours': 'Opening Hours',
    'pages.menu.contact.need_help': 'Need Help?',
    'pages.menu.contact.ok': 'OK',

    'pages.maintenance.title':
      'We’ve temporarily pulled over for planned maintenance',
    'pages.maintenance.message':
      'Our engineers are fine-tuning the INSHUR engine to help make our platform even better.\n\nWe’ll be back on the road again soon - check back later',
    'pages.dropdown.search.text': 'Search',
    'pages.dropdown.search.no_result': 'No results found',

    'pages.expired_mta.title': 'Sorry, your change timed out.',
    'pages.expired_mta.body':
      'If you still want to make a change to your policy, please give us a call.',
    'pages.expired_mta.goto_dashboard': 'Return to dashboard',
    'pages.expired_mta.contact_us': 'Contact us',
  },
};

export default TRANSLATIONS;
